import React from 'react';
import "../widgets/HeaderWidget.css";
import { useState, useEffect } from "react";
import { ModalAssessmentStarten } from "../widgets/ModalAssessmentStarten.js"
import { HinweisPopup } from "./HinweisPopup.js";
import { FirstLoginNoAssessmentPopup } from "./FirstLoginNoAssessmentPopup.js";
import AuthentificationManager from '../pages/classes/authentificationManager';
import GlossaryWidget from './GlossaryWidget.js';
import DataFetcher from '../pages/classes/databaseManager';


export const HeaderWidget = ({ LogoImg, SpitalName }) => {
  const [contentOpen, setContentOpen] = useState(false);
  const authentificationManager = new AuthentificationManager();
  const databaseManager = new DataFetcher();

  //console.log("getAssessment");

  const handleDisclaimerClick = () => {
    console.log("Disclaimer button clicked");
    // Call toggleContent to change the value of contentOpen
    toggleContent();
  };
  // Function to toggle the contentOpen state
  const toggleContent = () => {
    setContentOpen(!contentOpen);
  };

  return (
    <div className="Header-Formate">
      <div className="Image-Formate">
        {LogoImg && <img src={LogoImg} alt="Logo" draggable="false" />}
      </div>
      <div className="Title-Formate">
        <h1>{authentificationManager.getCookie("spital_Name")}</h1>
      </div>
      <div className="Buttons-Formate">
        {/*<div className="search-bar-container">
            <img className="search-logo" src="/search.svg" alt="Search Logo" draggable="false" />
            <input type="text" className="search-input" placeholder="Suchen" />
        </div>
        <img src= "/round-icons.svg" alt="Bell-Icon" draggable="false" />
        <img src= "/button-icon-light.svg" alt="Question-Icon" draggable="false" />*/}
        {/* {window.location.pathname.includes('uebersicht') ? (
        ) : null} */}

        <ModalAssessmentStarten />
        <HinweisPopup contentOpen={contentOpen} toggleContent={toggleContent} />
        <div key="Disclaimer" onClick={handleDisclaimerClick} style={{ width: "90px", marginRight: "-40px", cursor: "pointer" }}>
          <img className='vertical-navigation-pictures-disclaimer' alt="Disclaimer" src="/button-icon-light.svg" draggable="false" />
        </div>
        <GlossaryWidget />
      </div>
    </div>
  );
};
