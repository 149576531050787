import React, { useState, useEffect } from 'react';
import "../widgets/VerticalNavigationWidgets.css";
import AuthentificationManager from "../pages/classes/authentificationManager";
import { DisclaimerWidget } from "./ModalDisclamer.js";

export const VerticalNavigationBar = ({ verticalNavigationBarList }) => {
    const [pageNames, imageIcons, clickLinkToPage, currentPage, userName, userPicture] = verticalNavigationBarList;
    const [logoutButtonVisible, setLogoutButtonVisible] = useState(false);
    const [profileImgId, setProfileImgId] = useState('');
    const [profileFirstName, setProfileFirstName] = useState('');
    const [profileLastName, setProfileLastName] = useState('');
    const [contentOpen, setContentOpen] = useState(false);
    const authentificationManager = new AuthentificationManager();


    const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);
    const [disclaimerText, setDisclaimerText] = useState("");
    const [disclaimerTitle, setDisclaimerTitle] = useState("");
    const [disclaimerLeft, setDisclaimerLeft] = useState("");
    const [disclaimerRight, setDisclaimerRight] = useState("");
    const [disclaimerMiddle, setDisclaimerMiddle] = useState("");


    // Fetching data from the backend has to be asynchronous
    const onPageLoad = async () => {
        const cok_imgId = authentificationManager.getCookie('img_id');
        const cok_firstName = authentificationManager.getCookie('first_name');
        const cok_lastName = authentificationManager.getCookie('last_name');

        if (cok_imgId.includes("null")) {
            setProfileImgId(`${authentificationManager.getbaseURL()}/assets/56fee9d6-254d-418a-8cf8-9e60a8d94be7`);
        } else {
            setProfileImgId(authentificationManager.getbaseURL() + "/assets/" + cok_imgId);
        }
        setProfileFirstName(cok_firstName);
        setProfileLastName(cok_lastName);
    };

    // Hook to run the function on page load. Executed only one time
    useEffect(() => {
        onPageLoad();
    }, []);


    const renderNavItem = (name, icon, onClick, buttonClass) => {
        const isActive = name === currentPage;

        const handleClick = isActive ? undefined : onClick;

        return handleClick ? (
            <button key={name} className={buttonClass} onClick={handleClick}>
                <>
                    <img className='vertical-navigation-pictures' alt={name} src={icon} draggable="false" />
                    <div className="vertical-navigation-labels">{name}</div>
                </>
            </button>
        ) : (
            <div key={name} className={buttonClass}>
                <>
                    <img className='vertical-navigation-pictures1' alt={name} src={icon} draggable="false" />
                    <div className="vertical-navigation-labels">{name}</div>
                </>
            </div>
        );
    };



    let onClickIndex = 0;
    // Render navigation items except for the last two special ones (Settings and Kontakt)
    const navigationItems = pageNames.slice(0, -2).map((name, index) => {
        // Determine the appropriate button class
        const buttonClassSuffix = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth"][index];
        const buttonClass = `vertical-navigation-${buttonClassSuffix}-button`;

        let onClickHandler;
        if (name === currentPage) {
            // If the current page is being rendered, do not attach an onClick event
            onClickHandler = undefined;
        } else {
            // Use the next onClick handler from the array, if available
            onClickHandler = clickLinkToPage[onClickIndex];
            onClickIndex++; // Only increment if we've used the handler
        }

        return renderNavItem(name, imageIcons[index], onClickHandler, buttonClass);
    });






    const settingsIcon = imageIcons[imageIcons.length - 2];
    const contactIcon = imageIcons[imageIcons.length - 1];

    const handleLogoutClick = () => {
        setIsDisclaimerVisible(true);
        setDisclaimerTitle("Logout:");
        setDisclaimerText("Sind Sie sicher, dass Sie sich Ausloggen möchten?");
        setDisclaimerLeft("Nein");
        setDisclaimerRight("Ja");
    };


    const handleDisagree = () => {
        setIsDisclaimerVisible(false);
        setDisclaimerLeft("");
        setDisclaimerRight("");
        setDisclaimerMiddle("");
    }

    const handleAgree = () => {
        setLogoutButtonVisible(false);
        setIsDisclaimerVisible(false);

        // Add your logout logic here
        authentificationManager.logout();
        setLogoutButtonVisible(false);
    }

    /*
    const disclaimerNavItem = (
        <div key="Disclaimer" className="disclaimer-container" onClick={handleDisclaimerClick}>
            <img className='vertical-navigation-pictures-disclaimer' alt="Disclaimer" src="/button-icon-light2.svg" draggable="false" />
            <div className="vertical-navigation-labels">Disclaimer</div>
        </div>
    );

    navigationItems.splice(navigationItems.length, 0, disclaimerNavItem);
        */



    navigationItems.push(
        <>
            <div key="UserProfile" className="profile-container" onMouseEnter={() => setLogoutButtonVisible(true)} onMouseLeave={() => setLogoutButtonVisible(false)}>

                {isDisclaimerVisible &&
                    <DisclaimerWidget
                        title={disclaimerTitle}
                        text={disclaimerText}
                        disagree={disclaimerLeft}
                        buttonInTheMiddle={disclaimerMiddle}
                        agree={disclaimerRight}
                        buttonInTheMiddleOnClickMethode={handleDisagree}
                        disagreeOnClickMethode={handleDisagree}
                        agreeOnClickMethode={handleAgree}
                    />}

                <div className="profile-image-container">
                    <img className="profile-image" alt="User profile" src={profileImgId} draggable="false" />
                    {logoutButtonVisible && (
                        <img src='/Logout_black.svg' className="vertical-navigation-logout-button" onClick={handleLogoutClick} draggable="false" />
                    )}

                </div>
                <div className="profile-name-container">
                    <div className="profile-text">
                        <p>{profileFirstName}</p>
                        <p>{profileLastName}</p>
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <div className="vertical-navigation">
            {navigationItems}
        </div>
    );
};
