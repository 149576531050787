// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allContent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 80px; /* Fixed height of 80px */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1; /* Ensures header is on top */
}

.body {
  display: flex;
  height: 100%;
  width: 100%;
  margin-top: 80px; /* Ensure content starts below the header */
}

.Main-Content {
  display: flex;
  left: 180px;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,cAAc,EAAE,yBAAyB;EACzC,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,UAAU,EAAE,6BAA6B;AAC3C;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;EACX,gBAAgB,EAAE,2CAA2C;AAC/D;;AAEA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".allContent {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100vh;\r\n}\r\n\r\n.header {\r\n  flex: 0 0 80px; /* Fixed height of 80px */\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  z-index: 1; /* Ensures header is on top */\r\n}\r\n\r\n.body {\r\n  display: flex;\r\n  height: 100%;\r\n  width: 100%;\r\n  margin-top: 80px; /* Ensure content starts below the header */\r\n}\r\n\r\n.Main-Content {\r\n  display: flex;\r\n  left: 180px;\r\n  box-sizing: border-box;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
