import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login";
import BewertungAuswertung from "./pages/BewertungAuswertung";
import BewertungGruppenAuswertung from "./pages/BewertungGruppenAuswertung";
import BewertungAllgemeineInformati from "./pages/BewertungAllgemeineInformati";
import BewertungFrageboegen from "./pages/BewertungFrageboegen";
import PortfolioTabelle from "./pages/PortfolioTabelle";
import StrategieZieldimensionen from "./pages/StrategieZieldimensionen";
import StrategieGesamtunternehmen from "./pages/StrategieGesamtunternehmen";
import Uebersicht from "./pages/Uebersicht";
import LoginPasswortVergessen from "./pages/LoginPasswortVergessen";
import LoginRegistrierung from "./pages/LoginRegistrierung";
import MainPage from "./pages/MainPage";

import AuthentificationManager from "./pages/classes/authentificationManager";

/*
import StrategieGesamtunternehmen_old from "./pages/old/StrategieGesamtunternehmen_old";
import Uebersicht_old from "./pages/old/Uebersicht_old";
import StrategieZieldimensionen_old from "./pages/old/StrategieZieldimensionen_old";
import BewertungAuswertung_old from "./pages/old/BewertungAuswertung_old";
import BewertungAllgemeineInformati_old from "./pages/old/BewertungAllgemeineInformati_old";
import BewertungFrageboegen_old from "./pages/old/BewertungFrageboegen_old";
*/

function App() {
  const authentificationManager = new AuthentificationManager();

  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Your last function logic goes here
      console.log('Performing last function before unload');
      
      // You can also use this to display a confirmation message
      // event.returnValue = ''; // Standard for most browsers
      return ''; // For some older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/34-bewertung-gruppenassessment":
        title = "";
        metaDescription = "";
        break;
      case "/33-bewertung-assessment":
        title = "";
        metaDescription = "";
        break;
      case "/31-bewertung-allgemeine-informationen":
        title = "";
        metaDescription = "";
        break;
      case "/41-portfolio-tabelle":
        title = "";
        metaDescription = "";
        break;
      case "/32-bewertung-frageboegen":
        title = "";
        metaDescription = "";
        break;
      case "/22-strategie-zieldimensionen":
        title = "";
        metaDescription = "";
        break;
      case "/21-strategie-gesamtunternehmen":
        title = "";
        metaDescription = "";
        break;
      case "/10-uebersicht":
        title = "";
        metaDescription = "";
        break;
      case "/02-login-passwort-vergseesn":
        title = "";
        metaDescription = "";
        break;
      case "/01-login-registrierung":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />}/>
      <Route path="/main" element={<MainPage />}/>
      <Route path="/34-bewertung-gruppenassessment" element={<BewertungGruppenAuswertung />}/>
      <Route path="/33-bewertung-assessment" element={<BewertungAuswertung />}/>
      <Route path="/31-bewertung-allgemeine-informationen" element={<BewertungAllgemeineInformati />}/>
      <Route path="/32-bewertung-frageboegen" element={<BewertungFrageboegen />}/>
      <Route path="/41-portfolio-tabelle" element={<PortfolioTabelle />}/>
      <Route path="/22-strategie-zieldimensionen" element={<StrategieZieldimensionen />}/>
      <Route path="/21-strategie-gesamtunternehmen" element={<StrategieGesamtunternehmen />}/>
      <Route path="/10-uebersicht" element={<Uebersicht />} />
      <Route path="/02-login-passwort-vergseesn" element={<LoginPasswortVergessen />}/>
      <Route path="/01-login-registrierung" element={<LoginRegistrierung />}/>
      {/*
      <Route path="/33-bewertung-auswertung_old" element={<BewertungAuswertung_old/>}/>
      <Route path="/31-bewertung-allgemeine-informationen_old" element={<BewertungAllgemeineInformati_old/>}/>
      <Route path="/32-bewertung-frageboegen_old" element={<BewertungFrageboegen_old/>}/>
      <Route path="/22-strategie-zieldimensionen_old" element={<StrategieZieldimensionen_old/>}/>
      <Route path="/21-strategie-gesamtunternehmen_old" element={<StrategieGesamtunternehmen_old/>}/>
      <Route path="/10-uebersicht_old" element={<Uebersicht_old/>}/>
      */}
    </Routes>
  );
}
export default App;
