import { TreeGraph } from "../widgets/BewertungFrageboegenWidgets";
import React, { useState, useEffect } from "react";
import DataFetcher from "./../pages/classes/databaseManager";
import AuthentificationManager from "../pages/classes/authentificationManager";

import "../widgets/BewertungFrageboegenWidgets.css";
import "../pages/BewertungFrageboegen.css";

const BewertungFrageboegenMarkup = ({ onItemXLClick, onItemXL1Click, onItemXL2Click, linkToAuswertung,
  linkToAllgemeineAuswertung, linkToGruppenAuswertung, overlayContentLink, verticalNavigationBarList }) => {

  const [valueDate, setValueDate] = useState("1");
  const [assessmentDates, setAssessmentDates] = useState([]);
  const [treeGraphVisibility, setTreeGraphVisibility] = useState(false);

  const [treeGraphKey, setTreeGraphKey] = useState(0);
  const [loading, setLoading] = useState(true);

  const dataFetcher = new DataFetcher();
  const authentificationManager = new AuthentificationManager();

  function handleSelectDate(event) {
    setValueDate(event.target.value);
  }

  const onPageLoad = async () => {

    const user_id = dataFetcher.getCookie('user_id')
    const user_spital = dataFetcher.getCookie('spital_Name');
    // Fetch Assessments
    const resp_einzelAssessments = await dataFetcher.fetch_data_by_filter_when_equalto("Einzelassessment", "id,gruppenAssessmentName,bezugsdatum", "user_id", user_id);
    const gassessments = await dataFetcher.fetch_data_by_filter_when_equalto("Gruppenassessment", "id,name,bezugsdatum", "spital", user_spital);
  
    if (resp_einzelAssessments.data.length == 0) {
      console.log('No Assessment');
    } else {
      // Get and set selector data
      const sortedData = resp_einzelAssessments.data.sort((a, b) => new Date(b.bezugsdatum) - new Date(a.bezugsdatum));

      // Create the selectorYearList directly
      const selectorList = sortedData.map((item, index) => {
        const matchingGAssessment = gassessments.data.find(gItem => gItem.bezugsdatum === item.gruppenAssessmentName);
        const labelPrefix = matchingGAssessment ? `GA - ${matchingGAssessment.name}` : 'EA';
        return {
          label: `${labelPrefix} (${item.bezugsdatum})`,
          value: index + 1
        };
      });

      setTreeGraphVisibility(true);
      setAssessmentDates(selectorList);
    }

  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loading && !treeGraphVisibility) {
      setTreeGraphVisibility(false);
    }
  }, [loading, treeGraphVisibility]);

  useEffect(() => {
    onPageLoad();
  }, [])

  const fetchData = async () => {
    setTreeGraphKey(treeGraphKey + 1);
  };

  useEffect(() => {
    fetchData();
  }, [valueDate]);

  return (
    <div className="bewertung-frageboegen-screen">

      <div className="selector-container">
        <div className="selector-item-not-selected" onClick={linkToAllgemeineAuswertung}>
          Allgemeine Informationen
        </div>
        <div className="selector-divider" />
        <div className="selector-item-selected">
          Bewertung
        </div>
        <div className="selector-divider" />
        <div className="selector-item-not-selected" onClick={linkToAuswertung}>
          Mein Assessment
        </div>
        <div className="selector-divider" />
        <div className="selector-item-not-selected" onClick={linkToGruppenAuswertung}>
          Gruppen Assessment
        </div>
      </div>
      {loading ? (
        <div className="fr-singleContent-container-noAssessment">Loading ...</div>
      ) : (treeGraphVisibility ? (
        <div className="fr-singleContent-container">
          <div className="ba-singleContent-container-selector-doubleSelector">
            <div style={{ fontSize: "12px", color: "var(--dark-theme-gray-4)", fontWeight: 600, lineHeight: "20px" }}>
                EA: Einzel Assessment <br />
                GA: Gruppen Assessment
            </div>
            <select className="ba-singleContent-container-selector" onChange={handleSelectDate}>
              {assessmentDates.map(option => (
                <option className="ba-singleContent-container-selector-item" value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="treegraph-content" key={treeGraphKey}>
            <TreeGraph selectedAss={valueDate} />
          </div>
        </div>
       ) : (
      <div className="fr-singleContent-container-noAssessment">Es gibt noch keine Assessment Daten!</div>
      )
    )}
    </div>
  );
};

export default BewertungFrageboegenMarkup;
