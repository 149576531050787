import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PortfolioTabelle.css";
import PortfolioTabelleMarkup from "../html_jsx/PortfolioTabelle";
import { VerticalNavigationBar } from "../widgets/VerticalNavigationWidgets";
import { HeaderWidget } from "../widgets/HeaderWidget";
import AuthentificationManager from "./classes/authentificationManager";
import { EffizienzgewinnChart, UmsetzungsaufwandChart } from "../widgets/UebersichtWidgets"
import { width } from "@mui/system";

const PortfolioTabelle = () => {
  const navigate = useNavigate();

  const pageNames = ["Übersicht", "Zielsetzung", "Bewertung", "Nutzen & Aufwand", "Settings", "Kontakt"]
  const imageIcons = ["/uebersichtIcon.svg", "/strategieIcon.svg", "/bewertungIcon.svg", "/selected-portfolioIcon.svg", "/settingsIcon.svg", "/contactIcon.svg"]

  const authentificationManager = new AuthentificationManager();
  // Check for the presence of "auth_token" cookie when the component is mounted
  useEffect(() => {
    authentificationManager.checkAndRefreshToken();
  }, []);


  const onItemXLClick = useCallback(() => {
    navigate("/32-bewertung-frageboegen");
  }, [navigate]);

  const onItemXL1Click = useCallback(() => {
    navigate("/21-strategie-gesamtunternehmen");
  }, [navigate]);

  const onItemXL2Click = useCallback(() => {
    navigate("/10-uebersicht");
  }, [navigate]);

  //Same order as the lists above
  const clickLinkToPage = [onItemXL2Click, onItemXL1Click, onItemXLClick]
  const currentPage = "Nutzen & Aufwand"
  const userName = "Ueli Mueller"
  const userPicture = "/userpic@2x.png"

  const verticalNavigationBarList = [pageNames, imageIcons, clickLinkToPage, currentPage, userName, userPicture]

  return (
    <div className="allcontent">
      <div className="mainContent">
        <div className="pageTitle">
          <b className="pageTitle-title">Nutzen- und Aufwandsentwicklung</b>
        </div>
        <div className="portfolio-screen">
          <div className="singleContent-container-NA">
            
            <div className="portfolio-content">
              <EffizienzgewinnChart />
              <UmsetzungsaufwandChart />
            </div>
            <div style={{ fontWeight: 700, fontSize: "1.5em", marginTop: 80, marginBottom: 20}}>Erläuterungen zu Nutzen- und Aufwandsentwicklung</div>
            <div>Basierend auf publizierten Studien<sub style={{fontSize: "9px", fontWeight: 700 }}> [1] </sub>, Umfragen sowie Erfahrungswerten aus unserer Arbeit im Gesundheitswesen, gehen wir davon aus, dass eine umfassende digitale Transformation erhebliche Vorteile bringen kann. Der konkrete Nutzen<sub style={{fontSize: "9px", fontWeight: 700 }}> [2] </sub> für ein Spital hängt stark von verschiedenen Faktoren ab, darunter die Ausgangslage des Spitals, der Grad der Digitalisierung in vor- und nachsorgenden Bereichen sowie externe Einflüsse wie die Einführung des elektronischen Patientendossiers oder integrierter Gesundheitssystemen. Diese exemplarische Darstellung soll die potenziellen Nutzen und Aufwände veranschaulichen. Es ist wichtig zu beachten, dass für konkrete Investitionsvorhaben ein eigener Business Case zu rechnen ist.</div>
            <div style={{ fontSize: "12px", fontWeight: "normal" }}>
              <div style={{"borderTop": "1px solid #ccc", "marginTop": "10px", "marginBottom": "0px", maxWidth: "420px"}}></div>
              <span><sup style={{fontSize: "9px", fontWeight: 700 }}>1</sup>Quellen: </span>
              <span><a href="https://www.pwc.ch/de/publications/2023/spitalstudie.pdf" target="_blank">Studie PwC</a></span>
              <span> | </span>
              <span><a href="https://www.mckinsey.com/ch/~/media/mckinsey/locations/europe%20and%20middle%20east/switzerland/our%20insights/digitization%20in%20healthcare/digitalisierung%20im%20gesundheitswesen%20%20die%2082mrdchance%20fr%20die%20schweiz%20de.pdf" target="_blank">McKinsey</a></span>
            </div>
            <div style={{ fontSize: "12px", fontWeight: "normal" }}>
              <span><sup style={{fontSize: "9px", fontWeight: 700 }}>2</sup>Nutzen, Gesamtsystem sind im Glossar oben rechts beschrieben </span><img src='/glossar_icon.svg' style={{position: "absolut", marginTop: "-20px", borderRadius: "5px" ,background: "lightgray"}} draggable={false}/>
            </div>
          </div>
        </div>

      </div>
      <div className="navigationbar">
        <VerticalNavigationBar verticalNavigationBarList={verticalNavigationBarList} />
      </div>
      <div className="headerbar">
        <HeaderWidget LogoImg="/LogoDRI.svg" SpitalName="Spital Zürich" />
      </div>
    </div>
  );
};

export default PortfolioTabelle;
