// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-tabelle-page-container {
    display: flex;
    justify-content: flex-start; /* Align to the start of the container, which is left by default */
    align-items: center;
    height: 200vh;
    width: 100%;
    padding-top: 1%;
    padding-left: 6%; /* Add padding to the left to move the table inward */
    font-family: 'Poppins', sans-serif; /* Apply the Poppins font */
    font-size: 12px;
  }
  
  /* Apply a bold font weight to all header cells in the table */
  .rsuite-table .rs-table-header-cell-wrapper {
    font-weight: bold;
  }

  .Tabelle-Container{
    margin-top: 50px;
    width: 80%;
    height: 60%;
    overflow-y: scroll;
  }`, "",{"version":3,"sources":["webpack://./src/widgets/PortfolioTabelleWidgets.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B,EAAE,kEAAkE;IAC/F,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,eAAe;IACf,gBAAgB,EAAE,qDAAqD;IACvE,kCAAkC,EAAE,2BAA2B;IAC/D,eAAe;EACjB;;EAEA,8DAA8D;EAC9D;IACE,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,WAAW;IACX,kBAAkB;EACpB","sourcesContent":[".portfolio-tabelle-page-container {\r\n    display: flex;\r\n    justify-content: flex-start; /* Align to the start of the container, which is left by default */\r\n    align-items: center;\r\n    height: 200vh;\r\n    width: 100%;\r\n    padding-top: 1%;\r\n    padding-left: 6%; /* Add padding to the left to move the table inward */\r\n    font-family: 'Poppins', sans-serif; /* Apply the Poppins font */\r\n    font-size: 12px;\r\n  }\r\n  \r\n  /* Apply a bold font weight to all header cells in the table */\r\n  .rsuite-table .rs-table-header-cell-wrapper {\r\n    font-weight: bold;\r\n  }\r\n\r\n  .Tabelle-Container{\r\n    margin-top: 50px;\r\n    width: 80%;\r\n    height: 60%;\r\n    overflow-y: scroll;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
