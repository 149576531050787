import React, { useState, useEffect } from 'react';
import "./HinweisPopup.css"


export const HinweisPopup = ({ contentOpen, toggleContent }) => {

  return (
    <>
      {contentOpen && <div className='hinweis-background'>
        <div className='hinweis-content-container'>
          <img src='/closeButton.svg' className="hinweis-toggle-close-button" onClick={() => toggleContent()} draggable={false} />
            <div className="hinweis-title">Anleitung für den digitalen Reifegrad</div>
          <div className='hinweis-inner-container'>
            <div className="hinweis-subtitle">Ausgangslage</div>
            <div className="hinweis-text">In der sich stetig wandelnden Landschaft des Gesundheitswesens ist die digitale Transformation für Schweizer Unternehmen unerlässlich geworden. Um den Unternehmen Orientierung und Unterstützung zu bieten, haben der VZK und PwC das Assessment-Tool "Digitaler Reifegrad" entwickelt. Dieses Tool berücksichtigt den Grad der Digitalisierung aus der Gesamtsicht des Unternehmens, wohl wissend, dass die digitale Reife von einzelnen Bereichen oder Kliniken innerhalb desselben Unternehmens stark variieren kann.</div>
            <div className="hinweis-subtitle">Zweck des Tools:</div>
            <div className="hinweis-text">Dieses Tool dient Schweizer Unternehmen dazu, ihren aktuellen Stand in der digitalen Reife im Vergleich zu anderen Leistungserbringern zu ermitteln und fungiert als strategisches Instrument zur besseren Planung und Priorisierung von Investitionen in die digitale Transformation.</div>
            <div className="hinweis-subtitle">Schritt-für-Schritt-Anleitung:</div>

            <ol>
              <li>Vorbereitung:</li>
              <ul>
                <li>Stellen Sie sicher, dass Sie Zugang zum Assessment-Tool haben. Sie können den Zugang über die Adresse info@vzk.ch anfragen.</li>
                <li>Sammeln Sie relevante Informationen aus verschiedenen Unternehmensbereichen Ihres Unternehmens, um die Fragen präzise beantworten zu können oder nutzen Sie mit den Gruppen-Assessments die Möglichkeit, durch verschiedene Stakeholder den Reifegrad des eigenen Unternehmens zu bewerten. </li>
              </ul>
              <li>Zielfestlegung:</li>
              <ul>
                <li>Bestimmen Sie auf Basis Ihrer Unternehmensstrategie einen Zielwert für die digitale Reife Ihres Unternehmens.</li>
                <li>Sie können den Zielwert für das gesamte Unternehmenoder für die einzelnen Dimensionen vornehmen.</li>
                <li>Die Zielfestlegung wird nach der Durchführung eines Assessments für das Unternehmenfür die Kosten und Nutzenbewertung herangezogen.</li>
              </ul>
              <li>Durchführung des Assessments:</li>
              <ul>
                <li>Starten Sie das Assessment. Sie können entweder ein Einzel-Assessment als verantwortliche Stelle für die Bewertung der digitalen Reife starten oder ein Gruppen-Assessment zur internen Vorbereitung eines Assessments, welches in den Benchmark einfliessen soll. Bei Gruppen-Assessments wird nur die Bewertung der verantwortlichen Stelle für den digitalen Reifegrad in das Benchmark einfliessen, d.h. die Gruppen darf sich für eine finales Assessment entscheiden, welche über die verantwortliche Stelle / Person eingepflegt wird. </li>
                <li>Insgesamt umfasst das Assessment 35 Fragen. Diese Fragen decken verschiedene Aspekte und Management Dimensionen Ihres Unternehmens ab.</li>
                <li>Beantworten Sie jede Frage ehrlich, basierend auf dem aktuellen Stand Ihrer Einrichtung bzw. für den spezifischen Zeitpunkt, für den Sie das Assessment durchführen möchten.</li>
                <li>Die Fragen sind so gestaltet, dass sie die digitale Reife Ihres Unternehmens auf einer Skala von Level 1 (geringe digitale Reife) bis Level 5 (sehr hohe digitale Reife) bewerten.</li>
              </ul>
              <li>Auswertung und Planung:</li>
              <ul>
                <li>Nach Abschluss des Assessments erhalten Sie eine Auswertung, die den digitalen Reifegrad Ihres Unternehmens angibt.</li>
                <li>Sie können sich mit den vorhanden Assessment anderer Unternehmen vergleichen und pro Dimension festhalten, wie sie im Vergleich stehen.</li>
                <li>Der Vergleich dient ihnen als Anhaltspunkt, wo andere Unternehmen im Schnitt auf Basis ihrer subjektiven Bewertung stehen.</li>
                <li>Relevant für Sie wird der Vergleich zu ihren Zielwerten sein und dem erhofften Kosten-Nutzen-Verhältnis ihrer digitalen Transformation.</li>
              </ul>
              <li>Strategische Umsetzung:</li>
              <ul>
                <li>Aus der Ist-Bewertung, ihren Zielen und dem Benchmark entwickeln Sie einen Aktionsplan, um die identifizierten Handlungsfelder anzugehen.</li>
                <li>Priorisieren Sie die Maßnahmen basierend auf Ihrem Zielwert der digitalen Reife und den verfügbaren Ressourcen.</li>
              </ul>
            </ol>

            <div className="hinweis-subtitle">Disclaimer und Hinweise zu Einschränkungen des Tools:</div>
            <div className="hinweis-text"><p>Bitte beachten Sie, dass das Ergebnis des Assessments massgeblich von der subjektiven Einschätzung der beantwortenden Personen abhängt und auch abhängen soll. Aufgrund der begrenzten Anzahl von Fragen können nicht alle Unternehmensbereiche oder Aspekte der digitalen Transformation detailliert erfasst werden. Dies sollte sowohl beim Ausfüllen des Fragebogens als auch bei der Interpretation der Ergebnisse berücksichtigt werden.</p>
              <p>Die Auswahl des externen Benchmarks und die Festlegung des Durchschnitts basieren auf er Teilnahme anderer Leistungserbringer, was bedeutet, dass die Vergleichsgrundlage variieren kann. Die im Assessment vorgeschlagenen Handlungsfelder und Kosten-Nutzen der Investitionen basieren auf Erfahrungswerten und sollten als Richtwerte verstanden werden. Die spezifische Umsetzung und Kosten-Nutzen-Rechnung kann je nach den individuellen Gegebenheiten des Unternehmens variieren und sollte detailliert geprüft werden.</p>
              <p>Die vorgeschlagenen Handlungsfelder und der damit verbundene Investitionsbedarf sowie die Nutzungsabschätzung dienen lediglich als Orientierungshilfe das Tool soll als Ausgangspunkt dienen, erfordert jedoch eine tiefergehende Analyse und Anpassung an die spezifischen Gegebenheiten und Bedürfnisse Ihres Unternehmens.</p>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};