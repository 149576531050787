/**
 * Parses an identifier string into its individual components.
 *
 * @param {string} identifier - The identifier string to parse.
 * @returns {object} - An object containing the parsed components.
 *
 * Example:
 * 
 * const identifier = "ID001.DI001.CA001.LV005";
 * const parsedResult = parseIdentifier(identifier);
 * console.log("Parsed Identifier:", parsedResult);
 * 
 * Parsed Identifier: {
 *   Identifikation: "001",
 *   Dimension: "001",
 *   Kategorie: "001",
 *   Level: "005"
 * }
 */
const parseIdentifier = (identifier) => {
  const parts = identifier.split('.');
  return {
    Identifikation: parts[0].replace('ID', ''),
    Dimension: parts[1].replace('DI', ''),
    Kategorie: parts[2].replace('CA', ''),
    Level: parts[3].replace('LV', '')
  };
};


/**
 * Calculates the mean values of "Level" for each unique "Dimension" identifier in a list of data objects.
 * 
 * @param {array} dataList - An array of data objects with a "Dimension" and "Level" property.
 * @returns {object} - An object where each key is a unique "Dimension" identifier and the value is the mean "Level" value for that identifier.
 * 
 * Example:
 * 
 * // Calculating mean values by Dimension (Extended Output)
 * const data = [
 *   { Identifikation: "001", Dimension: "001", Kategorie: "001", Level: "5.5" },
 *   { Identifikation: "002", Dimension: "001", Kategorie: "002", Level: "7.3" },
 *   { Identifikation: "003", Dimension: "002", Kategorie: "001", Level: "10.2" },
 *   { Identifikation: "004", Dimension: "002", Kategorie: "002", Level: "8.7" },
 *   // Add more data objects as needed
 * ];
 * 
 * const meanValuesByDimension = calculateMeanByDimension(data);
 * console.log("Mean Values by Dimension:", meanValuesByDimension);
 * {
 *   "001": {
 *     "MeanLevel": 6.4
 *   },
 *   "002": {
 *     "MeanLevel": 9.45
 *   }
 *   // Other Dimension mean values...
 * }
 */
const calculateMeanByDimension = (dataList) => {
  const dimensionMap = {}; // Object to store mean values for each Dimension

  // Initialize mean values for each Dimension
  for (const item of dataList) {
    const dimension = item.Dimension;
    if (!(dimension in dimensionMap)) {
      dimensionMap[dimension] = {
        MeanLevel: 0,
        Count: 0,
      };
    }

    // Parse the "Level" value with leading zeros using parseFloat
    const levelValue = parseFloat(item.Level);

    if (!isNaN(levelValue)) {
      dimensionMap[dimension].MeanLevel += levelValue;
      dimensionMap[dimension].Count++;
    }
  }

  // Calculate the mean values for each Dimension
  for (const dimension in dimensionMap) {
    if (dimensionMap.hasOwnProperty(dimension)) {
      const meanLevel = dimensionMap[dimension].MeanLevel / dimensionMap[dimension].Count;
      dimensionMap[dimension].MeanLevel = meanLevel;
    }
  }

  return dimensionMap;
};

export { parseIdentifier, calculateMeanByDimension };




