import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useState } from "react"
import AuthentificationManager from "./classes/authentificationManager";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import "./LoginRegistrierung.css";

const LoginRegistrierung = () => {
  const navigate = useNavigate();
  const authentificationManager = new AuthentificationManager();

  const [in_user, set_in_user] = useState('');
  const [in_pw, set_in_pw] = useState('');
  const [in_pw_again, set_in_pw_again] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const onButtonFilledMClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onButtonBGClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="logincontainer">
      <ToastContainer />
      <img className="backimage" alt="" src="/Background.svg" />
      <div>
        <div className="logo-circle-blurred">
          <img alt="" src="/logo-circle-blurred.svg" />
          <img className="logo-1-icon" alt="" src="/logo-1.svg" />
        </div>
        <img className="logoclaim-1-icon" alt="" src="/logoclaim-1.svg" />


        {/* CONTENT START */}
        <div className="Login-Parent">
          <b className="Login-Title">Registrierung</b>
          <div className="Login-div">
            <img className="Login-icon" alt="" src="/user.svg" />
            <input className="Login-input" placeholder="Email" type="email" name="name_in" value={in_user} onChange={(e) => set_in_user(e.target.value)}/>
          </div>
          <div className="Password-Container">
            <div className="Login-div">
              <img className="Login-icon" alt="" src="/lock.svg" />
              <input className="Login-input" placeholder="Passwort" type="password" name="pw_in" value={in_pw} onChange={(e) => set_in_pw(e.target.value)}/>
            </div>
          </div>  
          <div className="Password-Container">
            <div className="Login-div">
              <img className="Login-icon" alt="" src="/lock.svg" />
              <input className="Login-input" placeholder="Passwort wiederholen" type="password" name="in_pw_again" value={in_pw_again} onChange={(e) => set_in_pw_again(e.target.value)}/>
            </div>
          </div>        
          <div className="Login-Button-formate">
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <button className="Login-Button" onClick={onButtonFilledMClick}>Registrieren</button>
          </div>
        </div>
        {/* CONTENT END */}

        <img className="button-icon-light" alt="" src="/button-icon-light.svg" />
        <img className="button-icon-light1" alt="" src="/button-icon-light2.svg" />
        <div className="logo">
          <img className="icon" alt="" src="/icon4.svg" />
          <b className="digital-reifegrad">Digitaler Reifegrad</b>
        </div>
      </div>
      <div className="pwc_container">
        Mit Unterstützung von
        <img className="pwc-img" alt="" src="/pwc_logo.png" draggable="false" />
      </div>
    </div>
  );
};

export default LoginRegistrierung;
