import React, { useState, useEffect } from "react";
import {
    GesamtauswertungTitleHeader, GesamtauswertungBarChartLegend,
    GesamtauswertungBarChart, GesamtauswertungRadialChartContainer,
    UserProfileListItem, GesamtauswertungRadarChart, UserSelectList
} from "../widgets/BewertungGruppenAuswertungWidgets"

import { RadarChart1 } from "../widgets/UebersichtWidgets"

import DataFetcher from "../pages/classes/databaseManager";
import AuthentificationManager from "../pages/classes/authentificationManager";
import GoalManager from "../pages/classes/goalManager";
import { DisclaimerGAWidget, DisclaimerWidget } from "../widgets/ModalDisclamer.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from 'react-toastify'; 

import 'rsuite-table/dist/css/rsuite-table.css';
import "../widgets/BewertungGruppenAuswertungWidgets.css";
import "../pages/BewertungGruppenAuswertung.css";



const BewertungGruppenAuswertungMarkup = ({ linkToFragebogen, linkToAllgemeine, linkToAuswertung, userdata, gaName, benchmarkAccess }) => {
    // Initialize DataFetcher and AuthentificationManager instances
    const dataFetcher = new DataFetcher();
    const authentificationManager = new AuthentificationManager();
    const goalManager = new GoalManager();


    // Initialize state variables
    const [isChecked, setIsChecked] = useState(true); // Initialize to true
    const [gaTitle, setGaTitle] = useState("");
    const [filteredExampleUsers, setFilteredExampleUsers] = useState(userdata); // Initially, all users are shown
    const [selectedUserIds, setSelectedUserIds] = useState(userdata.map(user => user.id));
    const [selectedForRadarChart, setSelectedForRadarChart] = useState([]);

    const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);
    const [firstHeader, setfirstHeader] = useState("");
    const [firstText, setfirstText] = useState("");
    const [secondHeader, setsecondHeader] = useState("");
    const [secondText, setsecondText] = useState([]);

    const [disclaimerTitle, setDisclaimerTitle] = useState("");
    const [disclaimerLeft, setDisclaimerLeft] = useState("");
    const [disclaimerRight, setDisclaimerRight] = useState("");

    const [goalValue, setGoalValue] = useState(1);
    const [onegoalValue, setOneGoalValue] = useState(1);
    const [twogoalValue, setTwoGoalValue] = useState(1);
    const [threegoalValue, setThreeGoalValue] = useState(1);
    const [fourgoalValue, setFourGoalValue] = useState(1);
    const [fivegoalValue, setFiveGoalValue] = useState(1);



    /**
     * Handle user selection change.
     * @param {string} userId - The unique identifier of the user.
     * @param {boolean} isSelected - Indicates whether the user is selected.
     */
    const handleUserSelectionChange = (userId, isSelected) => {
        setSelectedUserIds(currentSelectedUserIds => {
            if (isSelected) {
                return [...new Set([...currentSelectedUserIds, userId])];
            } else {
                return currentSelectedUserIds.filter(id => id !== userId);
            }
        });
    };


    useEffect(() => {
        setSelectedUserIds(userdata.map(user => user.id)); // This should select all users
        setIsChecked(true); // This should check the "Select All" box
    }, [userdata]);


    useEffect(() => {
        handleRadarChartUserSelection(userdata.slice(0, 3).map(user => user.id));
    }, [userdata]);

    /**
     * Set initial state for Radar chart with the first three users.
     * @param {string[]} selectedUserIds - The array of user identifiers.
     */
    const handleRadarChartUserSelection = (selectedUserIds) => {
        const selectedUsers = userdata.filter(user => selectedUserIds.includes(user.id));
        setSelectedForRadarChart(selectedUsers);
    };

    /**
     * Update filteredExampleUsers based on selectedUserIds.
     */
    useEffect(() => {
        if(selectedUserIds.length != userdata.length){
            setIsChecked(false);
        }
        else if(selectedUserIds.length == userdata.length){
            setIsChecked(true);
        }
        const filteredExampleUsers = userdata.filter(user => selectedUserIds.includes(user.id));
        setFilteredExampleUsers(filteredExampleUsers);
    }, [selectedUserIds]);


    /**
     * Update isChecked state based on selectedUserIds length.
     */
    useEffect(() => {
        async function fetchData() {
            setGaTitle(gaName);
            setIsChecked(selectedUserIds.length === userdata.length);
            // Set goalValue within your data's domain
            setGoalValue(await goalManager.getZielValue(0));
            setOneGoalValue(await goalManager.getZielValue(1));
            setTwoGoalValue(await goalManager.getZielValue(2));
            setThreeGoalValue(await goalManager.getZielValue(3));
            setFourGoalValue(await goalManager.getZielValue(4));
            setFiveGoalValue(await goalManager.getZielValue(5));
        }
    
        fetchData();
    }, []);

    /**
     * GesamtauswertungSelectAllCheckbox Component: Renders a "Select All" checkbox.
     * @returns {JSX.Element} - Returns JSX elements for the "Select All" checkbox.
     */
    const GesamtauswertungSelectAllCheckbox = () => {

        return (
            <div>
                <div className="all-user-listed-text">
                    Benutzer des Gruppenassessments
                </div>
                <div>
                    Wähle alle Gruppenmitglieder aus
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        style={{ marginLeft: '5px', alignSelf: "center" }} // Add margin to the left of the checkbox
                    />
                </div>
            </div>
        );
    }




    /**
     * Handle checkbox change.
     * @param {Event} event - The checkbox change event.
     */
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        if (event.target.checked) {
            setSelectedUserIds(userdata.map(user => user.id)); // Select all users
        } else {
            setSelectedUserIds([]); // Deselect all users
        }
    };



    /**
     * Calculates the average scores for each category of user answers
     * and stores the results in corresponding avg_score_X properties
     * for each user in the provided array.
     *
     * @param {Array} users - An array of user objects, each containing answers_X properties.
     */
    function calculateAverageAnswerScores(users) {
        // Iterate through each user
        users.forEach(user => {
            // update name_short from name
            if (user.name_short.length === 0) {
                for (let i = 0; i < user.name.length; i++) {
                    if (user.name[i] >= 'A' && user.name[i] <= 'Z') {
                        user.name_short += user.name[i];
                        if (user.name[i+1] === undefined){
                            user.name_short += "";
                        }
                        else{
                            user.name_short += user.name[i+1];
                        }
                    }
                    if(i+2 === 3){
                        user.name_short += " ";
                    }
                }
            }

            // Get the number of answers for each category
            const answerCount_1 = user.answers_1.length;
            const answerCount_2 = user.answers_2.length;
            const answerCount_3 = user.answers_3.length;
            const answerCount_4 = user.answers_4.length;
            const answerCount_5 = user.answers_5.length;

            // Initialize sum variables for each category
            let sum_1 = 0, sum_2 = 0, sum_3 = 0, sum_4 = 0, sum_5 = 0;

            // Calculate the sum of scores for each category
            user.answers_1.forEach(score => sum_1 += score);
            user.answers_2.forEach(score => sum_2 += score);
            user.answers_3.forEach(score => sum_3 += score);
            user.answers_4.forEach(score => sum_4 += score);
            user.answers_5.forEach(score => sum_5 += score);

            // Update avg_score_X properties with rounded values
            user.score_1 = Math.round((sum_1 / answerCount_1) * 10) / 10;
            user.score_2 = Math.round((sum_2 / answerCount_2) * 10) / 10;
            user.score_3 = Math.round((sum_3 / answerCount_3) * 10) / 10;
            user.score_4 = Math.round((sum_4 / answerCount_4) * 10) / 10;
            user.score_5 = Math.round((sum_5 / answerCount_5) * 10) / 10;

            // Update total average score
            user.score = Math.round((user.score_1 + user.score_2 + user.score_3 + user.score_4 + user.score_5) / 5 * 10) / 10;
        });

    }

    // Update user data with calculated average scores
    calculateAverageAnswerScores(userdata);


    /**
     * Calculates average scores based on the provided list of users.
     * @param {Array} users - An array of user objects with scores and answers.
     * @returns {Object} - A JSON object containing average scores for the entire list
     *                    and individual category-wise average scores.
     *                    Example:
     *                    {
     *                      "avg_score": 3.8,
     *                      "avg_score_1": 3.2,
     *                      "avg_score_2": 3.5,
     *                      "avg_score_3": 4.0,
     *                      "avg_score_4": 3.2,
     *                      "avg_score_5": 3.4
     *                    }
     */
    function calculateAverageScores(users) {
        // Filter out users with a score of 0

        const validUsers = users.filter(user => user.score > 0);
        const totalUsers = validUsers.length;

        // Initialize sums for total average and each category
        let totalSum = 0;
        let sum_1 = 0, sum_2 = 0, sum_3 = 0, sum_4 = 0, sum_5 = 0;

        // Iterate through each valid user
        validUsers.forEach(user => {
            totalSum += user.score;
            sum_1 += user.score_1;
            sum_2 += user.score_2;
            sum_3 += user.score_3;
            sum_4 += user.score_4;
            sum_5 += user.score_5;
        });

        // Calculate average scores for users with non-zero scores
        const avg_score = totalUsers > 0 ? (totalSum / totalUsers) : 0;
        const avg_score_1 = totalUsers > 0 ? (sum_1 / totalUsers) : 0;
        const avg_score_2 = totalUsers > 0 ? (sum_2 / totalUsers) : 0;
        const avg_score_3 = totalUsers > 0 ? (sum_3 / totalUsers) : 0;
        const avg_score_4 = totalUsers > 0 ? (sum_4 / totalUsers) : 0;
        const avg_score_5 = totalUsers > 0 ? (sum_5 / totalUsers) : 0;

        // Create and return the result as a JSON object
        return {
            "avg_score": avg_score.toFixed(1),
            "avg_score_1": avg_score_1.toFixed(1),
            "avg_score_2": avg_score_2.toFixed(1),
            "avg_score_3": avg_score_3.toFixed(1),
            "avg_score_4": avg_score_4.toFixed(1),
            "avg_score_5": avg_score_5.toFixed(1)
        };
    }

    const avg_score_values = calculateAverageScores(filteredExampleUsers);

    function countCompletedUsers(users, scoreKey) {
        return users.reduce((count, user) => count + (user[scoreKey] > 0 ? 1 : 0), 0);
    }

    const showDisclaimer = async () => {

        console.log(filteredExampleUsers);

        setDisclaimerTitle("Assessment speichern:");
        const textArray = [
            `Gesamter Durchschnitt: ${avg_score_values.avg_score}`,
            `Strategie, Governance, Organisation: ${avg_score_values.avg_score_1}`,
            `Prozesse: ${avg_score_values.avg_score_2}`,
            `Leadership, Kultur & People: ${avg_score_values.avg_score_3}`,
            `System & Daten: ${avg_score_values.avg_score_4}`,
            `Produkte und Innovation: ${avg_score_values.avg_score_5}`
        ];
        setfirstHeader("Die zum Benchmark gehörenden Teilnehmer sind:");
        setfirstText(`${filteredExampleUsers.map(user => user.name).join(', ')}`);
        setsecondHeader(`Die abgespeicherten Werte sind:`);
        setsecondText(textArray);

        setDisclaimerLeft("Nein");
        setDisclaimerRight("Ja");
        setIsDisclaimerVisible(true);
    };

    const saveAssessmentAsBenchmark = async () => {
        console.log("Saving assessment");
        console.log("HERE AVERAGE:");
        console.log(avg_score_values);

        const currentUserID = await dataFetcher.getCookie("user_id");
        const currentSpital = await dataFetcher.getCookie("spital_Name");

        const dataBody = {
            "spital": currentSpital,
            "total_benchmark": avg_score_values.avg_score,
            "user_id": currentUserID,
            "DI001": avg_score_values.avg_score_1,
            "DI002": avg_score_values.avg_score_2,
            "DI003": avg_score_values.avg_score_3,
            "DI004": avg_score_values.avg_score_4,
            "DI005": avg_score_values.avg_score_5
        };
        const safeSpitalMeanResponse = await dataFetcher.postData("Spital_Benchmark", dataBody);
        setIsDisclaimerVisible(false);
        if(safeSpitalMeanResponse.data){
            toast.success("Benchmark erfolgreich gespeichert.", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
        }
    }
    
    const closeDisclaimer = async () => {
        setIsDisclaimerVisible(false);
    }

    return (
        <div className="bewertung-auswertung-screen">
            <ToastContainer />
            <div className="selector-container">
                <div className="selector-item-not-selected" onClick={linkToAllgemeine}>
                    Allgemeine Informationen
                </div>
                <div className="selector-divider" />
                <div className="selector-item-not-selected" onClick={linkToFragebogen}>
                    Bewertung
                </div>
                <div className="selector-divider" />
                <div className="selector-item-not-selected" onClick={linkToAuswertung}>
                    Mein Assessment
                </div>
                <div className="selector-divider" />
                <div className="selector-item-selected" >
                    Gruppen Assessment
                </div>
            </div>

            {isDisclaimerVisible &&
                <DisclaimerGAWidget
                    title={disclaimerTitle}
                    firstHeader={firstHeader}
                    firstText={firstText}
                    secondHeader={secondHeader}
                    secondText={secondText}
                    disagree={disclaimerLeft}
                    agree={disclaimerRight}
                    disagreeOnClickMethode={closeDisclaimer}
                    agreeOnClickMethode={saveAssessmentAsBenchmark}
                />}

            <div className="ga-singleContent-container ga-radar-parent-container">
                <div style={{ position: "relative", display: "flex", flexDirection: "row" }}>
                    <div className="singleContent-row-container">
                        <GesamtauswertungTitleHeader name={"Gruppenassessment: " + gaName} />
                    </div>
                </div>
                <div className="singleContent-row-container">
                    <div className="left-circle-chart">
                        <UserSelectList
                            users={userdata}
                            onUserSelectionChange={handleRadarChartUserSelection} />
                    </div>
                    <div className="right-bar-chart">
                        <GesamtauswertungRadarChart users={selectedForRadarChart} />
                    </div>
                </div>
            </div>


            <div className="ga-singleContent-container">
                <div className="singleContent-row-container">
                    <GesamtauswertungTitleHeader name="Gesamt" />
                </div>
                <div className="singleContent-row-container">
                    <div className="left-circle-chart">
                        <GesamtauswertungRadialChartContainer id="Gesamt-Radial-Chart" Durchschnitt={avg_score_values.avg_score} AnzahlBeantworteteUser={countCompletedUsers(filteredExampleUsers, 'score')} AnzahlUser={filteredExampleUsers.length} />
                    </div>
                    <div className="right-bar-chart">
                        <GesamtauswertungBarChartLegend />
                        <GesamtauswertungBarChart data={filteredExampleUsers} dimension={"score"} avgValue={avg_score_values.avg_score} goalValue={goalValue} />
                    </div>
                </div>
            </div>

            <div className="ga-singleContent-container">
                <div className="singleContent-row-container">
                    <GesamtauswertungTitleHeader name="Strategie, Governance, Organisation" />
                </div>
                <div className="singleContent-row-container">
                    <div className="left-circle-chart">
                        <GesamtauswertungRadialChartContainer id="Strategie-Governance-Radial-Chart" Durchschnitt={avg_score_values.avg_score_1} AnzahlBeantworteteUser={countCompletedUsers(filteredExampleUsers, 'score_1')} AnzahlUser={filteredExampleUsers.length} />
                    </div>
                    <div className="right-bar-chart">
                        <GesamtauswertungBarChartLegend />
                        <GesamtauswertungBarChart data={filteredExampleUsers} dimension={"score_1"} avgValue={avg_score_values.avg_score_1} goalValue={onegoalValue} />
                    </div>
                </div>
                <div className="singleContent-row-container">
                    <GesamtauswertungSelectAllCheckbox />
                </div>
                <div className="usercards-container">
                    <div className="usercards-scrollable">
                        {userdata.map((user) => (
                            <UserProfileListItem
                                imgID={user.imgID}
                                userId={user.id}
                                userName={user.name}
                                userRole={user.role}
                                userScore={`${user.score_1.toFixed(1)}/5`}
                                answers={user.answers_1}
                                isSelected={selectedUserIds.includes(user.id)}
                                onSelectionChange={handleUserSelectionChange}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className="ga-singleContent-container">
                <div className="singleContent-row-container">
                    <GesamtauswertungTitleHeader name="Prozesse" />
                </div>
                <div className="singleContent-row-container">
                    <div className="left-circle-chart">
                        <GesamtauswertungRadialChartContainer id="Prozesse-Radial-Chart" Durchschnitt={avg_score_values.avg_score_2} AnzahlBeantworteteUser={countCompletedUsers(filteredExampleUsers, 'score_2')} AnzahlUser={filteredExampleUsers.length} />
                    </div>
                    <div className="right-bar-chart">
                        <GesamtauswertungBarChartLegend />
                        <GesamtauswertungBarChart data={filteredExampleUsers} dimension={"score_2"} avgValue={avg_score_values.avg_score_2} goalValue={twogoalValue} />
                    </div>
                </div>
                <div className="singleContent-row-container">
                    <GesamtauswertungSelectAllCheckbox />
                </div>
                <div className="usercards-container">
                    <div className="usercards-scrollable">
                        {userdata.map((user) => (
                            <UserProfileListItem
                                imgID={user.imgID}
                                userId={user.id}
                                userName={user.name}
                                userRole={user.role}
                                userScore={`${user.score_2.toFixed(1)}/5`}
                                answers={user.answers_2}
                                isSelected={selectedUserIds.includes(user.id)}
                                onSelectionChange={handleUserSelectionChange}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className="ga-singleContent-container">
                <div className="singleContent-row-container">
                    <GesamtauswertungTitleHeader name="Leadership, Kultur & People" />
                </div>
                <div className="singleContent-row-container">
                    <div className="left-circle-chart">
                        <GesamtauswertungRadialChartContainer id="Leadership-Kultur-People-Radial-Chart" Durchschnitt={avg_score_values.avg_score_3} AnzahlBeantworteteUser={countCompletedUsers(filteredExampleUsers, 'score_3')} AnzahlUser={filteredExampleUsers.length} />
                    </div>
                    <div className="right-bar-chart">
                        <GesamtauswertungBarChartLegend />
                        <GesamtauswertungBarChart data={filteredExampleUsers} dimension={"score_3"} avgValue={avg_score_values.avg_score_3} goalValue={threegoalValue} />
                    </div>
                </div>
                <div className="singleContent-row-container">
                    <GesamtauswertungSelectAllCheckbox />
                </div>
                <div className="usercards-container">
                    <div className="usercards-scrollable">
                        {userdata.map((user) => (
                            <UserProfileListItem
                                imgID={user.imgID}
                                userId={user.id}
                                userName={user.name}
                                userRole={user.role}
                                userScore={`${user.score_3.toFixed(1)}/5`}
                                answers={user.answers_3}
                                isSelected={selectedUserIds.includes(user.id)}
                                onSelectionChange={handleUserSelectionChange}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className="ga-singleContent-container">
                <div className="singleContent-row-container">
                    <GesamtauswertungTitleHeader name="System & Daten" />
                </div>
                <div className="singleContent-row-container">
                    <div className="left-circle-chart">
                        <GesamtauswertungRadialChartContainer id="System-Daten-Radial-Chart" Durchschnitt={avg_score_values.avg_score_4} AnzahlBeantworteteUser={countCompletedUsers(filteredExampleUsers, 'score_4')} AnzahlUser={filteredExampleUsers.length} />
                    </div>
                    <div className="right-bar-chart">
                        <GesamtauswertungBarChartLegend />
                        <GesamtauswertungBarChart data={filteredExampleUsers} dimension={"score_4"} avgValue={avg_score_values.avg_score_4} goalValue={fourgoalValue} />
                    </div>
                </div>
                <div className="singleContent-row-container">
                    <GesamtauswertungSelectAllCheckbox />
                </div>
                <div className="usercards-container">
                    <div className="usercards-scrollable">
                        {userdata.map((user) => (
                            <UserProfileListItem
                                imgID={user.imgID}
                                userId={user.id}
                                userName={user.name}
                                userRole={user.role}
                                userScore={`${user.score_4.toFixed(1)}/5`}
                                answers={user.answers_4}
                                isSelected={selectedUserIds.includes(user.id)}
                                onSelectionChange={handleUserSelectionChange}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className="ga-singleContent-container" style={{marginBottom: "30px"}}>
                <div className="singleContent-row-container">
                    <GesamtauswertungTitleHeader name="Produkte und Innovation" />
                </div>
                <div className="singleContent-row-container">
                    <div className="left-circle-chart">
                        <GesamtauswertungRadialChartContainer id="Produkte-Innovation-Radial-Chart" Durchschnitt={avg_score_values.avg_score_5} AnzahlBeantworteteUser={countCompletedUsers(filteredExampleUsers, 'score_5')} AnzahlUser={filteredExampleUsers.length} />
                    </div>
                    <div className="right-bar-chart">
                        <GesamtauswertungBarChartLegend />
                        <GesamtauswertungBarChart data={filteredExampleUsers} dimension={"score_5"} avgValue={avg_score_values.avg_score_5} goalValue={fivegoalValue} />
                    </div>
                </div>
                <div className="singleContent-row-container">
                    <GesamtauswertungSelectAllCheckbox />
                </div>
                <div className="usercards-container">
                    <div className="usercards-scrollable">
                        {userdata.map((user) => (
                            <UserProfileListItem
                                imgID={user.imgID}
                                userId={user.id}
                                userName={user.name}
                                userRole={user.role}
                                userScore={`${user.score_5.toFixed(1)}/5`}
                                answers={user.answers_5}
                                isSelected={selectedUserIds.includes(user.id)}
                                onSelectionChange={handleUserSelectionChange}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {benchmarkAccess && <div
                style={{
                    backgroundColor: "var(--blue-50)",
                    color: "white",
                    padding: "10px 20px",
                    width: "fit-content",
                    borderRadius: "5px",
                    cursor: "pointer",
                    border: "none",
                    marginBottom: "118px",
                    marginRight: "40px",
                    marginLeft: "auto",
                    fontWeight: "600",
                    fontSize: "20px",
                    transition: "all 0.3s",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Adding a slight box-shadow
                }}
                onClick={showDisclaimer}
                onMouseOver={(e) => {
                    e.target.style.transform = "translateY(-3px)"; // Move the button up slightly on hover
                    e.target.style.backgroundColor = "var(--blue-60)"; // Lighter background color on hover
                }}
                onMouseOut={(e) => {
                    e.target.style.transform = "translateY(0px)"; // Reset the button's position
                    e.target.style.backgroundColor = "var(--blue-50)"; // Original background color on hover out
                }}
            >
                Assessment zum Benchmark hinzufügen
            </div>}
        </div>
    );
};

export default BewertungGruppenAuswertungMarkup;