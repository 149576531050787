import { useCallback, useState, useEffect, useRef } from "react";
import { TripleSlider, RadialBarChart } from "../widgets/StrategieGesamtunternehmenWidgets"
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../widgets/StrategieGesamtunternehmenWidgets.css"
import React from "react";
import DataFetcher from "../pages/classes/databaseManager";
import AuthentificationManager from "../pages/classes/authentificationManager";


const StrategieGesamtunternehmenMarkup = ({ onItemXLClick, onItemXL1Click, onItemXL2Click, onSegmentedControlClick, GesamtIST, GesamtCH, GesamtZiel, setGesamtZiel, fetchedDataAvailable }) => {
    const [project_title, set_project_title] = useState('');
    const [project_dimension, set_project_dimension] = useState('');
    const [project_invest, set_project_invest] = useState('');
    const [project_programm, set_project_programm] = useState('');
    const [project_img, set_project_img] = useState('');

    const dataFetcher = new DataFetcher();
    const authentificationManager = new AuthentificationManager();

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [isChoiceOverlayVisible, setIsChoiceOverlayVisible] = useState(false);
    const [projectsList, setProjectsList] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [cardId, setCardId] = useState(0);
    const choiceOverlayRef = useRef();

    /* Add new Projects */
    const [createNewProject, setCreateNewProject] = useState(false);
    const [newProjectValues, setNewProjectValues] = useState(
        {
            Projektname: '',
            Dimension: '',
            Investitionsvolumen: '',
            Programm: '',
            Level: '',
            status: 0
        });



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isChoiceOverlayVisible && choiceOverlayRef.current && !choiceOverlayRef.current.contains(event.target)) {
                setIsChoiceOverlayVisible(false);
            }
        };

        // Attach the listener to the document
        if (isChoiceOverlayVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Clean up the listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isChoiceOverlayVisible]);


    const toggleOverlay = useCallback(async (text) => {
        if (text.title !== undefined) {
            try {
                // Use await to wait for the fetch to complete
                const resp_project = await dataFetcher.fetch_data_by_filter_when_equalto("Projekte", "Projektname,Dimension,Investitionsvolumen,Programm,Project_Icon", "Dimension", text.title);
                setCardId(text.id);
                // Make sure that resp_project.data is an array before setting it
                if (Array.isArray(resp_project.data)) {
                    setProjectsList(resp_project.data);
                    setIsChoiceOverlayVisible(true);
                } else {
                    // Handle the case where resp_project.data is not an array
                    console.error('Data fetched is not an array:', resp_project.data);
                }
            } catch (e) {
                // Handle any errors that occur during fetch
                console.log("Error fetching projects in Gesamtunternehmen.");
            }
        }
    }, [setIsChoiceOverlayVisible, setProjectsList]);

    const handleProjectSelect = (project) => {
        setSelectedProject(project);
        setIsOverlayVisible(true);
        setIsChoiceOverlayVisible(false);
    };

    const handleBackToChoiceOverlay = () => {
        setIsOverlayVisible(false);
        setIsChoiceOverlayVisible(true);
    };

    const toggleProjectSelection = (project) => {
        if (selectedProjects.includes(project.Projektname)) {
            // If project is already selected, remove it from the list
            setSelectedProjects((prevSelectedProjects) =>
                prevSelectedProjects.filter((name) => name !== project.Projektname)
            );
        } else {
            // If project is not selected, add it to the list
            setSelectedProjects((prevSelectedProjects) => [
                ...prevSelectedProjects,
                project.Projektname,
            ]);
        }
    };

    const confirmChoice = async () => {
        try {

            //console.log(cardId);
            setIsChoiceOverlayVisible(false); // Close the overlay
            setIsOverlayVisible(false); // Show the project info overlay
            let i = 0;
            while (i < selectedProjects.length) {
                const data = {
                    frage_id: cardId,
                    status: "Geplant",
                    projekt_name: selectedProjects[i],
                    user: authentificationManager.getCookie("user_id"),
                    spital: authentificationManager.getCookie("spital_Name")
                };
                await dataFetcher.postData("Portfolio", data);
                i++;
            }
        } catch (e) {
            // Handle the error, e.g., display an error message or retry fetching.
            console.log("Error confirming choice in Gesamtunternehmen.");
        }
    };

    authentificationManager.setCookie("GesamtZiel", GesamtZiel, 30000000);

    const addProjekt = async () => {
        //console.log("Hello From Pressed Button");
        setCreateNewProject(true);
    };


    const handleCloseNewProject = () => {
        setCreateNewProject(false);
    };


    const hanldeSaveProject = () => {
        console.log("Handle Save Project:");
        console.log(newProjectValues);

        // Check if any value is empty
        const areAllValuesFilled = Object.values(newProjectValues).every(value => value !== '' && value !== null);

        if (areAllValuesFilled) {
            const transformedInvestitionsvolumen = parseFloat(newProjectValues.Investitionsvolumen.replace(/[^0-9.]/g, ''));

            const transformedValues = {
                ...newProjectValues,
                Investitionsvolumen: transformedInvestitionsvolumen.toFixed(2)
            };

            console.log("Transformed Values:");
            console.log(transformedValues);

            dataFetcher.postData("Projekte", transformedValues);
        } else {
            console.log("Some values are empty. Cannot save the project.");
        }
    };

    const handleInputChange = (field, value) => {
        // Define constraints
        const investitionMaxValue = 1000000000;

        // Validate and update the state based on the input field
        switch (field) {
            case 'Investitionsvolumen':
                // Allow empty string or ensure numeric value is less than or equal to the max value
                if (value === '' || (!isNaN(value) && parseFloat(value) <= investitionMaxValue)) {
                    // Allow only numbers and up to 2 digits after the decimal point, no spaces
                    const sanitizedValue = value.replace(/[^0-9.]|[^\d\.]$/g, '');

                    // Check if there's a dot in the value and limit to 2 digits after the dot
                    if (sanitizedValue.includes(".")) {
                        const [integerPart, decimalPart] = sanitizedValue.split(".");
                        if (decimalPart.length > 2) {
                            // Break if more than 2 digits after the decimal point
                            break;
                        }
                    }

                    setNewProjectValues((prevValues) => ({
                        ...prevValues,
                        [field]: sanitizedValue,
                    }));
                }
                break;

            case 'Level':
                // Allow empty string or ensure value is an integer and within the allowed range
                const intValue = parseInt(value, 10);
                if (value === '' || (!isNaN(intValue) && intValue >= 1 && intValue <= 5)) {
                    setNewProjectValues((prevValues) => ({
                        ...prevValues,
                        [field]: value === '' ? '' : intValue,
                    }));
                }
                break;

            // Handle other fields as needed

            default:
                // For other fields, update the state directly
                setNewProjectValues((prevValues) => ({
                    ...prevValues,
                    [field]: value,
                }));
                break;
        }
    };


    const handleInvestitionBlur = () => {
        // When the 'investition' field gains focus, format the value with commas and a dot
        const formattedValue = parseFloat(newProjectValues.Investitionsvolumen).toLocaleString('de-CH', {
            style: 'currency',
            currency: 'CHF',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        setNewProjectValues((prevValues) => ({
            ...prevValues,
            Investitionsvolumen: formattedValue,
        }));
    };

    const handleInvestitionFocus = () => {
        // When the 'investition' field loses focus, remove formatting to get the raw numeric value
        const numericValue = newProjectValues.Investitionsvolumen.replace(/[^0-9.]/g, '');
        setNewProjectValues((prevValues) => ({
            ...prevValues,
            Investitionsvolumen: numericValue,
        }));
    };

    function saveGesamtZiel() {
        try {
            const dhiZieleBody = {
                "Spital": authentificationManager.getCookie('spital_Name'),
                "GesamtZiel": GesamtZiel,
                "GenauesGesamtZiel": GesamtZiel,
                "Dimension1Ziel": GesamtZiel,
                "Dimension2Ziel": GesamtZiel,
                "Dimension3Ziel": GesamtZiel,
                "Dimension4Ziel": GesamtZiel,
                "Dimension5Ziel": GesamtZiel,
            };
            dataFetcher.postData("DHI_Ziele", dhiZieleBody);

            toast.success("Ziel gespeichert", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
        }
        catch (e) {
            toast.error("Ziel konnte nicht gespeichert werden! Fehler:" + e, { autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER_CENTER });
        }
    }

    const [initialCounter, setInitialCounter] = useState(0);

    useEffect(() => {
        if (fetchedDataAvailable == 0 && initialCounter < 1) {
            setInitialCounter(initialCounter + 1);
        }
        else if (fetchedDataAvailable == 1 && initialCounter < 2) {
            setInitialCounter(initialCounter + 1);
        }
        else {
            saveGesamtZiel();
        }

    }, [GesamtZiel]);
    

    return (
        <div className="gesammtunternehmenscreen">
            <ToastContainer />
            <div className="selector-container">
                <div className="selector-item-selected">
                    Sicht auf Gesamtunternehmen
                </div>
                <div className="selector-divider" />
                <div className="selector-item-not-selected" onClick={onSegmentedControlClick}>
                    Sicht auf einzelne Zieldimensionen
                </div>
            </div>
            <div className="gesammtunternehmen-content">
                <div className="gesamt-left-content">
                    <div className="gesammt-slider">
                        <div className="gesammt-slider-innercontainer">
                            <div className="gesammt-slider-titlecontainer">
                                <div className="slider-title-gesamt">
                                    Legen Sie Ihr Ziel-Level fest
                                </div>
                                <div className="ziele-definieren-button" onClick={saveGesamtZiel}>
                                    Ziele speichern
                                </div>
                            </div>
                            <div className="slider-header-container-gesamt">
                                <div className="slider-legend-gesamt">
                                    <div className="slider-title-smaller-gesamt">
                                        Gesamtunternehmen
                                    </div>
                                    <div className="slider-legentitem-gesamt">
                                        <div className="slider-legentitem-1" />
                                        IST
                                    </div>
                                    <div className="slider-legentitem-gesamt">
                                        <div className="slider-legentitem-2" />
                                        Benchmark
                                    </div>
                                    <div className="slider-legentitem-gesamt">
                                        <div className="slider-legentitem-3" />
                                        Ziel
                                    </div>
                                </div>
                                {/*<div className="slider-jahres-filter-gesamt">
                                    <div className="filtercontainer">
                                        2023
                                        <img src="/filter_down.svg" alt="Card Image" draggable="false" />
                                    </div>
                                </div>
                                <div className="slider-Zielselection-filter-gesamt">
                                    <div className="filtercontainer">
                                        * Aktuelles Level
                                        <img src="/filter_down.svg" alt="Card Image" draggable="false" />
                                    </div>
                                </div>*/}
                            </div>
                            <div className="slider-container1">
                                <TripleSlider IST={GesamtIST} CH={GesamtCH} Ziel={GesamtZiel} setGesamtZiel={setGesamtZiel} />
                            </div>
                        </div>
                    </div>


                    <div className="gesammt-info">

                        <div style={{ margin: "20px" }}>
                            <div className="di-info-title">
                                <h4>Was bedeutet das?</h4>
                            </div>
                            {GesamtZiel == 1 && (
                                <div className="di-info-text">
                                    <p className="slider-title-smaller">{`L1 ('Reaktiv'):`}</p>
                                    <p className="ein-dti-von">{`Auf dieser Ebene operieren Krankenhäuser in einem reaktiven Modus ohne signifikante digitale Infrastruktur. Prozesse sind manuell, oft offline und stark auf papierbasierte Systeme angewiesen. Es mangelt an proaktiver Planung, und Lösungen werden typischerweise isoliert entwickelt, um unmittelbare Probleme anzugehen.`}</p>
                                </div>
                            )}
                            {GesamtZiel == 2 && (
                                <div className="di-info-text">
                                    <p className="slider-title-smaller">{`L2 ('Organisiert'):`}</p>
                                    <p className="ein-dti-von">{`Krankenhäuser auf dieser Stufe haben begonnen, digitale Lösungen zu integrieren, diese sind jedoch isoliert und nicht weit verbreitet. Einige Prozesse sind digitalisiert, und es gibt Bemühungen, Daten zu sammeln und zu speichern. Die Digitalisierung ist jedoch fleckenhaft und noch nicht Teil einer kohärenten Strategie.`}</p>
                                </div>
                            )}
                            {GesamtZiel == 3 && (
                                <div className="di-info-text">
                                    <p className="slider-title-smaller">{`L3 ('Digitalisiert'):`}</p>
                                    <p className="ein-dti-von">{`Auf dieser Ebene sind die meisten Krankenhausoperationen und Dokumentationen digitalisiert und strukturiert. Digitale Prozesse sind häufiger, was einen signifikanten Schritt weg von manuellen Systemen markiert. Der Fokus liegt darauf, Kernoperationen zu digitalisieren und sicherzustellen, dass die Daten effektiv organisiert sind.`}</p>
                                </div>
                            )}
                            {GesamtZiel == 4 && (
                                <div className="di-info-text">
                                    <p className="slider-title-smaller">{`L4 ('Verbunden'):`}</p>
                                    <p className="ein-dti-von">{`Krankenhäuser auf dieser Ebene zeigen eine weitreichende Digitalisierung in allen Abteilungen. Sie legen Wert auf Interoperabilität und integrierte Wertschöpfung, wobei Technologien, Daten und Systeme verbunden sind und harmonisch funktionieren. Ein zentrales Datenlager ist oft ein Merkmal, das sicherstellt, dass Daten im gesamten Krankenhaus zugänglich und nutzbar sind.`}</p>
                                </div>
                            )}
                            {GesamtZiel == 5 && (
                                <div className="di-info-text">
                                    <p className="slider-title-smaller">{`L5 ('Intelligent'):`}</p>
                                    <p className="ein-dti-von">{`Die höchste Ebene der digitalen Reife, gekennzeichnet durch modernste Technologie und kontinuierliche Entwicklung. Krankenhäuser auf dieser Stufe nutzen intelligente Systeme, die Daten durch automatisierte Werkzeuge nutzen. Prozesse werden in Echtzeit unter Verwendung von Leistungsindikatoren (KPIs) verwaltet, was eine ausgefeilte, datengesteuerte Entscheidungsfindung und betriebliche Effizienz ermöglicht.`}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="gesamt-right-content">
                    <div className="di-box">
                        <div className="di-title">
                            Digitaler Reifegrad
                        </div>
                        <div className="di-content">
                            <div style={{margin: "10px", width: "max-content", overflow: "visible"}}>
                                <div className="di-info-title">
                                    <h4>Totaler Reifegrad</h4>
                                </div>
                                <RadialBarChart Ziel={GesamtZiel} IST={GesamtIST} />
                                <div className="pagecontent-circlechart-info">
                                    <div className="pagecontent-circlechart-dot-IST" />
                                    IST
                                    <div className="pagecontent-circlechart-dot-Ziel" />
                                    Ziel
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StrategieGesamtunternehmenMarkup;