import React from 'react';
import "../widgets/VerticalNavigationWidgets.css";

export const NavigationBar = ({ navigationBarList }) => {
  const { pageNames, imageIcons, clickLinkToPage, currentPage, userName, userPicture } = navigationBarList;

  const renderNavItem = (name, icon, onClick, buttonClass) => {
    const isActive = name === currentPage;
    const navContent = (
      <>
        <img className="vertical-navigation-pictures" alt={name} src={icon} />
        <div className="vertical-navigation-labels">{name}</div>
      </>
    );

    return (
      <button key={name} className={isActive ? `${buttonClass} active` : buttonClass} onClick={() => onClick(name)}>
        {navContent}
      </button>
    );
  };

  return (
    <div className="vertical-navigation">
      {pageNames.map((name, index) => {
        const buttonClassSuffix = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth"][index];
        const buttonClass = `vertical-navigation-${buttonClassSuffix}-button`;
        const onClickHandler = clickLinkToPage[index];

        return renderNavItem(name, imageIcons[index], onClickHandler, buttonClass);
      })}

      {/* User profile button */}
      <div className="vertical-navigation-profile-button">
        <div className="user-profile-picture-position">
          <img className="user-profile-picture" alt="User profile" src={userPicture} />
        </div>
        <div className="vertical-navigation-labels">
          <span className="user-profile-txt-container">
            <p className="user-profile-name">{userName}</p>
          </span>
        </div>
      </div>
    </div>
  );
};
