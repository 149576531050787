import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataFetcher from "./classes/databaseManager";
import StrategieGesamtunternehmenMarkup from "../html_jsx/StrategieGesamtunternehmen";
import { VerticalNavigationBar } from "../widgets/VerticalNavigationWidgets";
import { HeaderWidget } from "../widgets/HeaderWidget";
import AuthentificationManager from "./classes/authentificationManager";
import { parseIdentifier, calculateMeanByDimension } from "./classes/identifierCalculator";


import "./StrategieGesamtunternehmen.css";
import "./Mainscreen_styles.css";

const StrategieGesamtunternehmen = () => {
  const navigate = useNavigate();
  const dataFetcher = new DataFetcher();

  const pageNames = ["Übersicht", "Zielsetzung", "Bewertung", "Nutzen & Aufwand", "Settings", "Kontakt"]
  const imageIcons = ["/uebersichtIcon.svg", "/selected1.svg", "/bewertungIcon.svg", "/portfolioIcon.svg", "/settingsIcon.svg", "/contactIcon.svg"]

  const authentificationManager = new AuthentificationManager();
  // Check for the presence of "auth_token" cookie when the component is mounted
  useEffect(() => {
    authentificationManager.checkAndRefreshToken();
  }, []);


  const onItemXLClick = useCallback(() => {
    navigate("/41-portfolio-tabelle");
  }, [navigate]);

  const onItemXL1Click = useCallback(() => {
    navigate("/32-bewertung-frageboegen");
  }, [navigate]);

  const onItemXL2Click = useCallback(() => {
    navigate("/10-uebersicht");
  }, [navigate]);

  const onSegmentedControlClick = useCallback(() => {
    navigate("/22-strategie-zieldimensionen");
  }, [navigate]);

  //Same order as the lists above
  const clickLinkToPage = [onItemXL2Click, onItemXL1Click, onItemXLClick]
  const currentPage = "Zielsetzung"
  const userName = "Ueli Mueller"
  const userPicture = "/userpic@2x.png"

  const verticalNavigationBarList = [pageNames, imageIcons, clickLinkToPage, currentPage, userName, userPicture]


  const [ziel, setZiel] = useState(1);
  const [ISTmean, setISTmean] = useState(0.0);
  const [fetchedDataAvailable, setfetchedDataAvailable] = useState(0);
  
  const [GesamtCH, setGesamtCH] = useState(null);

  

  // Fetching data from the backend has to be asynchronous
  const onPageLoad = async () => {
    

      const user_id = authentificationManager.getCookie('user_id');
      const latestAssessment = await dataFetcher.fetch_data_by_filter_when_equalto_by_bezugsdate_descending_order("Einzelassessment", "id", "user_id", user_id);
      if(latestAssessment == undefined)
      {
        setISTmean(1)
      }
      else
      {
        const current_scores = await dataFetcher.fetch_data_by_filter_when_equalto("Reifegradantworten", "levelident", "assessment_id", latestAssessment.id)
        const formattedData = current_scores["data"]
        ? current_scores["data"]
          .filter((item) => typeof item.levelident === 'string' && /^ID\d{3}\.DI\d{3}\.CA\d{3}\.LV\d{3}$/.test(item.levelident))
          .map((item) => parseIdentifier(item.levelident))
        : [];

      // Now, you have an array of formatted JSON structures, you can pass it to calculateMeanByDimension
      const meanValuesByDimension = calculateMeanByDimension(formattedData);
      // Print the result

      let mean = (
        (
          Object.values(meanValuesByDimension)
            .map((dimension) => dimension.MeanLevel)
            .reduce((sum, value) => sum + value, 0) / Object.keys(meanValuesByDimension).length
        ) || 0.0
      ).toFixed(1);
      setISTmean(mean)
      }
      


      const latestGoal = await dataFetcher.fetch_data_by_filter_when_equalto_by_date_descending_order("DHI_Ziele", "GesamtZiel", "Spital", authentificationManager.getCookie("spital_Name"))

      if(latestGoal == undefined)
      {
        setZiel(1)
        setfetchedDataAvailable(0)
      }
      else
      {
        setZiel(latestGoal.GesamtZiel)
        setfetchedDataAvailable(1)
      }
      
      const benchmarkCH = await dataFetcher.fetch_data_from_collection_newest_to_oldest_element("CH_benchmark", "total_benchmark_mean,DI001_mean,DI002_mean,DI003_mean,DI004_mean,DI005_mean");
      console.log(benchmarkCH);
      if (benchmarkCH.data.length > 0  && benchmarkCH != null && benchmarkCH.included_spital_count >= 3) {
        setGesamtCH(parseFloat(benchmarkCH.data[0].total_benchmark_mean).toFixed(1));
      }

  };

  // Hook to run the function on page load. Executed only one time
  useEffect(() => {
    onPageLoad();
  }, []); // Add ziel to the dependency array


  return (
    <div className="allcontent">
      <div className="mainContent">
        <div className="pageTitle">
          <b className="pageTitle-title">Zielsetzung</b>
        </div>
        
        <StrategieGesamtunternehmenMarkup
          onItemXLClick={onItemXLClick}
          onItemXL1Click={onItemXL1Click}
          onItemXL2Click={onItemXL2Click}
          onSegmentedControlClick={onSegmentedControlClick}
          GesamtIST={ISTmean}
          GesamtCH={GesamtCH}
          GesamtZiel={ziel}
          setGesamtZiel={setZiel}
          fetchedDataAvailable={fetchedDataAvailable}
        />
      </div>
      <div className="navigationbar">
        <VerticalNavigationBar verticalNavigationBarList={verticalNavigationBarList} />
      </div>
      <div className="headerbar">
        <HeaderWidget LogoImg="/LogoDRI.svg" SpitalName="Spital Zürich" />
      </div>
    </div>
  );
};

export default StrategieGesamtunternehmen;