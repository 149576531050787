import { forEach } from "@tripetto/runner";
import DatabaseManager from "./databaseManager";

const databaseManager = new DatabaseManager();

/**
 * fetch the Reifegrad questions and create vectors which can be used to build the tree data
 * @returns all questions and the necessary vectors to create the tree data
 */
const getFragen = async () => {
    try {
        const resp_Fragen = await databaseManager.fetchCollection('Reifegradfragen');
        const resp_dimension = await databaseManager.fetch_data_from_collection('Dimensionen', 'name');
        const resp_kategorien = await databaseManager.fetch_data_from_collection('Kategorien', 'name');

        const sortedFragen = resp_Fragen.data.sort(function(a, b) {
            var indexA = a.index.split('.').map(Number);
            var indexB = b.index.split('.').map(Number);
        
            if (indexA[0] !== indexB[0]) {
                return indexA[0] - indexB[0];
            } else {
                return indexA[1] - indexB[1];
            }
        });

        const rootNodes = [];
        const leafNodes = [];
        const allQuestions = [];
        const numberArray = [];

        sortedFragen.map(item => {
            rootNodes.push(resp_dimension.data[item.dimension - 1].name);
            leafNodes.push(resp_kategorien.data[item.kategorie - 1].name);
            allQuestions.push(item);
            numberArray.push(item.index)
        });

        return [allQuestions, numberArray, leafNodes, rootNodes];
    } catch (e) {
        // Handle the error, e.g., display an error message or retry fetching.
        console.error("Error getting Fragen in treeGraphManager from Reifegradfragen.");
    }
};

const getAntworten = async (selectedAss, alleFragen) => {
    try {
        const user_id = databaseManager.getCookie('user_id');

        const resp_antworten = await databaseManager.fetch_data_by_filter_when_equalto("Reifegradantworten", "id,antwort,fragen_id,fragen_index,levelident,bezugsdatum,assessment_id", "user_id", user_id);
        const resp_einzelAssessments = await databaseManager.fetch_data_by_filter_when_equalto("Einzelassessment", "id,gruppenAssessmentName,bezugsdatum,spital", "user_id", user_id);

        const sortedData = resp_einzelAssessments.data.sort((a, b) => new Date(b.bezugsdatum) - new Date(a.bezugsdatum));
        const selectedAssId = sortedData[selectedAss-1].id;
        const resp_antworten_2= await databaseManager.fetch_data_by_filter_when_equalto("Reifegradantworten", "id,antwort,fragen_id,fragen_index,levelident,bezugsdatum", "assessment_id", selectedAssId);
        
        const bezugsdatum = sortedData[selectedAss - 1].bezugsdatum;
        const alleAntworten = resp_antworten.data.filter(item => item.assessment_id === String(sortedData[selectedAss-1].id));
        // console.log(alleAntworten);
        // console.log(resp_antworten.data);
        // console.log(resp_antworten_2.data);
        const sortedAntworten = alleAntworten.sort(function(a, b) {
            var indexA = a.fragen_index.split('.').map(Number);
            var indexB = b.fragen_index.split('.').map(Number);
        
            if (indexA[0] !== indexB[0]) {
                return indexA[0] - indexB[0];
            } else {
                return indexA[1] - indexB[1];
            }
        });

        const progress = [];
        const antwortenListe = [];
        alleFragen.map((item) => {
            const antw = sortedAntworten.filter(ant => ant.fragen_id == item.id);
            if (antw.length != 0) {
                antwortenListe.push({
                    antwort: antw[0].antwort,
                    index: item.index
                });
                progress.push('complete');
            } else {
                antwortenListe.push({
                    antwort: '',
                    index: item.index
                });
                progress.push('incomplete');
            }
        })
        return [antwortenListe, progress, bezugsdatum, sortedAntworten, sortedData[selectedAss-1]];
    } catch (e) {
        // Handle the error, e.g., display an error message or retry fetching.
        console.log("No data to fetch");
    }
};

async function treeGraphManager(currAss) {
    try {
        const [alleFragen, numberArray, leafNodes, rootNodes] = await getFragen();
        const [alleAntworten, progress, bezugsdatum, alleAntwortenFullInfo, selectAss] = await getAntworten(currAss, alleFragen);

        const jsonData = {
            name: "Befragung",
            total_questions: rootNodes.length,
            open_questions: progress.filter(status => status === "incomplete").length,
            children: []
        };

        const categories = {};
        let complete = true;
        // Iterate over the arrays and group questions under categories
        for (let i = 0; i < rootNodes.length; i++) {
            const categoryName = rootNodes[i];
            const question = {
                name: leafNodes[i],
                questions: 1,
                progress: progress[i],
                fragennummer: parseFloat(numberArray[i])
            };

            // Check if the category already exists
            if (!categories[categoryName]) {
                categories[categoryName] = {
                    name: categoryName,
                    questions: 1,
                    progress: progress.includes("incomplete") ? "incomplete" : "complete",
                    children: [question]
                };
            } else {
                categories[categoryName].questions += 1;
                categories[categoryName].children.push(question);
            }
        }

        // Convert categories object to array and add it to jsonData
        jsonData.children = Object.values(categories);

        return [jsonData, rootNodes, leafNodes, numberArray, alleAntworten, alleFragen, bezugsdatum, alleAntwortenFullInfo, selectAss];
    } catch (e) {
        // Handle the error, e.g., display an error message or retry fetching.
        console.log("No data to initialize the tree graph manager");
    }
}

export default treeGraphManager;