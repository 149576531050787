import { useNavigate } from "react-router-dom";
import DataFetcher from "./databaseManager";

const dataFetcher = new DataFetcher();

class GoalManager {

    /**
     * Fetches the Ziel (Goal) value based on a specified goal category.
     * This method retrieves goal data and filters it to find active goals that match the specified category.
     * Disclaimer: Has not been used in the project
     * @param {string} goal - The goal category to filter the data.
     * @returns {Promise<Array<number>|number>} - An array of Ziel levels if matching items are found, or a default value otherwise.
     */
    async getZielValue(goal) {
        try {
            const spital = await dataFetcher.getCookie("spital_Name");
            // Fetching DHI goals data filtered by "Leistungserbringer" field
            const dhiZiel = await dataFetcher.fetch_data_by_filter_when_equalto_by_date_descending_order(
                "DHI_Ziele",
                "status,Dimension1Ziel,Dimension2Ziel,Dimension3Ziel,Dimension4Ziel,Dimension5Ziel,GesamtZiel",
                "Spital",
                spital
            );
            console.log(dhiZiel["data"]);
            const data = dhiZiel["data"];

            // Filter the list to get all items where 'Kategorie' matches the goal and 'status' is "aktiv"
            switch (goal) {
                case 0:
                    return dhiZiel.GesamtZiel;
                case 1:
                    return dhiZiel.Dimension1Ziel;
                case 2:
                    return dhiZiel.Dimension2Ziel;
                case 3:
                    return dhiZiel.Dimension3Ziel;
                case 4:
                    return dhiZiel.Dimension4Ziel;
                case 5:
                    return dhiZiel.Dimension5Ziel;
                default:
                    return "Invalid goal";
            }
        }
        catch (e) {
            // Handle the error, e.g., display an error message or retry fetching.
            console.error("Error getting Ziel Value");
        }
    }
}
export default GoalManager;

