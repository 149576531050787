import { RadarChart1, RadialBarChart, FiveTopCards, EffizienzgewinnChart, UmsetzungsaufwandChart } from "../widgets/UebersichtWidgets"
import "../pages/Uebersicht.css"
import React from "react";
import 'rsuite-table/dist/css/rsuite-table.css';
//import { DisclaimerWidget } from "./ModalDisclamer.js";


const UebersichtScreen = ({ onItemXLClick, onItemXL1Click, onItemXL2Click, IST1, IST2, IST3, IST4, IST5, CH1, CH2, CH3, CH4, CH5, CH, IST, WAS1, WAS2, WAS3, WAS4, WAS5, title1, title2, title3, title4, title5, lastDate, tableData, handleSelect, dateList }) => {

  return (
    <div className="uebersichtscreen">
      <div className="pageTitle-cards">
        <div className="pageTitle-selectorContainer">
          <div className="ba-singleContent-container-selector-doubleSelector">
            <div style={{ fontSize: "12px", color: "var(--dark-theme-gray-4)", fontWeight: 600, lineHeight: "20px" }}>
                EA: Einzel Assessment <br />
                GA: Gruppen Assessment
            </div>
            <select className="pageTitle-container1-selector" onChange={handleSelect}>
              {dateList.map(option => (
                <option className="pageTitle-container-selector-item" value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
        </div>
        <FiveTopCards IST1={IST1} IST2={IST2} IST3={IST3} IST4={IST4} IST5={IST5}
          WAS1={WAS1} WAS2={WAS2} WAS3={WAS3} WAS4={WAS4} WAS5={WAS5}
          title1={title1} title2={title2} title3={title3} title4={title4} title5={title5} lastDate={lastDate} />
      </div>
      <div class="pagecontent">
        <div class="top-section">
          <div class="pagecontent-radarchart">
            <div className="pagecontent-radarchart-background">
              <div className="pagecontent-radarchart-title-container">
                <b className="pagecontent-radarchart-title">Digitales Radar</b>
              </div>
              <div className="pagecontent-radarchart-graph">
                <RadarChart1
                  IST1={IST1} IST2={IST2} IST3={IST3} IST4={IST4} IST5={IST5}
                  CH1={CH1} CH2={CH2} CH3={CH3} CH4={CH4} CH5={CH5}
                />
                <div class="pagecontent-circlechart-info">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="pagecontent-circlechart-dot-orange" style={{ margin: "0px 10px 0px 0px", top: "-10px" }} />
                    <div style={{ height: "20px", marginTop: "-23px" }}>Ø Benchmark</div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="pagecontent-circlechart-dot-blue" style={{ margin: "0px 10px 0px 30px", top: "-10px" }} />
                    <div style={{ height: "20px", marginTop: "-23px" }}>IST</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagecontent-circlechart">
            <div className="pagecontent-circlechart-background">
              <div className="pagecontent-circlechart-innercontainer">
                <b class="pagecontent-circlechart-title">Digitaler Reifegrad</b>
                <div className="pagecontent-circlechart-radial-container-uebersicht">
                  <RadialBarChart CH={CH} IST={IST} />
                </div>

                <div class="pagecontent-circlechart-info">
                  <div className="pagecontent-circlechart-dot-orange" />
                  <div>Ø Benchmark</div>
                  <div className="pagecontent-circlechart-dot-blue" />
                  <div>IST</div>
                </div>

                <div className="pagecontent-circlechart-divider" />
                <div className="pagecontent-circlechart-text">
                  <div className="pagecontent-circlechart-text-title">L1 ('Reaktiv'):</div> Auf dieser Ebene operieren Krankenhäuser in einem reaktiven Modus ohne signifikante digitale Infrastruktur. Prozesse sind manuell, oft offline und stark auf papierbasierte Systeme angewiesen. Es mangelt an proaktiver Planung, und Lösungen werden typischerweise isoliert entwickelt, um unmittelbare Probleme anzugehen.
                  <div className="pagecontent-circlechart-text-title">L2 ('Organisiert'):</div> Krankenhäuser auf dieser Stufe haben begonnen, digitale Lösungen zu integrieren, diese sind jedoch isoliert und nicht weit verbreitet. Einige Prozesse sind digitalisiert, und es gibt Bemühungen, Daten zu sammeln und zu speichern. Die Digitalisierung ist jedoch fleckenhaft und noch nicht Teil einer kohärenten Strategie.
                  <div className="pagecontent-circlechart-text-title">L3 ('Digitalisiert'):</div> Auf dieser Ebene sind die meisten Krankenhausoperationen und Dokumentationen digitalisiert und strukturiert. Digitale Prozesse sind häufiger, was einen signifikanten Schritt weg von manuellen Systemen markiert. Der Fokus liegt darauf, Kernoperationen zu digitalisieren und sicherzustellen, dass die Daten effektiv organisiert sind.
                  <div className="pagecontent-circlechart-text-title">L4 ('Verbunden'):</div> Krankenhäuser auf dieser Ebene zeigen eine weitreichende Digitalisierung in allen Abteilungen. Sie legen Wert auf Interoperabilität und integrierte Wertschöpfung, wobei Technologien, Daten und Systeme verbunden sind und harmonisch funktionieren. Ein zentrales Datenlager ist oft ein Merkmal, das sicherstellt, dass Daten im gesamten Krankenhaus zugänglich und nutzbar sind.
                  <div className="pagecontent-circlechart-text-title">L5 ('Intelligent'):</div> Die höchste Ebene der digitalen Reife, gekennzeichnet durch modernste Technologie und kontinuierliche Entwicklung. Krankenhäuser auf dieser Stufe nutzen intelligente Systeme, die Daten durch automatisierte Werkzeuge nutzen. Prozesse werden in Echtzeit unter Verwendung von Leistungsindikatoren (KPIs) verwaltet, was eine ausgefeilte, datengesteuerte Entscheidungsfindung und betriebliche Effizienz ermöglicht.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-section">
          <b>Entwicklungen entlang der digitalen Reife</b>

          <div class="pagecontent-rowtable">
            <EffizienzgewinnChart />
            <UmsetzungsaufwandChart />            
            {/*isDisclaimerVisible && (
              <DisclaimerWidget
                title={disclaimerTitle}
                text={disclaimerText}
                disagree={disclaimerLeft}
                buttonInTheMiddle={disclaimerMiddle}
                agree={disclaimerRight}
                buttonInTheMiddleOnClickMethode={handleDisagree}
                disagreeOnClickMethode={handleDisagree}
                agreeOnClickMethode={handleSaveAssessment}
              />
            )*/}
          </div>
          <div style={{ fontWeight: 700, marginTop: 80, marginBottom: 0}}>Erläuterungen zu Nutzen- und Aufwandsentwicklung</div>
            <div style={{ fontWeight: 400, fontSize: "14px", marginTop: 20, marginBottom: 20}}>Basierend auf publizierten Studien<sub style={{fontSize: "9px", fontWeight: 700 }}> [1] </sub>, Umfragen sowie Erfahrungswerten aus unserer Arbeit im Gesundheitswesen, gehen wir davon aus, dass eine umfassende digitale Transformation erhebliche Vorteile bringen kann. Der konkrete Nutzen<sub style={{fontSize: "9px", fontWeight: 700 }}> [2] </sub> für ein Unternehmen im Gesundheitswesen hängt stark von verschiedenen Faktoren ab, darunter die Ausgangslage, der Grad der Digitalisierung in vor- und nachsorgenden Bereichen sowie externe Einflüsse wie die Einführung des elektronischen Patientendossiers oder integrierter Gesundheitssystemen. Diese exemplarische Darstellung soll die potenziellen Nutzen und Aufwände veranschaulichen. Es ist wichtig zu beachten, dass für konkrete Investitionsvorhaben ein eigener Business Case zu rechnen ist.</div>
            <div style={{ fontSize: "12px", fontWeight: "normal" }}>
              <div style={{"borderTop": "1px solid #ccc", "marginTop": "10px", "marginBottom": "0px", maxWidth: "420px"}}></div>
              <span><sup style={{fontSize: "9px", fontWeight: 700 }}>1</sup>Quellen: </span>
              <span><a href="https://www.pwc.ch/de/publications/2023/spitalstudie.pdf" target="_blank">Studie PwC</a></span>
              <span> | </span>
              <span><a href="https://www.mckinsey.com/ch/~/media/mckinsey/locations/europe%20and%20middle%20east/switzerland/our%20insights/digitization%20in%20healthcare/digitalisierung%20im%20gesundheitswesen%20%20die%2082mrdchance%20fr%20die%20schweiz%20de.pdf" target="_blank">McKinsey</a></span>
            </div>
            <div style={{ fontSize: "12px", fontWeight: "normal" }}>
              <span><sup style={{fontSize: "9px", fontWeight: 700 }}>2</sup>Nutzen, Gesamtsystem sind im Glossar oben rechts beschrieben </span><img src='/glossar_icon.svg' style={{position: "absolut", marginTop: "-20px", borderRadius: "5px" ,background: "lightgray"}} draggable={false}/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default UebersichtScreen;