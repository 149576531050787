import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StrategieZieldimensionenMarkup from "../html_jsx/StrategieZieldimensionen";
import { VerticalNavigationBar } from "../widgets/VerticalNavigationWidgets";
import { HeaderWidget } from "../widgets/HeaderWidget";
import AuthentificationManager from "./classes/authentificationManager";
import { parseIdentifier, calculateMeanByDimension } from "./classes/identifierCalculator";
import DataFetcher from "./classes/databaseManager";
import GoalManager from "./classes/goalManager";

import "./StrategieZieldimensionen.css";
import "./Mainscreen_styles.css";

const StrategieZieldimensionen = () => {
  const navigate = useNavigate();
  const dataFetcher = new DataFetcher();
  const authentificationManager = new AuthentificationManager();

  // Arrays for page names and image icons for navigation
  const pageNames = ["Übersicht", "Zielsetzung", "Bewertung", "Nutzen & Aufwand", "Settings", "Kontakt"]
  const imageIcons = ["/uebersichtIcon.svg", "/selected1.svg", "/bewertungIcon.svg", "/portfolioIcon.svg", "/settingsIcon.svg", "/contactIcon.svg"]
  const [cardDataExtracted, setExtractedCardData] = useState(null);

  // State variables for extracted card data and IST values
  const [IST1, setIST1] = useState(0.0);
  const [IST2, setIST2] = useState(0.0);
  const [IST3, setIST3] = useState(0.0);
  const [IST4, setIST4] = useState(0.0);
  const [IST5, setIST5] = useState(0.0);
  const [ISTmean, setISTmean] = useState(0.0);

  // State variables for goal dimensions and total goal
  const [StrategieZiel, setStrategieZiel] = useState(1.0);
  const [ProzesseZiel, setProzesseZiel] = useState(1.0);
  const [KulturZiel, setKulturZiel] = useState(1.0);
  const [SystemZiel, setSystemZiel] = useState(1.0);
  const [ProdukteZiel, setProdukteZiel] = useState(1.0);
  const [gesamtZiel, setGesamtZiel] = useState(1.0);
  const [fetchedDataAvailable, setfetchedDataAvailable] = useState(0);

  const [StrategieCH, setStrategieCH] = useState(1.0);
  const [ProzesseCH, setProzesseCH] = useState(1.0);
  const [KulturCH, setKulturCH] = useState(1.0);
  const [SystemCH, setSystemCH] = useState(1.0);
  const [ProdukteCH, setProdukteCH] = useState(1.0);

  // Check for the presence of "auth_token" cookie when the component is mounted
  useEffect(() => {
    authentificationManager.checkAndRefreshToken();
    onPageLoad();
  }, []);

  // Update when goal dimensions change
  useEffect(() => {
    onPageLoad();
  }, [StrategieZiel, ProzesseZiel, KulturZiel, SystemZiel, ProdukteZiel]);

  // Callback functions for XL navigation items
  const onItemXLClick = useCallback(() => {
    navigate("/41-portfolio-tabelle");
  }, [navigate]);

  const onItemXL1Click = useCallback(() => {
    navigate("/32-bewertung-frageboegen");
  }, [navigate]);

  const onItemXL2Click = useCallback(() => {
    navigate("/10-uebersicht");
  }, [navigate]);

  const onSegmentedControlClick = useCallback(() => {
    navigate("/21-strategie-gesamtunternehmen");
  }, [navigate]);

  // Function to fetch data on page load
  const onPageLoad = async () => {

    const user_id = authentificationManager.getCookie('user_id');
    const latestEinzelAssessment = await dataFetcher.fetch_data_by_filter_when_equalto_by_bezugsdate_descending_order("Einzelassessment", "id", "user_id", user_id);
    if (latestEinzelAssessment == undefined) {
      setIST1(1)
      setIST2(1)
      setIST3(1)
      setIST4(1)
      setIST5(1)
      setISTmean(1)
      
    }
    else {
      const latestEinzelAssessment_scores = await dataFetcher.fetch_data_by_filter_when_equalto("Reifegradantworten", "levelident", "assessment_id", latestEinzelAssessment.id)
      

      const formattedData = latestEinzelAssessment_scores["data"]
        ? latestEinzelAssessment_scores["data"]
          .filter((item) => typeof item.levelident === 'string' && /^ID\d{3}\.DI\d{3}\.CA\d{3}\.LV\d{3}$/.test(item.levelident))
          .map((item) => parseIdentifier(item.levelident))
        : [];

      // Now, you have an array of formatted JSON structures, you can pass it to calculateMeanByDimension
      const meanValuesByDimension = calculateMeanByDimension(formattedData);
      //console.log(meanValuesByDimension)
      setIST1((meanValuesByDimension["001"]?.["MeanLevel"] ?? 0.0).toFixed(1));
      setIST2((meanValuesByDimension["002"]?.["MeanLevel"] ?? 0.0).toFixed(1));
      setIST3((meanValuesByDimension["003"]?.["MeanLevel"] ?? 0.0).toFixed(1));
      setIST4((meanValuesByDimension["004"]?.["MeanLevel"] ?? 0.0).toFixed(1));
      setIST5((meanValuesByDimension["005"]?.["MeanLevel"] ?? 0.0).toFixed(1));

      // Calculate mean values by dimension
      const calculateISTmean = () => {
        const istValues = [IST1, IST2, IST3, IST4, IST5];
        const sum = istValues.reduce((acc, value) => acc + parseFloat(value), 0);
        const istMean = sum / istValues.length;
        return istMean.toFixed(1); // Round to one decimal place
      }

      // Set the calculated IST mean value in the state
      const ISTmeanValue = calculateISTmean();
      setISTmean(ISTmeanValue);
    }


    // Get all Ziel values
    const latestGoal = await dataFetcher.fetch_data_by_filter_when_equalto_by_date_descending_order("DHI_Ziele", "GesamtZiel,GenauesGesamtZiel,Dimension1Ziel,Dimension2Ziel,Dimension3Ziel,Dimension4Ziel,Dimension5Ziel", "Spital", authentificationManager.getCookie("spital_Name"))
    if (latestGoal == undefined) {
      setStrategieZiel(1);
      setProzesseZiel(1);
      setKulturZiel(1);
      setSystemZiel(1);
      setProdukteZiel(1);
      setGesamtZiel(1);
      setfetchedDataAvailable(0)
    }
    else
    {
      if (latestGoal.Dimension1Ziel === null || latestGoal.Dimension2Ziel === null || latestGoal.Dimension3Ziel === null || latestGoal.Dimension4Ziel === null || latestGoal.Dimension5Ziel === null) {
        setStrategieZiel(latestGoal.GesamtZiel);
        setProzesseZiel(latestGoal.GesamtZiel);
        setKulturZiel(latestGoal.GesamtZiel);
        setSystemZiel(latestGoal.GesamtZiel);
        setProdukteZiel(latestGoal.GesamtZiel);
      }
      else {
        setStrategieZiel(parseFloat(latestGoal.Dimension1Ziel));
        setProzesseZiel(latestGoal.Dimension2Ziel);
        setKulturZiel(parseFloat(latestGoal.Dimension3Ziel));
        setSystemZiel(parseFloat(latestGoal.Dimension4Ziel));
        setProdukteZiel(parseFloat(latestGoal.Dimension5Ziel));
      }
      setGesamtZiel(latestGoal.GenauesGesamtZiel);
      setfetchedDataAvailable(1)
    }

    const benchmarkCH = await dataFetcher.fetch_data_from_collection_newest_to_oldest_element("CH_benchmark", "total_benchmark_mean,DI001_mean,DI002_mean,DI003_mean,DI004_mean,DI005_mean");

    if (benchmarkCH.data.length > 0  && benchmarkCH != null && benchmarkCH.included_spital_count >= 3) {
      setStrategieCH(parseFloat(benchmarkCH.data[0].DI001_mean).toFixed(1));
      setProzesseCH(parseFloat(benchmarkCH.data[0].DI002_mean).toFixed(1));
      setKulturCH(parseFloat(benchmarkCH.data[0].DI003_mean).toFixed(1));
      setSystemCH(parseFloat(benchmarkCH.data[0].DI004_mean).toFixed(1));
      setProdukteCH(parseFloat(benchmarkCH.data[0].DI005_mean).toFixed(1));
    }

  }



// Arrays for XL navigation item click handlers
const clickLinkToPage = [onItemXL2Click, onItemXL1Click, onItemXLClick]
const currentPage = "Zielsetzung"
const userName = "Ueli Mueller"
const userPicture = "/userpic@2x.png"

// Vertical navigation bar data
const verticalNavigationBarList = [pageNames, imageIcons, clickLinkToPage, currentPage, userName, userPicture]

return (
  <div className="allcontent">
    <div className="mainContent">
      <div className="pageTitle">
        <b className="pageTitle-title">Zielsetzung</b>
      </div>
      <StrategieZieldimensionenMarkup
        onSegmentedControlClick={onSegmentedControlClick}
        StrategieIST={IST1}
        StrategieCH={StrategieCH}
        ProzesseIST={IST2}
        ProzesseCH={ProzesseCH}
        KulturIST={IST3}
        KulturCH={KulturCH}
        SystemIST={IST4}
        SystemCH={SystemCH}
        ProdukteIST={IST5}
        ProdukteCH={ProdukteCH}
        ISTmean={ISTmean}
        setISTmean={setISTmean}
        exactMean={gesamtZiel}
        setExactMean={setGesamtZiel}
        StrategieZiel={StrategieZiel}
        ProzesseZiel={ProzesseZiel}
        KulturZiel={KulturZiel}
        SystemZiel={SystemZiel}
        ProdukteZiel={ProdukteZiel}
        verticalNavigationBarList={verticalNavigationBarList}
        fetchedDataAvailable={fetchedDataAvailable}
      />

    </div>
    <div className="navigationbar">
      <VerticalNavigationBar verticalNavigationBarList={verticalNavigationBarList} />
    </div>
    <div className="headerbar">
      <HeaderWidget LogoImg="/LogoDRI.svg" SpitalName="Spital Zürich" />
    </div>
  </div>
);
};

export default StrategieZieldimensionen;
