import DataFetcher from "./databaseManager";
import AuthentificationManager from "./authentificationManager";

/**
 * Manages the retrieval of hospital data.
 * This function fetches data related to hospital questions and logs the response.
 * It also retrieves the hospital name from cookies for possible use.
 * Disclaimer: Has not been used in the project
 * @returns {null} - Currently returns null; can be modified to return relevant data.
 */
const spitaldatenManager = () => {
  // Initialize dataFetcher and authentificationManager instances
  const dataFetcher = new DataFetcher();
  const authentificationManager = new AuthentificationManager();

  // Retrieve the hospital name from cookies
  const spitalName = authentificationManager.getCookie('spital_Name');

  // Fetch hospital questions data from the collection 'Spitalfragen'
  const resp_sd = dataFetcher.fetch_data_from_collection("Spitalfragen", "frage,index,min_value,max_value,hilfe");

  // Log the response for debugging or informational purposes
  console.log(resp_sd);

  return null;
};

export default spitaldatenManager;