import { elementAcceptingRef } from "@mui/utils";
import DataFetcher from "./databaseManager";

/**
 * Finds the newest assessment ID based on creation dates.
 * @param {Array} fetchedData - Array of objects containing assessments with creation dates.
 * @param {string} currentAssessment - The current assessment context.
 * @returns {string|null} - The ID of the newest assessment or null if the array is empty.
 * 
 * Example:
 * 
 * let filteredData = [
 *    {
 *        creation_date: '2024-01-29T22:53:36.279Z',
 *        Assessment_id: 'X2rTQc9OhAIEN2RY2o74',
 *        reifegradfragen_beantwortet: 'true',
 *        spitalfragen_beantwortet: 'false'
 *    },
 *    {
 *        creation_date: '2024-01-29T22:48:08.766Z',
 *        Assessment_id: '2fVv38CY0p7EvXwapdws',
 *        reifegradfragen_beantwortet: 'true',
 *        spitalfragen_beantwortet: 'false'
 *    },
 *    {
 *        creation_date: '2024-01-29T00:10:30.705Z',
 *        Assessment_id: 'z2OJ5rN2IFha0Nqi77u2',
 *        reifegradfragen_beantwortet: 'true',
 *        spitalfragen_beantwortet: 'false'
 *    }
 * ];
 * 
 * let stringCurrentAssessmentDate = 'Aktuelles';
 * 
 * const newestAssID = findNewestAssessmentID(filteredData, stringCurrentAssessmentDate);
 * // Returns 'X2rTQc9OhAIEN2RY2o74'
 */
const findNewestAssessmentID = (fetchedData, currentAssessment) => {
    if (fetchedData.length === 0) {
        return null; // Return null if the fetched data is empty
    }

    let newestDate = new Date(fetchedData[0].creation_date);
    let selectedAssessment = fetchedData[0].Assessment_id;

    if (currentAssessment === 'Aktuelles') {
        // Iterate through fetched data to find the newest date
        for (let i = 1; i < fetchedData.length; i++) {
            const currentDate = new Date(fetchedData[i].creation_date);
            if (currentDate > newestDate) {
                newestDate = currentDate;
                selectedAssessment = fetchedData[i].Assessment_id;
            }
        }
    } else {
        // Find the assessment matching the current assessment date
        selectedAssessment = fetchedData.find((item) => item.creation_date.includes(currentAssessment)).Assessment_id;
    }
    return selectedAssessment;
};

/**
 * Merges Antwort and Fragen data based on matching 'tripetto_id'.
 * @param {Array} antData - Array of Antwort objects.
 * @param {Array} fragData - Array of Fragen objects.
 * @returns {Array|null} - Merged array of data or null if either array is empty.
 * Example:
 * 
 * let antwortData = [
 *    {
 *        antwort: "Alle Mitarbeitenden haben die Möglichkeit mit ihrem eigenen Gerät (BYOD) orts- und zeitunabhängigen Zugriff auf alle benötigten Informationen zu erhalten. Beispiel: klinische Daten in Echtzeit inkl. intelligente Alarmierung.",
 *        levelident: "ID026.DI004.CA026.LV005",
 *        tripetto_id1: "c5e562fb7e405559903f545e8485d022865cae4d3d7e03fac14999e39cecc783",
 *    },
 *    // ... other Antwort entries
 * ];
 * 
 * let fragenData = [
 *    {
 *        Frage: "Strategie:\nInwieweit hat das Spital eine umfassende digitale Strategie, welche integraler Teil der Spitalstrategie ist und mit den Zielen und der Vision des Spitals übereinstimmt?",
 *        category: "Strategie",
 *        dimension: "Strategie, Governance, Organisation",
 *        position_index: "26",
 *        tripetto_id: "56ceefaeb6cbfdcabeb0fb09ac73027df70a42ff717f1967dd5547ea826b33ce",
 *        version: "8.0.0",
 *    },
 *    // ... other Fragen entries
 * ];
 * 
 * const mergedData = getMatchingFragen(antwortData, fragenData);
 * 
 * // Returns an array of merged objects like:
 * // [
 * //    {
 * //        Frage: "Strategie:\nInwieweit hat das Spital eine umfassende digitale Strategie, welche integraler Teil der Spitalstrategie ist und mit den Zielen und der Vision des Spitals übereinstimmt?",
 * //        antData: [
 * //            {
 * //                antwort: "Eine Geschäftsstrategie ist integral mit Digitalisierungselementen verknüpft. Diese wird stetig überwacht und weiterentwickelt.",
 * //                levelident: "ID001.DI001.CA001.LV005",
 * //                tripetto_id1: "56ceefaeb6cbfdcabeb0fb09ac73027df70a42ff717f1967dd5547ea826b33ce",
 * //            },
 * //            // ... matching Antwort entries
 * //        ],
 * //        category: "Strategie",
 * //        dimension: "Strategie, Governance, Organisation",
 * //        position_index: "26",
 * //        tripetto_id: "56ceefaeb6cbfdcabeb0fb09ac73027df70a42ff717f1967dd5547ea826b33ce",
 * //        version: "8.0.0",
 * //    },
 * //    {
 * //        // ... other merged objects
 * //    },
 * // ]
 */
const getMatchingFragen = (antData, fragData) => {
    if (antData.length === 0 || fragData.length === 0) {
        return null; // Return null if either array is empty
    }
    let mergedData = fragData.map(antItem => {
        const { tripetto_id, ...rest } = antItem;
        const matchingFragData = antData.filter(antItem => antItem.tripetto_id1 === tripetto_id);
        return { ...rest, antData: matchingFragData };
    });
    return mergedData;
};

/**
 * Filters and formats Reifegraddaten based on 'tabellenArt'.
 * @param {Array} array - Array of data to be filtered.
 * @param {string} tabellenArt - Type of table ('reifegrad' or other).
 * @returns {Array} - Filtered and formatted data.
 * Example:
 * 
 * let data = [
 *    {
 *        Frage: "Digitaler Reifegrad / Strategie, Governance, Organisation / Strategie:\nInwieweit hat das Spital eine umfassende digitale Strategie, welche integraler Teil der Spitalstrategie ist und mit den Zielen und der Vision des Spitals übereinstimmt? / Score",
 *        position_index: "26",
 *        tripetto_id: "56ceefaeb6cbfdcabeb0fb09ac73027df70a42ff717f1967dd5547ea826b33ce",
 *        version: "8.0.0",
 *    },
 *    // ... other elements in the 'data' array
 * ];
 * 
 * let tabellenArt = "reifegrad";
 * 
 * const filteredData = filterSpitalReifegraddaten(data, tabellenArt);
 * 
 * // Returns an array of filtered objects like:
 * // [
 * //    {
 * //        Frage: "Strategie:\nInwieweit hat das Spital eine umfassende digitale Strategie, welche integraler Teil der Spitalstrategie ist und mit den Zielen und der Vision des Spitals übereinstimmt?",
 * //        position_index: "26",
 * //        tripetto_id: "56ceefaeb6cbfdcabeb0fb09ac73027df70a42ff717f1967dd5547ea826b33ce",
 * //        version: "8.0.0",
 * //        category: "Strategie",
 * //        dimension: "Strategie, Governance, Organisation",
 * //    },
 * //    {
 * //        // ... other filtered objects
 * //    },
 * // ]
 */
function filterSpitalReifegraddaten(array, tabellenArt) {
    if (tabellenArt === 'reifegrad') {
        // Filter and format the Fragen for digital maturity (Digitaler Reifegrad)
        const FragenOpt = array
            .filter(item => item.Frage.includes("Digitaler Reifegrad /"))
            .map(item => ({
                ...item,
                Frage: item.Frage.replace("Digitaler Reifegrad / ", "").replace(" / Score", "")
            }));

        // Sort Fragen based on their position index
        const sortedFragen = FragenOpt.sort((item1, item2) => item1.position_index - item2.position_index);

        // Filter and map Fragen based on different categories
        const getFilteredAndMappedArray = (filterKeyword) => sortedFragen
            .filter(item => item.Frage.includes(`${filterKeyword} /`))
            .map(item => ({ ...item, Frage: item.Frage.replace(`${filterKeyword} / `, "") }));

        // Categorizing and formatting questions based on various digital maturity aspects
        const digFragenStratGovOrga = getFilteredAndMappedArray("Strategie, Governance, Organisation");
        const digFragenpProz = getFilteredAndMappedArray("Prozesse");
        const digFragenLeadKultPeap = getFilteredAndMappedArray("Leadership, Kultur, People");
        const digFragenSysDat = getFilteredAndMappedArray("Systeme und Daten");
        const digFragenProdInnov = getFilteredAndMappedArray("Produkte und Innovationen");

        // Compiling all questions into a single array
        const allQuestions = [];
        allQuestions.push(...digFragenStratGovOrga);
        allQuestions.push(...digFragenpProz);
        allQuestions.push(...digFragenLeadKultPeap);
        allQuestions.push(...digFragenSysDat);
        allQuestions.push(...digFragenProdInnov);

        // Extracting categories and dimensions from questions
        const categories = digFragenStratGovOrga.map(item => item.Frage.split(':\n')[0]);
        categories.push(...digFragenpProz.map(item => item.Frage.split(':\n')[0]));
        categories.push(...digFragenLeadKultPeap.map(item => item.Frage.split(':\n')[0]));
        categories.push(...digFragenSysDat.map(item => item.Frage.split(':\n')[0]));
        categories.push(...digFragenProdInnov.map(item => item.Frage.split(':\n')[0]));

        const dimensions = Array(digFragenStratGovOrga.length).fill("Strategie, Governance, Organisation");
        dimensions.push(...Array(digFragenpProz.length).fill("Prozesse"));
        dimensions.push(...Array(digFragenLeadKultPeap.length).fill("Leadership, Kultur, People"));
        dimensions.push(...Array(digFragenSysDat.length).fill("Systeme und Daten"));
        dimensions.push(...Array(digFragenProdInnov.length).fill("Produkte und Innovationen"));

        // Assigning categories and dimensions to each question
        allQuestions.forEach((obj, index) => {
            obj.category = categories[index];
            obj.dimension = dimensions[index];
        });

        // Return the array with all Fragen categorized and formatted
        return allQuestions
    }

    // For other types of tables, filter and format Spitaldaten
    return array
        .filter(item => item.Frage.includes("Intro / Spitaldaten /"))
        .map(item => ({
            ...item,
            Frage: item.Frage.replace("Intro / Spitaldaten / ", "").replace(" / Score", "")
        }));
};

/**
 * Asynchronously fetches and processes Spital Reifegrad table data.
 * This function orchestrates multiple data retrieval and processing steps to compile a detailed dataset
 * for a specific user assessment, categorized by 'tabellenArt'.
 * @param {string} currentAssessment - The current assessment context.
 * @param {string} userID - User ID for filtering data.
 * @param {string} tabellenArt - Type of table ('reifegrad' or other).
 * @returns {Promise<Array>} - Promise resolving to the processed table data.
 * Example:
 * 
 * const authentificationManager = new AuthentificationManager();
 * let value = 1
 * const user_id = authentificationManager.getCookie('user_id');
 * let tabellenArt = 'reifegrad'
 * const tableData = await getSpitalReifegradTableData(value, user_id, tabellenArt);"
 * 
 * Returns an array of objects like:
 * [
 *    {
 *        ant_lv: "5",
 *        category: "Strategie",
 *        creation_date: "2024-01-29T22:53:36.279Z",
 *        dimension: "Strategie, Governance, Organisation",
 *        fragebogen_version: "8.0.0",
 *        id: 1,
 *        spitaldaten_antwort: "Eine Geschäftsstrategie ist integral mit Digitalisierungselementen verknüpft. Diese wird stetig überwacht und weiterentwickelt.",
 *        spitaldaten_fragen: "Strategie:\nInwieweit hat das Spital eine umfassende digitale Strategie, welche integraler Teil der Spitalstrategie ist und mit den Zielen und der Vision des Spitals übereinstimmt?"
 *    },
 *    {
 *        // ... other objects in the array
 *    },
 * ]
 */
const getSpitalReifegradTableData = async (currentAssessment, userID, tabellenArt) => {
    console.log(currentAssessment)
    console.log(userID)
    console.log(tabellenArt)
    const dataFetcher = new DataFetcher();

    // Fetching assessment data filtered by the user ID
    const resp_ass = await dataFetcher.fetch_data_by_filter_when_equalto("Assessment", "creation_date,Assessment_id,reifegradfragen_beantwortet,spitalfragen_beantwortet", "User_dir", userID);
    const resp_ass_data = resp_ass["data"];

    // Filtering data based on the type of table ('reifegrad' or other)
    let filteredData = []
    if (tabellenArt === "reifegrad") {
        filteredData.push(resp_ass_data.filter(item => item.reifegradfragen_beantwortet === "true"));
    } else {
        filteredData.push(resp_ass_data.filter(item => item.spitalfragen_beantwortet === "true"));
    }

    if (filteredData[0].length == 0) {
        return []; // Return an empty array if no data is found
    }

    // Sorting the data based on creation date
    const sortedData = filteredData[0].sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date));

    // Mapping sorted data to a format usable for assessment date selection
    const ass_dates = sortedData.map((item, index) => ({
        label: index === 0 ? "Aktuelles" : item.creation_date,
        value: index + 1
    }));

    // Determining the current assessment date based on the selected option
    const stringCurrentAssessmentDate = ass_dates[currentAssessment - 1].label;

    // Identifying the newest assessment ID
    const newestAssID = findNewestAssessmentID(filteredData[0], stringCurrentAssessmentDate);
    const newestAssessment = filteredData[0].find(item => item.Assessment_id === newestAssID.toString());

    // Fetching Antworten data for the newest assessment
    const resp_ant = await dataFetcher.fetch_data_by_filter_when_equalto("Antworten", "tripetto_id1,antwort,levelident", "assessment1", newestAssID);
    const resp_ant_data = resp_ant['data'];

    // Fetching Fragen data of all tripetto_id's
    const resp_frag = await dataFetcher.fetch_data_by_filter_when_equalto("Fragen", "Frage,version,tripetto_id,position_index", "status", "aktiv");
    const resp_frag_data = resp_frag['data'];

    // Filtering and formatting Fragen data based on the table type
    const resp_frag_data_filter = filterSpitalReifegraddaten(resp_frag_data, tabellenArt);
    
    // Matching Antworten and Fragen based on tripetto_id
    const antwortenFragen = getMatchingFragen(resp_ant_data, resp_frag_data_filter);

    
    // Merging information to create a structured json dataset
    const mergedData = { assessment_data: newestAssessment, antFrag: antwortenFragen };
    if (mergedData.antFrag[0].antData.length == 0) {
        return [];
    }

    // Formatting the final table information for presentation
    const tableInfo = mergedData.antFrag.map((item, index) => {
        // reifegraddaten
        if (item.antData.length != 0 && mergedData.antFrag[0].antData.length > 0 && item.antData[0].levelident) {
            return {
                "id": index + 1,
                "spitaldaten_fragen": item.Frage,
                "spitaldaten_antwort": item.antData[0].antwort,
                "creation_date": mergedData.assessment_data.creation_date,
                "fragebogen_version": item.version,
                "ant_lv": item.antData[0].levelident.charAt(item.antData[0].levelident.length - 1),
                "category": item.category,
                "dimension": item.dimension
            };
        }
        // spitaldaten  
        else {
            return {
                "id": index + 1,
                "spitaldaten_fragen": item.Frage,
                "spitaldaten_antwort": item.antData[0].antwort,
                "creation_date": mergedData.assessment_data.creation_date,
                "fragebogen_version": item.version
            };
        }
    });

    return tableInfo; // Return the final structured dataset


};
export default getSpitalReifegradTableData;
