import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BewertungFrageboegenMarkup from "../html_jsx/BewertungFrageboegen";
import { VerticalNavigationBar } from "../widgets/VerticalNavigationWidgets";
import { HeaderWidget } from "../widgets/HeaderWidget";
import AuthentificationManager from "./classes/authentificationManager";

import "../widgets/BewertungFrageboegenWidgets.css"
import "./BewertungFrageboegen.css";
import "./Mainscreen_styles.css";

const BewertungFrageboegen = () => {
  const navigate = useNavigate();
  
  const pageNames = ["Übersicht", "Zielsetzung", "Bewertung", "Nutzen & Aufwand", "Settings", "Kontakt"]
  const imageIcons =["/uebersichtIcon.svg", "/strategieIcon.svg","/selected-bewertungIcon.svg","/portfolioIcon.svg","/settingsIcon.svg","/contactIcon.svg"]
  
  const authentificationManager = new AuthentificationManager();
  // Check for the presence of "auth_token" cookie when the component is mounted
  useEffect(() => {
    authentificationManager.checkAndRefreshToken();
  }, []);

  const onItemXLClick = useCallback(() => {
    navigate("/41-portfolio-tabelle");
  }, [navigate]);

  const onItemXL1Click = useCallback(() => {
    navigate("/21-strategie-gesamtunternehmen");
  }, [navigate]);

  const onItemXL2Click = useCallback(() => {
    navigate("/10-uebersicht");
  }, [navigate]);

  const linkToAuswertung = useCallback(() => {
    navigate("/33-bewertung-assessment");
  }, [navigate]);

  const linkToAllgemeineAuswertung = useCallback(() => {
    navigate("/31-bewertung-allgemeine-informationen");
  }, [navigate]);

  const linkToGruppenAuswertung = useCallback(() => {
    navigate("/34-bewertung-gruppenassessment");
  }, [navigate]);

  //Same order as the lists above
  const clickLinkToPage = [onItemXL2Click,onItemXL1Click,onItemXLClick]
  const currentPage = "Bewertung"
  const userName = "Ueli Mueller"
  const userPicture = "/userpic@2x.png"

  const verticalNavigationBarList = [pageNames,imageIcons,clickLinkToPage,currentPage,userName,userPicture]

  return (   
  <div className="allcontent">
    <div className="mainContent">
      <div className="pageTitle">
          <b className="pageTitle-title">Bewertung</b>
      </div>
        <BewertungFrageboegenMarkup
        onItemXLClick={onItemXLClick}
        onItemXL1Click={onItemXL1Click}
        onItemXL2Click={onItemXL2Click}
        linkToAuswertung = {linkToAuswertung}
        linkToAllgemeineAuswertung = {linkToAllgemeineAuswertung}
        linkToGruppenAuswertung={linkToGruppenAuswertung}
        overlayContentLink={"https://tripetto.app/run/AN2QGG8Z9I?User="+authentificationManager.getCookie("user_id")+"&Spital="+authentificationManager.getCookie("spital_Name")}/>
    </div>
    <div className="navigationbar">
      <VerticalNavigationBar verticalNavigationBarList = {verticalNavigationBarList}/>
    </div>
    <div className="headerbar">
      <HeaderWidget LogoImg="/LogoDRI.svg" SpitalName="Spital Zürich" />
    </div>
  </div>
  );
};

export default BewertungFrageboegen;
