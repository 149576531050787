// Class for handling data fetching and posting operations
class DataFetcher {
  // Base URL for API
  base_url = "https://be.digitalerreifegrad.ch";
  base_url_items = this.base_url + "/items/";

  // Ordered by date (latest to oldest in descending order)
  entry_update = "?adv_where[type]=ENTRY&adv_where[action]=UPDATE&adv_where[table_name]="
  datetime_descending = "&orderBy=datetime&orderDirection=DESC&perPage=1"

  // Default username and password for API authentication
  username = 'admin@example.com';
  password = 'd1r3ctu5';

  // Headers for API requests
  headers = new Headers();

  /**
   * Asynchronously fetches a collection of data from the API.
   * @param {string} collection_name - The name of the collection to fetch.
   * @returns {Promise<Object>} - Returns a Promise that resolves to the JSON response from the API.
   */
  async fetchCollection(collection_name) {
    // Constructing the URL for fetching the collection
    const url = this.base_url_items + collection_name;

    // Setting up the authentication headers
    this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
    this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));

    // Performing the API request
    const response = await fetch(url, {
      method: 'GET',
      headers: this.headers,
    });

    // Parsing and returning the JSON response
    const json_resp = await response.json();
    return json_resp;
  };

  /**
   * Asynchronously fetches specific data from the API with filtering options.
   * To understand better: Get all retr_inf fields from the collection_name table
   * when filter_obj object is equal to filter.
   * @param {*} collection_name - the name of the collection to fetch.
   * @param {*} retr_inf - comma-separated fields to retrieve.
   * @param {*} filter_obj - object which should be filtered.
   * @param {*} filter - the filter value for the specified field.
   * @returns {Promise} - Returns a Promise that resolves to the JSON response from the API.
   * Example:
   * 
   * const dataFetcher = new DataFetcher();
   * const fetched_data = await dataFetcher.fetch_data_by_filter_when_equalto("Assessment", "creation_date,Assessment_id,spitalfragen_beantwortet", "User_dir", user_id);
   * 
   * Returns an array of objects like:
   * [
   *    {
   *        Assessment_id: "z2OJ5rN2IFha0Nqi77u2",
   *        creation_date: "2024-01-29T00:10:30.705Z",
   *        spitalfragen_beantwortet: "false",
   *    },
   *    {
   *        // ... other objects in the array
   *    },
   * ]
   */
  async fetch_data_by_filter_when_equalto(collection_name, retr_inf, filter_obj, filter, expectedNull = false) {
    let url = ""

    // Encoding '&' character for URL compatibilit
    if (typeof (filter) === "string") {
      if (filter.includes("&")) {
        filter = filter.replace("&", "%26");
      }
      if (filter.includes(" ")) {
        filter = filter.replace(" ", "%20");
      }
    }


    // Constructing the API URL based on collection name and filter
    if (collection_name === "users" || collection_name === "roles") {
      url = this.base_url + "/" + collection_name + "?fields=" + retr_inf + "&filter[" + filter_obj + "]=" + filter;
    }
    else {
      url = this.base_url_items + collection_name + "?fields=" + retr_inf + "&filter[" + filter_obj + "]=" + filter;
    }
    // Setting authentication headers
    this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
    this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));

    // Fetching data from the API
    const response = await fetch(url, {
      method: 'GET',
      headers: this.headers,
    });

    // Check if the response status is 401 / Handling unauthorized access
    if (response.status === 401) {
      // Redirect to the base URL
      window.location.href = window.location.origin;
      return; // Prevent further processing
    }

    const json_resp = await response.json();

    try {
      // Ensuring the response contains data and is in array format
      if (json_resp.data && Array.isArray(json_resp.data) && json_resp.data.length > 0) {
        // Check if all values in retr_inf are null
        if (json_resp.data.every(item => retr_inf.split(',').every(field => item[field] === null))) {
          if (expectedNull) {
            return json_resp;
          }
          return -1; // Returning -1 if all specified fields are null
        }
      }
      return json_resp;
    }

    catch (e) {
      // Returning -1 in case of an exception
      return -1;
    }
  };

  /**
 * Asynchronously fetches specific single data from the API with filtering options. 
 * Retrieves the latest data entry based on 'date_created'. The table must have the 'date_created' column available.
 * @param {string} collection_name - The name of the collection to fetch data from.
 * @param {string} retr_inf - Comma-separated fields to retrieve.
 * @param {string} filter_obj - The object/field name to apply the filter on.
 * @param {string} filter - The filter value for the specified field.
 * @returns {Promise<Object>} - Returns a Promise that resolves to the latest JSON response from the API based on the 'date_created' field.
 * Example:
 * const dataFetcher = new DataFetcher();
 * const fetched_data = await dataFetcher.fetch_data_by_filter_when_equalto_by_date_descending_order("Gruppen_Assessment", "Teilnehmer,Teilnehmer_Fertig,Assessment_Liste,Name", "Organisation", authentificationManager.getCookie("spital_Name"))
 * Returns an array of objects like:
 * [
 *    {
 *        Assessment_Liste: "X2rTQc9OhAIEN2RY2o74",
 *        Name: "Versuch 3",
 *        Teilnehmer: "51469532-ee7b-44c6-a0bf-2efded88ed6e,6cabd596-2435-4610-a9c1-90090bcce822",
 *        Teilnehmer_Fertig: "6cabd596-2435-4610-a9c1-90090bcce822",
 *        date_created: "2024-01-29T22:52:25.532Z",
 *    },
 *    {
 *        // ... other objects in the array
 *    },
 * ]
 */
  async fetch_data_by_filter_when_equalto_by_date_descending_order(collection_name, retr_inf, filter_obj, filter, second = 0) {
    let url = ""

    // Encoding '&' character for URL compatibility
    if (typeof (filter) === "string") {
      if (filter.includes("&")) {
        filter = filter.replace("&", "%26");
      }
    }

    // Constructing the API URL based on collection name
    if (collection_name === "users" || collection_name === "roles") {
      url = this.base_url + "/" + collection_name + this.entry_update + collection_name + this.datetime_descending + "&fields=date_created," + retr_inf + "&filter[" + filter_obj + "]=" + filter + "&sort[]=-date_created";
    }
    else {
      url = this.base_url_items + collection_name + this.entry_update + collection_name + this.datetime_descending + "&fields=date_created," + retr_inf + "&filter[" + filter_obj + "]=" + filter + "&sort[]=-date_created";
    }

    // Setting authentication headers
    this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
    this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));

    // Fetching data from the API
    const response = await fetch(url, {
      method: 'GET',
      headers: this.headers,
    });
    const json_resp = await response.json();

    try {
      // Ensuring the response contains data and is in array format
      if (json_resp.data && Array.isArray(json_resp.data) && json_resp.data.length > 0) {
        // Check if all specified fields are null
        if (json_resp.data.every(item => retr_inf.split(',').every(field => item[field] === null))) {
          return -1; // Returning -1 if all specified fields are null
        }
      }

      // Identifying the latest entry based on 'date_created'
      let dateArray = [];
      let latestDateIndex = -1;
      let latestDate = null;

      if (second == 0) {
        for (let i = 0; i < json_resp.data.length; i++) {
          let dateCreated = new Date(json_resp.data[i].date_created);
          dateArray.push(dateCreated);

          if (latestDate === null || dateCreated > latestDate) {
            latestDate = dateCreated;
            latestDateIndex = i;
          }
        }
      }
      else{
        for (let i = 0; i < json_resp.data.length; i++) {
          let dateCreated = new Date(json_resp.data[i].date_created);
          dateArray.push(dateCreated);

          if (latestDate === null || dateCreated > latestDate) {
            latestDate = dateCreated;
            latestDateIndex = i+second;
          }
        }
      }

      // Sorting the dates in descending order
      dateArray.sort((a, b) => b - a);

      // Extracting the latest data entry
      // console.log(latestDateIndex);
      const json_resp_latest = json_resp.data[latestDateIndex]
      return json_resp_latest;
    }

    catch (e) {
      return -1;
    }
  };


  /**
   * Asynchronously fetches specific data from the API with filtering options by fields comma-seperated I.e."ID,TEMP,TIME".
   * @param {*} collection_name the name of the collection to fetch.
   * @param {*} retr_inf comma-separated fields to retrieve e.g. you have email, username and password and you just want email and username you would enter "email, username"
   * @returns Returns a Promise that resolves to the JSON response from the API.
   * Example:
   * 
   * const dataFetcher = new DataFetcher();
   * const fetched_data = await dataFetcher.fetch_data_from_collection("Spitalfragen", "id,frage,index,min_value,max_value,hilfe");
   * 
   * Returns:
   * 
   * Promise {<pending>}
   * [[Prototype]]: Promise
   * [[PromiseState]]: "fulfilled"
   * [[PromiseResult]]: Object
   */
  async fetch_data_from_collection(collection_name, retr_inf) {
    try {
      // Constructing the URL for fetching data
      const url = this.base_url_items + collection_name + "?fields=" + retr_inf;

      // Setting up the authentication headers
      this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
      this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));

      // Performing the API request
      const response = await fetch(url, {
        method: 'GET',
        headers: this.headers,
      });

      // Parsing and returning the JSON response
      const json_resp = await response.json();
      return json_resp;
    } catch (e) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.log("Error fetching from collection " + collection_name);
    }
  };


  /**
   * 
   * @param {*} collection_name 
   * @param {*} retr_inf 
   * @returns 
   */
  async fetch_data_from_collection_newest_to_oldest_element(collection_name, retr_inf) {
    try {
      // Constructing the URL for fetching data
      const url = this.base_url_items + collection_name + "?fields=" + retr_inf + "&sort[]=-date_created";

      // Setting up the authentication headers
      this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
      this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));

      // Performing the API request
      const response = await fetch(url, {
        method: 'GET',
        headers: this.headers,
      });

      // Parsing and returning the JSON response
      const json_resp = await response.json();
      return json_resp;
    } catch (e) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.log("Error fetching from collection " + collection_name);
    }
  };


  /**
   * fetch user data
   * @param {*} retr_inf comma-separated fields to retrieve e.g. you have email, username and password and you just want email and username you would enter "email, username"
   * @param {*} email Email form user
   * @returns return user data
   * Example:
   * const dataFetcher = new DataFetcher();
   * const fetched_data = await dataFetcher.fetch_user_data('id,first_name,last_name,avatar,email,location', email);
   */
  async fetch_user_data(retr_inf, email) {
    try {
      // Constructing the URL for fetching user data
      const url = this.base_url + "/users?fields=" + retr_inf + "&filter[email]=" + email;

      // Setting up the authentication headers
      this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
      this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));

      // Performing the API request
      const response = await fetch(url, {
        method: 'GET',
        headers: this.headers,
      });

      // Parsing and returning the JSON response
      const json_resp = await response.json();
      return json_resp;
    } catch (e) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.log("Error fetching user data from email: " + email);
    }
  };


  /**
   * DISCLAIMER: Verifying the hash is currently not being used, thus no documentation for that
   * @param {*} pw_str 
   * @param {*} pw_hash 
   * @returns 
   */
  async verifyHash(pw_str, pw_hash) {
    // In process not working yet
    const verify_url = this.base_url + "/utils/hash/verify"
    // body to send
    const body = {
      "string": "joshy@mail.com",
      "hash": "test"
    };

    console.log('OUTPUT body:', body);
    this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
    this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));
    const response = await fetch(verify_url, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(body),
    });
    const json_resp = await response.json();
    console.log('Response: ', json_resp);
    return json_resp;
  }

  /**
   * Asynchronously posts data to the API.
   * @param {*} collection_name collection_name (String) - the name of the collection to which data will be posted.
   * @returns a Promise that resolves to the JSON response from the API.
   * Example:
   * const dataFetcher = new DataFetcher();
   * const dhiZieleBody = {
            "Spital": authentificationManager.getCookie('spital_Name'),
            "GesamtZiel": Math.round(exactMean),
            "GenauesGesamtZiel": exactMean,
            "Dimension1Ziel": strategieZiel,
            "Dimension2Ziel": prozesseZiel,
            "Dimension3Ziel": kulturZiel,
            "Dimension4Ziel": systemZiel,
            "Dimension5Ziel": produkteZiel,
        };
   * await dataFetcher.postData("DHI_Ziele", dhiZieleBody);
   */
  async postData(collection_name, body) {
    // Construct the URL for posting data
    const url = this.base_url_items + collection_name;

    // Set the required headers for the request
    this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
    this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));
    this.headers.set("Content-Type", "application/json")
    this.headers.set("Content-Length", "<calculated when request is sent>")

    // Perform the POST request
    const response = await fetch(url, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(body),
    });

    // Check if the response is successful
    const json_resp = await response.json();
    if (!response.ok) {
      console.log(json_resp);
      return json_resp;
    }

    // Parse and return the JSON response
    return json_resp;
  };

  /**
   * Asynchronously updates data in a specified collection.
   * @param {string} collection_name - The name of the collection to be updated.
   * @param {string|number} id - The ID of the specific entry to update.
   * @param {Object} body - The data to update in JSON format.
   * @returns {Promise<Object>} - Returns a Promise that resolves to the JSON response from the API.
   * Example:
   const dataFetcher = new DataFetcher();
   const dhiZieleBody = {
          "Spital": authentificationManager.getCookie('spital_Name'),
          "GesamtZiel": Math.round(exactMean),
          "GenauesGesamtZiel": exactMean,
          "Dimension1Ziel": strategieZiel,
          "Dimension2Ziel": prozesseZiel,
          "Dimension3Ziel": kulturZiel,
          "Dimension4Ziel": systemZiel,
          "Dimension5Ziel": produkteZiel,
      };
   await dataFetcher.updateData("DHI_Ziele", Zielid, dhiZieleBody);
   */
  async updateData(collection_name, id, body) {
    try {
      // Constructing the URL for updating data
      const url = this.base_url_items + collection_name + '/' + id;

      // Setting up the necessary headers for the request
      this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
      this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));
      this.headers.set("Content-Type", "application/json")
      this.headers.set("Content-Length", "<calculated when request is sent>")

      // Performing the PATCH request to update data
      try {
        const response = await fetch(url, {
          method: 'PATCH',
          headers: this.headers,
          body: JSON.stringify(body),
        });

        //console.log("PROBLEM");
        //console.log(response);

        // Checking if the response is successful
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Parsing and returning the JSON response
        const json_resp = await response.json();
        return json_resp;
      } catch (error) {
        console.error('Error in postData:', error);
        throw error;
      }
    } catch (e) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.log("Error updating data in collection " + collection_name);
    }
  };

  /**
   *  
   * 
   * 
   *  */
  async deleteTmpData(collection_name, index) {
    // Construct the URL for posting data
    const url = this.base_url_items + collection_name + '/' + index;

    // Set the required headers for the request
    this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
    this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));
    this.headers.set("Content-Type", "application/json")
    this.headers.set("Content-Length", "<calculated when request is sent>")

    const response = await fetch(url, {
      method: 'DELETE',
      headers: this.headers,
    });

    return 1;
  }


  /**
   * Gets the value of a cookie with the given name.
   * @param {string} name - The name of the cookie.
   * @returns {string|null} - The value of the cookie, or null if the cookie is not found.
   * Example:
   * const authentificationManager = new AuthentificationManager();
   * const user_id = authentificationManager.getCookie('user_id');
   * 
   * Returns e.g.:
   * 6cabd596-2435-4610-a9c1-90090bcce822
   */
  getCookie(name) {
    try {
      // Splitting the document cookie string into individual cookies
      const cookies = document.cookie.split(';');
      for (const cookie of cookies) {
        // Splitting each cookie into its name and value
        const [cookieName, cookieValue] = cookie.trim().split('=');
        // Returning the value if the name matches the requested cookie
        if (cookieName === name) {
          return cookieValue;
        }
      }
      // Returning null if the cookie is not found
    
      if(name === "user_id" || name === "dhi_auth_token" || name === "dhi_refresh_token" || name === "spital_Name") {
        this.naviToLoginPage();
        return null;
      }
    } catch (e) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.log("Error getting Cookie");
    }
  };


  /**
 * Redirects to the base URL if the user is not already there.
 */
  naviToLoginPage() {
    // Check if the current URL is already the base URL
    const isOnBaseURL = window.location.pathname === '/';

    if (!isOnBaseURL) {
      // Redirect to the base URL
      window.location.href = window.location.origin;
    }
  }




  async fetch_data_by_filter_when_equalto_by_bezugsdate_descending_order(collection_name, retr_inf, filter_obj, filter, second = 0) {
    let url = ""

    // Encoding '&' character for URL compatibility
    if (typeof (filter) === "string") {
      if (filter.includes("&")) {
        filter = filter.replace("&", "%26");
      }
    }

    // Constructing the API URL based on collection name
    if (collection_name === "users" || collection_name === "roles") {
      url = this.base_url + "/" + collection_name + this.entry_update + collection_name + this.datetime_descending + "&fields=bezugsdatum," + retr_inf + "&filter[" + filter_obj + "]=" + filter + "&sort[]=-bezugsdatum";
    }
    else {
      url = this.base_url_items + collection_name + this.entry_update + collection_name + this.datetime_descending + "&fields=bezugsdatum," + retr_inf + "&filter[" + filter_obj + "]=" + filter + "&sort[]=-bezugsdatum";
    }

    // Setting authentication headers
    this.headers.set('Authorization', 'Basic ' + btoa(this.username + ":" + this.password));
    this.headers.set('Authorization', 'Bearer ' + this.getCookie("dhi_auth_token"));

    // Fetching data from the API
    const response = await fetch(url, {
      method: 'GET',
      headers: this.headers,
    });
    const json_resp = await response.json();

    try {
      // Ensuring the response contains data and is in array format
      if (json_resp.data && Array.isArray(json_resp.data) && json_resp.data.length > 0) {
        // Check if all specified fields are null
        if (json_resp.data.every(item => retr_inf.split(',').every(field => item[field] === null))) {
          return -1; // Returning -1 if all specified fields are null
        }
      }

      // Identifying the latest entry based on 'date_created'
      let dateArray = [];
      let latestDateIndex = -1;
      let latestDate = null;

      if (second == 0) {
        for (let i = 0; i < json_resp.data.length; i++) {
          let dateCreated = new Date(json_resp.data[i].date_created);
          dateArray.push(dateCreated);

          if (latestDate === null || dateCreated > latestDate) {
            latestDate = dateCreated;
            latestDateIndex = i;
          }
        }
      }
      else{
        for (let i = 0; i < json_resp.data.length; i++) {
          let dateCreated = new Date(json_resp.data[i].date_created);
          dateArray.push(dateCreated);

          if (latestDate === null || dateCreated > latestDate) {
            latestDate = dateCreated;
            latestDateIndex = i+second;
          }
        }
      }

      // Sorting the dates in descending order
      dateArray.sort((a, b) => b - a);

      // Extracting the latest data entry
      // console.log(latestDateIndex);
      const json_resp_latest = json_resp.data[latestDateIndex]
      return json_resp_latest;
    }

    catch (e) {
      return -1;
    }
  };

}
export default DataFetcher;
