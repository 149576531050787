import React, { useState, useEffect } from "react";
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import DataFetcher from "../pages/classes/databaseManager";
import AuthentificationManager from "../pages/classes/authentificationManager";

import 'rsuite-table/dist/css/rsuite-table.css';
import "../widgets/PortfolioTabelleWidgets.css"
import "../pages/PortfolioTabelle.css"

const PortfolioTabelleMarkup = ({ in_volume, in_cashOut }) => {

  const [investitionsvolume, setInvestitionsvolume] = useState("");
  // const [cashOut, setCashOut] = useState("");
  const [gesamtInvestition, setGesamtInvestition] = useState(0);
  const [portfolioSize, setPortfolioSize] = useState(0);
  const authentificationManager = new AuthentificationManager();

  useEffect(() => {
    // Check if in_volume is positive or negative
    const volumeMovement = in_volume >= 0 ? `↑ ${in_volume}%` : `↓ ${Math.abs(in_volume)}%`;
    setInvestitionsvolume(volumeMovement);
  }, [in_volume]);

  // useEffect(() => {
  //   // Check if in_cashOut is positive or negative
  //   const cashOutMovement = in_cashOut >= 0 ? `↑ ${in_cashOut}%` : `↓ ${Math.abs(in_cashOut)}%`;
  //   setCashOut(cashOutMovement);
  // }, [in_cashOut]);



  const PortfolioTabelleTable = () => {
    const [data, setData] = useState([]);
    const [protfolioData, setPortfolioData] = useState(null);
    const dataFetcher = new DataFetcher();

    // Fetch data when the component is mounted
    useEffect(() => {
      const onPageLoad = async () => {
        try{
        let spital_name = authentificationManager.getCookie("spital_Name")

        const relevantProjektnames = await dataFetcher.fetch_data_by_filter_when_equalto("Portfolio", "projekt_name,status", "spital", spital_name);
        const response = await dataFetcher.fetch_data_from_collection("Projekte", "ID,Projektname,status,Typ,Dimension,Kategorie,Investitionsvolumen");
        const tempArray = [];
        let investitionen = 0;
        if (relevantProjektnames.data == undefined) {
          return;
        }
        for (let i = 0; i < relevantProjektnames["data"].length; i++) {
          const relevantProjektNames = relevantProjektnames["data"][i]["projekt_name"];
          for (let j = 0; j < response["data"].length; j++) {
            const allProjektNames = response["data"][j]["Projektname"];
            if (relevantProjektNames == allProjektNames) {
              switch (relevantProjektnames["data"][i]["status"]) {
                case "Geplant":
                  response["data"][j]["status"] = "0";
                  break;

                case "Start":
                  response["data"][j]["status"] = "1";
                  break;

                case "Fertig":
                  response["data"][j]["status"] = "4";
                  break;
              }
              investitionen += response["data"][j]["Investitionsvolumen"];
              tempArray.push(response["data"][j]);
              break;
            }
          }
        }
        setGesamtInvestition(investitionen);

        setPortfolioData(tempArray);
      } catch (e) {
        // Handle the error, e.g., display an error message or retry fetching.
        console.log("Error on page load when fetching investitionen from Portfolio.");
      }
    };

      onPageLoad();
    }, []);

    // If data is fetched from the backend, reassign tableData
    useEffect(() => {
      let iterator = 0;
      if (protfolioData !== null) {
        const updatedData = protfolioData.map(({ ID, Projektname, status, Typ, Kategorie, Dimension, Investitionsvolumen }) => {

          // Format dates as DD-MM-YYYY
          const formatDate = (dateString) => {
            const date = new Date(dateString);
            var returndate = date.toLocaleDateString('de-CH', { day: '2-digit', month: '2-digit', year: 'numeric' });

            if (returndate == "01.01.1970") {
              returndate = "Nicht gesetzt"
            }
            return returndate;
          };

          const formatLongText = (longString) => {
            if (longString === null) {
              return "";
            }
            const MAX_LENGTH = 10; // adjust this value to your liking
            const words = longString.split(' ');
            let formattedText = '';

            for (let i = 0; i < words.length; i++) {
              const word = words[i];
              if (formattedText.length + word.length > MAX_LENGTH) {
                formattedText += '\n';
              }
              formattedText += word + ' ';
            }
            return formattedText.trim();
          };

          // Format Status
          const formateStatus = (statusString) => {
            switch (statusString) {
              case '0': return 'Geplant';
              case '1': return 'In Bearbeitung';
              case '2': return 'In Prüfung';
              case '3': return 'Im Verzug';
              case '4': return 'Fertig';
            }
          }

          // Format Investitionsvolumen as desired
          const formattedVolume = new Intl.NumberFormat('de-CH', {
            style: 'currency',
            currency: 'CHF',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(Investitionsvolumen);

          // Remove the currency symbol from the formatted string and add " CHF" at the end
          //const volumeWithCHF = formattedVolume.replace('CHF', '') + ' CHF';
          iterator++;
          return {
            id: iterator,
            projektname: Projektname ?? '',
            typ: Typ ?? '',
            kategorie: formatLongText(Kategorie) ?? '',
            dimension: formatLongText(Dimension) ?? '',
            investitionsvolumen: parseFloat(Investitionsvolumen)?.toLocaleString('de-CH', { style: 'currency', currency: 'CHF', minimumFractionDigits: 2 }) ?? '',
            status: formateStatus(status) ?? ''
          };
        });

        setPortfolioSize(protfolioData.length);
        setData(updatedData);
      }
    }, [protfolioData]);

    const handleScroll = (x, y) => {
      // This should print 200 under normal conditions
      // console.log(data.length);
    };

    return (
      <Table
        fillHeight
        data={data}
        onScroll={handleScroll}
        cellBordered
        affixHeader
        wordWrap="break-word"
        style={{ width: "100%", position: "relative", zIndex: 0 }}>

        <Column flexGrow={1} resizable fullText>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>

        <Column flexGrow={6} resizable fullText>
          <HeaderCell>Projektname</HeaderCell>
          <Cell dataKey="projektname" />
        </Column>

        <Column flexGrow={2} resizable fullText>
          <HeaderCell>Typ</HeaderCell>
          <Cell dataKey="typ" />
        </Column>

        <Column flexGrow={3} resizable fullText>
          <HeaderCell>Dimension</HeaderCell>
          <Cell dataKey="dimension" />
        </Column>

        <Column flexGrow={6} resizable fullText>
          <HeaderCell>Kategorie</HeaderCell>
          <Cell dataKey="kategorie" />
        </Column>


        <Column flexGrow={4} resizable fullText>
          <HeaderCell>Investitionsvolumen</HeaderCell>
          <Cell dataKey="investitionsvolumen" />
        </Column>

        <Column flexGrow={3} resizable fullText>
          <HeaderCell>Status</HeaderCell>
          <Cell dataKey="status" />
        </Column>
      </Table>
    );
  };

  return (
    <div className="portfolio-screen">
      <div className="cashinfo-container">
        <div className="cashinfo">
          <div className="cashinfo-title">
            Gesamtportfolio
          </div>
          <div className="cashinfo-maincontent">
            <div className="cashinfo-maincontent-number">
              {portfolioSize + " Projekte und Programme"}
            </div>
          </div>
          <div className="cashinfo-year">
            2024
          </div>
        </div>
        <div className="cashinfo">
          <div className="cashinfo-title">
            Investitionen
          </div>
          <div className="cashinfo-maincontent">
            <div className="cashinfo-maincontent-number">
              {parseFloat(gesamtInvestition).toLocaleString('de-CH', { style: 'currency', currency: 'CHF', minimumFractionDigits: 2 })}
            </div>
            <div className={`cashinfo-maincontent-movement ${investitionsvolume.includes("↑") ? 'up' : 'down'}`}>
              {investitionsvolume}
            </div>
          </div>
          <div className="cashinfo-year">
            2024 - 2027
          </div>
        </div>
        {/* <div className="cashinfo">
        <div className="cashinfo-title">
          Cash out
        </div>
        <div className="cashinfo-maincontent">
          <div className="cashinfo-maincontent-number">
            CHF 000'000.00
          </div>
          <div className={`cashinfo-maincontent-movement ${cashOut.includes("↑") ? "up" : "down"}`}>
            {cashOut}
          </div>
        </div>
        <div className="cashinfo-year">
          2024
        </div>
      </div> */}
      </div>
      <div className="singleContent-container">
        <div className="portfolio-title">
          Projekte und Programme
        </div>
        <div className="portfolio-content">
          <PortfolioTabelleTable />
        </div>
      </div>
    </div>
  );
};

export default PortfolioTabelleMarkup;
