import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    LabelList,
} from 'recharts';
// For Radial bar
import ApexCharts from 'apexcharts'
import { useEffect, useState } from "react";
// For Radial Chart
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto';
import AuthentificationManager from "../pages/classes/authentificationManager";


/**
 * UserSelectList Component: A user list selection component with filtering and multi-select functionality.
 * 
 * @param {Object[]} users - An array of user objects to display and select from.
 * @param {Function} onUserSelectionChange - A callback function called when user selection changes.
 * @returns {JSX.Element} - Returns JSX elements to render the user selection list.
 */
export const UserSelectList = ({ users, onUserSelectionChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserIndexes, setSelectedUserIndexes] = useState({});

  useEffect(() => {
    const initialSelectedUserIds = users.slice(0, 3).map(user => user.id);
    setSelectedUserIds(initialSelectedUserIds);
    updateSelectedUserIndexes(initialSelectedUserIds);
  }, [users]);

  useEffect(() => {
    const filtered = users.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchTerm, users]);



  
  const updateSelectedUserIndexes = (selectedIds) => {
    const newIndexes = {};
    selectedIds
      .sort((a, b) => users.findIndex(user => user.id === a) - users.findIndex(user => user.id === b))
      .forEach((id, index) => {
        newIndexes[id] = index + 1;
      });
    setSelectedUserIndexes(newIndexes);
  };

  const handleSelectAllChange = (isChecked) => {
    const newSelectedUserIds = isChecked ? users.map(user => user.id) : [];
    setSelectedUserIds(newSelectedUserIds);
    onUserSelectionChange(newSelectedUserIds);
    updateSelectedUserIndexes(newSelectedUserIds);
  };

  const handleUserClick = userId => {
    const isSelected = selectedUserIds.includes(userId);
    const updatedSelectedUserIds = isSelected
      ? selectedUserIds.filter(id => id !== userId)
      : [...selectedUserIds, userId];
    setSelectedUserIds(updatedSelectedUserIds);
    onUserSelectionChange(updatedSelectedUserIds);
    updateSelectedUserIndexes(updatedSelectedUserIds);
  };

  return (
    <div className="user-select-container">
      <div className='user-select-innercontainer'>
        <div className="select-all-container">
          <input
            type="checkbox"
            id="select-all-checkbox"
            checked={selectedUserIds.length === users.length}
            onChange={(e) => handleSelectAllChange(e.target.checked)}
          />
          <label htmlFor="select-all-checkbox">Alle auswählen</label>
        </div>
        <input
          type="text"
          placeholder="Suche..."
          value={searchTerm}
          className="search-input"
          onChange={e => setSearchTerm(e.target.value)}
        />
        <ul className="user-list">
          {filteredUsers.map((user) => (
            <div key={`div-${user.id}`} className='user-list-itemcontainer'>
              <li key={`li-${user.id}`}
                className={`user-item ${selectedUserIndexes[user.id] ? `selected-${selectedUserIndexes[user.id]}` : ''}`}
                onClick={() => handleUserClick(user.id)}>
                <div className={`user-item-text-container`}>
                  {user.name}
                  {selectedUserIds.includes(user.id) && <span className="checkmark">✓</span>}
                </div>
              </li>
            </div>
          ))}
        </ul>
        <div className="selection-counter">
          {selectedUserIds.length} ausgewählt
        </div>
      </div>
    </div>
  );
};


/**
 * GesamtauswertungRadarChart Component: A radar chart component for displaying average scores across categories.
 * 
 * @param {Object[]} users - An array of user objects containing scores for different categories.
 * @returns {JSX.Element} - Returns JSX elements to render the radar chart.
 */
export const GesamtauswertungRadarChart = ({ users }) => {

    // Colors with low opacity for dataset backgrounds
    const colors = [
        'rgba(255, 99, 132, 0.2)',  // Pink
        'rgba(54, 162, 235, 0.2)',  // Blue
        'rgba(255, 206, 86, 0.2)',  // Yellow
        'rgba(75, 192, 192, 0.2)',  // Green
        'rgba(255, 0, 0, 0.2)',     // Red
        'rgba(0, 255, 0, 0.2)',     // Lime
        'rgba(0, 0, 255, 0.2)',     // Blue
        'rgba(128, 0, 128, 0.2)',   // Purple
        'rgba(255, 255, 0, 0.2)',   // Cyan
        'rgba(255, 165, 0, 0.2)',   // Orange
        'rgba(139, 69, 19, 0.2)',   // Brown
        'rgba(0, 128, 0, 0.2)',     // Olive
        'rgba(0, 0, 128, 0.2)',     // Navy
        'rgba(128, 0, 0, 0.2)',     // Maroon
        'rgba(0, 255, 255, 0.2)',   // Aqua
        'rgba(128, 128, 0, 0.2)',   // Teal
        'rgba(255, 0, 255, 0.2)',   // Fuchsia
        'rgba(128, 128, 128, 0.2)', // Gray
        'rgba(192, 192, 192, 0.2)', // Silver
        'rgba(0, 0, 0, 0.2)',        // Black
        'rgba(255, 255, 255, 0.2)'
    ];

    // Colors with full opacity for dataset borders
    const borderColors = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(255, 0, 0, 1)',
        'rgba(0, 255, 0, 1)',
        'rgba(0, 0, 255, 1)',
        'rgba(128, 0, 128, 1)',
        'rgba(255, 255, 0, 1)',
        'rgba(255, 165, 0, 1)',
        'rgba(139, 69, 19, 1)',
        'rgba(0, 128, 0, 1)',
        'rgba(0, 0, 128, 1)',
        'rgba(128, 0, 0, 1)',
        'rgba(0, 255, 255, 1)',
        'rgba(128, 128, 0, 1)',
        'rgba(255, 0, 255, 1)',
        'rgba(128, 128, 128, 1)',
        'rgba(192, 192, 192, 1)',
        'rgba(0, 0, 0, 1)',
        'rgba(255, 255, 255, 1)'
    ];

    const filteredUsers = users.filter(user => {
        return user.score_1 !== 0 && user.score_2 !== 0 && user.score_3 !== 0 && user.score_4 !== 0 && user.score_5 !== 0;
    });

    // Calculate average scores for each category
    const averageScores = filteredUsers.reduce((acc, user) => {
        acc[0] += user.score_1;
        acc[1] += user.score_2;
        acc[2] += user.score_3;
        acc[3] += user.score_4;
        acc[4] += user.score_5;
        return acc;
    }, [0, 0, 0, 0, 0]).map(score => parseFloat((score / filteredUsers.length).toFixed(1)));

    // Create datasets for users and the average
    const datasets = filteredUsers.map((user, index) => ({
        label: user.name, 
        data: [user.score_1, user.score_2, user.score_3, user.score_4, user.score_5],
        backgroundColor: colors[index % colors.length],
        borderColor: borderColors[index % borderColors.length],
        borderWidth: 2, // Increased border width for more definition
    }));

    // Add a dataset for the average scores
    datasets.push({
        label: 'Durchschnitt',
        data: averageScores,
        backgroundColor: colors[21],
        borderColor: borderColors[21],
        borderWidth: 2
    });

    // Define data and options for the radar chart
    const data = {
        labels: [
            'Strategie, Governance & Organisation',
            'Prozesse & Patient Journey',
            'Leadership, Kultur & Menschen',
            'Systeme & Daten',
            'Produkte & Innovationen'
        ],
        datasets: datasets
    };

    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true
                },
                pointLabels: {
                    font: {
                        family: 'Inter',
                        size: 16,
                        style: 'normal',
                        weight: 600,
                    },
                    color: 'black',
                    padding: 20
                },
                suggestedMin: 1,
                suggestedMax: 5
            }
        },
        plugins: {
            legend: {
                display: true, // Enable the legend
                position: 'top', // Set the legend position
                labels: {
                    usePointStyle: true, // Use point style for the legend items
                    padding: 20, // Adjust padding
                    font: {
                        size: 14, // Adjust font size
                        fontWeight: 600
                    }
                }
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
            }
        },
        maintainAspectRatio: false
    };
    

    return (
        <div className="radar-chart-container">
            <Radar data={data} options={options} />
        </div>
    );
};



/**
 * GesamtauswertungTitleHeader Component: Renders the title header for the chart.
 * 
 * @param {string} name - The title to be displayed.
 * @returns {JSX.Element} - Returns JSX elements to render the chart title header.
 */
export const GesamtauswertungTitleHeader = ({ name }) => (
    <div className="chart-header-container">
        <div className="chart-title-container">
            <h2 className="chart-title">{name}</h2>
        </div>

    </div>
);

/**
 * GesamtauswertungBarChartLegend Component: Renders a legend for the bar chart.
 * 
 * @returns {JSX.Element} - Returns JSX elements to render the legend.
 */
export const GesamtauswertungBarChartLegend = () => (
    <div className="chart-legend-container">
        <div className="legend-item">
            <span className="dot dot-ist"></span> IST
        </div>
        <div className="legend-item">
            <span className="dot dot-lv"></span> Durchschnitt
        </div>
        <div className="legend-item">
            <span className="dot dot-ziel"></span> Ziel
        </div>
    </div>
);

/**
 * GesamtauswertungBarChart Component: Renders a bar chart with data and customization.
 * 
 * @param {Object[]} data - An array of data objects to be displayed in the bar chart.
 * @param {string} dimension - The dimension for the bar chart (e.g., 'score_1', 'score_2', etc.).
 * @param {number} avgValue - The average value to be displayed as a reference line.
 * @param {number} goalValue - The goal value to be displayed as a reference line.
 * @returns {JSX.Element} - Returns JSX elements to render the bar chart.
 */
export const GesamtauswertungBarChart = ({ data, dimension, avgValue, goalValue }) => (
    <ResponsiveContainer width="100%" height={300}>

        <BarChart
            data={data}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            barSize={40} // Increased bar size for thicker bars
        >
            <XAxis dataKey="name_short" axisLine={false} tickLine={false} />
            <YAxis hide domain={[0, 5]} />
            {/* Removed the Tooltip component to disable the hover function */}
            <Tooltip
                isAnimationActive={false}
                content={<CustomGesamtauswertungBarChartTooltip dimension={dimension} />}
                wrapperStyle={{ pointerEvents: 'none' }} // Prevent interaction with underlying elements
                itemStyle={{ display: 'none' }} // Hide individual tooltip items
                labelStyle={{ display: 'none' }} // Hide the tooltip label
                cursor={false} // Disable the cursor
                separator=": " // Customize the separator between label and value
            />
            <Bar dataKey={dimension} fill="#347ae2" radius={[10, 10, 10, 10]} background={{ fill: '#eee' }}>
                {/* Adjusted the style for LabelList */}
                <LabelList dataKey={dimension} position="insideTop" offset={10} style={{ fill: 'white', fontWeight: 'bold', fontSize: '110%' }}
                 formatter={(value) => value > 0 ? value : ''}/>
            </Bar>

            {/* Adjusted the ReferenceLine components */}
            <ReferenceLine y={avgValue} stroke="#ff9500" strokeDasharray="3 3" strokeWidth={2} isFront />
            <ReferenceLine y={goalValue} stroke="#53ca43" strokeDasharray="3 3" strokeWidth={2} isFront />

        </BarChart>
    </ResponsiveContainer>
);

/**
 * CustomGesamtauswertungBarChartTooltip Component: Renders a custom tooltip for the bar chart.
 * 
 * @param {boolean} active - Indicates whether the tooltip is active.
 * @param {Object[]} payload - An array of payload objects for tooltip data.
 * @param {string} dimension - The dimension for the bar chart (e.g., 'score_1', 'score_2', etc.).
 * @returns {JSX.Element} - Returns JSX elements to render the custom tooltip.
 */
const CustomGesamtauswertungBarChartTooltip = ({ active, payload, dimension }) => {
    let value;
    let name;

    if (active && payload && payload.length) {

        const { name: fullname, [dimension]: val } = payload[0].payload; // Change 'value' to 'val' to avoid conflict
        value = val;
        name = fullname;

        // Check if value is a number and convert it if necessary
        const numericValue = typeof value === 'number' ? value : parseFloat(value);

        // Now we check if numericValue is a valid number before calling toFixed
        const formattedValue = !isNaN(numericValue) ? numericValue.toFixed(1) : 'N/A'; // If it's not a number, show 'N/A'

        return (
            <div className="recharts-tooltip-wrapper" style={{
                backgroundColor: '#f0f0f0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                fontSize: '14px',
                boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)'
            }}>
                <p className="label">{name}</p> {/* Full name here */}
                <p className="intro">{`Durchschnittswert: ${formattedValue}`}</p> {/* Mean value here, with check for validity */}
            </div>
        );
    }
    return null;
};

/**
 * GesamtauswertungRadialChartChart Component: Renders a radial chart displaying the average score.
 * 
 * @param {string} id - The HTML element id where the chart will be rendered.
 * @param {number} Durchschnitt - The average score to be displayed in the radial chart.
 * @returns {JSX.Element} - Returns JSX elements to render the radial chart.
 */
export const GesamtauswertungRadialChartChart = ({ id, Durchschnitt }) => {
    useEffect(() => {
        // Calculate the percentage for the radial bar to fill
        const fillPercentage = (Durchschnitt / 5) * 100;

        // Chart options
        const options = {
            chart: {
                width: '100%',
                height: '100%',
                type: 'radialBar',
            },
            series: [fillPercentage], // Set the calculated fill percentage
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '70%',
                        background: 'transparent',
                    },
                    track: {
                        background: '#f2f2f2',
                        strokeWidth: '100%',
                        margin: 0,
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            show: false, // Not showing this label
                        },
                        value: {
                            formatter: function (val) {
                                // Display the "Durchschnitt" value as "Ø 3"
                                return `Ø ${Durchschnitt}`;
                            },
                            color: '#ff9500', // Set to orange color
                            fontSize: '30px', // Increased font size for better visibility
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600, // Make the font bold
                        }
                    }
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#ffab00'], // Adjust the ending color of the gradient if needed
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100]
                },
            },
            stroke: {
                lineCap: 'round', // This gives the bar end the round shape
            },
            colors: ['#ff9500'], // Starting color of the gradient
            labels: ['Durchschnitt'] // Not used since we're not showing this label
        };

        // Initialize chart
        const chart = new ApexCharts(document.getElementById(id), options);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, [id, Durchschnitt]); // Add id to the dependency array

    // Use the provided id for the div
    return <div id={id}></div>;
};


/**
 * GesamtauswertungRadialChartContainer Component: Renders a container for the radial chart and related information.
 * 
 * @param {string} id - The HTML element id where the chart will be rendered.
 * @param {number} Durchschnitt - The average score to be displayed in the radial chart.
 * @param {number} AnzahlBeantworteteUser - The number of users who completed assessments.
 * @param {number} AnzahlUser - The total number of users.
 * @returns {JSX.Element} - Returns JSX elements to render the radial chart container.
 */
export const GesamtauswertungRadialChartContainer = ({ id, Durchschnitt, AnzahlBeantworteteUser, AnzahlUser }) => {
    // Pass the id to the GesamtauswertungRadialChartChart component
    return (
        <div>
            <div className="radial-chart-gesamtbewertung-container">
                <GesamtauswertungRadialChartChart id={id} Durchschnitt={Durchschnitt} />
            </div>
            <div className="radial-chart-gesamtbewertung-description">
                <h5>{AnzahlBeantworteteUser} von {AnzahlUser} Assessments abgeschlossen</h5>
            </div>
        </div>
    );
};


/**
 * UserProfileListItem Component: Renders a user profile card with information.
 * 
 * @param {string} userId - The unique identifier for the user.
 * @param {string} userName - The name of the user.
 * @param {string} userRole - The role of the user.
 * @param {string} userScore - The user's score in the format 'currentScore/totalScore'.
 * @param {number[]} answers - An array of user's answers.
 * @param {boolean} isSelected - Indicates if the user is selected.
 * @param {Function} onSelectionChange - Callback function when the user selection changes.
 * @returns {JSX.Element} - Returns JSX elements to render the user profile card.
 */
export const UserProfileListItem = ({ userId, userName, userRole, userScore, answers, isSelected, onSelectionChange, imgID, key}) => {
    // Assume userScore is like '3.2/5'
    
    const authentificationManager = new AuthentificationManager();
    if(imgID === undefined) {
        imgID = "ed148a10-bf60-4b86-8671-ace505b54370";
    }
    const [currentScore, totalScore] = userScore.split('/');

    // Function to check if the answers contain 0
    const containsZero = answers.some(answer => answer === 0);

    // Determine the class for the card based on whether it contains a 0
    let cardClass = "usercard-border";
    if (containsZero) {
        cardClass += isSelected ? " greyed-out" : "";
    } else {
        cardClass += isSelected ? " usercard-selected" : "";
    }


    // Simulate checkbox behavior with card click
    const handleCardClick = () => {
        // Toggle the isSelected state
        onSelectionChange(userId, !isSelected);
    };

    return (
        <div className={cardClass} onClick={handleCardClick}>
            <div className='usercard-inner-container'>
                <div className="user-profile-header">
                    <img src={authentificationManager.getbaseURL() + "/assets/" + imgID} alt={userName} className="user-profile-image" draggable="false" />
                    <div className='user-nameSection-container'>
                        <div className="user-profile-name">{userName}</div>
                        <div className="user-profile-role">{userRole}</div>
                    </div>
                </div>
                <div className="user-profile-body">
                    <div className="user-profile-score-value">Wert: {currentScore}/{totalScore}</div>
                    <div className="user-profile-score-bars">
                        {answers.map((answer, index) => (
                            <div key={index} className={`score-bar score-bar-${answer}`}></div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
