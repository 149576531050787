import React from 'react';
import Slider from '@mui/material/Slider';
import { Box, styled } from '@mui/system';


// Define custom styled components based on the Slider component
const ISTSlider1 = styled(Slider)(({  }) => ({
    position: 'relative',
    width: '100%',
    '& .MuiSlider-thumb': {
      visibility: 'visible',
      backgroundColor: '#347AE2', // Change the handle color here for the first slider
    },
    // Override the display properties for the rail, track, and marks
    '& .MuiSlider-rail': {
      visibility: 'visible', // Show the rail
      backgroundColor: '#7F56D9',
      height: '8px', // Increase the height of the rail and track
      border: 'none',
    },
    '& .MuiSlider-track': {
      visibility: 'visible', // Show the track
      backgroundColor: '#7F56D9',
      height: '8px', // Increase the height of the rail and track
      border: 'none',
    },
    '& .MuiSlider-markLabel': {
      display: 'block', // Show the marks
      border: 'none',
      fontSize: '1rem'
    },
    '& .MuiSlider-valueLabel': {
      backgroundColor: 'transparent', // Set the background to transparent
      color: '#000', // Optional: set the text color to match your theme
      top: '1px !important',
      '& span': {
        fontSize: '1rem', // Adjust the font size for the value label text
      },
    },
    
  }));
  
  const BenchmarkSlider1 = styled(Slider)(({ theme }) => ({
    width: '100%',
    '& .MuiSlider-rail': {
      display: 'none',
      height: '8px', // Increase the height of the rail and track
      border: 'none', 
    },
    '& .MuiSlider-track': {
      display: 'none',
      backgroundColor: 'white',
      height: '8px', // Increase the height of the rail and track
      border: 'none', 
    },
    '& .MuiSlider-thumb': {
      visibility: 'visible',
      backgroundColor: '#FF9500', // Change the handle color here for the second slider
    },
    '& .MuiSlider-valueLabel': {
      backgroundColor: 'transparent', // Set the background to transparent
      color: '#000', // Optional: set the text color to match your theme
      top: '1px !important',
      '& span': {
        fontSize: '1rem', // Adjust the font size for the value label text
      },
    },
  }));
  
  const ZielSlider1 = styled(Slider)(({ theme }) => ({
    width: '100%',
    '& .MuiSlider-rail': {
      display: 'none',
      height: '8px', // Increase the height of the rail and track
      border: 'none',
    },
    '& .MuiSlider-track': {
      display: 'none',
      backgroundColor: 'white',
      height: '8px', // Increase the height of the rail and track
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      visibility: 'visible',
      backgroundColor: '#34C759', // Change the handle color here for the third slider
    },
    '& .MuiSlider-valueLabel': {
      backgroundColor: 'transparent', // Set the background to transparent
      color: '#000', // Optional: set the text color to match your theme
      top: '1px !important',
      '& span': {
        fontSize: '1rem', // Adjust the font size for the value label text
      },
    },
  }));
  
export const TripleSlider = ({ IST, CH, Ziel, onZielChange }) => {
  const [values, setValues] = React.useState([IST, CH, Ziel]);

  React.useEffect(() => {
    setValues([IST, CH, Ziel]);
  }, [IST, CH, Ziel]);

  const handleChange = (event, newValue, index) => {
    const newValues = [...values];
    newValues[index] = newValue;
    setValues(newValues);
    if (onZielChange) {
      onZielChange(newValue);
    }
  };

  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
  ];

  function valuetext(value) {
    return `${value.toFixed(1)}°C`; // Adding toFixed(1) to ensure one decimal place
  }

  
    // Custom label format function to ensure always one decimal place
    const valueLabelFormat = (value) => {
      return value % 1 === 0 ? `${value}.0` : `${value}`;
    };
  
    return (
      <Box position="relative" sx={{ width: '100%' }} height={20} mt={2}>
        <ISTSlider1
          value={values[0]}
          onChange={(e, newValue) => handleChange(e, newValue, 0)}
          step={0.1}
          valueLabelDisplay="on"
          marks={marks}
          min={1}
          max={5}
          disabled
          valueLabelFormat={valueLabelFormat} // Apply custom label format
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            //zIndex: 1,
          }}
        />
        <BenchmarkSlider1
          value={values[1]}
          onChange={(e, newValue) => handleChange(e, newValue, 1)}
          step={0.1}
          valueLabelDisplay="on"
          min={1}
          max={5}
          track={false}
          disabled
          valueLabelFormat={valueLabelFormat} // Apply custom label format
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            //zIndex: 2,
          }}
        />
        <ZielSlider1
          value={values[2]}
          onChange={(e, newValue) => handleChange(e, newValue, 2)}
          step={1}
          valueLabelDisplay="on"
          min={1}
          max={5}
          valueLabelFormat={valueLabelFormat} // Apply custom label format
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            //zIndex: 3,
          }}
        />
      </Box>
    );
  };