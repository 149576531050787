import React, { useState, useEffect } from 'react';
import './GlossaryWidget.css';
import DataFetcher from "../pages/classes/databaseManager";


const GlossaryWidget = () => {
    const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [glossaryData, setGlossaryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGlossaryData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const dataFetcher = new DataFetcher();
                const fetchedData = await dataFetcher.fetchCollection("Glossar");
                const processedData = fetchedData.data.map(({ wort, bedeutung }) => ({
                    name: wort, // 'wort' from the fetched data becomes 'name'
                    description: bedeutung // 'bedeutung' from the fetched data becomes 'description'
                }));
                // Sort alphabetically by 'name'
                processedData.sort((a, b) => a.name.localeCompare(b.name));
                setGlossaryData(processedData);
            } catch (e) {
                console.log("Failing to fetch glossary data in Glossary Widget.");
            }
            setIsLoading(false);
        };

        fetchGlossaryData();
    }, []);

    const toggleGlossary = () => setIsGlossaryOpen(!isGlossaryOpen);
    const onSearchChange = (e) => setSearchTerm(e.target.value.toLowerCase());

    const filteredTerms = glossaryData.filter((term) =>
        term.name.toLowerCase().includes(searchTerm)
    );

    return (
        <>
            <div className="glossary-toggle-button" onClick={toggleGlossary}>
                <img src='/glossar_icon.svg' className="glossary-toggle-icon" onClick={() => setIsGlossaryOpen(false)} draggable={false}/>
            </div>
            {isGlossaryOpen && (
                <div className='glossary-modal'>
                    <div className="glossary-header">
                        <h2 className="glossary-title">Glossar</h2>
                        <img src='/closeButton.svg' className="glossary-toggle-close-button" onClick={() => setIsGlossaryOpen(false)} draggable={false}/>
                    </div>
                    <input
                        className='glossary-search'
                        type='text'
                        placeholder='Suche ein Begriff...'
                        value={searchTerm}
                        onChange={onSearchChange}
                    />
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : error ? (
                        <div>Error: {error}</div>
                    ) : (
                        <div className="glossary-entries">
                            {filteredTerms.map((term) => (
                                <div key={term.name} className='glossary-entry'>
                                    <div className='glossary-name'>{term.name}</div>
                                    <div className='glossary-description'>{term.description}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default GlossaryWidget;