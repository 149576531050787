import React from "react";
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import { useState, useEffect } from "react";
import Slider from '@mui/material/Slider';
import { Box, styled } from '@mui/system';
import getSpitalReifegradTableData from "../pages/classes/getSpitalReifegradDaten";
import spitaldatenManager from "../pages/classes/spitaldatenManager";
import DataFetcher from "./../pages/classes/databaseManager";
import AuthentificationManager from "../pages/classes/authentificationManager";

import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'rsuite-table/dist/css/rsuite-table.css';
import "../widgets/BewertungAllgemeineInformatiWidgets.css";
import "../pages/BewertungAllgemeineInformati.css";
import { Input } from "@mui/material";

const EditableCell = ({ rowData, dataKey, onChange, borderRedAntwort, borderRedBezugsdatum, isDate, ...props }) => {
  const editing = rowData.status === 'EDIT';
  return (
    <Cell {...props} className={editing ? 'table-content-editing' : ''}>
      {editing ? (
        <input
          className={`table-content-editing-input ${borderRedAntwort ? 'table-content-editing-input-red' : ''} ${borderRedBezugsdatum ? 'table-content-editing-input-red' : ''}`}
          type={`${isDate ? 'date' : 'text'}`}
          defaultValue={rowData[dataKey]}
          onChange={event => {
            onChange && onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};

const ActionCell = ({ rowData, dataKey, onClick, onBreak, ...props }) => {
  return (
    <Cell {...props} style={{ padding: '6px' }}>
      {rowData.status === 'EDIT' ?
        <div className="table-content-save-stop-button">
          {/* Save Button */}
          <div
            className="table-content-save-button"
            // appearance="link"
            onClick={() => { onClick(rowData.id); }}
          >
            &#10003;
          </div>

          {/* Break Button */}
          <div
            className="table-content-stop-button"
            // appearance="link"
            onClick={() => { onBreak(rowData.id); }}
          >
            &#10007;
          </div>
        </div>
        :
        <div
          className="table-content-edit-button"
          // appearance="link"
          onClick={() => { onClick(rowData.id); }}
        >
          Edit
        </div>}
    </Cell>
  );
};

export const BewertungAllgemeineInformatiMarkup = ({ onItemXLClick, onItemXL1Click, onItemXL2Click, linkToAuswertung, linkToFragebogen, linkToGruppenAuswertung, overlayContentLink, verticalNavigationBarList }) => {

  const [tableData, setTableData] = useState([]);
  const [selectedBox, setSelectedBox] = useState(null);
  const [updateData, setUpdateData] = useState(true);
  const [borderRedAntwort, setBorderRedAntwort] = useState(false);
  const [borderRedBezugsdatum, setBorderRedBezugsdatum] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [selectorValue, setSelectorValue] = useState(1);
  function handleSelect(event) {
    setSelectorValue(event.target.value);
  }

  const dataFetcher = new DataFetcher();
  const authentificationManager = new AuthentificationManager();

  /**
   * Function to get the data to fill the table. When called it will update the table data.
   */
  const getTableData = async (selectorValue) => {
    try {
      const user_id = authentificationManager.getCookie('user_id');
      const resp_spitalfragen_promise = dataFetcher.fetch_data_from_collection("Spitalfragen", "id,frage,index,min_value,max_value,hilfe");
      const resp_spitaldaten_promise = dataFetcher.fetch_data_by_filter_when_equalto("Spitaldaten", "antwort,kommentar,frage_id,bezugsdatum,user_created,user_updated,date_created,date_updated", "user_id", user_id);

      const [resp_spitalfragen, resp_spitaldaten] = await Promise.all([resp_spitalfragen_promise, resp_spitaldaten_promise]);

      if (resp_spitaldaten.data.length > 0) {

        // Find the latest answers
        // Sorting the spitaldaten by bezugsdatum
        const dateSortedSpitaldaten = resp_spitaldaten.data.sort((a, b) => new Date(a.bezugsdatum) - new Date(b.bezugsdatum));
        // Extract unique years using Set and map
        const uniqueYearsSet = new Set(dateSortedSpitaldaten.map(item => item.bezugsdatum.split('-')[0]));
        // Sort them descending
        const uniqueYears = Array.from(uniqueYearsSet).sort((a, b) => b - a);

        // Create the selectorYearList directly
        const selectorYearList = uniqueYears.map((item, index) => ({
          label: item,
          value: index + 1
        }));
        setYearList(selectorYearList);
        const selectedYear = selectorYearList.find(item => item.value == parseInt(selectorValue)).label;

        const relevantSpitaldaten = dateSortedSpitaldaten.filter(item => {
          const year = new Date(item.bezugsdatum).getFullYear();
          return year === parseInt(selectedYear);
        })

        // Build the graph history data
        const historySpitaldaten = [];
        for (let i = 1; i <= resp_spitalfragen.data.length; i++) {
          const historyAntwortArray = [];
          relevantSpitaldaten.map(item => {
            if (parseInt(item.frage_id) == i) {
              historyAntwortArray.push({
                antwort: item.antwort,
                kommentar: item.kommentar,
                date_updated: item.date_updated,
                frage_id: item.frage_id,
                bezugsdatum: item.bezugsdatum
              });
            }
          });
          historySpitaldaten.push(historyAntwortArray);
        }

        // Merge spitaldaten and spitalfragen and set tableData to the merged value
        const tempData = resp_spitalfragen.data.map((item, index) => {
          // Answer exist
          if (historySpitaldaten[index].length != 0) {
            return {
              ...item,
              antwort: historySpitaldaten[index][historySpitaldaten[index].length - 1].antwort,
              kommentar: historySpitaldaten[index][historySpitaldaten[index].length - 1].kommentar,
              bezugsdatum: historySpitaldaten[index][historySpitaldaten[index].length - 1].bezugsdatum
            };
          }
          // Answer does not exist
          else {
            return {
              ...item,
              antwort: "",
              kommentar: "",
              bezugsdatum: ""
            };
          }

        });
        const tempTabData = tempData.filter(item => item.id !== 21)
        const tempSliderData = tempData.filter(item => item.id === 21);
        if(tempSliderData[0].antwort !== "") {
          setSelectedBox(parseInt(tempSliderData[0].antwort)-1);
        }
        setTableData(tempTabData);
        // No Answer exist
      } else {
        const tempData = resp_spitalfragen.data.map(item => {
          return {
            ...item,
            antwort: "",
            kommentar: "",
            bezugsdatum: ""
          };
        })
        const tempTabData = tempData.filter(item => item.id !== 21)
        setTableData(tempTabData);
      }
    } catch (e) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.log("Error getting the table date in Allgemeine Informationen");
    }
  };

  useEffect(() => {
    // Update the table Data. Will be executed on the initial load.
    if (updateData) {
      getTableData(selectorValue);
      setUpdateData(!updateData);
      setBorderRedAntwort(false);
      setBorderRedBezugsdatum(false);
    }
  }, [updateData]);

  useEffect(() => {
    getTableData(selectorValue);
  }, [selectorValue]);

  // handler of the input when a cell is editable
  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], tableData);
    nextData.find(item => item.id === id)[key] = value;
    setTableData(nextData);
  };

  // handler for the edit button
  const handleSave = async (id) => {
    try {
      const nextData = Object.assign([], tableData);
      const activeItem = nextData.find(item => item.id === id);
      // Switch Mode EDIT = You can save and break or null = you can change nothing
      activeItem.status = activeItem.status ? null : 'EDIT';
      setTableData(nextData);

      // Code to save the changed data in the backend
      if (activeItem.status != 'EDIT') {
        // check if antwort is empty
        if (tableData[id - 1].antwort === "" && !(tableData[id - 1].bezugsdatum === "")) {
          // Toast 
          toast.error('Antwort ist leer. Bitte ausfüllen', { autoClose: 2000, position: toast.POSITION.BOTTOM_CENTER });
          setBorderRedAntwort(true);
          setBorderRedBezugsdatum(false);
          // Stay in edit mode
          activeItem.status = activeItem.status ? null : 'EDIT';
        } else if (tableData[id - 1].bezugsdatum === "" && !(tableData[id - 1].antwort === "")) {
          // Toast
          toast.error('Bezugsdatum ist leer. Bitte ausfüllen', { autoClose: 2000, position: toast.POSITION.BOTTOM_CENTER });
          setBorderRedAntwort(false);
          setBorderRedBezugsdatum(true);
          // Stay in edit mode
          activeItem.status = activeItem.status ? null : 'EDIT';
        } else if (tableData[id - 1].antwort === "" && tableData[id - 1].bezugsdatum === "") {
          // Toast
          toast.error('Antwort und Bezugsdatum ist leer. Bitte ausfüllen', { autoClose: 2000, position: toast.POSITION.BOTTOM_CENTER });
          setBorderRedAntwort(true);
          setBorderRedBezugsdatum(true);
          // Stay in edit mode
          activeItem.status = activeItem.status ? null : 'EDIT';
        } else {

          // Check if Antwort is in limits
          // Antwort not in the limit give feedback
          if (parseInt(tableData[id - 1].antwort) <= parseInt(tableData[id - 1].max_value) && parseInt(tableData[id - 1].antwort) >= parseInt(tableData[id - 1].min_value)) {
            // Post the answer in directus
            const currentDate = new Date();
            const formattedDateTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
            const saveBody = {
              user_created: authentificationManager.getCookie('user_id'),
              user_updated: authentificationManager.getCookie('user_id'),
              date_created: formattedDateTime,
              date_updated: formattedDateTime,
              spital: authentificationManager.getCookie('spital_Name'),
              bezugsdatum: nextData[id - 1].bezugsdatum,
              antwort: nextData[id - 1].antwort,
              user_id: authentificationManager.getCookie('user_id'),
              frage_id: nextData[id - 1].id,
              kommentar: nextData[id - 1].kommentar
            };

            await dataFetcher.postData('Spitaldaten', saveBody);

            // Toast that data is successfully saved
            toast.success('Speichern erfolgreich', { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
            setUpdateData(true);
          }
          // Antwort is not between min and max value 
          else {
            // Toast
            toast.error(`Antort muss größer als ${tableData[id - 1].min_value} und kleiner als ${tableData[id - 1].max_value} sein`, { autoClose: 2000, position: toast.POSITION.BOTTOM_CENTER });
            setBorderRedAntwort(true);
            // Stay in edit mode
            activeItem.status = activeItem.status ? null : 'EDIT';
          }


        }
      }
    } catch (e) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.log("Error handling the save after editing an entry in the table in Allgemeine Informationen");
    }
  };

  const handleBreak = async (id) => {
    setUpdateData(true);
  };

  async function saveSliderInput(antwort) {
    // setValues([IST, CH, Ziel]);
    const currentDate = new Date();
    const formattedDateTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
    let bezugsdatum = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    if (yearList.length > 0) {
      bezugsdatum = `${yearList.filter((item) => item.value === selectorValue)[0].label}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    }
    const saveBody = {
      user_created: authentificationManager.getCookie('user_id'),
      user_updated: authentificationManager.getCookie('user_id'),
      date_created: formattedDateTime,
      date_updated: formattedDateTime,
      spital: authentificationManager.getCookie('spital_Name'),
      bezugsdatum: bezugsdatum,
      antwort: antwort,
      user_id: authentificationManager.getCookie('user_id'),
      frage_id: 21,
      kommentar: ""
    };
    await dataFetcher.postData('Spitaldaten', saveBody);

    // Toast that data is successfully saved
    toast.success('Speichern erfolgreich', { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
  };

  // Function to handle click event
  const handleEmpfindung = (index) => {
    setSelectedBox(index); // Update selectedBox state to the index of the clicked box
    saveSliderInput(index+1);
  };

  return (
    <div className="bewertung-allgemeine-screen">
      <ToastContainer />
      <div className="selector-container">
        <div className="selector-item-selected">
          Allgemeine Informationen
        </div>
        <div className="selector-divider" />
        <div className="selector-item-not-selected" onClick={linkToFragebogen}>
          Bewertung
        </div>
        <div className="selector-divider" />
        <div className="selector-item-not-selected" onClick={linkToAuswertung}>
          Mein Assessment
        </div>
        <div className="selector-divider" />
        <div className="selector-item-not-selected" onClick={linkToGruppenAuswertung}>
          Gruppen Assessment
        </div>
      </div>

      <div className="singleContent-container1" key="1">
        <select className="singleContent-container1-selector" onChange={handleSelect}>
          {yearList.map(option => (
            <option className="singleContent-container-selector-item" value={option.value}>{option.label}</option>
          ))}
        </select>
        <div className="singleContent-container-titel">Unternehmensdaten</div>
        {/* <BewertungAllgemeineInformatiTable/> */}
        <Table
          // wordWrap="break-word"
          autoHeight
          data={tableData}
          cellBordered
          affixHeader
          style={{ width: "100%" , position: "relative", zIndex: 0 }}>

          <Column flexGrow={5} align="left" resizable fullText>
            <HeaderCell className="HeaderCell-Custom">Frage</HeaderCell>
            <Cell dataKey="frage" />
          </Column>

          <Column flexGrow={2} resizable fullText>
            <HeaderCell className="HeaderCell-Custom">Antwort</HeaderCell>
            <EditableCell dataKey="antwort" onChange={handleChange} borderRedAntwort={borderRedAntwort} isDate={false} />
          </Column>

          <Column flexGrow={3} resizable fullText>
            <HeaderCell className="HeaderCell-Custom">Kommentar</HeaderCell>
            <EditableCell dataKey="kommentar" onChange={handleChange} isDate={false} />
          </Column>

          <Column flexGrow={2} resizable fullText>
            <HeaderCell className="HeaderCell-Custom">Datenerhebungsdatum</HeaderCell>
            <EditableCell dataKey="bezugsdatum" onChange={handleChange} borderRedBezugsdatum={borderRedBezugsdatum} isDate={true} />
          </Column>

          <Column flexGrow={1} resizable fullText>
            <HeaderCell className="HeaderCell-Custom"></HeaderCell>
            <ActionCell dataKey="id" onClick={handleSave} onBreak={handleBreak} />
          </Column>
        </Table>
        <div className="slider-card">
          <div className="slider-card-container-ziel">
            <div className="slider-title-smaller-ziel">Wie gross empfinden Sie den Druck zur digitalen Transformation?</div>
            {/* <Box>
            <input
              className={`table-content-editing-input ${borderRedAntwort ? 'table-content-editing-input-red' : ''} ${borderRedBezugsdatum ? 'table-content-editing-input-red' : ''}`}
              type={`${isDate ? 'date' : 'text'}`}
              defaultValue={rowData[dataKey]}
              onChange={event => {
                onChange && onChange(rowData.id, dataKey, event.target.value);
              }}
            />
            </Box> */}
            <Box display="flex" flexDirection="row" gap="8px">
              {[...Array(10).keys()].map((index) => (
                <Box
                  key={index}
                  width="10%"
                  height="40px"
                  border="1px solid var(--border)"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => handleEmpfindung(index)}
                  borderRadius={
                    // Apply border radius to first and last box
                    index === 0 ? '10px 0 0 10px' : index === 9 ? '0 10px 10px 0' : ''
                  }
                  fontWeight={600}
                  fontSize={18}
                  
                  style={{
                    // Change background color based on selected box
                    color: selectedBox === index ? 'white' : 'black',
                    backgroundColor: selectedBox === index ? 'var(--blue-60)' : 'white',
                  }}
                >
                  {index + 1}
                </Box>
              ))}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BewertungAllgemeineInformatiMarkup;