import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BewertungGruppenAuswertungMarkup from "../html_jsx/BewertungGruppenAuswertung";
import { VerticalNavigationBar } from "../widgets/VerticalNavigationWidgets";
import { HeaderWidget } from "../widgets/HeaderWidget";
import AuthentificationManager from "./classes/authentificationManager";
import DataFetcher from "./classes/databaseManager";

import { parseIdentifier } from "./classes/identifierCalculator";

import "./BewertungGruppenAuswertung.css";
import "./Mainscreen_styles.css";

const BewertungGruppenAuswertung = () => {
  const navigate = useNavigate();

  const pageNames = ["Übersicht", "Zielsetzung", "Bewertung", "Nutzen & Aufwand", "Settings", "Kontakt"]
  const imageIcons = ["/uebersichtIcon.svg", "/strategieIcon.svg", "/selected-bewertungIcon.svg", "/portfolioIcon.svg", "/settingsIcon.svg", "/contactIcon.svg"]

  const dataFetcher = new DataFetcher();
  const authentificationManager = new AuthentificationManager();

  const [assessmentData, setAssessmentData] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [alluserdata, setalluserdata] = useState([]);
  const [gaName, setGaName] = useState("");
  const [nameList, setNameList] = useState([]);
  const [selectorValue, setSelectorValue] = useState(1);
  const [updateData, setUpdateData] = useState(true);
  const [benchmarkAccess, setBenchmarkAccess] = useState(false);

  function handleSelect(event) {
    setSelectorValue(event.target.value);
  }

  // Check for the presence of "auth_token" cookie when the component is mounted
  useEffect(() => {
    authentificationManager.checkAndRefreshToken();
  }, []);

  const onItemXLClick = useCallback(() => {
    navigate("/41-portfolio-tabelle");
  }, [navigate]);

  const onItemXL1Click = useCallback(() => {
    navigate("/21-strategie-gesamtunternehmen");
  }, [navigate]);

  const onItemXL2Click = useCallback(() => {
    navigate("/10-uebersicht");
  }, [navigate]);

  const linkToFragebogen = useCallback(() => {
    navigate("/32-bewertung-frageboegen");
  }, [navigate]);

  const linkToAllgemeine = useCallback(() => {
    navigate("/31-bewertung-allgemeine-informationen");
  }, [navigate]);

  const linkToAuswertung = useCallback(() => {
    navigate("/33-bewertung-assessment");
  }, [navigate]);

  //Same order as the lists above
  const clickLinkToPage = [onItemXL2Click, onItemXL1Click, onItemXLClick]
  const currentPage = "Bewertung"
  const userName = "Ueli Mueller"
  const userPicture = "/userpic@2x.png"

  const verticalNavigationBarList = [pageNames, imageIcons, clickLinkToPage, currentPage, userName, userPicture]


  const getAssessmentData = async (selectorValue) => {
    try {
      const gruppenAssessmentNames = await dataFetcher.fetch_data_by_filter_when_equalto("Gruppenassessment", "bezugsdatum,teilnehmer,teilnehmer_fertig,assessment_liste,name", "spital", authentificationManager.getCookie("spital_Name"))
      const sortedData = gruppenAssessmentNames.data.sort((a, b) => new Date(b.bezugsdatum) - new Date(a.bezugsdatum));
      const names = sortedData.map(item => item.name);
      const selectorNameList = names.map((item, index) => ({
        label: item,
        value: index + 1
      }));

      let success = false;

      while (!success && selectorNameList.length > 0) {
        try {
          const selectedName = selectorNameList.find(item => item.value == parseInt(selectorValue));
          if (!selectedName) {
            throw new Error('Selected name not found.');
          }
          setGaName(selectedName.label);

          const gruppenAssessmentName_response = await dataFetcher.fetch_data_by_filter_when_equalto_by_date_descending_order("Gruppenassessment", "teilnehmer,teilnehmer_fertig,assessment_liste", "name", selectedName.label);

          const splitSafely = (str) => str ? str.split(",") : [];

          // If any of these calls fail, it will jump to the catch block
          const allParticipantsList = splitSafely(gruppenAssessmentName_response["teilnehmer"]);
          const allDoneParticipantsList = splitSafely(gruppenAssessmentName_response["teilnehmer_fertig"]);
          const mappedAssessmentToDoneParticipantsList = splitSafely(gruppenAssessmentName_response["assessment_liste"]);

          // If the code reaches this point without throwing an error, it means the operation was successful
          success = true;

          setNameList(selectorNameList)

          // Initialize arrays to store participant information based on their completion status.
          let allNotDoneParticipantsNamesList = [];
          let allNotDoneParticipantsTagsList = [];
          let allDoneParticipantsNamesList = [];
          let allDoneParticipantsTagsList = [];
          let allDoneParticipantsUserIDList = [];
          let allNotDoneParticipantsUserIDList = [];

          // Function to get the full name from the participant data
          const getFullName = (data) => {
            return data["first_name"] + " " + data["last_name"];
          };

          // Iterate over all participants to categorize them based on completion status.
          for (let participantId of allParticipantsList) {
            let participantData = await dataFetcher.fetch_data_by_filter_when_equalto("users", "first_name,last_name,job_position", "id", participantId);
            

            if (participantData && participantData["data"] && participantData["data"].length > 0) {
              let fullName = getFullName(participantData["data"][0]);
              let tags = participantData["data"][0]["job_position"] ? participantData["data"][0]["job_position"] : "";

              // Add to the 'not done' lists if the participant hasn't completed the assessment.
              if (!allDoneParticipantsList.includes(participantId)) {
                allNotDoneParticipantsNamesList.push(fullName);
                allNotDoneParticipantsTagsList.push(tags);
                allNotDoneParticipantsUserIDList.push(participantId);
              }
            }
          }

          // Process the data for participants who have completed the assessment.
          for (let participantId of allDoneParticipantsList) {
            let participantData = await dataFetcher.fetch_data_by_filter_when_equalto("users", "first_name,last_name,job_position", "id", participantId);

            if (participantData && participantData["data"] && participantData["data"].length > 0) {
              let fullName = getFullName(participantData["data"][0]);
              let tags = participantData["data"][0]["job_position"] ? participantData["data"][0]["job_position"] : "";

              // Store the full name, tags, and user ID for each done participant.
              allDoneParticipantsNamesList.push(fullName);
              allDoneParticipantsTagsList.push(tags);
              allDoneParticipantsUserIDList.push(participantId);
            }
          }


          // Process assessment data to map assessments to levels.
          let mappedAssessmentToLevelIdentList = [];
          for (let i = 0; i < mappedAssessmentToDoneParticipantsList.length; i++) {
            let LevelIdentList = await dataFetcher.fetch_data_by_filter_when_equalto("Reifegradantworten", "levelident", "assessment_id", mappedAssessmentToDoneParticipantsList[i]);
            let tempLevelIdentList = [];
            for (let j = 0; j < LevelIdentList["data"].length; j++) {
              if (LevelIdentList["data"][j]["levelident"] !== null) {
                tempLevelIdentList.push(LevelIdentList["data"][j]["levelident"]);
              }
            }
            mappedAssessmentToLevelIdentList.push(tempLevelIdentList);
          }

          // Parse the level identifiers from the assessment data.
          let parsedList = [];
          for (let i = 0; i < mappedAssessmentToLevelIdentList.length; i++) {
            let innerList = mappedAssessmentToLevelIdentList[i];
            let parsedInnerList = [];

            for (let j = 0; j < innerList.length; j++) {
              let parsedItem = parseIdentifier(innerList[j]);
              parsedInnerList.push(parsedItem);
            }

            parsedList.push(parsedInnerList);
          }

          // Organize the parsed data into dimensions and levels for further processing.
          const result = [];
          parsedList.forEach(innerList => {
            const dimensionLists = [];

            for (let dimension of ['001', '002', '003', '004', '005']) {
              const dimensionLevels = innerList
                .filter(item => item.Dimension === dimension)
                .map(item => parseInt(item.Level, 10));

              dimensionLists.push(dimensionLevels);
            }
            result.push(dimensionLists);
          });

          // Prepare user data for participants who have completed the assessment.
          const DoneUsers = [];
          const AllUsers = [];
          for (let iteration = 1; iteration <= result.length; iteration++) {
            const imageAvatarDone = await dataFetcher.fetch_data_by_filter_when_equalto("users", "avatar", "id", allDoneParticipantsUserIDList[iteration - 1]);
            let avatar;
            if(imageAvatarDone != -1 && imageAvatarDone) {
              avatar = imageAvatarDone.data[0].avatar;
            }
            else{
              avatar = "56fee9d6-254d-418a-8cf8-9e60a8d94be7";
            }
            // Construct and add user objects to the respective lists.
            const user = {
              id: iteration,
              imgID: avatar,
              name: allDoneParticipantsNamesList[iteration - 1],
              name_short: '',
              role: allDoneParticipantsTagsList[iteration - 1],
              score: 0,
              score_1: 0,
              score_2: 0,
              score_3: 0,
              score_4: 0,
              score_5: 0,
              answers_1: result[iteration - 1][0],
              answers_2: result[iteration - 1][1],
              answers_3: result[iteration - 1][2],
              answers_4: result[iteration - 1][3],
              answers_5: result[iteration - 1][4]
            };

            DoneUsers.push(user);
            AllUsers.push(user);
          }


          // Process data for participants who have not completed the assessment.
          let NotDoneIterator = 0
          for (let iteration = DoneUsers.length + 1; iteration <= allParticipantsList.length; iteration++) {
            const imageAvatarNotDone = await dataFetcher.fetch_data_by_filter_when_equalto("users", "avatar", "id", allNotDoneParticipantsUserIDList[iteration - DoneUsers.length - 1]);
            let avatar;
            if(imageAvatarNotDone != -1 && imageAvatarNotDone) {
              avatar = imageAvatarNotDone.data[0].avatar;
            }
            else{
              avatar = "56fee9d6-254d-418a-8cf8-9e60a8d94be7";
            }

            const notDoneUser = {
              id: iteration,
              imgID: avatar,
              name: allNotDoneParticipantsNamesList[NotDoneIterator],
              name_short: '',
              role: allNotDoneParticipantsTagsList[NotDoneIterator],
              score: 0,
              score_1: 0,
              score_2: 0,
              score_3: 0,
              score_4: 0,
              score_5: 0,
              answers_1: [0],
              answers_2: [0],
              answers_3: [0],
              answers_4: [0],
              answers_5: [0]
            };
            AllUsers.push(notDoneUser)
            NotDoneIterator++;

          }

          // Update the state with the processed user data.
          // console.log("Userdata:\n");
          // console.log(DoneUsers);
          // console.log(AllUsers);
          setuserdata(DoneUsers);
          setalluserdata(AllUsers);



        } catch (e) {

          console.error("Error processing data for selected name, trying next available option.");
          console.log(e)

          // Remove the problematic entry from selectorNameList
          selectorNameList = selectorNameList.filter(item => item.value !== selectorValue);

          // Adjust selectorValue to try the next item in the list, if available
          selectorValue = selectorNameList.length > 0 ? selectorNameList[0].value : null;

          if (!selectorValue) {
            // console.log("No more names to try.");
            break; // Exit the loop if there are no more names to try
          }
        }
      }

      if (!success) {
        console.error("Failed to process any names successfully.");
        console.log(e)
        // Handle the case where no names could be processed successfully
      }
    } catch (e) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.error("Error fetching assessment data in Gruppenauswertung");
      console.log(e)
    }

    

    const currenUserId = await dataFetcher.getCookie("user_id");
    const role_id = await dataFetcher.fetch_data_by_filter_when_equalto("users","role","id",currenUserId);
    const role = await dataFetcher.fetch_data_by_filter_when_equalto("roles","name","id",role_id.data[0].role);
    // console.log(role.data[0]);
    if(role.data[0].name === "Administrator" || role.data[0].name === "Spital_Data_Manager"){
      setBenchmarkAccess(true);
    }
  };

    useEffect(() => {
      // Update the table Data. Will be executed on the initial load.
      if (updateData) {
        getAssessmentData(selectorValue);
        setUpdateData(!updateData);
      }
    }, [updateData]);

    useEffect(() => {
      getAssessmentData(selectorValue);
    }, [selectorValue]);


    return (
      <div className="allcontent">
        <div className="mainContent">
          <div className="pageTitle">
            <b className="pageTitle-title">Gruppen Assessment</b>
          </div>

          <select className="gruppenauswertung-selector-container" onChange={handleSelect}>
            {nameList.map(option => (
              <option className="gruppenauswertung-selector-item" value={option.value}>{option.label}</option>
            ))}
          </select>

          <BewertungGruppenAuswertungMarkup
            linkToFragebogen={linkToFragebogen}
            linkToAllgemeine={linkToAllgemeine}
            linkToAuswertung={linkToAuswertung}
            userdata={alluserdata}
            gaName={gaName}
            benchmarkAccess={benchmarkAccess} />
        </div>

        <div className="navigationbar">
          <VerticalNavigationBar verticalNavigationBarList={verticalNavigationBarList} />
        </div>
        <div className="headerbar">
          <HeaderWidget LogoImg="/LogoDRI.svg" SpitalName="Spital Zürich" />
        </div>
      </div>
    );
  };

  export default BewertungGruppenAuswertung;



/*
THEWIND guck mal das du hier nur das aktuellste Gruppen Assessment bekommst, wo der aktuelle User als Teilnehmer eingetragen ist habe gefunden, dass es verlängerte URL befehle gitb, das das vereinfachen soll hier die zwei Issues dazu

Wie kann ich in einer URL merhere fiilter/befehle einfpgen https://github.com/directus/directus/discussions/15875

Wie kann ich die antwort oder den Fetch nach Datum sortieren, sodass ich das neuste Geupdatete Item bekomme https://github.com/directus/directus/issues/1354 sieht sehr kompliziert aus aber ist es nicht 
habe das bereits in insominia probiert hier die fertige URL: kannst du in insomnia testen der Token macht das unendlich lang aber man blikt durch :
http://81.89.199.164:8055/items/Gruppen_Assessment?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjgxMmZhZmNmLWUzNmItNGI2ZS1hZTY5LWI0OGMzNjYwYjA0OSIsInJvbGUiOiI1ZjVmMzQ4Ni05NjE4LTQwYmEtYTU2MS03YjY0YjBlMDgyZDciLCJhcHBfYWNjZXNzIjp0cnVlLCJhZG1pbl9hY2Nlc3MiOnRydWUsImlhdCI6MTcwNjI2NDA0MywiZXhwIjoxNzA2MzUwNDQzLCJpc3MiOiJkaXJlY3R1cyJ9.y2p60AmKUkDFb0E8eUmjfFUzrbE02SiFKEvDCBM34WA&adv_where[type]=ENTRY&adv_where[action]=UPDATE&adv_where[table_name]=Gruppen_Assessment&orderBy=datetime&orderDirection=DESC&perPage=1

TEMPLATE ZUM LINK : http://81.89.199.164:8055/items/Gruppen_Assessment?access_token=<api-token>&adv_where[type]=ENTRY&adv_where[action]=UPDATE&adv_where[table_name]=<table-name>&orderBy=datetime&orderDirection=DESC&perPage=1
*/