import React, { useState, useEffect } from 'react';
import { FragebogenWidget } from "../widgets/FragebogenWidget.js"
import AuthentificationManager from '../pages/classes/authentificationManager';
import DataFetcher from '../pages/classes/databaseManager';
import "./FirstLoginNoAssessmentPopup.css"


export const FirstLoginNoAssessmentPopup = ({ contentOpen, toggleContent }) => {
  const databaseManager = new DataFetcher();
  const authentificationManager = new AuthentificationManager();
  const [fragebogenData, setFragebogenData] = useState("");
  const [einzelAssessmentVisibility, setEinzelAssessmentVisibility] = useState(false);
  const [notAnsweredAssessments, setNotAnsweredAssessments] = useState([]);
  const [selectedAssessmentName, setSelectedAssessmentName] = useState("");



  async function getFragenData(assessmentTitle) {
    try {
      let spital = authentificationManager.getCookie('spital_Name');
      let title = assessmentTitle;
      let antworttyp = "Level";
      let levelindent = "";
      let kommentar = ""
      const fetchFragen = await databaseManager.fetchCollection('Reifegradfragen');

      const fetchDimension = await databaseManager.fetchCollection('Dimensionen');
      const fetchKategorie = await databaseManager.fetchCollection('Kategorien');

      console.log("------------------------ Hier Hier Fragen --------------------------");
      console.log(fetchFragen);

      // Mapping the fetched data to the new JSON structure
      let jsonStructure = fetchFragen.data.map(item => {
        // Find the corresponding dimension name based on item.dimension
        const matchingDimension = fetchDimension.data && fetchDimension.data.length > 0
          ? fetchDimension.data.find(dim => dim.id === item.dimension)
          : undefined;

        // Find the corresponding kategorie name based on item.kategorie
        const matchingKategorie = fetchKategorie.data && fetchKategorie.data.length > 0
          ? fetchKategorie.data.find(kat => kat.id === item.kategorie)
          : undefined;

        // Set fragenDimension and fragenKategorie based on the condition
        let frageDimension = matchingDimension ? matchingDimension.name : "";
        let frageKategorie = matchingKategorie ? matchingKategorie.name : "";

        return {
          id: item.id,
          index: item.index,
          spital: spital,
          titel: title,
          frage: item.frage,
          dimension: frageDimension,
          dimension_id: item.dimension,
          kategorie: frageKategorie,
          kategorie_id: item.kategorie,
          antworttyp: antworttyp,
          antwort_1: item.level_1,
          beispiel_1: item.beispiel_1,
          antwort_2: item.level_2,
          beispiel_2: item.beispiel_2,
          antwort_3: item.level_3,
          beispiel_3: item.beispiel_3,
          antwort_4: item.level_4,
          beispiel_4: item.beispiel_4,
          antwort_5: item.level_5,
          beispiel_5: item.beispiel_5,
          effizienzgewinn_spital_1: item.effizienzgewinn_spital_1,
          effizienzgewinn_system_1: item.effizienzgewinn_system_1,
          umsetzungsaufwand_spital_1: item.umsetzungsaufwand_spital_1,
          umsetzungsaufwand_system_1: item.umsetzungsaufwand_system_1,
          effizienzgewinn_spital_2: item.effizienzgewinn_spital_2,
          effizienzgewinn_system_2: item.effizienzgewinn_system_2,
          umsetzungsaufwand_spital_2: item.umsetzungsaufwand_spital_2,
          umsetzungsaufwand_system_2: item.umsetzungsaufwand_system_2,
          effizienzgewinn_spital_3: item.effizienzgewinn_spital_3,
          effizienzgewinn_system_3: item.effizienzgewinn_system_3,
          umsetzungsaufwand_spital_3: item.umsetzungsaufwand_spital_3,
          umsetzungsaufwand_system_3: item.umsetzungsaufwand_system_3,
          effizienzgewinn_spital_4: item.effizienzgewinn_spital_4,
          effizienzgewinn_system_4: item.effizienzgewinn_system_4,
          umsetzungsaufwand_spital_4: item.umsetzungsaufwand_spital_4,
          umsetzungsaufwand_system_4: item.umsetzungsaufwand_system_4,
          effizienzgewinn_spital_5: item.effizienzgewinn_spital_5,
          effizienzgewinn_system_5: item.effizienzgewinn_system_5,
          umsetzungsaufwand_spital_5: item.umsetzungsaufwand_spital_5,
          umsetzungsaufwand_system_5: item.umsetzungsaufwand_system_5,
          levelident: levelindent,
          kommentar: kommentar
        };
      });

      jsonStructure.sort((a, b) => {
        // Split the index by dot and compare each part as integer
        const partsA = a.index.split('.').map(Number);
        const partsB = b.index.split('.').map(Number);

        // Compare each part until a difference is found
        for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
          // If one of the parts doesn't exist, assume it's 0 (e.g., 2 is before 2.1)
          const numA = i < partsA.length ? partsA[i] : 0;
          const numB = i < partsB.length ? partsB[i] : 0;

          // If the numbers are not equal, return the difference
          if (numA !== numB) {
            return numA - numB;
          }
        }

        // If all parts are equal, then the indices are equal
        return 0;
      });
      return jsonStructure;
    } catch (error) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.error("Error getting Fragendata in Model Assessment starten");
    }
  };

  const startEinzelAssessment = async (name) => {
    try {
      setSelectedAssessmentName(name);

      const fetchedData = await getFragenData(name);
      console.log(fetchedData);
      setFragebogenData(fetchedData);
      setEinzelAssessmentVisibility(true);
    } catch (error) {
      // Handle the error, e.g., display an error message or retry fetching.
      console.error("Error starting the Einzelassessment in Modal Assessment starten");
    }
  };


  return (
    <>
      {contentOpen && <div className='hinweis-background-first'>
        <div className='hinweis-content-container-first'>
          <div style={{ margin: "20px" }}>
            <div className='hinweis-inner-container-first'>
              <div className='hinweis-title-first'>Erster Login</div>
              <div className='hinweis-text-first'><p>Herzlich Willkommen beim Digitalen Reifegrad.</p>
              <p>Um die Plattform mit allen Funktionen nutzen zu können, sollte mindestens ein Assessment durchgeführt werden.</p>
              <p>Bitte führen Sie ein <strong>Einzelassessment</strong> oder - falls bereits von ihrem Verantwortlichen für den Digitalen Reifegrad initiert - ein <strong>Gruppenassessment</strong> durch.</p>
              Viel Freude mit der Plattform Digitaler Reifegrad.</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className='disclaimer-button-first ausloggen-first' onClick={() => authentificationManager.logout()}>Ausloggen</div>
                <div className='disclaimer-button-first assessment-starten-first' onClick={toggleContent}>Assessment Starten</div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {einzelAssessmentVisibility &&
        <FragebogenWidget fragebogenData={fragebogenData} setVisible={setEinzelAssessmentVisibility} bezugsdatum={notAnsweredAssessments.find(item => item.name === fragebogenData[0].titel)?.bezugsdatum ?? ""}></FragebogenWidget>
      }
    </>
  );
};