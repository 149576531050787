import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { useState } from "react"
import AuthentificationManager from "./classes/authentificationManager";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const Login = () => {
  const navigate = useNavigate();
  const authentificationManager = new AuthentificationManager();

  const [in_user, set_in_user] = useState('');
  const [in_pw, set_in_pw] = useState('');

  const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    if (authentificationManager.getCookie("dhi_refresh_token")) {
      setTimeout(() => {
        if (authentificationManager.checkAndRefreshToken()) {
          navigate("/10-uebersicht");
        }
      }, 300);
    }
  }, []);


  // Do not work yet with verify hash
  const login = async () => {
    if (in_user) {
      const response = await authentificationManager.login_User(in_user.toLowerCase(), in_pw);
      if (response["valid"] === 1) {
        // Pass the access_token as state to the next page

        setTimeout(() => {
          navigate("/10-uebersicht");
        }, 1500);
      } else {
        setErrorMessage('Benutzername oder Passwort falsch');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    } else {
      setErrorMessage('Benutzername nicht vorhanden');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onButtonFilledMClick();// Methode die aufgerufen werden soll bei drücken von enter
    };
  }

  const onButtonFilledMClick = async () => {
    await login();
  };




  const onKontaktAufnehmenClick = useCallback(() => {
    navigate("/01-login-registrierung");
  }, [navigate]);

  const onPasswortVergessenClick = useCallback(() => {
    navigate("/02-login-passwort-vergseesn");
  }, [navigate]);

  return (
    <div className="logincontainer">
      <ToastContainer />
      <img className="backimage" alt="" src="/Background.svg" draggable="false" />
      <div>
        <div className="logo-circle-blurred">
          <img alt="" src="/logo-circle-blurred.svg" draggable="false" />
          <img className="logo-1-icon" alt="" src="/logo-1.svg" draggable="false" />
        </div>
        <img className="logoclaim-1-icon" alt="" src="/logoclaim-1.svg" draggable="false" />


        {/* CONTENT START */}
        <div className="Login-Parent">
          <b className="Login-Title">Anmeldung</b>
          <div className="Login-div">
            <img className="Login-icon" alt="" src="/user.svg" draggable="false" />
            <input className="Login-input" placeholder="Benutzername" type="email" name="name_in" value={in_user} onChange={(e) => set_in_user(e.target.value)} />
          </div>
          <div className="Password-Container">
            <div className="Login-div"  style={{ marginBottom: 20}}>
              <img className="Login-icon" alt="" src="/lock.svg" draggable="false" />
              <input className="Login-input" placeholder="Passwort" type="password" name="pw_in" value={in_pw} onChange={(e) => set_in_pw(e.target.value)} onKeyDown={handleEnterPress} />
            </div>
          </div>
          <div className="Login-Button-formate">
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <button className="Login-Button" onClick={onButtonFilledMClick}>Login</button>
          </div>
            <div style={{textAlign: "right"}}>
            <a href="https://be.digitalerreifegrad.ch/admin/reset-password">Passwort vergessen</a>
            </div>
          {/*<div className="Register-Formate">
          
            <div className="Konto-Erstellen" onClick={onKontaktAufnehmenClick}>Konto erstellen</div>
          </div>*/}
        </div>
        {/* CONTENT END */}

        {/*<img className="button-icon-light" alt="" src="/button-icon-light.svg" draggable="false" />
        <img className="button-icon-light1" alt="" src="/button-icon-light2.svg" draggable="false" />*/}
        <div className="logo">
          <img className="icon" alt="" src="/icon4.svg" draggable="false" />
          <b className="digital-reifegrad">Digitaler Reifegrad</b>
        </div>
      </div>
      <div className="pwc_container">
        <img className="pwc-img" alt="" src="/pwc_Logo.svg" draggable="false" />
      </div>
      <div className="login-version">
        V. 1.0
      </div>
    </div>
  );
};

export default Login;
