import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useState } from "react"
import AuthentificationManager from "./classes/authentificationManager";
import "react-toastify/dist/ReactToastify.css";
import "./LoginPasswortVergessen.css";
import "./Login.css";

const LoginPasswortVergessen = () => {
  const navigate = useNavigate();


  
  const [my_email, set_my_email] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onButtonFilledMClick = useCallback(() => {

    
    navigate("/");
  }, [navigate]);


  return (
    <div className="logincontainer">
      <ToastContainer />
      <img className="backimage" alt="" src="/Background.svg" />
      <div>
        <div className="logo-circle-blurred">
          <img alt="" src="/logo-circle-blurred.svg" />
          <img className="logo-1-icon" alt="" src="/logo-1.svg" />
        </div>
        <img className="logoclaim-1-icon" alt="" src="/logoclaim-1.svg" />


        {/* CONTENT START */}
        
        <div className="Login-Parent">
          <b className="Login-Title">Passwort vergessen</b>

          <div className="Password-Container">
            <div className="Login-div">
              <img className="Login-icon" alt="" src="/user.svg" />
              <input className="Login-input" placeholder="Email"  type="email" name="email" value={my_email} onChange={(e) => set_my_email(e.target.value)}/>
            </div>
            Sie erhalten eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts.
          </div>        
          <div className="Login-Button-formate">
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <button className="Login-Button" onClick={onButtonFilledMClick}>Senden</button>
          </div>

        </div>
        {/* CONTENT END */}

        <img className="button-icon-light" alt="" src="/button-icon-light.svg" />
        <img className="button-icon-light1" alt="" src="/button-icon-light2.svg" />
        <div className="logo">
          <img className="icon" alt="" src="/icon4.svg" />
          <b className="digital-reifegrad">Digitaler Reifegrad</b>
        </div>
      </div>
      <div className="pwc_container">
        Mit Unterstützung von
        <img className="pwc-img" alt="" src="/pwc_logo.png" draggable="false" />
      </div>
    </div>
  );
};

export default LoginPasswortVergessen;
