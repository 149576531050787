import { useState, useEffect } from "react";
import { TripleSlider } from "../widgets/StrategieZieldimensionenWidgets"
import { RadialBarChart } from "../widgets/StrategieGesamtunternehmenWidgets"
import "../widgets/StrategieZieldimensionenWidgets"
import React from "react";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import DataFetcher from "../pages/classes/databaseManager";
import AuthentificationManager from "../pages/classes/authentificationManager";


/**
 * `StrategieZieldimensionenMarkup` is a React component for managing and displaying strategic goal dimensions.
 *
 * @param {Object} props - The component's props.
 * @param {number} props.exactMean - The exact mean value of the goals.
 * @param {number} props.ISTmean - The IST (current state) mean value.
 * @param {Function} props.onSegmentedControlClick - Callback function for segmented control click.
 * @param {number} props.StrategieIST - The current state for Strategie goal.
 * @param {number} props.StrategieCH - The benchmark value for Strategie goal.
 * @param {number} props.StrategieZiel - The target value for Strategie goal.
 * @param {number} props.ProzesseIST - The current state for Prozesse goal.
 * @param {number} props.ProzesseCH - The benchmark value for Prozesse goal.
 * @param {number} props.ProzesseZiel - The target value for Prozesse goal.
 * @param {number} props.KulturIST - The current state for Kultur goal.
 * @param {number} props.KulturCH - The benchmark value for Kultur goal.
 * @param {number} props.KulturZiel - The target value for Kultur goal.
 * @param {number} props.SystemIST - The current state for System goal.
 * @param {number} props.SystemCH - The benchmark value for System goal.
 * @param {number} props.SystemZiel - The target value for System goal.
 * @param {number} props.ProdukteIST - The current state for Produkte goal.
 * @param {number} props.ProdukteCH - The benchmark value for Produkte goal.
 * @param {number} props.ProdukteZiel - The target value for Produkte goal.
 * @param {Function} props.setISTmean - Callback to set the IST mean value.
 * @param {Function} props.setExactMean - Callback to set the exact mean value.
 * @returns {JSX.Element} A JSX component rendering strategic goal dimensions and related information.
 */
const StrategieZieldimensionenMarkup = ({ exactMean, ISTmean, onSegmentedControlClick, StrategieIST, StrategieCH, StrategieZiel, ProzesseIST, ProzesseCH, ProzesseZiel, KulturIST, KulturCH, KulturZiel, SystemIST, SystemCH, SystemZiel, ProdukteIST, ProdukteCH, ProdukteZiel, setISTmean, setExactMean, fetchedDataAvailable }) => {


  // Function to calculate average of all goals
  const calculateAverage = () => {
    const total = parseFloat(strategieZiel) + parseFloat(prozesseZiel) + parseFloat(kulturZiel) + parseFloat(systemZiel) + parseFloat(produkteZiel);
    const numberOfGoals = 5;
    return total / numberOfGoals;
  };

  // State variables for each goal dimension
  const [strategieZiel, setStrategieZiel] = useState(StrategieZiel);
  const [prozesseZiel, setProzesseZiel] = useState(ProzesseZiel);
  const [kulturZiel, setKulturZiel] = useState(KulturZiel);
  const [systemZiel, setSystemZiel] = useState(SystemZiel);
  const [produkteZiel, setProdukteZiel] = useState(ProdukteZiel);

  // State variable to keep track of the initial render
  const [initialCounter, setInitialCounter] = useState(0);

  const authentificationManager = new AuthentificationManager();
  const dataFetcher = new DataFetcher();
  // Use useEffect for the initial update
  useEffect(() => {
    if (fetchedDataAvailable == 1 && initialCounter < 3) {
      // Update state during the initial render
      if (StrategieZiel !== strategieZiel) {
        setStrategieZiel(StrategieZiel);
      }
      if (ProzesseZiel !== prozesseZiel) {
        setProzesseZiel(ProzesseZiel);
      }
      if (KulturZiel !== kulturZiel) {
        setKulturZiel(KulturZiel);
      }
      if (SystemZiel !== systemZiel) {
        setSystemZiel(SystemZiel);
      }
      if (ProdukteZiel !== produkteZiel) {
        setProdukteZiel(ProdukteZiel);
      }
      // Increment the initialCounter by 1
      setInitialCounter(initialCounter + 1);
    }
    else if (fetchedDataAvailable == 0 && initialCounter < 1) {
      setInitialCounter(initialCounter + 1);
    }
    else {
      const newAverage = calculateAverage();
      setExactMean(newAverage)
      saveAlleZiel();
    }
  }, [StrategieZiel, ProzesseZiel, KulturZiel, SystemZiel, ProdukteZiel, strategieZiel, prozesseZiel, kulturZiel, systemZiel, produkteZiel]);




  // Callback functions for goal dimension changes
  const onStrategieZielChange = (newValue) => {
    setStrategieZiel(newValue);
    const newAverage = calculateAverage();
    setExactMean(newAverage);
  };

  const onProzesseZielChange = (newValue) => {
    setProzesseZiel(newValue);
    const newAverage = calculateAverage();
    setExactMean(newAverage);
  };

  const onKulturZielChange = (newValue) => {
    setKulturZiel(newValue);
    const newAverage = calculateAverage();
    setExactMean(newAverage);
  };

  const onSystemZielChange = (newValue) => {
    setSystemZiel(newValue);
    const newAverage = calculateAverage();
    setExactMean(newAverage)

  };

  const onProduktZielChange = (newValue) => {
    setProdukteZiel(newValue);
    const newAverage = calculateAverage();
    setExactMean(newAverage);
  };

  async function saveAlleZiel() {


    try {

      let genauesGesamtZiel = calculateAverage();

      const dhiZieleBody = {
        "Spital": authentificationManager.getCookie('spital_Name'),
        "GesamtZiel": Math.round(genauesGesamtZiel),
        "GenauesGesamtZiel": genauesGesamtZiel,
        "Dimension1Ziel": strategieZiel,
        "Dimension2Ziel": prozesseZiel,
        "Dimension3Ziel": kulturZiel,
        "Dimension4Ziel": systemZiel,
        "Dimension5Ziel": produkteZiel,
      };

      const latestGoal = await dataFetcher.fetch_data_by_filter_when_equalto_by_date_descending_order("DHI_Ziele", "id,GesamtZiel,GenauesGesamtZiel,Dimension1Ziel,Dimension2Ziel,Dimension3Ziel,Dimension4Ziel,Dimension5Ziel", "Spital", authentificationManager.getCookie("spital_Name"))

      if (latestGoal == undefined) {
        await dataFetcher.postData("DHI_Ziele", dhiZieleBody);
      }
      else {
        // Check if latestGoal has all required fields and none are missing
        const requiredFields = ["GesamtZiel", "GenauesGesamtZiel", "Dimension1Ziel", "Dimension2Ziel", "Dimension3Ziel", "Dimension4Ziel", "Dimension5Ziel"];
        const allFieldsPresent = requiredFields.every(field => latestGoal.hasOwnProperty(field) && latestGoal[field] != null);
        if (allFieldsPresent) {
          // If no values are missing, call postData with two parameters
          let response = await dataFetcher.postData("DHI_Ziele", dhiZieleBody);
          console.log(response)
        } else {
          // If values are missing, call updateData with three parameters
          let response = await dataFetcher.updateData("DHI_Ziele", latestGoal.id, dhiZieleBody);
          console.log(response)
        }
      }

      toast.success("Ziel gespeichert", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });

    } catch (e) {
      toast.error(`Ziel konnte nicht gespeichert werden! Fehler: ${e}`, { autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER });
    }

  }

  return (
    <div className="gesammtunternehmenscreen">
      <ToastContainer />
      <div className="selector-container">
        <div className="selector-item-not-selected1" onClick={onSegmentedControlClick}>
          Sicht auf Gesamtunternehmen
        </div>
        <div className="selector-divider" />
        <div className="selector-item-selected">
          Sicht auf einzelne Zieldimensionen
        </div>
      </div>
      <div className="main-content">
        <div className="ziel-left-content">
          <div className="slider-container">
            <div className="slider-header-title-container1">
              <div className="slider-title1">
                Legen Sie Ihre individuellen Ziele fest
              </div>
              <div className="green-button1" onClick={saveAlleZiel}>Ziele speichern</div>
            </div>
            <div className="slider-innercontainer1">
              <div className="slider-header-container1">
                <div className="slider-legend1">
                  <div className="slider-legentitem">
                    <div className="slider-legentitem-1" />
                    IST
                  </div>
                  <div className="slider-legentitem">
                    <div className="slider-legentitem-2" />
                    Benchmark
                  </div>
                  <div className="slider-legentitem">
                    <div className="slider-legentitem-3" />
                    Ziel
                  </div>
                </div>
                {/*<div className="slider-jahres-ziel">
                  2023
                  <img src="/filter_down.svg" alt="Card Image" draggable="false" />
                </div>
                <div className="slider-Zielselection-ziel">
                  * Aktuelles Level
                  <img className="ziel-slider-filterimg" src="/filter_down.svg" alt="Card Image" draggable="false" />
                </div>*/}
              </div>
              <div className="slider-card">
                <div className="slider-card-container-ziel">
                  <div className="slider-title-smaller-ziel">Strategie, Governance & Organisation</div>
                  <TripleSlider IST={StrategieIST} CH={StrategieCH} Ziel={strategieZiel} onZielChange={onStrategieZielChange} />
                </div>
              </div>
              <div className="slider-card">
                <div className="slider-card-container-ziel">
                  <div className="slider-title-smaller-ziel">Prozesse</div>
                  <TripleSlider IST={ProzesseIST} CH={ProzesseCH} Ziel={prozesseZiel} onZielChange={onProzesseZielChange} />
                </div>
              </div>
              <div className="slider-card">
                <div className="slider-card-container-ziel">
                  <div className="slider-title-smaller-ziel">Leadership, Kultur & People</div>
                  <TripleSlider IST={KulturIST} CH={KulturCH} Ziel={kulturZiel} onZielChange={onKulturZielChange} />
                </div>
              </div>
              <div className="slider-card">
                <div className="slider-card-container-ziel">
                  <div className="slider-title-smaller-ziel">System & Daten</div>
                  <TripleSlider IST={SystemIST} CH={SystemCH} Ziel={systemZiel} onZielChange={onSystemZielChange} />
                </div>
              </div>
              <div className="slider-card">
                <div className="slider-card-container-ziel">
                  <div className="slider-title-smaller-ziel">Produkte und Innovation</div>
                  <TripleSlider IST={ProdukteIST} CH={ProdukteCH} Ziel={produkteZiel} onZielChange={onProduktZielChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ziel-right-content">
          <div className="di-box1">
            <div className="di-title1">
              Digitaler Reifegrad
            </div>
            <div className="di-content1">
              <div className="Ziel-di-legend-container-gesamt">
                <div className="di-info-title1">
                  <h4>Totaler Reifegrad</h4>
                </div>
                <RadialBarChart Ziel={exactMean} IST={ISTmean} />
                <div className="pagecontent-circlechart-info">
                  <div className="pagecontent-circlechart-dot-IST" />
                  IST
                  <div className="pagecontent-circlechart-dot-Ziel" />
                  Ziel
                </div>
              </div>
            </div>
            <div className="gesammt-info1">
              <div className="di-info-title1">
                <h4>Was bedeutet das?</h4>
              </div>
              {exactMean >= 1.0 && exactMean < 1.5 && (
                <div className="di-info-text1">
                  <p className="slider-title-smaller">{`L1 ('Reaktiv'):`}</p>
                  <p className="ein-dti-von">{`Auf dieser Ebene operieren Krankenhäuser in einem reaktiven Modus ohne signifikante digitale Infrastruktur. Prozesse sind manuell, oft offline und stark auf papierbasierte Systeme angewiesen. Es mangelt an proaktiver Planung, und Lösungen werden typischerweise isoliert entwickelt, um unmittelbare Probleme anzugehen.`}</p>
                </div>
              )}
              {exactMean >= 1.5 && exactMean < 2.5 && (
                <div className="di-info-text1">
                  <p className="slider-title-smaller">{`L2 ('Organisiert'):`}</p>
                  <p className="ein-dti-von">{`Krankenhäuser auf dieser Stufe haben begonnen, digitale Lösungen zu integrieren, diese sind jedoch isoliert und nicht weit verbreitet. Einige Prozesse sind digitalisiert, und es gibt Bemühungen, Daten zu sammeln und zu speichern. Die Digitalisierung ist jedoch fleckenhaft und noch nicht Teil einer kohärenten Strategie.`}</p>
                </div>
              )}
              {exactMean >= 2.5 && exactMean < 3.5 && (
                <div className="di-info-text1">
                  <p className="slider-title-smaller">{`L3 ('Digitalisiert'):`}</p>
                  <p className="ein-dti-von">{`Auf dieser Ebene sind die meisten Krankenhausoperationen und Dokumentationen digitalisiert und strukturiert. Digitale Prozesse sind häufiger, was einen signifikanten Schritt weg von manuellen Systemen markiert. Der Fokus liegt darauf, Kernoperationen zu digitalisieren und sicherzustellen, dass die Daten effektiv organisiert sind.`}</p>
                </div>
              )}
              {exactMean >= 3.5 && exactMean < 4.5 && (
                <div className="di-info-text1">
                  <p className="slider-title-smaller">{`L4 ('Verbunden'):`}</p>
                  <p className="ein-dti-von">{`Krankenhäuser auf dieser Ebene zeigen eine weitreichende Digitalisierung in allen Abteilungen. Sie legen Wert auf Interoperabilität und integrierte Wertschöpfung, wobei Technologien, Daten und Systeme verbunden sind und harmonisch funktionieren. Ein zentrales Datenlager ist oft ein Merkmal, das sicherstellt, dass Daten im gesamten Krankenhaus zugänglich und nutzbar sind.`}</p>
                </div>
              )}
              {exactMean >= 4.5 && exactMean <= 5.0 && (
                <div className="di-info-text1">
                  <p className="slider-title-smaller">{`L5 ('Intelligent'):`}</p>
                  <p className="ein-dti-von">{`Die höchste Ebene der digitalen Reife, gekennzeichnet durch modernste Technologie und kontinuierliche Entwicklung. Krankenhäuser auf dieser Stufe nutzen intelligente Systeme, die Daten durch automatisierte Werkzeuge nutzen. Prozesse werden in Echtzeit unter Verwendung von Leistungsindikatoren (KPIs) verwaltet, was eine ausgefeilte, datengesteuerte Entscheidungsfindung und betriebliche Effizienz ermöglicht.`}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategieZieldimensionenMarkup;