import React from 'react';
import "./FragebogenWidget.css"
import { useState, useEffect, useCallback } from "react";
import DataFetcher from "../pages/classes/databaseManager";
import { DisclaimerWidget } from "./ModalDisclamer.js";
import AuthentificationManager from '../pages/classes/authentificationManager';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from 'react-toastify';  // Correct import statement

export const FragebogenWidget = ({ fragebogenData, setVisible, bezugsdatum, modus = "NORMAL", continueFlag, title }) => {

    const startimg = "/assessmentstart.jpeg";
    const endimg = "/";

    const [isKommentarVisible, setIsKommentarVisible] = useState(false);
    const [isHelpVisible, setIsHelpVisible] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [data, setData] = useState(fragebogenData);
    const [isGruppenAssessment, setIsGruppenAssessment] = useState((title !== '' && title !== 'null'));
    const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);
    const [assessmentBezugsdatum, setAssessmentBezugsdatum] = useState((title !== '' || title === 'null') ? bezugsdatum : "");
    const [saveable, setSaveable] = useState(true);
    const [firstOpened, setFirstOpened] = useState(true);
    const [currentAssessment, setCurrentAssessment] = useState("");
    const [safeOnlyOnce, setSafeOnlyOnce] = useState(true);
    const [toggleCommentAktiv, setToggleCommentAktiv] = useState(false);

    // Continue Assessment
    const [continueAssessmentTMP, setContinueAssessmentTMP] = useState("");
    const [settingExistingBezugsdatum, setSettingExistingBezugsdatum] = useState(false);
    const [fragenIndex, setFragenIndex] = useState();

    // Disclaimer Setter
    const [disclaimerText, setDisclaimerText] = useState("");
    const [disclaimerTitle, setDisclaimerTitle] = useState("");
    const [disclaimerLeft, setDisclaimerLeft] = useState("");
    const [disclaimerRight, setDisclaimerRight] = useState("");
    const [disclaimerMiddle, setDisclaimerMiddle] = useState("");
    const [disclaimerClose, setDisclaimerClose] = useState(false);
    const [disclaimerMode, setDisclaimerMode] = useState("NORMAL");


    // State to manage selected level for each question
    const [selectedLevels, setSelectedLevels] = useState({});
    const [kommentarList, setKommentarList] = useState({});

    const [user_id, setUser_id] = useState("");
    const [spital_name, setSpital_name] = useState("");

    const dataFetcher = new DataFetcher();
    const authentication = new AuthentificationManager();

    useEffect(() => {
        async function fetchData() {
            setUser_id(await dataFetcher.getCookie("user_id"));
            setSpital_name(await dataFetcher.getCookie("spital_Name"));

            const user_id_intern = await dataFetcher.getCookie("user_id");

            if(continueFlag){
                const continueAssessment = await dataFetcher.fetch_data_by_filter_when_equalto("Temp_Einzelassessment", "id,gruppenAssessmentName,user_id,bezugsdatum,spital", "user_id", user_id_intern);
    
                if (continueAssessment.data.length != 0) {
                    // TMP Assessment Exists
                    setContinueAssessmentTMP(continueAssessment);
                    setDisclaimerLeft("Altes löschen");
                    setDisclaimerMiddle("");
                    setDisclaimerRight("Vorheriges Assessment fortsetzen");
                    setDisclaimerTitle("Assessment fortsetzen?");
                    setDisclaimerText("Möchten Sie Ihr vorheriges Assessment fortsetzen oder ein neues beginnen?");
                    setDisclaimerMode("INITIAL");
                    setDisclaimerClose(false);
                    setIsDisclaimerVisible(true);
                    return true;
                }
            }
        }

        fetchData();
    }, []);

    const closeContent = () => {
        setVisible(false);

    };

    const startAssessment = async () => {



        //console.log("Assessment start");
        //console.log(user_id);

        if (assessmentBezugsdatum == undefined || assessmentBezugsdatum == null || assessmentBezugsdatum === "") {
            setDisclaimerLeft("");
            setDisclaimerRight("");
            setDisclaimerMiddle("");
            setDisclaimerTitle("Datenerhebungsdatum eingeben:");
            setDisclaimerText("Bitte wählen Sie zunächst ein Datenerhebungsdatum für das Assessment aus, um fortzufahren.");
            setDisclaimerMiddle("OK");
            setDisclaimerMode("NORMAL");
            setDisclaimerClose(false);
            setIsDisclaimerVisible(true);
            return false;
        }

        const allUserAssessments = await dataFetcher.fetch_data_by_filter_when_equalto("Einzelassessment","id,bezugsdatum,user_id","bezugsdatum",assessmentBezugsdatum);

        if(allUserAssessments.data.length != 0 && !isGruppenAssessment){
            setDisclaimerLeft("");
            setDisclaimerRight("");
            setDisclaimerMiddle("");
            setDisclaimerTitle("Datenerhebungsdatum belegt:");
            setDisclaimerText("Bitte wählen Sie ein neues Datenerhebungsdatum für das Assessment aus, um fortzufahren. Dieses ist bereits belegt");
            setDisclaimerMiddle("OK");
            setDisclaimerMode("NORMAL");
            setDisclaimerClose(false);
            setIsDisclaimerVisible(true);
            return false;
        }

        // if (continueAssessment.data[0] !== undefined) {
        //     setAssessmentBezugsdatum(continueAssessment.data[0].bezugsdatum ?? "");
        // }

        //console.log("HIER HIER SHIER");
        //console.log(data[0].titel);
        let tmp_assessment = {};
        if (data[0].titel) {
            tmp_assessment = {
                user_id: user_id,
                bezugsdatum: assessmentBezugsdatum,
                gruppenAssessmentName: data[0].titel,
                spital: spital_name
            };
        } else {
            tmp_assessment = {
                user_id: user_id,
                bezugsdatum: assessmentBezugsdatum,
                gruppenAssessmentName: "",
                spital: spital_name
            };
        }
        //console.log("AMsakdjkjish fdkjasddhf");
        //console.log(continueAssessment);

        // if (continueAssessment.data.length > 0) {
        //     const getGAID = await dataFetcher.fetch_data_by_filter_when_equalto("Gruppenassessment", "id,teilnehmer_fertig,assessment_liste", "name", data[0].titel);
        //     if (getGAID.data[0].teilnehmer_fertig != undefined || getGAID.data[0].teilnehmer_fertig != null) {
        //         updateGAbody = {
        //             "teilnehmer_fertig": getGAID.data[0].teilnehmer_fertig + "," + user_id,
        //             "assessment_liste": getGAID.data[0].assessment_liste + "," + continueAssessment.data[0].id,
        //         };
        //     }
        //     else {
        //         updateGAbody = {
        //             "teilnehmer_fertig": user_id,
        //             "assessment_liste": continueAssessment.data[0].id
        //         };
        //     }
        //     const setFertigTeilnehmer = await dataFetcher.updateData("Gruppenassessment", getGAID.data[0].id, updateGAbody);
        // }

        const createTMPAssessment_response = await dataFetcher.postData("Temp_Einzelassessment", tmp_assessment);
        console.log(createTMPAssessment_response);

        if (createTMPAssessment_response.data) {
            //console.log(createTMPAssessment_response.data.id);
            setCurrentAssessment(createTMPAssessment_response.data.id);
        }
        else {
            if (!continueFlag && createTMPAssessment_response.data == undefined && createTMPAssessment_response.errors[0].extensions.code === "RECORD_NOT_UNIQUE") {
                setSaveable(false);
                setDisclaimerLeft("");
                setDisclaimerRight("");
                setDisclaimerTitle("Das Assessment existiert bereits:");
                setDisclaimerText("Sie nehmen bereits an einem laufenden Assessment teil. Bitte schließen Sie dieses zunächst ab, bevor Sie ein neues beginnen.");
                setDisclaimerMiddle("OK");
                setDisclaimerMode("NORMAL");
                setDisclaimerClose(false);
                setIsDisclaimerVisible(true);
                return true;
            }
        }
        return true;
    };

    /**
     * Delete all data in the TMP collection from the user
     */
    const deleteandCreate = async () => {
        deleteTmpAssessment();
        setCurrentIndex(0);
        setFirstOpened(true);
        //console.log("DELETE AND CREATE");
        let tmp_assessment = {};
        if (data[0].titel) {
            tmp_assessment = {
                user_id: user_id,
                bezugsdatum: assessmentBezugsdatum,
                gruppenAssessmentName: data[0].titel,
                spital: spital_name
            };
        } else {
            tmp_assessment = {
                user_id: user_id,
                bezugsdatum: assessmentBezugsdatum,
                gruppenAssessmentName: "",
                spital: spital_name
            };
        }


        /*
        setTimeout(async () => {
            const createTMPAssessment_response = await dataFetcher.postData("Temp_Einzelassessment", tmp_assessment);
            if (createTMPAssessment_response.data) {
                //console.log(createTMPAssessment_response.data.id);
                setCurrentAssessment(createTMPAssessment_response.data.id);
            }
            else {
                if (createTMPAssessment_response.data == undefined && createTMPAssessment_response.errors[0].extensions.code === "RECORD_NOT_UNIQUE") {
                    setSaveable(false);
                    setDisclaimerLeft("");
                    setDisclaimerRight("");
                    setDisclaimerTitle("Das Assessment existiert bereits:");
                    setDisclaimerText("Sie nehmen bereits an einem laufenden Assessment teil. Bitte schließen Sie dieses zunächst ab, bevor Sie ein neues beginnen.");
                    setDisclaimerMiddle("OK");
                    setDisclaimerMode("NORMAL");
                    setIsDisclaimerVisible(true);
                    return true;
                }
            }
            //console.log(createTMPAssessment_response);
        }, 1000);*/
    };


    /**
     * Delete all data in the TMP collection from the user
     */
    const deleteTmpAssessment = async () => {

        const getTMPAnswers = await dataFetcher.fetch_data_by_filter_when_equalto("Temp_Reifegradantworten", "id", "user_id", user_id);
        const getTMPAssessment = await dataFetcher.fetch_data_by_filter_when_equalto("Temp_Einzelassessment", "id", "user_id", user_id);

        const deletedAnswers = 1;
        getTMPAnswers.data.map(async item => {
            //console.log(item.id);
            const responseDeleteAnswer = await dataFetcher.deleteTmpData("Temp_Reifegradantworten", item.id);

            if (responseDeleteAnswer != 1) {
                deletedAnswers = -1;
            }
        });
        try{
            const responeDeleteAssessment = await dataFetcher.deleteTmpData("Temp_Einzelassessment", getTMPAssessment.data[0].id)

            if (responeDeleteAssessment && deletedAnswers == 1 && responeDeleteAssessment == 1) {
                console.log("Success of deleting tmp data");
            } else {
                console.log("Fail of deleting tmp data");
            }

        } catch{
            console.log("No tmp assessment");
        }

        setIsDisclaimerVisible(false);
    };

    const handlePausieren = async () => {
        if(continueFlag){
            setCurrentIndex(1);
        }

        const loadAntwortenOfAssessment = await dataFetcher.fetch_data_by_filter_when_equalto("Temp_Reifegradantworten", "fragen_index,antwort,kommentar", "assessment_id", continueAssessmentTMP.data[0].id);
        setCurrentAssessment(continueAssessmentTMP.data[0].id);

        //console.log(loadAntwortenOfAssessment);

        loadAntwortenOfAssessment.data.forEach(item => {
            setSelectedLevels(prevState => ({
                ...prevState,
                [item.fragen_index]: item.antwort,
            }));


            setKommentarList(prevState => ({
                ...prevState,
                [item.fragen_index]: item.kommentar,
            }));
        });

        setSettingExistingBezugsdatum(true);
        setIsDisclaimerVisible(false);
    };

    const handleSaveAssessment = async () => {
        if (safeOnlyOnce) {
            setSafeOnlyOnce(false);
            //console.log("NUR EINMALE 1:++++++++++++++++++++++++++++++");
            if (Object.values(selectedLevels).length !== fragebogenData.length) {
                setIsDisclaimerVisible(false);
                setSafeOnlyOnce(true);
                closeContent();
                location.reload(true);
                return;
            }
            else {
                //console.log("NUR EINMAL 2:--------------------");
                const getTmpAssessment = await dataFetcher.fetch_data_by_filter_when_equalto("Temp_Einzelassessment", "id,gruppenAssessmentName,bezugsdatum,spital", "user_id", user_id);
                let assessmentID = "";

                console.log(getTmpAssessment);
                if (currentAssessment) {
                    assessmentID = currentAssessment;
                }
                else {
                    assessmentID = getTmpAssessment.data[0].id;
                }
                const allTMPAnswers = await dataFetcher.fetch_data_by_filter_when_equalto("Temp_Reifegradantworten", "antwort,levelident,fragen_index,fragen_id,user_id,assessment_id,spital,kommentar,effizienzgewinn_spital,effizienzgewinn_system,umsetzungsaufwand_spital,umsetzungsaufwand_system,bezugsdatum,antwort_text,kategorie,dimension,fragen_index", "assessment_id", assessmentID);

                console.log(allTMPAnswers);
                const assessmentBezugsdatumCurr = getTmpAssessment.data[0].bezugsdatum;

                const currentDate = new Date();
                if (assessmentBezugsdatumCurr == undefined || assessmentBezugsdatumCurr == null || assessmentBezugsdatumCurr === "") {
                    setDisclaimerLeft("");
                    setDisclaimerRight("");
                    setDisclaimerMiddle("");
                    setDisclaimerTitle("Datenerhebungsdatum vergessen:");
                    setDisclaimerText("Es scheint, als ob Sie vergessen haben, ein Datenerhebungsdatum für das Einzelassessment zu wählen.");
                    setDisclaimerMiddle("OK");
                    setDisclaimerMode("NORMAL");
                    setIsDisclaimerVisible(true);
                    setDisclaimerClose(false);
                    setSafeOnlyOnce(true);
                    return;
                }
                // Create new Assessment
                let assessment = "";
                // for EA
                let isGA = false;
                if ((getTmpAssessment.data[0] !== undefined || getTmpAssessment.data[0].gruppenAssessmentName === "")) {

                    //setAssessmentBezugsdatum(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`);
                    assessment = {
                        user_id: user_id,
                        bezugsdatum: assessmentBezugsdatumCurr,
                        spital: getTmpAssessment.data[0].spital
                    };
                }
                // For GA
                if (getTmpAssessment.data[0].gruppenAssessmentName != "") {

                    isGA = true;
                    assessment = {
                        user_id: user_id,
                        bezugsdatum: getTmpAssessment.data[0].bezugsdatum,
                        gruppenAssessmentName: assessmentBezugsdatumCurr,
                        spital: getTmpAssessment.data[0].spital
                    };

                    dataFetcher.updateData()

                }

                const newAssessmentId = await dataFetcher.postData("Einzelassessment", assessment);
                const antworten = [];

                // Check if saved correctly
                if (newAssessmentId) {
                } else {
                    setDisclaimerLeft("");
                    setDisclaimerRight("");
                    setDisclaimerMiddle("");
                    setDisclaimerTitle("Es ist ein Fehler aufgetreten:");
                    setDisclaimerText("Es scheint, als ob das Assessment nicht richtig gepseichert werden konnte. Versuchen Sie es bitte erneut.");
                    setDisclaimerMiddle("OK");
                    setDisclaimerMode("NORMAL");
                    setIsDisclaimerVisible(true);
                    setDisclaimerClose(false);
                    setSafeOnlyOnce(true);
                    return;
                }

                // Update Groupassessment info
                //console.log("IS GA VALUE HIER");
                //console.log(isGA);
                if (isGA) {
                    let groupAssessmentResponse = await dataFetcher.fetch_data_by_filter_when_equalto("Gruppenassessment", "id,teilnehmer_fertig,assessment_liste", "name", getTmpAssessment.data[0].gruppenAssessmentName, true)

                    //console.log(groupAssessmentResponse);

                    let addInfotoGroupAssessment = {};

                    if (groupAssessmentResponse == undefined || groupAssessmentResponse.data[0].teilnehmer_fertig == null || groupAssessmentResponse.data[0].teilnehmer_fertig === "") {
                        addInfotoGroupAssessment = {
                            "teilnehmer_fertig": user_id,
                            "assessment_liste": newAssessmentId.data.id
                        };
                    } else {
                        addInfotoGroupAssessment = {
                            "teilnehmer_fertig": `${groupAssessmentResponse.data[0].teilnehmer_fertig},${user_id}`,
                            "assessment_liste": `${groupAssessmentResponse.data[0].assessment_liste},${newAssessmentId.data.id}`
                        };
                    }
                    const updateGArasponse = await dataFetcher.updateData("Gruppenassessment", groupAssessmentResponse.data[0].id, addInfotoGroupAssessment);
                    //console.log("UpadteGA");
                    //console.log(updateGArasponse);
                }


                console.log(allTMPAnswers);
                for (let i = 0; i < allTMPAnswers.data.length; i++) {
                    allTMPAnswers.data[i].assessment_id = newAssessmentId.data.id;
                }

                //console.log(allTMPAnswers.data);
                const createAntwortenResponse = await dataFetcher.postData("Reifegradantworten", allTMPAnswers.data);
                //console.log("CreateAntwort");
                //console.log(createAntwortenResponse);


                // Check if saved correctly
                if (newAssessmentId && createAntwortenResponse) {
                    toast.success("Assessment erfolgreich gespeichert.", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });

                    await deleteTmpAssessment();

                    setSafeOnlyOnce(false);
                    setTimeout(() => {
                        setVisible(false);
                        location.reload(true);
                        return;
                    }, 2000);
                } else {
                    setDisclaimerLeft("");
                    setDisclaimerRight("");
                    setDisclaimerMiddle("");
                    setDisclaimerTitle("Es ist ein Fehler aufgetreten:");
                    setDisclaimerText("Es scheint, als ob das Assessment nicht gepseichert werden konnte. Versuchen Sie es bitte erneut.");
                    setDisclaimerMiddle("OK");
                    setDisclaimerMode("NORMAL");
                    setDisclaimerClose(false);
                    setIsDisclaimerVisible(true);
                    setSafeOnlyOnce(true);
                    return;
                }
                location.reload(true);
            }
            setSafeOnlyOnce(false);
        }
    };



    const handleAbschliessen = async () => {
        if (Object.values(selectedLevels).length == 0) {
            console.log("Liste ist leer");
            await deleteTmpAssessment();
            closeContent();
            location.reload(true);
            return;
        }

        if (Object.values(selectedLevels).length <= 0 || Object.values(selectedLevels).length !== fragebogenData.length) {
            setDisclaimerLeft("");
            setDisclaimerRight("");
            setDisclaimerMiddle("");
            setDisclaimerTitle("Assessment nicht vollständig:");
            setDisclaimerText("Es scheint, als sei das Assessment nicht vollständig ausgefüllt. Bitte überprüfen Sie dies erneut mithilfe der Navigationsleiste (der Pfeil unten in der Mitte).");
            setDisclaimerLeft("Weiter ausfüllen");
            setDisclaimerRight("Speichern und später Fortfahren");
            setDisclaimerMode("NORMAL");
            setDisclaimerClose(false);
            setIsDisclaimerVisible(true);
            return;
        }
        else {
            setDisclaimerLeft("");
            setDisclaimerRight("");
            setDisclaimerMiddle("");
            setDisclaimerTitle("Assessment abschliessen");
            setDisclaimerText("Möchten Sie das Assessment abschliessen und speichern oder nur speichern und später weiterbearbeiten?");
            setDisclaimerLeft("Speichern");
            setDisclaimerRight("Abschliessen");
            setDisclaimerMode("NORMAL");
            setDisclaimerClose(true);
            setIsDisclaimerVisible(true);
            console.log("hif");
        }
    };


    /**
     *  Handle the aisagree botton normaly doing nothing just closing the disclaimer window
     */
    const handleDisagree = () => {
        if (disclaimerText === "Möchten Sie das Assessment abschliessen und speichern oder nur speichern und später weiterbearbeiten?") {
            closeContent();
            setIsDisclaimerVisible(false);
            setDisclaimerLeft("");
            setDisclaimerRight("");
            setDisclaimerMiddle("");
            location.reload(true);
            return;
        }
        setIsDisclaimerVisible(false);
        setDisclaimerLeft("");
        setDisclaimerRight("");
        setDisclaimerMiddle("");
    }


    /**
     * Toggle the Comment Window
     */
    const toggleKommentar = () => {
        setIsKommentarVisible((prev) => !prev);
    };


    /**
     * Toggle the Help Window
     */
    const toggleHelp = () => {
        setIsHelpVisible((prev) => !prev);
    };


    /**
     * Increase the index of the Questions
     */
    const increaseIndex = async () => {
        setIsKommentarVisible(false);
        // Refresh User Token
        authentication.checkAndRefreshToken();
        
        if (currentIndex == 0 && firstOpened) {
            let isReady = await startAssessment();
            if (!isReady) {
                return;
            }
            setFirstOpened(false);
        }
        if (currentIndex != data.length + 1) {
            setCurrentIndex((prev) => prev + 1);
        }
        else {
            setCurrentIndex(1);
        }
    };

    /**
     * Decrease the index of the Questions
     */
    const decreaseIndex = () => {
        setIsKommentarVisible(false);
        // Refresh User Token
        authentication.checkAndRefreshToken();
        
        if (!firstOpened || currentIndex > 1) {
            if (currentIndex != 0) {
                setCurrentIndex((prev) => prev - 1)
            }
            else {
                setCurrentIndex(data.length)
            }
        }
    };


    useEffect(() => {
        if (selectedLevels && currentIndex > 0 && currentIndex < data.length + 1) {
            setToggleCommentAktiv(!selectedLevels.hasOwnProperty(data[currentIndex - 1].index));
        }
    }, [currentIndex, selectedLevels]);

    // Function to handle radio change and update the selected level for the current index
    const handleRadioChangeLocal = async (index, selectedValue) => {
        // //console.log("Answer has been selected");
        // //console.log(index);
        // //console.log(selectedValue);
        // //console.log(kommentarList);
        // ------------------------------------------------------------------------------------------------------------------ TODO UPDATE ANTWORT WENN SCHON VORHANDEN !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        console.log("------------ Hier ---------------");
        console.log(currentAssessment);

        if (saveable) {
            let levelident_antwort = `ID${String(data[data.findIndex(item => item.index === index)].id).padStart(3, '0')}.DI${String(data[data.findIndex(item => item.index === index)].dimension_id).padStart(3, '0')}.CA${String(data[data.findIndex(item => item.index === index)].kategorie_id).padStart(3, '0')}.LV${String(selectedValue).padStart(3, '0')}`;

            let tmp_antwort = {};

            if (kommentarList.length > 0) {
                kommentarList.map(async item => {
                    if (item.index == index) {
                        tmp_antwort = {
                            antwort: selectedValue,                                                                                                     // Assuming selectedLevels contains the answer
                            levelident: levelident_antwort,                                                                                             // Adjust this based on your data structure
                            fragen_index: index,                                                                                                        // fragen_index 
                            fragen_id: data[data.findIndex(item => item.index === index)].id,                                                           // Adjust this based on your data structure
                            user_id: user_id,                                                                                                           // Add your logic to get user_id
                            assessment_id: currentAssessment,                                                                                           // Add your logic to get assessment_id
                            spital: spital_name,                                                                                                        // Assuming spital is the same for all questions
                            kommentar: kommentarList[index],                                                                                            // Assuming kommentarList contains the comments
                            effizienzgewinn_spital: data[data.findIndex(item => item.index === index)][`effizienzgewinn_spital_${selectedValue}`],      // Add your logic to get effizienzgewinn_spital
                            effizienzgewinn_system: data[data.findIndex(item => item.index === index)][`effizienzgewinn_system_${selectedValue}`],      // Add your logic to get effizienzgewinn_system
                            umsetzungsaufwand_spital: data[data.findIndex(item => item.index === index)][`umsetzungsaufwand_spital_${selectedValue}`],  // Add your logic to get umsetzungsaufwand_spital
                            umsetzungsaufwand_system: data[data.findIndex(item => item.index === index)][`umsetzungsaufwand_system_${selectedValue}`],  // Add your logic to get umsetzungsaufwand_system
                            bezugsdatum: assessmentBezugsdatum,
                            antwort_text: data[data.findIndex(item => item.index === index)][`antwort_${selectedValue}`],
                            kategorie: data[data.findIndex(item => item.index === index)].kategorie,
                            dimension: data[data.findIndex(item => item.index === index)].dimension,
                            fragen_index: data[data.findIndex(item => item.index === index)].index
                        };
                    }
                });
            } else {
                tmp_antwort = {
                    antwort: selectedValue,                                                                                                             // Assuming selectedLevels contains the answer
                    levelident: levelident_antwort,                                                                                                     // Adjust this based on your data structure
                    fragen_index: index,                                                                                                                // fragen_index 
                    fragen_id: data[data.findIndex(item => item.index === index)].id,                                                                   // Adjust this based on your data structure
                    user_id: user_id,                                                                                                                   // Add your logic to get user_id
                    assessment_id: currentAssessment,                                                                                                   // Add your logic to get assessment_id
                    spital: spital_name,                                                                                                                // Assuming spital is the same for all questions
                    kommentar: kommentarList[index],                                                                                                    // Assuming kommentarList contains the comments
                    effizienzgewinn_spital: data[data.findIndex(item => item.index === index)][`effizienzgewinn_spital_${selectedValue}`],              // Add your logic to get effizienzgewinn_spital
                    effizienzgewinn_system: data[data.findIndex(item => item.index === index)][`effizienzgewinn_system_${selectedValue}`],              // Add your logic to get effizienzgewinn_system
                    umsetzungsaufwand_spital: data[data.findIndex(item => item.index === index)][`umsetzungsaufwand_spital_${selectedValue}`],          // Add your logic to get umsetzungsaufwand_spital
                    umsetzungsaufwand_system: data[data.findIndex(item => item.index === index)][`umsetzungsaufwand_system_${selectedValue}`],          // Add your logic to get umsetzungsaufwand_system
                    bezugsdatum: assessmentBezugsdatum,
                    antwort_text: data[data.findIndex(item => item.index === index)][`antwort_${selectedValue}`],
                    kategorie: data[data.findIndex(item => item.index === index)].kategorie,
                    dimension: data[data.findIndex(item => item.index === index)].dimension,
                    fragen_index: data[data.findIndex(item => item.index === index)].index
                };
            }

            const allTempAnswers_response = await dataFetcher.fetch_data_by_filter_when_equalto("Temp_Reifegradantworten", "id,fragen_index,bezugsdatum", "user_id", dataFetcher.getCookie('user_id'));
            const currentAnswer = allTempAnswers_response.data.filter(item => item.fragen_index === data[data.findIndex(item => item.index === index)].index);

            //console.log(currentAnswer);
            if (currentAnswer.length == 0) {
                const createAnswer_response = await dataFetcher.postData("Temp_Reifegradantworten", tmp_antwort);
                if (createAnswer_response.data) {

                }
                else {
                    if (createAnswer_response.data == undefined && createAnswer_response.errors[0].extensions.code === "RECORD_NOT_UNIQUE") {
                        setSaveable(false);
                        setDisclaimerLeft("");
                        setDisclaimerRight("");
                        setDisclaimerTitle("Es ist ein Fehler eingetroffen:");
                        setDisclaimerText("Antwort konnte nicht zwischengespeichert werden!");
                        setDisclaimerMiddle("OK");
                        setDisclaimerMode("NORMAL");
                        setIsDisclaimerVisible(true);
                        return;
                    }
                }
            } else {
                let komm = '';
                if (kommentarList[index] !== null) {
                    komm = kommentarList[index];
                }

                const updateReifegradantwort = {
                    antwort: selectedValue,
                    levelident: levelident_antwort,
                    antwort_text: data[data.findIndex(item => item.index === index)][`antwort_${selectedValue}`],
                    kommentar: komm,
                    bezugsdatum: currentAnswer[0].bezugsdatum,
                    effizienzgewinn_spital: data[data.findIndex(item => item.index === index)][`effizienzgewinn_spital_${selectedValue}`],
                    effizienzgewinn_system: data[data.findIndex(item => item.index === index)][`effizienzgewinn_system_${selectedValue}`],
                    umsetzungsaufwand_spital: data[data.findIndex(item => item.index === index)][`umsetzungsaufwand_spital_${selectedValue}`],
                    umsetzungsaufwand_system: data[data.findIndex(item => item.index === index)][`umsetzungsaufwand_system_${selectedValue}`]
                };

                const updateAnswer_response = await dataFetcher.updateData("Temp_Reifegradantworten", currentAnswer[0].id, updateReifegradantwort);
                //console.log(updateAnswer_response);

            }
        }

        setSelectedLevels(prevState => ({
            ...prevState,
            [index]: selectedValue,
        }));
    };

    const handlekommentarChange = async (index, kommentar) => {
        setFragenIndex(index);
        setKommentarList(prevState => ({
            ...prevState,
            [index]: kommentar,
        }));

        // ------------------------------------------------------------------------------------------ Speichere kommentar in TMP Collection auch wenn keine Antwort vorhanden ! Alternativ: TODO
    }


    useEffect(() => {
        const updateKommentar = async () => {
            if (fragenIndex !== undefined && fragenIndex !== "") {
                const getAnswerFromKommentar = await dataFetcher.fetch_data_by_filter_when_equalto("Temp_Reifegradantworten", "id", "fragen_index", fragenIndex);
                if (getAnswerFromKommentar.data.length !== 0) {
                    const kommentarBody = {
                        kommentar: kommentarList[fragenIndex]
                    };

                    const response = await dataFetcher.updateData("Temp_Reifegradantworten", getAnswerFromKommentar.data[0].id, kommentarBody);
                    //console.log(response);
                }
            }
        };

        updateKommentar();
    }, [kommentarList]);

    // Speichert neues Bezugsdatum wenn ein Assessment fortgeführt wird
    const handleBezugsdatumChange = async (currentDate, tempAssessmentId) => {
        const resp_antworten = await dataFetcher.fetch_data_by_filter_when_equalto('Temp_Reifegradantworten', 'id,fragen_index,antwort', 'assessment_id', tempAssessmentId);

        const updateBody = {
            bezugsdatum: currentDate
        };
        setAssessmentBezugsdatum(currentDate)
        if (resp_antworten.data !== undefined) {
            resp_antworten.data.forEach(item => {
                dataFetcher.updateData("Temp_Reifegradantworten", item.id, updateBody);
            })
        }
        const response = await dataFetcher.updateData("Temp_Einzelassessment", tempAssessmentId, updateBody);
        //console.log(response);
    }



    return (
        <div className='fragen-background'>
            <ToastContainer />
            <div className='fragen-content-container'>
                {isDisclaimerVisible && (
                    <>
                        {disclaimerMode === "NORMAL" && (
                            <DisclaimerWidget
                                title={disclaimerTitle}
                                text={disclaimerText}
                                disagree={disclaimerLeft}
                                buttonInTheMiddle={disclaimerMiddle}
                                agree={disclaimerRight}
                                buttonInTheMiddleOnClickMethode={handleDisagree}
                                disagreeOnClickMethode={handleDisagree}
                                agreeOnClickMethode={handleSaveAssessment}
                                close={disclaimerClose}
                                closeAction={setIsDisclaimerVisible}
                            />
                        )}
                        {disclaimerMode === "INITIAL" && (
                            <DisclaimerWidget
                                title={disclaimerTitle}
                                text={disclaimerText}
                                disagree={disclaimerLeft}
                                buttonInTheMiddle={disclaimerMiddle}
                                agree={disclaimerRight}
                                disagreeOnClickMethode={deleteandCreate}
                                agreeOnClickMethode={handlePausieren}
                                close={disclaimerClose}
                                closeAction={setIsDisclaimerVisible}
                            />
                        )}
                    </>
                )}
                <img src='/closeButton.svg' className="fragen-closebutton" onClick={handleAbschliessen} />
                <div className='fragen-header-container'>
                    {data[0].titel === '' ? (
                        "Einzelassessment"
                    ) : (
                        "Gruppenassessment"
                    )}
                    {data[0].titel !== '' && <div className='fragen-header-gruppenassessment'>{data[0].titel}</div>}
                </div>
                <div className='fragen-main-content-split'>
                    <div className='fragen-main-content'>
                        <div className='fragen-main-inner-content'>
                            {currentIndex > 0 && currentIndex <= data.length ? (
                                <Frage
                                    index={data[currentIndex - 1].index}
                                    frage={data[currentIndex - 1].frage}
                                    dimension={data[currentIndex - 1].dimension}
                                    kategorie={data[currentIndex - 1].kategorie}
                                    beispiel={data[currentIndex - 1].beispiel}
                                    antworttyp={data[currentIndex - 1].antworttyp}
                                    level_1={data[currentIndex - 1].antwort_1}
                                    level_2={data[currentIndex - 1].antwort_2}
                                    level_3={data[currentIndex - 1].antwort_3}
                                    level_4={data[currentIndex - 1].antwort_4}
                                    level_5={data[currentIndex - 1].antwort_5}
                                    beispiel_1={data[currentIndex - 1].beispiel_1}
                                    beispiel_2={data[currentIndex - 1].beispiel_2}
                                    beispiel_3={data[currentIndex - 1].beispiel_3}
                                    beispiel_4={data[currentIndex - 1].beispiel_4}
                                    beispiel_5={data[currentIndex - 1].beispiel_5}
                                    kommentar={data[currentIndex - 1].kommentar}
                                    //levelident={data[currentIndex - 1].levelident}
                                    selectedLevels={selectedLevels}
                                    handleRadioChangeLocal={handleRadioChangeLocal}
                                />
                            ) : currentIndex == 0 ? (
                                <div className='fragen-page-container'>
                                    <div className='fragen-page-title'>Starten Sie die Bewertung der digitalen Reife ihres Unternehmens</div>
                                    <div className='fragen-page-img'>
                                        <div className='fragen-page-disclaimer'>Bedenken Sie, dass dieses Assessment Ihre persönliche Einschätzung darstellt und sich auf das ganze Unternehmen bezieht, nicht nur auf die einzelnen Abteilungen! </div>
                                        {window.innerHeight >= 932 && (
                                            <img
                                                src={startimg}
                                                alt="startimg"
                                                draggable="false"
                                                style={{ height: "60%" }}
                                            />
                                        )}
                                        <div className='fragen-page-button-container'>
                                            <div className='fragen-page-button' onClick={closeContent}> Abbrechen </div>
                                            <div className='fragen-page-button' onClick={increaseIndex}> Starten &gt; </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='fragen-page-container'>
                                    <div className='fragen-page-img'>
                                        <div className='fragen-page-end-title'>Danke für die Teilnahme!</div>
                                        <div className='fragen-page-button-container fragen-end-container'>
                                            <div className='fragen-page-button' onClick={decreaseIndex}> &lt; </div>
                                            <div className='fragen-page-button' onClick={increaseIndex}> &lt; Zurück zur ersten Frage </div>
                                            <div className='fragen-page-button' onClick={handleAbschliessen}> Beenden und speichern </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {isKommentarVisible && currentIndex > 0 && currentIndex <= data.length ? (
                            <Kommentar
                                number={currentIndex}
                                index={data[currentIndex - 1].index}
                                handlekommentarChange={handlekommentarChange}
                                kommentarValue={kommentarList[data[currentIndex - 1].index]}
                                toggleKommentar={toggleKommentar}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                    {isHelpVisible && (
                        <Help></Help>
                    )}
                </div>
                {currentIndex > 0 && (
                    <FragenNavigation
                        setIndex={setCurrentIndex}
                        frageListe={data}
                        currentIndex={currentIndex}
                        filledListe={selectedLevels} />
                )}
                <Footer
                    index={currentIndex}
                    openCloseKommentar={toggleKommentar}
                    openCloseHelp={toggleHelp}
                    increaseIndex={increaseIndex}
                    decreaseIndex={decreaseIndex}
                    handlePausieren={handlePausieren}
                    handleAbschliessen={handleAbschliessen}
                    toggleCommentAktiv={toggleCommentAktiv}
                    isGruppenAssessment={isGruppenAssessment}
                    assessmentBezugsdatum={assessmentBezugsdatum}
                    setAssessmentBezugsdatum={setAssessmentBezugsdatum}
                    settingExistingBezugsdatum={settingExistingBezugsdatum}
                    setSettingExistingBezugsdatum={setSettingExistingBezugsdatum}
                    handleBezugsdatumChange={handleBezugsdatumChange}
                    kommentarVisible={isKommentarVisible}>
                </Footer>
            </div>
        </div>
    );
};













/* SIDE WIDGETS */

export const Frage = ({ index, frage, dimension, kategorie, beispiel, antworttyp, level_1, level_2, level_3, level_4, level_5, beispiel_1, beispiel_2, beispiel_3, beispiel_4, beispiel_5, kommentar, levelident, selectedLevels, handleRadioChangeLocal }) => {
    // Retrieve the selected level for the current index from state
    const selectedLevel = selectedLevels[index];

    // //console.log(level_1);
    // //console.log(level_2);
    // //console.log(level_3);
    // //console.log(level_4);
    // //console.log(level_5);
    // //console.log(frage);
    // //console.log(selectedLevels);
    // //console.log(index);
    // //console.log(selectedLevel);

    return (
        <div className='frage-title-header-container' key={index}>
            <div className='frage-title-container'>
                <div className='frage-title-header'>{index} - {kategorie}</div>
                <div className='frage-container'>
                    <div className='frage-text'>{frage}</div>
                    {beispiel && <div className='frage-beispiel'>Bsp. : {beispiel}</div>}
                    <div className='frage-kategorie'>{dimension}</div>
                </div>
            </div>
            {antworttyp === "Level" && (
                <div className='antwort-container'>
                    <div className='antonwort-item-container'>
                        <input type="radio" name={`answer_${index}`} id={`level_1_${index}`} onChange={() => handleRadioChangeLocal(index, 1)} checked={selectedLevel == 1} key={`level_1_${index}`} />
                        <label htmlFor={`level_1_${index}`} className='antwort-level'>{level_1}</label>
                        <div className='antonwort-item-beispiel'>{beispiel_1}</div>
                    </div>
                    <div className='antonwort-item-container'>
                        <input type="radio" name={`answer_${index}`} id={`level_2_${index}`} onChange={() => handleRadioChangeLocal(index, 2)} checked={selectedLevel == 2} key={`level_2_${index}`} />
                        <label htmlFor={`level_2_${index}`} className='antwort-level'>{level_2}</label>
                        <div className='antonwort-item-beispiel'>{beispiel_2}</div>
                    </div>
                    <div className='antonwort-item-container'>
                        <input type="radio" name={`answer_${index}`} id={`level_3_${index}`} onChange={() => handleRadioChangeLocal(index, 3)} checked={selectedLevel == 3} key={`level_3_${index}`} />
                        <label htmlFor={`level_3_${index}`} className='antwort-level'>{level_3}</label>
                        <div className='antonwort-item-beispiel'>{beispiel_3}</div>
                    </div>
                    <div className='antonwort-item-container'>
                        <input type="radio" name={`answer_${index}`} id={`level_4_${index}`} onChange={() => handleRadioChangeLocal(index, 4)} checked={selectedLevel == 4} key={`level_4_${index}`} />
                        <label htmlFor={`level_4_${index}`} className='antwort-level'>{level_4}</label>
                        <div className='antonwort-item-beispiel'>{beispiel_4}</div>
                    </div>
                    <div className='antonwort-item-container'>
                        <input type="radio" name={`answer_${index}`} id={`level_5_${index}`} onChange={() => handleRadioChangeLocal(index, 5)} checked={selectedLevel == 5} key={`level_5_${index}`} />
                        <label htmlFor={`level_5_${index}`} className='antwort-level'>{level_5}</label>
                        <div className='antonwort-item-beispiel'>{beispiel_5}</div>
                    </div>
                </div>
            )}
        </div>
    );
};


export const Kommentar = ({ number, index, handlekommentarChange, kommentarValue, toggleKommentar }) => {
    const handleChange = (event) => {
        const newValue = event.target.value;
        handlekommentarChange(index, newValue);
    };

    return (
        <div className="comment-container">
            <div className='comment-title'>Kommentar zu Frage {index}</div>
            <div className='comment-content'>
                <textarea
                    key={number}
                    className='comment-textarea'
                    placeholder="Schreibe hier deinen Kommentar ..."
                    value={kommentarValue || ''} // Use the kommentarValue prop
                    onChange={handleChange}
                />
            </div>
            <div className='comment-button-section'>
                <div className='commen-button-l' onClick={toggleKommentar}>Abbrechen</div>
                <div className='commen-button-r' onClick={toggleKommentar}>Speichern</div>
            </div>
        </div>
    );
};


export const Help = ({ }) => {
    return (
        <div className="help-container">
            <div className='help-title'>Hilfe Text</div>
            <div className='help-content'>
                <div className='help-subtitle'>L1 ('Reaktiv'):</div>
                Auf dieser Ebene operieren Krankenhäuser in einem reaktiven Modus ohne signifikante digitale Infrastruktur. Prozesse sind manuell, oft offline und stark auf papierbasierte Systeme angewiesen. Es mangelt an proaktiver Planung, und Lösungen werden typischerweise isoliert entwickelt, um unmittelbare Probleme anzugehen.
                <div className='help-subtitle'>L2 ('Organisiert'):</div>
                Krankenhäuser auf dieser Stufe haben begonnen, digitale Lösungen zu integrieren, diese sind jedoch isoliert und nicht weit verbreitet. Einige Prozesse sind digitalisiert, und es gibt Bemühungen, Daten zu sammeln und zu speichern. Die Digitalisierung ist jedoch fleckenhaft und noch nicht Teil einer kohärenten Strategie.
                <div className='help-subtitle'>L3 ('Digitalisiert'):</div>
                Auf dieser Ebene sind die meisten Krankenhausoperationen und Dokumentationen digitalisiert und strukturiert. Digitale Prozesse sind häufiger, was einen signifikanten Schritt weg von manuellen Systemen markiert. Der Fokus liegt darauf, Kernoperationen zu digitalisieren und sicherzustellen, dass die Daten effektiv organisiert sind.
                <div className='help-subtitle'>L4 ('Verbunden'):</div>
                Krankenhäuser auf dieser Ebene zeigen eine weitreichende Digitalisierung in allen Abteilungen. Sie legen Wert auf Interoperabilität und integrierte Wertschöpfung, wobei Technologien, Daten und Systeme verbunden sind und harmonisch funktionieren. Ein zentrales Datenlager ist oft ein Merkmal, das sicherstellt, dass Daten im gesamten Krankenhaus zugänglich und nutzbar sind.
                <div className='help-subtitle'>L5 ('Intelligent'):</div>
                Die höchste Ebene der digitalen Reife, gekennzeichnet durch modernste Technologie und kontinuierliche Entwicklung. Krankenhäuser auf dieser Stufe nutzen intelligente Systeme, die Daten durch automatisierte Werkzeuge nutzen. Prozesse werden in Echtzeit unter Verwendung von Leistungsindikatoren (KPIs) verwaltet, was eine ausgefeilte, datengesteuerte Entscheidungsfindung und betriebliche Effizienz ermöglicht.
            </div>
        </div>
    );
};



export const FragenNavigation = ({ setIndex, currentIndex, frageListe, filledListe }) => {
    const [isNavigationOpen, setNavigationOpen] = useState(true);

    const toggleNavigation = () => {
        setNavigationOpen(!isNavigationOpen);
    };

    return (
        <div className='navigation-whole'>
            <div className={`navigation-opener-container-${isNavigationOpen ? 'up' : 'down'}`} onClick={toggleNavigation}>
                <img
                    className={`navigation-img-${isNavigationOpen ? 'up' : 'down'}`}
                    src={`./Icons/Expand_up.svg`}
                    alt={`Expand ${isNavigationOpen ? 'up' : 'down'}`}
                />
            </div>

            {isNavigationOpen && (
                <div className='navigation-container'>
                    <div className='navigation-inner-container'>
                        {frageListe.map((frageItem, i) => (
                            <div
                                key={frageItem.index}
                                className={`navigation-item-container ${currentIndex === i + 1 ? 'nav-item-selected' :
                                    (filledListe[frageItem.index] && currentIndex !== i + 1) ? 'nav-item-filled' : ''}`}
                                onClick={() => setIndex(i + 1)}>
                                <div className='navigation-item'>
                                    {frageItem.index}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};



export const Footer = ({ index, openCloseKommentar, openCloseHelp, increaseIndex, decreaseIndex, handlePausieren, handleAbschliessen, toggleCommentAktiv, isGruppenAssessment, assessmentBezugsdatum, setAssessmentBezugsdatum, settingExistingBezugsdatum, setSettingExistingBezugsdatum, handleBezugsdatumChange, modus = "NORMAL", kommentarVisible }) => {

    if(index != 0){
        isGruppenAssessment = true;
    }

    const dataFetcher = new DataFetcher();
    const [tempBezugsdatum, setTempBezugsdatum] = useState('');
    const [initBezugsdatumSet, setInitBezugsdatumSet] = useState(true);
    const [tempAssessmentId, setTempAssessmentId] = useState('');



    const retrieve_tempData = (async () => {
        const tempAssessmentData = await dataFetcher.fetch_data_by_filter_when_equalto("Temp_Einzelassessment", "id,gruppenAssessmentName,user_id,bezugsdatum,spital", "user_id", dataFetcher.getCookie('user_id'));
        //console.log(tempAssessmentData);
        if (tempAssessmentData.data.length != 0) {
            //console.log(assessmentBezugsdatum);
            if (assessmentBezugsdatum == '') {
                setTempBezugsdatum(tempAssessmentData.data[0].bezugsdatum);
                setTempAssessmentId(tempAssessmentData.data[0].id);
            } else {
                setTempAssessmentId(tempAssessmentData.data[0].id);
                handleBezugsdatumChange(assessmentBezugsdatum, tempAssessmentData.data[0].id);
            }
        }
        setInitBezugsdatumSet(false);
    })

    if (settingExistingBezugsdatum && initBezugsdatumSet && isGruppenAssessment) {
        retrieve_tempData();
        setSettingExistingBezugsdatum(false);
    }

    const closeContent = () => {
        // Add your closeContent logic here if needed
    };

    const handleKeyPress = (event) => {
        // Check if the pressed key is the left arrow key
        if (!kommentarVisible && event.key === 'ArrowLeft') {
            decreaseIndex();
        }
        // Check if the pressed key is the right arrow key
        else if (!kommentarVisible && event.key === 'ArrowRight') {
            increaseIndex();
        }
    };

    const changeBezugsdatum = (value) => {
        setAssessmentBezugsdatum(value);
        handleBezugsdatumChange(value, tempAssessmentId);
    }

    useEffect(() => {
        // Add event listeners when the component mounts
        window.addEventListener('keydown', handleKeyPress);

        // Remove event listeners when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]); // Add handleKeyPress as a dependency to prevent stale closure

    return (
        <div className='footer-container' id='footer'>
            <div className='footer-item-container'>
                {modus === "NORMAL" && (
                    <>
                        <div className='footer-item item-left' onClick={decreaseIndex}>&lt;</div>
                        {/*<div className='footer-item item-pause' onClick={handlePausieren}>Pausieren</div>*/}
                        <div className='footer-item item-right' onClick={increaseIndex}>&gt;</div>
                    </>
                )}

                <div className={`footer-item item-comment-${toggleCommentAktiv ? 'disabled' : 'aktive'}`} onClick={openCloseKommentar} disabled={toggleCommentAktiv}>Kommentar</div>
                <div className='footer-item item-help' onClick={openCloseHelp}>?</div>

                {/* bezugsdatum eingeben, nur bei einzelassessment*/}
                <div className='footer-item-text'>Datenerhebungsdatum:</div>
                {!isGruppenAssessment ? (
                    <input
                        className='footer-item item-bezugsdatum-festlegen'
                        type='date'
                        value={assessmentBezugsdatum || tempBezugsdatum}
                        onChange={event => { changeBezugsdatum(event.target.value); }} />
                ) : (
                    <div className="footer-item item-bezugsdatum-anzeigen">{assessmentBezugsdatum}</div>
                )}
                <div className={`footer-item item-save ${index === 0 ? 'button-disabled' : 'active'}`} onClick={index !== 0 ? handleAbschliessen : null}>
                    Abschliessen
                </div>
            </div>
        </div>
    );
};

export const TreegraphFrage = ({ closeContent, index, frage, dimension, kategorie, beispiel, antworttyp, level_1, level_2, level_3, level_4, level_5, beispiel_1, beispiel_2, beispiel_3, beispiel_4, beispiel_5, kommentar, levelident, selectedLevels, handleRadioChangeLocal }) => {
    // Retrieve the selected level for the current index from state
    const [savedIndex, setSavedIndex] = useState(index);
    const [savedValue, setSavedValue] = useState(selectedLevels[index]);

    const saveSelection = () => {
        console.log("I am here");
        if (savedIndex !== null && savedValue !== null) {
            handleRadioChangeLocal(savedIndex, savedValue);
        }
    };

    const cancelSelection = () => {
        closeContent(false);
    };

    return (
        <div className='treefrage-title-header-container' key={index} style={{height:"100%", marginBottom:"20px", display:"flex", flexDirection:"column", alignItems: "space-between"}}>
            <div style={{maxHeight:"100%", overflowY: "scroll", marginBottom:"20px", display:"flex", flexDirection:"column", alignItems: "space-between"}}>
                <div className='frage-title-container'>
                    <div className='frage-title-header'>{index} - {kategorie}</div>
                    <div className='frage-container'>
                        <div className='frage-text'>{frage}</div>
                        {beispiel && <div className='frage-beispiel'>Bsp. : {beispiel}</div>}
                        <div className='frage-kategorie'>{dimension}</div>
                    </div>
                </div>
                {antworttyp === "Level" && (
                    <div className='antwort-container'>
                        <div className='antonwort-item-container'>
                            <input type="radio" name={`answer_${index}`} id={`level_1_${index}`} onChange={() => { setSavedIndex(index); setSavedValue(1); }} checked={savedValue == 1} key={`level_1_${index}`} />
                            <label htmlFor={`level_1_${index}`} className='antwort-level'>{level_1}</label>
                            <div className='antonwort-item-beispiel'>{beispiel_1}</div>
                        </div>
                        <div className='antonwort-item-container'>
                            <input type="radio" name={`answer_${index}`} id={`level_2_${index}`} onChange={() => { setSavedIndex(index); setSavedValue(2); }} checked={savedValue == 2} key={`level_2_${index}`} />
                            <label htmlFor={`level_2_${index}`} className='antwort-level'>{level_2}</label>
                            <div className='antonwort-item-beispiel'>{beispiel_2}</div>
                        </div>
                        <div className='antonwort-item-container'>
                            <input type="radio" name={`answer_${index}`} id={`level_3_${index}`} onChange={() => { setSavedIndex(index); setSavedValue(3); }} checked={savedValue == 3} key={`level_3_${index}`} />
                            <label htmlFor={`level_3_${index}`} className='antwort-level'>{level_3}</label>
                            <div className='antonwort-item-beispiel'>{beispiel_3}</div>
                        </div>
                        <div className='antonwort-item-container'>
                            <input type="radio" name={`answer_${index}`} id={`level_4_${index}`} onChange={() => { setSavedIndex(index); setSavedValue(4); }} checked={savedValue == 4} key={`level_4_${index}`} />
                            <label htmlFor={`level_4_${index}`} className='antwort-level'>{level_4}</label>
                            <div className='antonwort-item-beispiel'>{beispiel_4}</div>
                        </div>
                        <div className='antonwort-item-container'>
                            <input type="radio" name={`answer_${index}`} id={`level_5_${index}`} onChange={() => { setSavedIndex(index); setSavedValue(5); }} checked={savedValue == 5} key={`level_5_${index}`} />
                            <label htmlFor={`level_5_${index}`} className='antwort-level'>{level_5}</label>
                            <div className='antonwort-item-beispiel'>{beispiel_5}</div>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ position: "relative", width:"100%", bottom: "10px", top: "auto", marginTop: "auto"}}>
                <button className='treefragen-button treefragen-button-cancel' onClick={cancelSelection}>Abbrechen</button>
                <button className='treefragen-button treefragen-button-save' onClick={saveSelection}>Speichern</button>
            </div>
        </div>
    );
};