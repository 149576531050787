import React from 'react';
import "./ModalAssessmentStarten.css"
import { useState, useEffect, useCallback } from "react";
import DataFetcher from '../pages/classes/databaseManager';
import AuthentificationManager from '../pages/classes/authentificationManager';
import { TripettoWidget } from "../widgets/TripettoWidget.js"
import { ModalCreateGruppenAssessments } from './ModalCreateGruppenassessment.js';
import { FragebogenWidget } from "../widgets/FragebogenWidget.js"
import { FirstLoginNoAssessmentPopup } from "./FirstLoginNoAssessmentPopup.js";


export const ModalAssessmentStarten = ({ }) => {
    const databaseManager = new DataFetcher();
    const authentificationManager = new AuthentificationManager();


    const [notAnsweredAssessments, setNotAnsweredAssessments] = useState([]);
    const [bezugdatumContinue, setBezugdatumContinue] = useState("");
    const [bezugdatum, setBezugdatum] = useState("");
    const [fragebogenTitle, setFragebogenTitle] = useState("");
    const [areYouADirector, setAreYouADirector] = useState(true);

    const [firstLogin, setfirstLogin] = useState(false);

    const [einzelAssessmentVisibility, setEinzelAssessmentVisibility] = useState(false);
    const [createGruppenAssessmentVisibility, setCreateGruppenAssessmentVisibility] = useState(false);
    const [modalVisiblity, setModalVisiblity] = useState(false);

    const [selectedAssessmentName, setSelectedAssessmentName] = useState("");
    const [fragebogenData, setFragebogenData] = useState("");

    const [contiueAssessmenAvailible, setContiueAssessmenAvailible] = useState(false);
    const [contiueAssessmentName, setContiueAssessmentName] = useState("");

    const [continueFlag, setContinueFlag] = useState(false);

    const toggleEinzelAssessment = useCallback(() => {
        setEinzelAssessmentVisibility(!einzelAssessmentVisibility);
    });
    const toggleCreateGruppenAssessment = useCallback(() => {
        setCreateGruppenAssessmentVisibility(!createGruppenAssessmentVisibility);
    });
    const toggleModal = useCallback(() => {
        setModalVisiblity(!modalVisiblity);
    });

    useEffect(() => {
        const fetchData = async () => {
          try {
            const userID = await databaseManager.getCookie('user_id');
            const getAssessment = await databaseManager.fetch_data_by_filter_when_equalto("Einzelassessment", "id", "user_id", userID);
            //console.log(getAssessment);
            if (getAssessment.data.length === 0) {
              setfirstLogin(true);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
      
        fetchData();
      
      }, []);

    async function getFragenData(assessmentTitle) {
        try {
            let spital = authentificationManager.getCookie('spital_Name');
            let title = assessmentTitle;
            let antworttyp = "Level";
            let levelindent = "";
            let kommentar = ""
            const fetchFragen = await databaseManager.fetchCollection('Reifegradfragen');

            const fetchDimension = await databaseManager.fetchCollection('Dimensionen');
            const fetchKategorie = await databaseManager.fetchCollection('Kategorien');

            console.log("------------------------ Hier Hier Fragen --------------------------");
            console.log(fetchFragen);

            // Mapping the fetched data to the new JSON structure
            let jsonStructure = fetchFragen.data.map(item => {
                // Find the corresponding dimension name based on item.dimension
                const matchingDimension = fetchDimension.data && fetchDimension.data.length > 0
                    ? fetchDimension.data.find(dim => dim.id === item.dimension)
                    : undefined;

                // Find the corresponding kategorie name based on item.kategorie
                const matchingKategorie = fetchKategorie.data && fetchKategorie.data.length > 0
                    ? fetchKategorie.data.find(kat => kat.id === item.kategorie)
                    : undefined;

                // Set fragenDimension and fragenKategorie based on the condition
                let frageDimension = matchingDimension ? matchingDimension.name : "";
                let frageKategorie = matchingKategorie ? matchingKategorie.name : "";

                return {
                    id: item.id,
                    index: item.index,
                    spital: spital,
                    titel: title,
                    frage: item.frage,
                    dimension: frageDimension,
                    dimension_id: item.dimension,
                    kategorie: frageKategorie,
                    kategorie_id: item.kategorie,
                    antworttyp: antworttyp,
                    antwort_1: item.level_1,
                    beispiel_1: item.beispiel_1,
                    antwort_2: item.level_2,
                    beispiel_2: item.beispiel_2,
                    antwort_3: item.level_3,
                    beispiel_3: item.beispiel_3,
                    antwort_4: item.level_4,
                    beispiel_4: item.beispiel_4,
                    antwort_5: item.level_5,
                    beispiel_5: item.beispiel_5,
                    effizienzgewinn_spital_1: item.effizienzgewinn_spital_1,
                    effizienzgewinn_system_1: item.effizienzgewinn_system_1,
                    umsetzungsaufwand_spital_1: item.umsetzungsaufwand_spital_1,
                    umsetzungsaufwand_system_1: item.umsetzungsaufwand_system_1,
                    effizienzgewinn_spital_2: item.effizienzgewinn_spital_2,
                    effizienzgewinn_system_2: item.effizienzgewinn_system_2,
                    umsetzungsaufwand_spital_2: item.umsetzungsaufwand_spital_2,
                    umsetzungsaufwand_system_2: item.umsetzungsaufwand_system_2,
                    effizienzgewinn_spital_3: item.effizienzgewinn_spital_3,
                    effizienzgewinn_system_3: item.effizienzgewinn_system_3,
                    umsetzungsaufwand_spital_3: item.umsetzungsaufwand_spital_3,
                    umsetzungsaufwand_system_3: item.umsetzungsaufwand_system_3,
                    effizienzgewinn_spital_4: item.effizienzgewinn_spital_4,
                    effizienzgewinn_system_4: item.effizienzgewinn_system_4,
                    umsetzungsaufwand_spital_4: item.umsetzungsaufwand_spital_4,
                    umsetzungsaufwand_system_4: item.umsetzungsaufwand_system_4,
                    effizienzgewinn_spital_5: item.effizienzgewinn_spital_5,
                    effizienzgewinn_system_5: item.effizienzgewinn_system_5,
                    umsetzungsaufwand_spital_5: item.umsetzungsaufwand_spital_5,
                    umsetzungsaufwand_system_5: item.umsetzungsaufwand_system_5,
                    levelident: levelindent,
                    kommentar: kommentar
                };
            });

            jsonStructure.sort((a, b) => {
                // Split the index by dot and compare each part as integer
                const partsA = a.index.split('.').map(Number);
                const partsB = b.index.split('.').map(Number);

                // Compare each part until a difference is found
                for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
                    // If one of the parts doesn't exist, assume it's 0 (e.g., 2 is before 2.1)
                    const numA = i < partsA.length ? partsA[i] : 0;
                    const numB = i < partsB.length ? partsB[i] : 0;

                    // If the numbers are not equal, return the difference
                    if (numA !== numB) {
                        return numA - numB;
                    }
                }

                // If all parts are equal, then the indices are equal
                return 0;
            });
            return jsonStructure;
        } catch (error) {
            // Handle the error, e.g., display an error message or retry fetching.
            console.error("Error getting Fragendata in Model Assessment starten");
        }
    };

    const onPageLoad = async () => {
        const spitalName = authentificationManager.getCookie('spital_Name');
        const userID = authentificationManager.getCookie('user_id');
        try {
            const fetchedGroupAssessments = await databaseManager.fetch_data_by_filter_when_equalto('Gruppenassessment', 'name,teilnehmer,teilnehmer_fertig,bezugsdatum', "spital", spitalName);

            // Check if Teilnhemer is includes user but user is not included in the Teilnehmer_fertig list
            const notAnswered = fetchedGroupAssessments.data.filter(obj => {
                const participantIds = obj.teilnehmer.split(',');
                const finishedParticipants = obj.teilnehmer_fertig ? obj.teilnehmer_fertig.split(',') : [];

                return participantIds.includes(userID) && !finishedParticipants.includes(userID);
            });

            setNotAnsweredAssessments(notAnswered);

            const tmpAssessmentAvailible = await databaseManager.fetch_data_by_filter_when_equalto("Temp_Einzelassessment", "id,gruppenAssessmentName,bezugsdatum", "user_id", userID);
            // console.log(tmpAssessmentAvailible);
            if(tmpAssessmentAvailible && tmpAssessmentAvailible.data.length != 0){
                setContiueAssessmenAvailible(true);
                setContiueAssessmentName(tmpAssessmentAvailible.data[0].gruppenAssessmentName);
                setBezugdatum(tmpAssessmentAvailible.data[0].bezugsdatum);
            }
            
            try {
                const userID = await databaseManager.getCookie('user_id');
                const getAssessment = await databaseManager.fetch_data_by_filter_when_equalto("Einzelassessment", "id", "user_id", userID);
                //console.log(getAssessment);
                if (getAssessment.data.length === 0) {
                    setfirstLogin(true);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }

        } catch (e) {
            setNotAnsweredAssessments([])
            console.log(e);
        }

    };

    useEffect(() => {
        onPageLoad();
    }, []);

    const startEinzelAssessment = async (name, continueFlagIntern) => {
        if(continueFlagIntern){
            try {
                setSelectedAssessmentName(name);
                setModalVisiblity(false);
                setBezugdatumContinue(bezugdatum);
                setFragebogenTitle("null")
                const fetchedData = await getFragenData(name);
                setFragebogenData(fetchedData);
                setEinzelAssessmentVisibility(true);
                setContinueFlag(true);
            } catch (error) {
                // Handle the error, e.g., display an error message or retry fetching.
                console.error("Error starting the Einzelassessment in Modal Assessment starten");
            }
        } else{
            try {
                setSelectedAssessmentName(name);
                setModalVisiblity(false);
    
                const fetchedData = await getFragenData(name);
                setFragebogenData(fetchedData);
                setEinzelAssessmentVisibility(true);
            } catch (error) {
                // Handle the error, e.g., display an error message or retry fetching.
                console.error("Error starting the Einzelassessment in Modal Assessment starten");
            }
        }
    };

    const createGruppenAssessments = () => {
        setModalVisiblity(false);
        setCreateGruppenAssessmentVisibility(true);
    };

    const assessmentFortführen = () => {
        setModalVisiblity(false);
        // TODO start assessment with the fetched data from temp-collection 
    }



    return (
        <>
            <FirstLoginNoAssessmentPopup contentOpen={firstLogin} toggleContent={toggleModal}/>
            <div className="header-assessment-container" onClick={toggleModal}>
                {notAnsweredAssessments.length > 0 && (
                    <div className='header-assessment-red'>{notAnsweredAssessments.length}</div>
                )}
                <div className="header-assessment-textcontainer">
                    <div className='header-assessment-text'>
                        &#x002B; Assessments
                    </div>
                </div>
            </div>
            <div className={`modalAS-content ${modalVisiblity ? 'open' : ''}`}>
                {modalVisiblity && <div className='modalAS-content-dropdown'>                    
                    {contiueAssessmenAvailible && (
                    <div className='modalAS-content-ass-ga'>
                        <a className='modalAS-content-ass-ga-titel'>Assessment fortsetzen</a>
                        <div 
                            className='modalAS-content-ass modalAS-content-ass-einzel'
                            onClick={() => startEinzelAssessment(contiueAssessmentName, true)}
                            title={contiueAssessmentName !== "" ? contiueAssessmentName : "Einzelassessment"}
                        >
                            {contiueAssessmentName !== "" ? contiueAssessmentName : "Einzel Assessment"}
                        </div>
                    </div>
                    )}
                    {/* Einzel Assessment */}
                    <div className='modalAS-content-ass-ga'>
                        <a className='modalAS-content-ass-ga-titel'>Einzel Assessments</a>
                        <div className={`modalAS-content-ass modalAS-content-ass-einzel ${contiueAssessmenAvailible ? 'disabled' : ''}`} onClick={() => startEinzelAssessment("", false)}>Einzel Assessment starten</div>
                    </div>
                    {/* Gruppen Assessments */}
                    <div className='modalAS-content-ass-ga'>
                        <a className='modalAS-content-ass-ga-titel'>Gruppen Assessments</a>
                        {notAnsweredAssessments.map((assessment, index) => (
                            <div key={index} className={`modalAS-content-ass modalAS-content-ass-group ${contiueAssessmenAvailible ? 'disabled' : ''}`} onClick={() => startEinzelAssessment(assessment.name, false)}>{assessment.name}</div>
                        ))}
                        {/* Gruppen Assessment erstellen */}
                        {areYouADirector && <div className='modalAS-content-ass modalAS-content-ass-group' onClick={createGruppenAssessments}>&#x002B;</div>}
                    </div>


                    {/*<FragebogenWidget fragebogenData={fragebogenData}></FragebogenWidget>*/}
                </div>}
            </div>
            {einzelAssessmentVisibility && (
                <FragebogenWidget
                    fragebogenData={fragebogenData}
                    title={fragebogenTitle ? fragebogenTitle : fragebogenData[0].titel}
                    setVisible={setEinzelAssessmentVisibility}
                    bezugsdatum={bezugdatumContinue || (notAnsweredAssessments.find(item => item.name === fragebogenData[0].titel)?.bezugsdatum ?? "")}
                    continueFlag={continueFlag}
                />
            )}
            <ModalCreateGruppenAssessments isModalCreateGruppenAssessment={createGruppenAssessmentVisibility} toggleCGAVisibility={toggleCreateGruppenAssessment} />
        </>
    );
};