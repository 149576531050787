// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allcontent {
    position: relative;
    height: 100vh; /* Set height to 100% of viewport height */
    overflow: hidden; /* Disable scrolling */
}

.mainContent {
    position: relative;
    display: flex;
    flex-direction: column; /* Display children in a column */
    width: calc(100% - 118px);
    height: calc(100% - 80px);
    left: 118px;
    top: 80px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box; /* Adjust box sizing as needed */
}


.uebersichtscreen {
    position: relative;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
}

.pageTitle {
    position: relative;
    width: max-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 1px solid var(--borderColor);
    margin-top: var(--gap-pagetitle-top);
    margin-left: var(--gap-pagetitle-left);
    font-family: Inter;
}

.pageTitle-title{
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px; /* 116.667% */
    letter-spacing: -1.25px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Mainscreen_styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa,EAAE,0CAA0C;IACzD,gBAAgB,EAAE,sBAAsB;AAC5C;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB,EAAE,iCAAiC;IACzD,yBAAyB;IACzB,yBAAyB;IACzB,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB,EAAE,gCAAgC;AAC5D;;;AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,oCAAoC;IACpC,oCAAoC;IACpC,sCAAsC;IACtC,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,aAAa;IAChC,uBAAuB;AAC3B","sourcesContent":[".allcontent {\r\n    position: relative;\r\n    height: 100vh; /* Set height to 100% of viewport height */\r\n    overflow: hidden; /* Disable scrolling */\r\n}\r\n\r\n.mainContent {\r\n    position: relative;\r\n    display: flex;\r\n    flex-direction: column; /* Display children in a column */\r\n    width: calc(100% - 118px);\r\n    height: calc(100% - 80px);\r\n    left: 118px;\r\n    top: 80px;\r\n    overflow-y: scroll;\r\n    overflow-x: hidden;\r\n    box-sizing: border-box; /* Adjust box sizing as needed */\r\n}\r\n\r\n\r\n.uebersichtscreen {\r\n    position: relative;\r\n    width: 100%;\r\n    height: fit-content;\r\n}\r\n\r\n.pageTitle {\r\n    position: relative;\r\n    width: max-content;\r\n    height: fit-content;\r\n    border: 1px solid var(--borderColor);\r\n    margin-top: var(--gap-pagetitle-top);\r\n    margin-left: var(--gap-pagetitle-left);\r\n    font-family: Inter;\r\n}\r\n\r\n.pageTitle-title{\r\n    position: relative;\r\n    width: fit-content;\r\n    font-size: 38px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    line-height: 56px; /* 116.667% */\r\n    letter-spacing: -1.25px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
