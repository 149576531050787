import React from 'react';
import "./ModalDisclamer.css"
import { useState, useEffect, useCallback } from "react";
import DataFetcher from "../pages/classes/databaseManager";

export const DisclaimerWidget = ({ title="Title", text="Text", disagree, agree, buttonInTheMiddle, disagreeOnClickMethode, agreeOnClickMethode, buttonInTheMiddleOnClickMethode, close=false, closeAction}) => {

    return (
        <div className='disclaimer-background'>
            <div className='disclaimer-content-container'>
                <div className='disclaimer-content-inner-container'>
                    {close && <img src='/closeButton.svg' className="disclaimer-close-button" onClick={() => closeAction(false)} draggable={false}/>}
                    <div className='disclaimer-header-title'>{title}</div>
                    <div className='disclaimer-information'><pre style={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.12px',
                        textAlign: 'left',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap'
                        }}>{text}</pre>
                    </div>
                    <div className='disclaimer-decission-container'>
                        {/* Render buttons based on the presence of agree and disagree props */}
                        {agree === "Ja" && disagree === "Nein" ? (
                            <>
                                <div className='disclaimer-button disclaimer-left-button-nein' onClick={disagreeOnClickMethode}>{disagree}</div>
                                <div className='disclaimer-button' onClick={buttonInTheMiddleOnClickMethode}>{buttonInTheMiddle}</div>
                                <div className='disclaimer-button disclaimer-right-button-ja' onClick={agreeOnClickMethode}>{agree}</div>
                            </>
                        ) : (
                            <>                               
                                <div className='disclaimer-button disclaimer-left-button' onClick={disagreeOnClickMethode}>{disagree}</div>
                                <div className='disclaimer-button' onClick={buttonInTheMiddleOnClickMethode}>{buttonInTheMiddle}</div>
                                <div className='disclaimer-button disclaimer-right-button' onClick={agreeOnClickMethode}>{agree}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};



export const DisclaimerGAWidget = ({ title="Title", firstHeader="", firstText="", secondHeader="", secondText=[], disagree, agree, buttonInTheMiddle, disagreeOnClickMethode, agreeOnClickMethode, buttonInTheMiddleOnClickMethode, close=false, closeAction}) => {

    return (
        <div className='disclaimer-background'>
            <div className='disclaimer-content-container'>
                <div className='disclaimer-content-inner-container'>
                    {close && <img src='/closeButton.svg' className="disclaimer-close-button" onClick={() => closeAction(false)} draggable={false}/>}
                    <div className='disclaimer-header-title'>{title}</div>
                    <div className='disclaimer-information' style={{textAlign: 'left'}}>
                        <div style={{ fontWeight: 'bold' }}>{firstHeader}</div>
                        <div>{firstText}</div>
                        <div style={{ fontWeight: 'bold', marginTop: "20px" }}>{secondHeader}</div>
                        <div>
                            {secondText.map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </div>
                    </div>
                    <div className='disclaimer-decission-container'>
                        {/* Render buttons based on the presence of agree and disagree props */}
                        {agree === "Ja" && disagree === "Nein" ? (
                            <>
                                <div className='disclaimer-button disclaimer-left-button-nein' onClick={disagreeOnClickMethode}>{disagree}</div>
                                <div className='disclaimer-button' onClick={buttonInTheMiddleOnClickMethode}>{buttonInTheMiddle}</div>
                                <div className='disclaimer-button disclaimer-right-button-ja' onClick={agreeOnClickMethode}>{agree}</div>
                            </>
                        ) : (
                            <>                               
                                <div className='disclaimer-button disclaimer-left-button' onClick={disagreeOnClickMethode}>{disagree}</div>
                                <div className='disclaimer-button' onClick={buttonInTheMiddleOnClickMethode}>{buttonInTheMiddle}</div>
                                <div className='disclaimer-button disclaimer-right-button' onClick={agreeOnClickMethode}>{agree}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};