import { useCallback, useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import UebersichtScreen from "../html_jsx/UebersichtScreen";
import DataFetcher from "./classes/databaseManager";
import { VerticalNavigationBar } from "../widgets/VerticalNavigationWidgets"
import { HeaderWidget } from "../widgets/HeaderWidget";
import AuthentificationManager from "./classes/authentificationManager";
import { parseIdentifier, calculateMeanByDimension } from "./classes/identifierCalculator";

import "./Uebersicht.css";
import "./Mainscreen_styles.css";

const UebersichtTry = () => {

  const navigate = useNavigate();
  const dataFetcher = new DataFetcher();
  const authentificationManager = new AuthentificationManager();
  // Check for the presence of "auth_token" cookie when the component is mounted
  useEffect(() => {
    authentificationManager.checkAndRefreshToken();
  }, []);

  // Declare a state variable named 'data' and a function 'setData' to update its value,
  // initialized with the initial state of 'null'. This is using the 'useState' hook from React.
  // useState is a React Hook that allows functional components in React to manage state. State in 
  // React is a way for components to keep track of and manage their data, and it enables components 
  // to re-render when the state changes.
  //const [data, setData] = useState(null);
  const [cardDataExtracted, setExtractedCardData] = useState(null);
  const [cardDataExtractedSecond, setExtractedCardDataSecond] = useState(null);
  const [benchmarkCHInfo, setBenchmarkCHInfo] = useState(null);
  const [changeSince, setChangeSince] = useState(null);

  // Selector
  const [dateList, setDateList] = useState([]);
  const [sortedAssessments, setSortedAssessments] = useState([]);
  const [selectorValue, setSelectorValue] = useReducer(async (state, action) => {
    switch (action.type) {
      case "CHANGE_SELECTOR": {
        const card = await dataFetcher.fetch_data_by_filter_when_equalto("Reifegradantworten", "levelident,bezugsdatum", "assessment_id", sortedAssessments[action.payload-1].id);
        const card_data = card["data"];
        if (sortedAssessments.length > action.payload) {
          const card_second = await dataFetcher.fetch_data_by_filter_when_equalto("Reifegradantworten", "levelident,bezugsdatum", "assessment_id", sortedAssessments[action.payload].id);
          const card_data_second = card_second["data"];
          setExtractedCardDataSecond(card_data_second);
        }
        setExtractedCardData(card_data);
        return action.payload;
      }
      default : {
        return 1;
      }
    }
  }, []);

  function handleSelect(event) {
    setSelectorValue({ type: "CHANGE_SELECTOR", payload: event.target.value });
  }

  // Fetching data from the backend has to be asynchronous
  const onPageLoad = async () => {
    try {
      // Get lastest Assessment from User    
      const user_id = authentificationManager.getCookie('user_id');
      const user_spital = authentificationManager.getCookie('spital_Name');
      const latestAssessment = await dataFetcher.fetch_data_by_filter_when_equalto_by_bezugsdate_descending_order("Einzelassessment", "id", "user_id", user_id);

      const card = await dataFetcher.fetch_data_by_filter_when_equalto("Reifegradantworten", "levelident", "assessment_id", latestAssessment.id);

      const card_data = card["data"];
      const latestAssessment_second = await dataFetcher.fetch_data_by_filter_when_equalto_by_bezugsdate_descending_order("Einzelassessment", "id", "user_id", user_id, 1);
      
      if (latestAssessment_second != undefined && latestAssessment_second != -1) {
        const card_second = await dataFetcher.fetch_data_by_filter_when_equalto("Reifegradantworten", "levelident,bezugsdatum", "assessment_id", latestAssessment_second.id);
        const card_data_second = card_second["data"];
        setExtractedCardDataSecond(card_data_second);
      }

      const benchmarkCH = await dataFetcher.fetch_data_from_collection_newest_to_oldest_element("CH_benchmark", "total_benchmark_mean,DI001_mean,DI002_mean,DI003_mean,DI004_mean,DI005_mean,included_spital_count");

      if (benchmarkCH) {
        setBenchmarkCHInfo(benchmarkCH.data[0]);
      }
      setExtractedCardData(card_data);
      // Selector
      // Fetch necessary data
      const assessments = await dataFetcher.fetch_data_by_filter_when_equalto("Einzelassessment", "id,bezugsdatum,gruppenAssessmentName", "user_id", user_id);
      const gassessments = await dataFetcher.fetch_data_by_filter_when_equalto("Gruppenassessment", "id,name,bezugsdatum", "spital", user_spital);
      const dateSortedAssessments = assessments.data.sort((a, b) => new Date(b.bezugsdatum) - new Date(a.bezugsdatum));
      // Create the selectorDateList directly
      const selectorDateList = dateSortedAssessments.map((item, index) => {
        const matchingGAssessment = gassessments.data.find(gItem => gItem.bezugsdatum === item.gruppenAssessmentName);
        const labelPrefix = matchingGAssessment ? `GA - ${matchingGAssessment.name}` : 'EA';
        return {
          label: `${labelPrefix} (${item.bezugsdatum})`,
          value: index + 1
        };
      });
      setSortedAssessments(dateSortedAssessments);
      setDateList(selectorDateList);
    }
    catch (e) {
      const dummyCardData = [
        { Identifier: 'ID001.DI001.CA001.LV001' },
        { Identifier: 'ID001.DI001.CA001.LV002' },
        { Identifier: 'ID003.DI001.CA003.LV005' },
        { Identifier: 'ID004.DI001.CA004.LV005' },
        { Identifier: 'ID002.DI002.CA002.LV003' },
        { Identifier: 'ID002.DI002.CA002.LV005' },
        { Identifier: 'ID002.DI002.CA002.LV001' },
        { Identifier: 'ID003.DI003.CA003.LV005' },
        { Identifier: 'ID003.DI003.CA003.LV004' },
        { Identifier: 'ID003.DI003.CA003.LV002' },
        { Identifier: 'ID004.DI004.CA004.LV002' },
        { Identifier: 'ID004.DI004.CA004.LV002' },
        { Identifier: 'ID004.DI004.CA004.LV003' },
        { Identifier: 'ID005.DI005.CA005.LV004' },
        { Identifier: 'ID005.DI005.CA005.LV001' },
        { Identifier: 'ID005.DI005.CA005.LV001' },
        { Identifier: 'ID004.DI001.CA004.LV004' },
        { Identifier: 'ID004.DI001.CA004.LV005' },
        { Identifier: 'InvalidIdentifier'       }, // Incorrect format
        { Identifier: 'ID006.DI005.CA006.LV006' }, // Correct format
        { Identifier: 'ID007.DI001.CA007.LV007' }, // Correct format
        { Identifier: 'AnotherInvalidIdentifier'}, // Incorrect format
      ];

      // Set the dummyCardData for testing
      setExtractedCardData(dummyCardData);
    }

  };
  // Hook to run the function on page load. Executed only one time
  useEffect(() => {
    onPageLoad();
  }, []);

  //Lists and variables for the vertical navigation bar that you get from the database
  //Here an example of how it should then look like
  const pageNames = ["Übersicht", "Zielsetzung", "Bewertung", "Nutzen & Aufwand", "Settings", "Kontakt"];
  const imageIcons = ["/selected-uebersichtIcon.svg", "/strategieIcon.svg", "/bewertungIcon.svg", "/portfolioIcon.svg", "/settingsIcon.svg", "/contactIcon.svg"];

  const onItemXLClick = useCallback(() => {
    navigate("/41-portfolio-tabelle");
  }, [navigate]);

  const onItemXL1Click = useCallback(() => {
    navigate("/32-bewertung-frageboegen");
  }, [navigate]);

  const onItemXL2Click = useCallback(() => {
    navigate("/21-strategie-gesamtunternehmen");
  }, [navigate]);

  //Same order as the lists above
  const clickLinkToPage = [onItemXL2Click, onItemXL1Click, onItemXLClick,]
  const currentPage = "Übersicht"
  const userName = "Ueli Mueller"
  const userPicture = "/userpic@2x.png"

  const verticalNavigationBarList = [pageNames, imageIcons, clickLinkToPage, currentPage, userName, userPicture]

  // Let variable because it wil be reassigned
  let tableData = [{}];

  const formattedData = cardDataExtracted
    ? cardDataExtracted
      .filter((item) => typeof item.levelident === 'string' && /^ID\d{3}\.DI\d{3}\.CA\d{3}\.LV\d{3}$/.test(item.levelident))
      .map((item) => parseIdentifier(item.levelident))
    : [];

  // Now, you have an array of formatted JSON structures, you can pass it to calculateMeanByDimension
  const meanValuesByDimension = calculateMeanByDimension(formattedData);
  //console.log(meanValuesByDimension)
  // Print the result

  let IST1 = (meanValuesByDimension["001"]?.["MeanLevel"] ?? 0.0).toFixed(1);
  let IST2 = (meanValuesByDimension?.["002"]?.["MeanLevel"] ?? 0.0).toFixed(1);
  let IST3 = (meanValuesByDimension["003"]?.["MeanLevel"] ?? 0.0).toFixed(1);
  let IST4 = (meanValuesByDimension?.["004"]?.["MeanLevel"] ?? 0.0).toFixed(1);
  let IST5 = (meanValuesByDimension["005"]?.["MeanLevel"] ?? 0.0).toFixed(1);

  let WAS1 = 0;
  let WAS2 = 0;
  let WAS3 = 0;
  let WAS4 = 0;
  let WAS5 = 0;
  let lastDate = "dd.mm.YYYY";

  let CH1 = 0;
  let CH2 = 0;
  let CH3 = 0;
  let CH4 = 0;
  let CH5 = 0;
  let CH  = 0;
  let benchmarkSpitalCount = 0;

  if(benchmarkCHInfo != null && benchmarkCHInfo.included_spital_count >= 3){
    CH1 = parseFloat(benchmarkCHInfo.DI001_mean).toFixed(1);
    CH2 = parseFloat(benchmarkCHInfo.DI002_mean).toFixed(1);
    CH3 = parseFloat(benchmarkCHInfo.DI003_mean).toFixed(1);
    CH4 = parseFloat(benchmarkCHInfo.DI004_mean).toFixed(1);
    CH5 = parseFloat(benchmarkCHInfo.DI005_mean).toFixed(1);
    CH  = parseFloat(benchmarkCHInfo.total_benchmark_mean).toFixed(1);
    console.log(CH);
    benchmarkSpitalCount = benchmarkCHInfo.included_spital_count;
  }

  if (cardDataExtractedSecond != null) {
    const formattedData_second = cardDataExtractedSecond
      ? cardDataExtractedSecond
        .filter((item) => typeof item.levelident === 'string' && /^ID\d{3}\.DI\d{3}\.CA\d{3}\.LV\d{3}$/.test(item.levelident))
        .map((item) => parseIdentifier(item.levelident))
      : [];

    // Now, you have an array of formatted JSON structures, you can pass it to calculateMeanByDimensionlet WAS1
    const meanValuesByDimension_second = calculateMeanByDimension(formattedData_second);

    WAS1 = (meanValuesByDimension_second["001"]?.["MeanLevel"] ?? 0.0).toFixed(1);
    WAS2 = (meanValuesByDimension_second?.["002"]?.["MeanLevel"] ?? 0.0).toFixed(1);
    WAS3 = (meanValuesByDimension_second["003"]?.["MeanLevel"] ?? 0.0).toFixed(1);
    WAS4 = (meanValuesByDimension_second?.["004"]?.["MeanLevel"] ?? 0.0).toFixed(1);
    WAS5 = (meanValuesByDimension_second["005"]?.["MeanLevel"] ?? 0.0).toFixed(1);


    // console.log(cardDataExtractedSecond);
    const dateObj = new Date(cardDataExtractedSecond[0].bezugsdatum)
    const monthNamesGerman = [
      "Jan", "Feb", "Mär", "Apr", "Mai", "Jun",
      "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
    ];
    const monthIndex = dateObj.getMonth();
    const monthGermanShort = monthNamesGerman[monthIndex];
    const year = dateObj.getFullYear();
    var formattedDate = `seit ${monthGermanShort} ${year}`;
    if(cardDataExtracted != null && cardDataExtractedSecond[0].bezugsdatum === cardDataExtracted[0].bezugsdatum){
      formattedDate = "Start";
    }
    if(formattedDate){

    }
    lastDate = formattedDate;
  }
  if (cardDataExtractedSecond == null){
    lastDate = "Start";
    
    const formattedData_second = cardDataExtracted
      ? cardDataExtracted
        .filter((item) => typeof item.levelident === 'string' && /^ID\d{3}\.DI\d{3}\.CA\d{3}\.LV\d{3}$/.test(item.levelident))
        .map((item) => parseIdentifier(item.levelident))
      : [];
    const meanValuesByDimension_second = calculateMeanByDimension(formattedData_second);
    WAS1 = (meanValuesByDimension_second["001"]?.["MeanLevel"] ?? 0.0).toFixed(1);
    WAS2 = (meanValuesByDimension_second?.["002"]?.["MeanLevel"] ?? 0.0).toFixed(1);
    WAS3 = (meanValuesByDimension_second["003"]?.["MeanLevel"] ?? 0.0).toFixed(1);
    WAS4 = (meanValuesByDimension_second?.["004"]?.["MeanLevel"] ?? 0.0).toFixed(1);
    WAS5 = (meanValuesByDimension_second["005"]?.["MeanLevel"] ?? 0.0).toFixed(1);
  }




  let ISTmean = (
    (
      Object.values(meanValuesByDimension)
        .map((dimension) => dimension.MeanLevel)
        .reduce((sum, value) => sum + value, 0) / Object.keys(meanValuesByDimension).length
    ) || 0.0
  ).toFixed(1);

  //console.log(ISTmean)

  return (
    <div className="allcontent">
      <div className="mainContent">
        <div className="pageTitle">
          <b className="pageTitle-title">Übersicht</b>
        </div>
        <UebersichtScreen
          onItemXLClick={onItemXLClick}
          onItemXL1Click={onItemXL1Click}
          onItemXL2Click={onItemXL2Click}
          IST1={IST1}
          IST2={IST2}
          IST3={IST3}
          IST4={IST4}
          IST5={IST5}
          CH1={CH1}
          CH2={CH2}
          CH3={CH3}
          CH4={CH4}
          CH5={CH5}
          CH={CH}
          IST={ISTmean}
          WAS1={WAS1}
          WAS2={WAS2}
          WAS3={WAS3}
          WAS4={WAS4}
          WAS5={WAS5}
          title1={"Strategie, Governance & Organisation"}
          title2={"Prozesse"}
          title3={"Leadership, Kultur und Menschen"}
          title4={"Systeme & Daten"}
          title5={"Produkte & Innovationen"}
          lastDate={lastDate}
          tableData={tableData}
          handleSelect={handleSelect}
          dateList={dateList}
        />
      </div>
      <div className="navigationbar">
        <VerticalNavigationBar verticalNavigationBarList={verticalNavigationBarList} />
      </div>
      <div className="headerbar">
        <HeaderWidget LogoImg="/LogoDRI.svg" SpitalName="Spital Zürich" />
      </div>
    </div>

  );
};

export default UebersichtTry;
