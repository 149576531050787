import React, { useState, useEffect } from 'react';
import { NavigationBar } from "../widgets/NavigationBar";
import { HeaderWidget } from "../widgets/HeaderWidget";
import "./MainPage.css";
import AuthentificationManager from "./classes/authentificationManager";


const MainPage = () => {

  const authentificationManager = new AuthentificationManager();
  // Check for the presence of "auth_token" cookie when the component is mounted
  useEffect(() => {
    authentificationManager.checkAndRefreshToken();
  }, []);

  
  const [currentPage, setCurrentPage] = useState("Übersicht");

  const handleNavigation = (pageName) => {
    setCurrentPage(pageName);
  };

  const navigationBarList = {
    pageNames: ["Übersicht", "Zielsetzung", "Bewertung", "Nutzen & Aufwand", "Settings", "Kontakt"],
    imageIcons: ["/selected-uebersichtIcon.svg", "/strategieIcon.svg", "/bewertungIcon.svg", "/portfolioIcon.svg", "/settingsIcon.svg", "/contactIcon.svg"],
    clickLinkToPage: [handleNavigation, handleNavigation, handleNavigation, handleNavigation],
    currentPage: currentPage,
    userName: "Ueli Mueller",
    userPicture: "/userpic@2x.png",
  };

  return (
    <div className='allContent'>
        <div className='header'>
            <HeaderWidget LogoImg="/LogoDRI.svg" SpitalName="Spital Zürich" />
        </div>
        <div className='body'>
            <NavigationBar navigationBarList={navigationBarList} />
            <div className="Main-Content">
                {/* Display the content based on the current page */}
                {currentPage === "Übersicht" && <iframe src={"/10-uebersicht"}/>}
                {currentPage === "Strategie" && <iframe src={"/21-strategie-gesamtunternehmen"}/>}
                {currentPage === "Bewertung" && <iframe src={"/32-bewertung-frageboegen"}/>}
                {currentPage === "Nutzen & Aufwand" && <iframe src={"/41-portfolio-tabelle"}/>}
                {/* Add other widgets for different pages */}
            </div>
        </div>
    </div>
  );
};

export default MainPage;
