// For Radial Chart
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto';
// For Radial bar
import ApexCharts from 'apexcharts'
import { useState, useEffect } from "react";
// For Line Chart
import { PureComponent } from 'react';
import {
  AreaChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Area,
  LabelList,
  Label
} from 'recharts';
import DataFetcher from '../pages/classes/databaseManager';
import AuthentificationManager from '../pages/classes/authentificationManager';

import "./UebersichtWidgets.css";

export const RadarChart1 = ({ IST1, IST2, IST3, IST4, IST5, CH1, CH2, CH3, CH4, CH5 }) => {
  const data = {
    labels: [
      'Strategie, Governance & Organisation',
      'Prozesse',
      'Leadership, Kultur & Menschen',
      'Systeme & Daten',
      'Produkte & Innovationen'
    ],
    datasets: [
      {
        label: 'IST',
        data: [IST1, IST2, IST3, IST4, IST5],
        backgroundColor: 'rgba(0, 123, 255, 0.2)',
        borderColor: 'rgba(0, 123, 255, 1)',
        borderWidth: 1,
      },
      {
        label: 'Ø CH',
        data: [CH1, CH2, CH3, CH4, CH5],
        backgroundColor: 'rgba(255, 193, 7, 0.2)',
        borderColor: 'rgba(255, 193, 7, 1)',
        borderWidth: 1,
      }
    ]
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          display: true
        },
        pointLabels: {
          font: {
            family: 'Inter',
            size: 20,
            style: 'normal',
            weight: 600,
          },
          color: 'black',
          padding: 20
        },
        suggestedMin: 1,
        suggestedMax: 5
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
      }
    },
    maintainAspectRatio: false
  };

  return (
    <div className="radar-chart-container">
      <Radar data={data} options={options} />
    </div>
  );
};

//Radial bar for the main dashboard
export const RadialBarChart = ({ IST, CH }) => {
  useEffect(() => {
    try {
      // Calculate percentages
      const ISTPercentage = IST * 100 / 5;
      const CHPercentage = CH * 100 / 5;

      // Chart options
      const options = {
        chart: {
          height: 280,
          type: 'radialBar',
        },
        series: [ISTPercentage, CHPercentage],
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: 'always',
              name: {
                show: true,
                fontSize: '50px',
                fontFamily: undefined,
                fontWeight: 600,
                color: undefined,
                offsetY: -10
              },
              value: {
                fontSize: "24px",
                formatter: function (val) {
                  return (val / 100 * 5).toFixed(1);
                },
              },
              total: {
                show: true,
                label: 'IST',
                color: '#347AE2',
                formatter: function (w) {
                  // Since the total is the only permanent value displayed, 
                  // we return the IST value here
                  return IST;
                }
              }
            }
          },
        },
        colors: ['#347AE2', '#FF9500'],
        labels: ['IST', 'Ø Spitäler']

      };

      // Initialize chart
      const chart = new ApexCharts(document.querySelector("#radialbarchart"), options);
      chart.render();

      // Cleanup function to destroy chart
      return () => {
        chart.destroy();
      };
    } catch (e) {
      console.log("Error in Radial Bar Chart while calculating the percentages and initializing the chart");
    }
  }, [IST, CH]); // Depend on IST and CH to recreate the chart when they change

  return <div className='radialbarchart-uebersicht' id="radialbarchart"></div>;
};

// Cards used for the main dashboard
export const FiveTopCards = ({
  IST1, IST2, IST3, IST4, IST5,
  WAS1, WAS2, WAS3, WAS4, WAS5,
  title1, title2, title3, title4, title5,
  lastDate
}) => {

  const calculatePercentageChange = (IST, WAS) => ((IST - WAS) / WAS) * 100;

  const createCard = (IST, WAS, title, Icon) => {
    const formattedIST = parseFloat(IST).toFixed(1);
    const formattedWAS = parseFloat(WAS).toFixed(1);

    var percentageChange = calculatePercentageChange(formattedIST, formattedWAS);
    if(percentageChange == Infinity){
      percentageChange = 0.0;
    }
    const arrowDirection = percentageChange > 0 ? 'up' : (percentageChange < 0 ? 'down' : 'equal');
    const arrowIcon = `/${arrowDirection}.svg`;

    // Determine if we need to add an extra empty line for spacing
    const shouldAddEmptyLine = title.length < 25;

    return (
      <div className="cardcontent">
        <div className="cardcontent-container">
          <div className="cardtitle">
            <b className='cardTitleNumber'>{formattedIST}</b>
            <div className='cardIconContainer'>
              <img className='cardIcon' alt="" src={Icon} draggable={false} />
            </div>
          </div>
          <p className='cardTitleText'>{title}</p>
          <div className="cardinfo">
            <img className="down-icon" alt="" src={arrowIcon} draggable={false} />
            <b>{formattedWAS}</b>
            <b>{percentageChange.toFixed(1)}% {lastDate}</b>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="cards">
      {createCard(IST1, WAS1, title1, "/Icons/Strategie.svg")}
      <div className="divider1" />
      {createCard(IST2, WAS2, title2, "/Icons/Prozesse.svg")}
      <div className="divider1" />
      {createCard(IST3, WAS3, title3, "/Icons/Kultur.svg")}
      <div className="divider1" />
      {createCard(IST4, WAS4, title4, "/Icons/Daten.svg")}
      <div className="divider1" />
      {createCard(IST5, WAS5, title5, "/Icons/Innovation.svg")}
    </div>
  );
};

const renderCustomLabel = (props) => {
  const { viewBox, value } = props;
  const lines = value.split('\n');
  // Adjust the x and y positions as needed for your chart
  const xPos = viewBox.width + viewBox.x - 10; // Position x to the right
  const yPos = viewBox.y + 20; // Position y to align with the top of the reference line

  return (
    <g>
      {lines.map((line, index) => (
        <text
          x={xPos}
          y={yPos}
          dy={index * 18} // Line height
          fill="red"
          fontSize={14}
          textAnchor="end"
          key={`line-${index}`}
        >
          {line}
        </text>
      ))}
    </g>
  );
};

// Effizienzgewinn Spital und System Chart
export const EffizienzgewinnChart = (date) => {
  const [effizienzgewinnData, setEffizienzgewinnData] = useState([]);
  const [effizienzgewinnRawData, setEffizienzgewinnRawData] = useState([]);

  const [yAxisTicks, setYAxisTicks] = useState([]);
  const [userLevel, setUserLevel] = useState("1");
  const [goalLevel, setGoalLevel] = useState("1");
  const [accumulativeGoalEffizienzgewinnSpital, setAccumulativeGoalEffizienzgewinnSpital] = useState("1");
  const [accumulativeGoalEffizienzgewinnSystem, setAccumulativeGoalEffizienzgewinnSystem] = useState("1");
  const [accumulativeUserEffizienzgewinnSpital, setAccumulativeUserEffizienzgewinnSpital] = useState("1");
  const [accumulativeUserEffizienzgewinnSystem, setAccumulativeUserEffizienzgewinnSystem] = useState("1");

  //const [totalUserEffizienzgewinnSpital, settotalUserEffizienzgewinnSpital] = useState("")
  //const [totalUserEffizienzgewinnSystem, settotalUserEffizienzgewinnSystem] = useState([]);
  let initialData = [];
  const dataFetcher = new DataFetcher();
  const authentificationManager = new AuthentificationManager();

  useEffect(() => {

    try {
      const fetchLatestUserAssessmentData = async () => {


        const user_id = authentificationManager.getCookie('user_id');
        const spitalName = authentificationManager.getCookie('spital_Name');

        const latestgoal = await dataFetcher.fetch_data_by_filter_when_equalto_by_date_descending_order
          ("DHI_Ziele", "GesamtZiel", "Spital", spitalName);

        let latestAssessment = "";
        if (!date === "") {
          const Assessments = await dataFetcher.fetch_data_by_filter_when_equalto
            ("Einzelassessment", "date_created,id", "user_id", user_id);
          const list = Assessments.data
          const findElementByDate = (list, date) => {
            return list.find(element => element.date_created === date);
          };
          latestAssessment = findElementByDate(list, date);
        }
        else {
          latestAssessment = await dataFetcher.fetch_data_by_filter_when_equalto_by_bezugsdate_descending_order
            ("Einzelassessment", "id", "user_id", user_id);
        }

        if (latestAssessment == undefined) {
          console.log("No Assessment available");
          return;
        }

        const user_assessment_scores = await dataFetcher.fetch_data_by_filter_when_equalto
          ("Reifegradantworten",
            "index,antwort,effizienzgewinn_spital,effizienzgewinn_system,umsetzungsaufwand_spital,umsetzungsaufwand_system,fragen_id",
            "assessment_id", latestAssessment.id);
        const spital_system_scores = await dataFetcher.fetchCollection("Reifegradfragen")


        let cumulativeScoresList = []; // This will store the new objects with cumulative scores


        user_assessment_scores.data.forEach(userScore => {
          const systemScore = spital_system_scores.data.find(systemScore => systemScore.id.toString() === userScore.fragen_id);

          if (systemScore) {
            let cumulative_effizienzgewinn_spital = 0;
            let cumulative_effizienzgewinn_system = 0;
            let cumulative_umsetzungsaufwand_spital = 0;
            let cumulative_umsetzungsaufwand_system = 0;

            const answer = parseInt(userScore.antwort);
            for (let i = 1; i <= answer; i++) {
              cumulative_effizienzgewinn_spital += parseFloat(systemScore[`effizienzgewinn_spital_${i}`].replace(',', '.'));
              cumulative_effizienzgewinn_system += parseFloat(systemScore[`effizienzgewinn_system_${i}`].replace(',', '.'));
              cumulative_umsetzungsaufwand_spital += parseFloat(systemScore[`umsetzungsaufwand_spital_${i}`].replace(',', '.'));
              cumulative_umsetzungsaufwand_system += parseFloat(systemScore[`umsetzungsaufwand_system_${i}`].replace(',', '.'));
            }

            // Create a new object for the cumulative scores and add it to the list
            cumulativeScoresList.push({
              level: userScore.antwort, // Assuming 'level' is based on the 'antwort' value
              cumulative_effizienzgewinn_spital: cumulative_effizienzgewinn_spital.toFixed(6), // Adjust precision as needed
              cumulative_effizienzgewinn_system: cumulative_effizienzgewinn_system.toFixed(6),
              cumulative_umsetzungsaufwand_spital: cumulative_umsetzungsaufwand_spital.toFixed(6),
              cumulative_umsetzungsaufwand_system: cumulative_umsetzungsaufwand_system.toFixed(6),
            });
          }
        });

        let totals = {
          total_cumulative_effizienzgewinn_spital: 0,
          total_cumulative_effizienzgewinn_system: 0,
          total_cumulative_umsetzungsaufwand_spital: 0,
          total_cumulative_umsetzungsaufwand_system: 0
        };

        cumulativeScoresList.forEach(item => {
          totals.total_cumulative_effizienzgewinn_spital += (parseFloat(item.cumulative_effizienzgewinn_spital) * 100);
          totals.total_cumulative_effizienzgewinn_system += (parseFloat(item.cumulative_effizienzgewinn_system) * 100);
          totals.total_cumulative_umsetzungsaufwand_spital += (parseFloat(item.cumulative_umsetzungsaufwand_spital) * 100);
          totals.total_cumulative_umsetzungsaufwand_system += (parseFloat(item.cumulative_umsetzungsaufwand_system) * 100);
        });

        // Creating a new list with a single object that contains the totals
        totals.total_cumulative_effizienzgewinn_spital = parseFloat(totals.total_cumulative_effizienzgewinn_spital.toFixed(2));
        totals.total_cumulative_effizienzgewinn_system = parseFloat(totals.total_cumulative_effizienzgewinn_system.toFixed(2));
        totals.total_cumulative_umsetzungsaufwand_spital = parseFloat(totals.total_cumulative_umsetzungsaufwand_spital.toFixed(2));
        totals.total_cumulative_umsetzungsaufwand_system = parseFloat(totals.total_cumulative_umsetzungsaufwand_system.toFixed(2));

        // Creating a new list with a single object that contains the adjusted totals
        let totalList = [totals];

        const sumsByDigitaleReife = {};
        // Function to convert string with comma to float
        const convertToFloat = (str) => parseFloat(str.replace(',', '.'));

        spital_system_scores.data.forEach(item => {
          const digitaleReifeLevels = 5; // Assuming there are 5 levels of digital maturity
          for (let i = 1; i <= digitaleReifeLevels; i++) {
            const digitaleReife = i; // Use the loop index as the digitale_reife level

            // Initialize the object for this digitaleReife if it does not exist
            if (!sumsByDigitaleReife[digitaleReife]) {
              sumsByDigitaleReife[digitaleReife] = { spital: 0, system: 0 };
            }

            // Aggregate the values for spital and system for this digitaleReife level
            sumsByDigitaleReife[digitaleReife].spital += convertToFloat(item[`effizienzgewinn_spital_${i}`] || "0");
            sumsByDigitaleReife[digitaleReife].system += convertToFloat(item[`effizienzgewinn_system_${i}`] || "0");
          }
        });

        // Convert the aggregated sums into the desired format
        const processedData = Object.entries(sumsByDigitaleReife).map(([digitaleReife, { spital, system }]) => ({
          digitale_reife: parseInt(digitaleReife),
          spital: (spital * 100).toFixed(2),
          system: (system * 100).toFixed(2),
        }));

        setEffizienzgewinnRawData(processedData)

        //User Total Effizienzgewinn Spital
        //User Total Effizienzgewinn System
        //User Total UmsetzungsAufwand Unternehmen
        //User Total Umsetzungsaufwand System 
        let totalUserEffizienzgewinnSpital = 0;
        let totalUserEffizienzgewinnSystem = 0;
        let totalUserUmsetzungsaufwandSpital = 0;
        let totalUserUmsetzungsaufwandSystem = 0;

        user_assessment_scores.data.forEach(item => {

          totalUserEffizienzgewinnSpital += parseFloat(item.effizienzgewinn_spital.replace(',', '.'));
          totalUserEffizienzgewinnSystem += parseFloat(item.effizienzgewinn_system.replace(',', '.'));
          totalUserUmsetzungsaufwandSpital += parseFloat(item.umsetzungsaufwand_spital.replace(',', '.'));
          totalUserUmsetzungsaufwandSystem += parseFloat(item.umsetzungsaufwand_system.replace(',', '.'));
        });

        const dimensionToAntwortMap = {};
        spital_system_scores.data.forEach(score => {
          const matchingUserAssessment = user_assessment_scores.data.find(assessment => assessment.fragen_id === score.id.toString());
          if (matchingUserAssessment) {
            const dimension = score.dimension;
            const antwort = parseFloat(matchingUserAssessment.antwort.replace(',', '.'));
            if (!dimensionToAntwortMap[dimension]) {
              dimensionToAntwortMap[dimension] = [];
            }
            dimensionToAntwortMap[dimension].push(antwort);
          }
        });

        // Step 2: Calculate the average antwort value for each dimension
        const averagesByDimension = Object.entries(dimensionToAntwortMap).map(([dimension, antworts]) => {
          const sum = antworts.reduce((acc, curr) => acc + curr, 0);
          const average = sum / antworts.length;
          return { dimension, average };
        });


        // Step 3: Calculate a single mean value from the averages of each dimension
        const totalAverage = averagesByDimension.reduce((acc, curr) => acc + curr.average, 0) / averagesByDimension.length;

        setUserLevel(totalAverage.toFixed(1));
        if (latestgoal) {
          setGoalLevel(parseFloat(latestgoal.GesamtZiel).toFixed(1));
        }
        //setAccumulativeGoalEffizienzgewinnSpital((sumsByDigitaleReife[latestgoal.GesamtZiel].spital * 100).toFixed(2))
        //setAccumulativeGoalEffizienzgewinnSystem((sumsByDigitaleReife[latestgoal.GesamtZiel].system * 100).toFixed(2))
        setAccumulativeUserEffizienzgewinnSpital(totalList[0].total_cumulative_effizienzgewinn_spital);
        setAccumulativeUserEffizienzgewinnSystem(totalList[0].total_cumulative_effizienzgewinn_system);
        //settotalUserEffizienzgewinnSpital((totalUserEffizienzgewinnSpital * 100).toFixed(2))
        //settotalUserEffizienzgewinnSystem((totalUserEffizienzgewinnSystem * 100).toFixed(2))

        //console.log((sumsByDigitaleReife[latestgoal.GesamtZiel].spital * 100).toFixed(2))

      };
      fetchLatestUserAssessmentData();

    } catch (e) {
      console.log("Unexpected error while fetching the latest User Assessment Data");
    }
  }, []);

  useEffect(() => {
    if (!(effizienzgewinnRawData === "")) {
      try {
        // Calculate cumulative data
        const cumulativeData = effizienzgewinnRawData.map((entry, index, array) => {
          const cumulative = array.slice(0, index + 1).reduce((acc, item) => ({
            spital: acc.spital + parseFloat(item.spital),
            system: acc.system + parseFloat(item.system),
          }), { spital: 0, system: 0 });
  
          return {
            digitale_reife: entry.digitale_reife,
            spital: parseFloat(cumulative.spital.toFixed(1)).toString().includes('.') ? parseFloat(cumulative.spital.toFixed(1)) : parseFloat(cumulative.spital.toFixed(1)) + '.0',
            system: parseFloat(cumulative.system.toFixed(1)).toString().includes('.') ? parseFloat(cumulative.system.toFixed(1)) : parseFloat(cumulative.system.toFixed(1)) + '.0',
          };
        });
  
        setAccumulativeGoalEffizienzgewinnSpital(cumulativeData[goalLevel - 1].spital);
        setAccumulativeGoalEffizienzgewinnSystem(cumulativeData[goalLevel - 1].system);
  
        // Set yAxisTicks based on the highest value in cumulative data
        const highestValue = Math.max(...cumulativeData.map(d => Math.max(d.spital, d.system)));
        const maxTick = Math.ceil(highestValue / 10) * 10;
        setYAxisTicks(Array.from({ length: (maxTick / 10) + 1 }, (_, i) => i * 10));
  
        setEffizienzgewinnData(cumulativeData);
      } catch (e) {
        console.log("Error while calculating the cumulative data of spital and system or while setting the maximum highest y-tick to be shown in the graph Nutzen-Entwicklung" + e);
      }
    }
  }, [effizienzgewinnRawData]);
  

  // Map numeric values to 'Lx' labels for the X-Axis
  const labelMap = {
    1: 'L1', 2: 'L2', 3: 'L3', 4: 'L4', 5: 'L5'
  };

  const formatXAxis = (tickItem) => {
    return labelMap[tickItem];
  };

  const customYAxisTick = (value) => {
    return `${value}%`;
  };

  const CustomLegend = () => (
    <div className="custom-legend" style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }}>
      <div style={{ margin: '4px' }}>
        <span style={{ color: '#48C7B6', marginRight: '8px' }}>—</span>
        Nutzen Gesamtsystem
      </div>
      <div style={{ margin: '4px' }}>
        <span style={{ color: '#FF6F61', marginRight: '8px' }}>—</span>
        Nutzen Unternehmen
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '4px' }}>
        
      </div>
    </div>
  );



  return (
    <div style={{ width: '50%', height: '600px', position: 'relative' }}>
      <h3 style={{ textAlign: 'center' }}>Nutzen-Entwicklung entlang der digitalen Reife</h3>
      <CustomLegend />
      <ResponsiveContainer>
        <AreaChart
          data={effizienzgewinnData}
          margin={{ top: 20, right: 30, left: 30, bottom: 100 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            type="number"
            domain={[1, 5]}
            dataKey="digitale_reife"
            tickFormatter={formatXAxis}
            label={{ value: 'Digitale Reife', position: 'insideBottom', offset: -10 }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
            tickFormatter={customYAxisTick}
            label={{ value: 'Gesamtnutzen in % des Jahresumsatzes', angle: -90, position: 'insideBottomLeft', offset: 20 }}
            interval="preserveStartEnd"
            ticks={yAxisTicks} // Specify the ticks to render
          />
          <Tooltip />



          <Area type="monotone" dataKey="system" stroke="#48C7B6" strokeWidth={2} fill="#B8EDE4" fillOpacity={0.8} name="Nutzen System isoliert" />
          <Area type="monotone" dataKey="spital" stroke="#FF6F61" strokeWidth={2} fill="#FFB6AE" fillOpacity={0.8} name="Nutzen Unternehmen System" />
          
          {goalLevel > 1 && (
            <>
              <ReferenceLine
                y={accumulativeGoalEffizienzgewinnSpital}
                stroke="#299f47"
                strokeWidth={2}
                strokeDasharray="3 3"
                label={{ value: `Nutzengewinn Unternehmen: ${parseFloat(accumulativeGoalEffizienzgewinnSpital).toFixed(1)}%`, position: 'insideTopLeft', fill: '#299f47', fontSize: 15, fontWeight: 700 }} />
              <ReferenceLine
                y={accumulativeGoalEffizienzgewinnSystem}
                stroke="#299f47"
                strokeWidth={2}
                strokeDasharray="3 3"
                label={{ 
                  value: `Nutzengewinn Gesamtsystem: ${parseFloat(accumulativeGoalEffizienzgewinnSystem).toFixed(1)}%`, 
                  position: 'insideTopLeft', 
                  fill: '#299f47', 
                  fontSize: 15, 
                  fontWeight: 700, 
                  dy: -20 
                }}/>

              <ReferenceLine
                x={parseFloat(goalLevel)}
                strokeDasharray="3 3"
                stroke="#299f47"
                strokeWidth={2}
                label={{
                  value: `Ziel: ${parseFloat(goalLevel).toFixed(1)}`,
                  position: 'top',
                  fontSize: 15, fontWeight: 700
                }}
              />
            </>
          )}

          <ReferenceLine
            x={userLevel}
            stroke="#347AE2"
            strokeWidth={2}
            label={{
              value: `IST: ${parseFloat(userLevel).toFixed(1)}`,
              position: Math.abs(parseFloat(userLevel) - parseFloat(goalLevel)) <= 0.7 ? 'center' : 'top',
              fontSize: 15,
              fontWeight: 700
            }}
          />
          
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};



export const UmsetzungsaufwandChart = (date) => {
  const [umsetzungsaufwandData, setUmsetzungsaufwandData] = useState([]);
  const [umsetzungsaufwandRawData, setUmsetzungsaufwandRawData] = useState([]);

  const [yAxisTicks, setYAxisTicks] = useState([]);
  const [userLevel, setUserLevel] = useState("1");
  const [goalLevel, setGoalLevel] = useState("1");
  const [accumulativeGoalUmsetzungsaufwandSpital, setAccumulativeGoalUmsetzungsaufwandSpital] = useState("0");
  const [accumulativeGoalUmsetzungsaufwandSystem, setAccumulativeGoalUmsetzungsaufwandSystem] = useState("0");
  const [accumulativeUserUmsetzungsaufwandSpital, setAccumulativeUserUmsetzungsaufwandSpital] = useState("0");
  const [accumulativeUserUmsetzungsaufwandSystem, setAccumulativeUserUmsetzungsaufwandSystem] = useState("0");
  let initialData = [];
  const dataFetcher = new DataFetcher();
  const authentificationManager = new AuthentificationManager();

  useEffect(() => {
    try {

      const fetchLatestUserAssessmentData = async () => {
        try {

          const user_id = authentificationManager.getCookie('user_id');
          const spitalName = authentificationManager.getCookie('spital_Name');
          const latestgoal = await dataFetcher.fetch_data_by_filter_when_equalto_by_date_descending_order
            ("DHI_Ziele", "GesamtZiel", "Spital", spitalName);
          let latestAssessment = "";
          if (!date === "") {
            const Assessments = await dataFetcher.fetch_data_by_filter_when_equalto("Einzelassessment", "date_created,id", "user_id", user_id);
            const list = Assessments.data
            const findElementByDate = (list, date) => {
              return list.find(element => element.date_created === date);
            };
            latestAssessment = findElementByDate(list, date);
          }
          else {
            latestAssessment = await dataFetcher.fetch_data_by_filter_when_equalto_by_bezugsdate_descending_order("Einzelassessment", "id", "user_id", user_id);
          }

          if (latestAssessment == undefined) {
            console.log("No Assessment available");
            return;
          }

          const user_assessment_scores = await dataFetcher.fetch_data_by_filter_when_equalto("Reifegradantworten", "index,antwort,effizienzgewinn_spital,effizienzgewinn_system,umsetzungsaufwand_spital,umsetzungsaufwand_system,fragen_id", "assessment_id", latestAssessment.id);
          const spital_system_scores = await dataFetcher.fetchCollection("Reifegradfragen")


          let cumulativeScoresList = []; // This will store the new objects with cumulative scores

          user_assessment_scores.data.forEach(userScore => {
            const systemScore = spital_system_scores.data.find(systemScore => systemScore.id.toString() === userScore.fragen_id);

            if (systemScore) {
              let cumulative_effizienzgewinn_spital = 0;
              let cumulative_effizienzgewinn_system = 0;
              let cumulative_umsetzungsaufwand_spital = 0;
              let cumulative_umsetzungsaufwand_system = 0;

              const answer = parseInt(userScore.antwort);
              for (let i = 1; i <= answer; i++) {
                cumulative_effizienzgewinn_spital += parseFloat(systemScore[`effizienzgewinn_spital_${i}`].replace(',', '.'));
                cumulative_effizienzgewinn_system += parseFloat(systemScore[`effizienzgewinn_system_${i}`].replace(',', '.'));
                cumulative_umsetzungsaufwand_spital += parseFloat(systemScore[`umsetzungsaufwand_spital_${i}`].replace(',', '.'));
                cumulative_umsetzungsaufwand_system += parseFloat(systemScore[`umsetzungsaufwand_system_${i}`].replace(',', '.'));
              }

              // Create a new object for the cumulative scores and add it to the list
              cumulativeScoresList.push({
                level: userScore.antwort, // Assuming 'level' is based on the 'antwort' value
                cumulative_effizienzgewinn_spital: cumulative_effizienzgewinn_spital.toFixed(6), // Adjust precision as needed
                cumulative_effizienzgewinn_system: cumulative_effizienzgewinn_system.toFixed(6),
                cumulative_umsetzungsaufwand_spital: cumulative_umsetzungsaufwand_spital.toFixed(6),
                cumulative_umsetzungsaufwand_system: cumulative_umsetzungsaufwand_system.toFixed(6),
              });
            }
          });

          let totals = {
            total_cumulative_effizienzgewinn_spital: 0,
            total_cumulative_effizienzgewinn_system: 0,
            total_cumulative_umsetzungsaufwand_spital: 0,
            total_cumulative_umsetzungsaufwand_system: 0
          };

          cumulativeScoresList.forEach(item => {
            totals.total_cumulative_effizienzgewinn_spital += (parseFloat(item.cumulative_effizienzgewinn_spital) * 100);
            totals.total_cumulative_effizienzgewinn_system += (parseFloat(item.cumulative_effizienzgewinn_system) * 100);
            totals.total_cumulative_umsetzungsaufwand_spital += (parseFloat(item.cumulative_umsetzungsaufwand_spital) * 100);
            totals.total_cumulative_umsetzungsaufwand_system += (parseFloat(item.cumulative_umsetzungsaufwand_system) * 100);
          });

          // Creating a new list with a single object that contains the totals
          totals.total_cumulative_effizienzgewinn_spital = parseFloat(totals.total_cumulative_effizienzgewinn_spital.toFixed(2));
          totals.total_cumulative_effizienzgewinn_system = parseFloat(totals.total_cumulative_effizienzgewinn_system.toFixed(2));
          totals.total_cumulative_umsetzungsaufwand_spital = parseFloat(totals.total_cumulative_umsetzungsaufwand_spital.toFixed(2));
          totals.total_cumulative_umsetzungsaufwand_system = parseFloat(totals.total_cumulative_umsetzungsaufwand_system.toFixed(2));

          // Creating a new list with a single object that contains the adjusted totals
          let totalList = [totals];



          const sumsByDigitaleReife = {};
          // Function to convert string with comma to float
          const convertToFloat = (str) => parseFloat(str.replace(',', '.'));

          spital_system_scores.data.forEach(item => {
            const digitaleReifeLevels = 5; // Assuming there are 5 levels of digital maturity
            for (let i = 1; i <= digitaleReifeLevels; i++) {
              const digitaleReife = i; // Use the loop index as the digitale_reife level

              // Initialize the object for this digitaleReife if it does not exist
              if (!sumsByDigitaleReife[digitaleReife]) {
                sumsByDigitaleReife[digitaleReife] = { spital: 0, system: 0 };
              }

              // Aggregate the values for spital and system for this digitaleReife level
              sumsByDigitaleReife[digitaleReife].spital += convertToFloat(item[`umsetzungsaufwand_spital_${i}`] || "0");
              sumsByDigitaleReife[digitaleReife].system += convertToFloat(item[`umsetzungsaufwand_system_${i}`] || "0");
            }
          });



          // Convert the aggregated sums into the desired format
          const processedData = Object.entries(sumsByDigitaleReife).map(([digitaleReife, { spital, system }]) => ({
            digitale_reife: parseInt(digitaleReife),
            spital: (spital * 100).toFixed(2),
            system: (system * 100).toFixed(2),
          }));

          setUmsetzungsaufwandRawData(processedData)

          let totalUserEffizienzgewinnSpital = 0;
          let totalUserEffizienzgewinnSystem = 0;
          let totalUserUmsetzungsaufwandSpital = 0;
          let totalUserUmsetzungsaufwandSystem = 0;

          user_assessment_scores.data.forEach(item => {

            totalUserEffizienzgewinnSpital += parseFloat(item.effizienzgewinn_spital.replace(',', '.'));
            totalUserEffizienzgewinnSystem += parseFloat(item.effizienzgewinn_system.replace(',', '.'));
            totalUserUmsetzungsaufwandSpital += parseFloat(item.umsetzungsaufwand_spital.replace(',', '.'));
            totalUserUmsetzungsaufwandSystem += parseFloat(item.umsetzungsaufwand_system.replace(',', '.'));
          });

          const dimensionToAntwortMap = {};
          spital_system_scores.data.forEach(score => {
            const matchingUserAssessment = user_assessment_scores.data.find(assessment => assessment.fragen_id === score.id.toString());
            if (matchingUserAssessment) {
              const dimension = score.dimension;
              const antwort = parseFloat(matchingUserAssessment.antwort.replace(',', '.'));
              if (!dimensionToAntwortMap[dimension]) {
                dimensionToAntwortMap[dimension] = [];
              }
              dimensionToAntwortMap[dimension].push(antwort);
            }
          });

          // Step 2: Calculate the average antwort value for each dimension
          const averagesByDimension = Object.entries(dimensionToAntwortMap).map(([dimension, antworts]) => {
            const sum = antworts.reduce((acc, curr) => acc + curr, 0);
            const average = sum / antworts.length;
            return { dimension, average };
          });

          // Step 3: Calculate a single mean value from the averages of each dimension
          const totalAverage = averagesByDimension.reduce((acc, curr) => acc + curr.average, 0) / averagesByDimension.length;

          setUserLevel(totalAverage.toFixed(1))
          if(!latestgoal){
            setGoalLevel(1.0);
          }else{
            setGoalLevel(parseFloat(latestgoal.GesamtZiel).toFixed(1))
          }
          //setAccumulativeGoalUmsetzungsaufwandSpital((sumsByDigitaleReife[latestgoal.GesamtZiel].spital * 100).toFixed(2))
          //setAccumulativeGoalUmsetzungsaufwandSystem((sumsByDigitaleReife[latestgoal.GesamtZiel].system * 100).toFixed(2))
          setAccumulativeUserUmsetzungsaufwandSpital(totalList[0].total_cumulative_effizienzgewinn_spital)
          setAccumulativeUserUmsetzungsaufwandSystem(totalList[0].total_cumulative_effizienzgewinn_system)

        } catch (e) {
          console.log("Error while fetching or processing data while fetching data from Einzelantworten, Reifegradfragen or Reifegradantworten. Most of the time empty fetches that are processed.");
          console.log(e);
        }
      };


      fetchLatestUserAssessmentData();

    } catch (e) {
      console.log("Unexpected error while fetching the latest User Assessment Data");
    }
  }, []);


  useEffect(() => {
    if (!(umsetzungsaufwandRawData == "")) {
        try {
            // Calculate cumulative data
            const cumulativeData = umsetzungsaufwandRawData.map((entry, index, array) => {
                const cumulative = array.slice(0, index + 1).reduce((acc, item) => ({
                    spital: acc.spital + parseFloat(item.spital),
                    system: acc.system + parseFloat(item.system),
                }), { spital: 0, system: 0 });

                return {
                    digitale_reife: entry.digitale_reife,
                    spital: parseFloat(cumulative.spital.toFixed(1)).toString().includes('.') ? parseFloat(cumulative.spital.toFixed(1)) : parseFloat(cumulative.spital.toFixed(1).toString() + '.0'),
                    system: parseFloat(cumulative.system.toFixed(1)).toString().includes('.') ? parseFloat(cumulative.system.toFixed(1)) : parseFloat(cumulative.system.toFixed(1)) + '.0',
                };
            });

            
            if(cumulativeData[goalLevel - 1].system == "0.0"){
              setAccumulativeGoalUmsetzungsaufwandSystem(0);
            }else{
              setAccumulativeGoalUmsetzungsaufwandSystem(cumulativeData[goalLevel - 1].system.toFixed(1));
            }
            setAccumulativeGoalUmsetzungsaufwandSpital(cumulativeData[goalLevel - 1].spital.toFixed(1));

            // Set yAxisTicks based on the highest value in cumulative data
            const highestValue = Math.max(...cumulativeData.map(d => Math.max(d.spital, d.system)));
            const maxTick = Math.ceil(highestValue / 10) * 10 + 10;
            setYAxisTicks(Array.from({ length: (maxTick / 10) + 1 }, (_, i) => i * 10));

            setUmsetzungsaufwandData(cumulativeData);
        } catch (e) {
            console.log("Error while calculating the cumulative data of spital and system or while setting the maximum highest y-tick to be shown in the graph Aufwand-Entwicklung");
            console.log(e);
        }
    }
  }, [umsetzungsaufwandRawData]);

  // Map numeric values to 'Lx' labels for the X-Axis
  const labelMap = {
    1: 'L1', 2: 'L2', 3: 'L3', 4: 'L4', 5: 'L5'
  };

  const formatXAxis = (tickItem) => {
    return labelMap[tickItem];
  };

  const customYAxisTick = (value) => {
    return `${value}%`;
  };

  function formatNumber(num) {
    const formattedUserLevel = parseFloat(num);
    return formattedUserLevel % 1 === 0 ? formattedUserLevel.toFixed(1) + ".0" : formattedUserLevel.toFixed(1);
  }

  const CustomLegend = () => (
    <div className="custom-legend" style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }}>
      <div style={{ margin: '4px' }}>
        <span style={{ color: '#1e90ff', marginRight: '8px' }}>—</span>
        Aufwand Gesamtsystem
      </div>
      <div style={{ margin: '4px' }}>
        <span style={{ color: '#ffa500', marginRight: '8px' }}>—</span>
        Aufwand Unternehmen
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '4px' }}>
        
      </div>
    </div>
  );



  return (
    <div style={{ width: '50%', height: '600px', position: 'relative' }}>
      <h3 style={{ textAlign: 'center' }}>Aufwand-Entwicklung entlang der digitalen Reife</h3>
      <CustomLegend />
      <ResponsiveContainer>
        <AreaChart
          data={umsetzungsaufwandData}
          margin={{ top: 20, right: 30, left: 30, bottom: 100 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            type="number"
            domain={[1, 5]}
            dataKey="digitale_reife"
            tickFormatter={formatXAxis}
            label={{ value: 'Digitale Reife', position: 'insideBottom', offset: -10 }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            domain={[0, 10]}
            tickFormatter={customYAxisTick}
            label={{ value: 'Aufwand in % des Jahresumsatzes', angle: -90, position: 'insideBottomLeft', offset: 20 }}
            interval="preserveStartEnd"
            ticks={yAxisTicks} // Specify the ticks to render
          />
          <Tooltip />
          <Area type="monotone" dataKey="system" stroke="#1e90ff" strokeWidth={2} fill="#add8e6" fillOpacity={0.8} name="Aufwand System isoliert" />
          <Area type="monotone" dataKey="spital" stroke="#ffa500" strokeWidth={2} fill="#ffdab9" fillOpacity={0.8} name="Aufwand Unternehmen System" />
          
          {goalLevel > 1 && (
            <>
              <ReferenceLine
                y={accumulativeGoalUmsetzungsaufwandSpital}
                stroke="#299f47"
                strokeWidth={2}
                strokeDasharray="3 3"
                label={{
                  value: `Aufwand Unternehmen: ${parseFloat(accumulativeGoalUmsetzungsaufwandSpital).toFixed(1)}%`,
                  position: 'insideTopLeft',
                  fill: '#299f47',
                  fontSize: 15,
                  fontWeight: 700,
                  dy: goalLevel >= 3 ? 0 : -20
                }}
              />
              <ReferenceLine
                y={accumulativeGoalUmsetzungsaufwandSystem}
                stroke="#299f47"
                strokeWidth={2}
                strokeDasharray="3 3"
                label={{
                  value: `Aufwand Gesamtsystem: ${parseFloat(accumulativeGoalUmsetzungsaufwandSystem).toFixed(1)}%`,
                  position: 'insideTopLeft',
                  fill: '#299f47',
                  fontSize: 15,
                  fontWeight: 700,
                  dy: goalLevel >= 3 ? -20 : 0
                }}
              />
              <ReferenceLine
                x={parseFloat(goalLevel)}
                strokeDasharray="3 3"
                stroke="#299f47"
                strokeWidth={2}
                label={{
                  value: `Ziel: ${parseFloat(goalLevel).toFixed(1)}`,
                  position: 'top',
                  fontSize: 15,
                  fontWeight: 700
                }}
              />
            </>
          )}


          <ReferenceLine
            x={userLevel}
            stroke="#347AE2"
            strokeWidth={2}
            label={{
              value: `IST: ${userLevel}`,
              position: Math.abs(parseFloat(userLevel) - parseFloat(goalLevel)) <= 0.7 ? 'center' : 'top',
              fontSize: 15,
              fontWeight: 700
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};