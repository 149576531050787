import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BewertungAuswertungMarkup from "../html_jsx/BewertungAuswertung";
import { VerticalNavigationBar } from "../widgets/VerticalNavigationWidgets";
import { HeaderWidget } from "../widgets/HeaderWidget";
import AuthentificationManager from "./classes/authentificationManager";

import "./BewertungAuswertung.css";
import "./Mainscreen_styles.css";

const BewertungAuswertung = () => {
  const navigate = useNavigate();

  const pageNames = ["Übersicht", "Zielsetzung", "Bewertung", "Nutzen & Aufwand", "Settings", "Kontakt"]
  const imageIcons =["/uebersichtIcon.svg", "/strategieIcon.svg","/selected-bewertungIcon.svg","/portfolioIcon.svg","/settingsIcon.svg","/contactIcon.svg"]

  const authentificationManager = new AuthentificationManager();
  // Check for the presence of "auth_token" cookie when the component is mounted
  useEffect(() => {
    authentificationManager.checkAndRefreshToken();
  }, []);


  const onItemXLClick = useCallback(() => {
    navigate("/41-portfolio-tabelle");
  }, [navigate]);

  const onItemXL1Click = useCallback(() => {
    navigate("/21-strategie-gesamtunternehmen");
  }, [navigate]);

  const onItemXL2Click = useCallback(() => {
    navigate("/10-uebersicht");
  }, [navigate]);

  const linkToFragebogen = useCallback(() => {
    navigate("/32-bewertung-frageboegen");
  }, [navigate]);

  const linkToAllgemeine = useCallback(() => {
    navigate("/31-bewertung-allgemeine-informationen");
  }, [navigate]);

  const linkToGruppenAuswertung = useCallback(() => {
    navigate("/34-bewertung-gruppenassessment");
  }, [navigate]);

  //Same order as the lists above
  const clickLinkToPage = [onItemXL2Click,onItemXL1Click,onItemXLClick]
  const currentPage = "Bewertung"
  const userName = "Ueli Mueller"
  const userPicture = "/userpic@2x.png"

  const verticalNavigationBarList = [pageNames,imageIcons,clickLinkToPage,currentPage,userName,userPicture]

  return (
    <div className="allcontent">
      <div className="mainContent">
        <div className="pageTitle">
            <b className="pageTitle-title">Mein Assessment</b>
        </div>
        <BewertungAuswertungMarkup
        onItemXLClick={onItemXLClick}
        onItemXL1Click={onItemXL1Click}
        onItemXL2Click={onItemXL2Click}
        linkToFragebogen={linkToFragebogen}
        linkToAllgemeine={linkToAllgemeine}
        linkToGruppenAuswertung={linkToGruppenAuswertung}
        overlayContentLink = {"https://tripetto.app/run/AN2QGG8Z9I" + authentificationManager.getCookie("user_id") + "&Spital=" + authentificationManager.getCookie("spital_Name")}/>
      </div>
      <div className="navigationbar">
        <VerticalNavigationBar verticalNavigationBarList = {verticalNavigationBarList}/>
      </div>
      <div className="headerbar">
        <HeaderWidget LogoImg="/LogoDRI.svg" SpitalName="Spital Zürich" />
      </div>
    </div>
  );
};

export default BewertungAuswertung;
