import React from 'react';
import "../widgets/ModalCreateGruppenassessment.css";
import { useState, useEffect } from "react";
import { DisclaimerWidget } from "./ModalDisclamer.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from 'react-toastify';  // Correct import statement

import DataFetcher from '../pages/classes/databaseManager';
import AuthentificationManager from '../pages/classes/authentificationManager';



export const ModalCreateGruppenAssessments = ({ isModalCreateGruppenAssessment, toggleCGAVisibility}) => {
    const databaseManager = new DataFetcher();
    const authentificationManager = new AuthentificationManager();
    const [spitalNameList, setSpitalNameList] = useState([]);
    const [nameClicked, setNameClicked] = useState([]);
    const [visibleDisclaimer, setVisibleDisclaimer] = useState(false);
    const [disclaimerTitle, setDisclaimerTitle] = useState("");
    const [disclaimerText, setDisclaimerText] = useState("");
    const [refreshData, setRefreshData] = useState(false);
    const [inputValues, setInputValues] = useState(
        {
          name: '',
          teilnehmer: '',
          spital: authentificationManager.getCookie('spital_Name'),
          bezugsdatum: ''
        });


    const onPageLoad = async () => {
        try {
            const spital_name = authentificationManager.getCookie('spital_Name');
            const resp_namen = await databaseManager.fetch_data_by_filter_when_equalto('users', 'id,first_name,last_name,job_position,avatar', 'organisation', spital_name);
            const user_id = await databaseManager.getCookie('user_id');
    
            const nameList = resp_namen.data;
            setSpitalNameList(nameList);
    
            // Create a new array filled with false
            const updatedClickedArray = Array(nameList.length).fill(false);
    
            // Loop through nameList and update the corresponding index if id matches user_id
            nameList.forEach((item, index) => {
                if (item.id === user_id) {
                    updatedClickedArray[index] = true;
                }
            });
    
            // Set the updated array in setNameClicked
            setNameClicked(updatedClickedArray);
    
            setRefreshData(false);
        } catch (error) {
            console.error("Error on Page Load of Modal Create Gruppenassessment:", error);
        }
    };
        

    useEffect(() => {
        onPageLoad();
    },[]);

    const handleInputChange = (field, value) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [field]: value
        }));
    };

    const handleDateChange = (field, value) => {
        //console.log(field);
        //console.log(value);
        setInputValues((prevValues) => ({
            ...prevValues,
            [field]: value
        }));
    };

    const selectUser = (index) => {
        setNameClicked((prevNameClicked) => {
            const nameClickedTemp = [...prevNameClicked];
            nameClickedTemp[index] = !nameClickedTemp[index];
            return nameClickedTemp;
        });
    };
    
    const saveGA = async () => {

        // Disclaimer für Bezugsdatum vergessen
        if(inputValues.bezugsdatum === undefined || inputValues.bezugsdatum === null || inputValues.bezugsdatum === "") { 
            setVisibleDisclaimer(true);
            setDisclaimerTitle("Bezugsdatum vergessen:");
            setDisclaimerText("Es scheint, als ob Sie vergessen haben, ein Bezugsdatum für das Gruppenassessment zu wählen.");
            return;
        }

        // Retrieve aller Bezugsdaten der User
        const resp_alleBezugsdaten = await databaseManager.fetch_data_from_collection('Einzelassessment', 'user_id,bezugsdatum');
        const schonExistierendeBezugsdaten = resp_alleBezugsdaten.data.filter(item => item.bezugsdatum === inputValues.bezugsdatum);

        // Teilnehmer in string speichern
        let teilnehmer = '';
        let maybetrue = 0;
        const teilnehmer_array = []; // Wird für die nächste Funktionalität gebraucht
        for(let i = 0; i<nameClicked.length; i++) {
            if(teilnehmer===''){
                if(nameClicked[i]) {
                    teilnehmer = spitalNameList[i].id;
                    maybetrue++;
                    teilnehmer_array.push(spitalNameList[i].id);
                }
            } else {
                if(nameClicked[i]) {
                    teilnehmer = teilnehmer + ',' + spitalNameList[i].id;
                    maybetrue++;
                    teilnehmer_array.push(spitalNameList[i].id);
                }
            }
        }

        // Disclaimer dass mehr als eine Person ausgewählt wird
        if(maybetrue < 2){
            setVisibleDisclaimer(true);
            setDisclaimerTitle("Teilnehmer vergessen:");
            setDisclaimerText("Es scheint, als ob Sie vergessen haben, mindestens 2 Teilnehmer für das Gruppenassessment auszuwählen.");           
            return;
        }

        // Test ausgewählter user besitzt an dem Bezugsdatum schon ein Assessment
        const bezugsdatumBelegt = teilnehmer_array.some(id => schonExistierendeBezugsdaten.some(item => item.user_id === id));

        if(bezugsdatumBelegt) { 
            setVisibleDisclaimer(true);
            setDisclaimerTitle("Bezugsdatum schon belegt!");
            setDisclaimerText("Für ihr ausgewähltes Bezugsdatum wurde bereits ein Assessment durchgeführt. Bitte wählen Sie ein anderes Bezugsdatum, da pro Tag nur ein Assessment durchgeführt werden kann.");
            return;
        }

        //console.log(nameClicked);
        if(inputValues.name === undefined || inputValues.name === null || inputValues.name === "") { 
            setVisibleDisclaimer(true);
            setDisclaimerTitle("Gruppen Assessment Name vergessen:");
            setDisclaimerText("Es scheint, als ob Sie vergessen haben, einen Namen für das Gruppenassessment einzufügen.");
            return;
        }
        
        const spitalname = authentificationManager.getCookie('spital_Name');

        const body = {
            name: inputValues.name,
            teilnehmer: teilnehmer,
            spital: spitalname,
            bezugsdatum: inputValues.bezugsdatum
        };



        //console.log(body);
        try {
            // Your code that sends the request
            await databaseManager.postData('Gruppenassessment', body);
        } catch (error) {
            // Check if the error is a bad request (status code 400)
            if (error || error.message === "Network response was not ok") {
                setVisibleDisclaimer(true);
                setDisclaimerTitle("Der Name für das Gruppenassessment ist bereits vorhanden:");
                setDisclaimerText("Es sieht so aus, als ob dieses Assessment bereits existiert. Bitte wählen Sie einen anderen Namen aus.");    
                return;
            } else {
                // Handle other types of errors or rethrow the error
                //console.error('error message from DB:', error);
                setVisibleDisclaimer(true);
                setDisclaimerTitle("Unbekannter Fehler:");
                setDisclaimerText("Bitte setzen Sie sich mit Ihrem Serveradministrator in Verbindung.");
                return;
            }
        }
        toast.success("Gruppen Assessment erfolgreich erstellt!", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
        setTimeout(() => {
            toggleCGAVisibility();
            setRefreshData(true); // Trigger data refresh
            window.location.reload();
        }, 2000);
    };

    const closeDisclaimer = () => {
        setVisibleDisclaimer(false);
    }

    return (
        <>
            {isModalCreateGruppenAssessment && (
                <div className="modalCGA-background">
                    <ToastContainer />
                    {visibleDisclaimer && <DisclaimerWidget 
                        title={disclaimerTitle}
                        text={disclaimerText}
                        agree="OK"
                        agreeOnClickMethode={closeDisclaimer}/>}
                    <div className="modalCGA-content">
                        {/* Close Button */}
                        <img src='/closeButton.svg' className="modalCGA-content-close-btn" onClick={toggleCGAVisibility} draggable={false}/>
                        {/* Input GA Titel */}
                        <div className='modalCGA-content-container'>
                            <div className='modalCGA-content-container-left'>
                                <a className='modalCGA-content-text-titel'>Gruppen Assessment Name:</a>
                                <input className={`modalCGA-content-input-titel ${false ? 'error-border' : ''}`}
                                    placeholder="Name hier eingeben..." 
                                    value={inputValues.name} 
                                    onChange={(e) => handleInputChange('name', e.target.value)}/>
                            </div>
                            <div className='modalCGA-content-container-right'>
                                <a className='modalCGA-content-text-titel'>Bezugsdatum</a>
                                <input className={`modalCGA-content-input-date ${false ? 'error-border' : ''}`}
                                    type='date'
                                    placeholder="Name hier eingeben..." 
                                    value={inputValues.bezugsdatum} 
                                    onChange={(e) => handleDateChange('bezugsdatum', e.target.value)}/>
                            </div>
                        </div>
                        {/* Add People */}
                        <a className='modalCGA-content-text-mitglieder'>Wer nimmt teil:</a>
                        <div className='modalCGA-content-names'>
                        {spitalNameList.map((name, index) => (
                            <UserProfileItem 
                                userId={name.id} 
                                userName={`${name.first_name} ${name.last_name}`} 
                                userRole={name.job_position} 
                                isSelected={nameClicked[index]} 
                                onSelectionChange={() => selectUser(index)} 
                                imgID={name.avatar || "56fee9d6-254d-418a-8cf8-9e60a8d94be7"}
                            />
                        ))}
                        </div>
                        <div className='modalCGA-content-create' onClick={saveGA}>
                                Erstelle Gruppen Assessment
                        </div>
                    </div>
                </div>                
            )}            
        </>
    );
};



/**
 * UserProfileListItem Component: Renders a user profile card with information.
 * 
 * @param {string} userId - The unique identifier for the user.
 * @param {string} userName - The name of the user.
 * @param {string} userRole - The role of the user.
 * @param {string} userScore - The user's score in the format 'currentScore/totalScore'.
 * @param {number[]} answers - An array of user's answers.
 * @param {boolean} isSelected - Indicates if the user is selected.
 * @param {Function} onSelectionChange - Callback function when the user selection changes.
 * @returns {JSX.Element} - Returns JSX elements to render the user profile card.
 */
export const UserProfileItem = ({ userId, userName, userRole, isSelected, onSelectionChange, imgID }) => {

    const authentificationManager = new AuthentificationManager();
    if (imgID === undefined) {
        imgID = "ed148a10-bf60-4b86-8671-ace505b54370";
    }

    const handleBorderClick = (e) => {
        // Additional logic if needed
        onSelectionChange(userId, !isSelected);
    };

    return (
        <div className={`modalCGA-content-names-border ${isSelected ? 'modalCGA-content-names-selected' : ''}`} onClick={handleBorderClick}>
            <div className="modalCGA-content-names-inner-container">
                <div className="modalCGA-content-names-profile">
                    <img src={authentificationManager.getbaseURL() + "/assets/" + imgID }draggable="false"
                        alt={userName}
                        className="modalCGA-content-names-profile-image" />
                    <div className='modalCGA-content-names-nameSection-container'>
                        <div className="modalCGA-content-names-profile-name">{userName}</div>
                        <div className="modalCGA-content-names-profile-role">{userRole}</div>
                    </div>
                    <div className="modalCGA-content-names-profile-choice-button">
                    </div>
                </div>
            </div>
        </div>
    );
};