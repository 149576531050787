import React from "react";
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import { useState, useEffect } from "react";
import getSpitalReifegradTableData from "../pages/classes/getSpitalReifegradDaten";
import DataFetcher from "./../pages/classes/databaseManager";
import AuthentificationManager from "../pages/classes/authentificationManager";

import 'rsuite-table/dist/css/rsuite-table.css';
import "../widgets/BewertungAuswertungWidgets.css";
import "../pages/BewertungAuswertung.css";
import zIndex from "@mui/material/styles/zIndex";


const BewertungAuswertungMarkup = ({ onItemXLClick, onItemXL1Click, onItemXL2Click, linkToFragebogen, linkToAllgemeine, linkToGruppenAuswertung, overlayContentLink, verticalNavigationBarList }) => {

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const toggleOverlay = () => {
        setIsOverlayVisible(!isOverlayVisible);
    };

    const [valueDate, setValueDate] = useState("1");
    const [valueName, setValueName] = useState("1");
    const [tabData, setTabData] = useState([]);
    const [tableHeader, setTableHeader] = useState('')
    const [assessmentDates, setAssessmentDates] = useState([]);
    const [assessmentNames, setAssessmentNames] = useState([]);
    const [tableVisibility, setTableVisibility] = useState(false);
    const [loading, setLoading] = useState(true);
    

    function handleSelectDate(event) {
        setValueDate(event.target.value);
    }

    function handleSelectName(event) {
        setValueName(event.target.value);
    }

    const dataFetcher = new DataFetcher();
    const authentificationManager = new AuthentificationManager();

    const getTableData = async (assessment_id) => {
        try {
            const user_id = authentificationManager.getCookie('user_id');
            // Get and set table data
            const resp_dimensionen = await dataFetcher.fetch_data_from_collection('Dimensionen', 'name');
            const resp_reifegradAntworten = await dataFetcher.fetch_data_by_filter_when_equalto('Reifegradantworten', 'antwort,antwort_text,fragen_id,fragen_index,kommentar,kategorie,dimension,bezugsdatum', 'assessment_id', assessment_id);
            const resp_reifegradFragen = await dataFetcher.fetch_data_from_collection('Reifegradfragen', 'id,frage,index');

            // Build up the table data
            const tableData = [];

            if (resp_reifegradAntworten.data.length != 0) {
                const mergedAntwFrag = resp_reifegradAntworten.data.map(antw => {
                    const matchingFrage = resp_reifegradFragen.data.filter(frage => String(frage.id) === antw.fragen_id);
                    // Check if there is a match
                    if (matchingFrage) {
                        // Merge properties from both objects
                        return { ...antw, ...matchingFrage[0] };
                    } else {
                        // If no match found, return the original item from Array1
                        return antw;
                    }
                })
                resp_dimensionen.data.forEach(dimension => {
                    const dimensionAntworten = mergedAntwFrag.filter(antwort => antwort.dimension === dimension.name);

                    const sortedTableData = dimensionAntworten.sort(function (a, b) {
                        // Custom sorting function based on the index property
                        var indexA = a.index.split('.').map(Number);
                        var indexB = b.index.split('.').map(Number);

                        if (indexA[0] !== indexB[0]) {
                            return indexA[0] - indexB[0];
                        } else {
                            return indexA[1] - indexB[1];
                        }
                    });

                    if (sortedTableData.length != 0) {
                        tableData.push(sortedTableData);
                    }
                });
            } else {
                // setAssessmentDates([])
            }
            // console.log(tableData[0][0].bezugsdatum);
            setTableHeader(tableData[0][0].bezugsdatum);
            setTabData(tableData);
        } catch (e) {
            // Handle the error, e.g., display an error message or retry fetching.
            console.log("Error getting the table data in Auswertung.");
        }
    };

    const onPageLoad = async () => {
        try {
            const user_id = authentificationManager.getCookie('user_id');
            const user_spital = authentificationManager.getCookie('spital_Name');

            // Fetch Assessments
            const assessments = await dataFetcher.fetch_data_by_filter_when_equalto("Einzelassessment", "id,gruppenAssessmentName,bezugsdatum", 'user_id', user_id);
            const gassessments = await dataFetcher.fetch_data_by_filter_when_equalto("Gruppenassessment", "id,name,bezugsdatum", "spital", user_spital);
            const dateSortedAssessments = assessments.data.sort((a, b) => new Date(b.bezugsdatum) - new Date(a.bezugsdatum));


            // Create the selectorDateList directly
            const selectorDateList = dateSortedAssessments.map((item, index) => {
              const matchingGAssessment = gassessments.data.find(gItem => gItem.bezugsdatum === item.gruppenAssessmentName);
              const labelPrefix = matchingGAssessment ? `GA - ${matchingGAssessment.name}` : 'EA';
              return {
                label: `${labelPrefix} (${item.bezugsdatum})`,
                value: index + 1
              };
            });

            if (assessments.data.length > 0) {
                setTableVisibility(true);
            }
            // Get and set selector data
            const sortedData = assessments.data.sort((a, b) => new Date(b.bezugsdatum) - new Date(a.bezugsdatum));
            // Extract unique years using Set and map
            const uniqueYearsSet = new Set(sortedData.map(item => item.bezugsdatum.split('-')[0]));
            // Sort them descending
            const uniqueYears = Array.from(uniqueYearsSet).sort((a, b) => b - a);
            // Create the selectorYearList directly
            const selectorYearList = uniqueYears.map((item, index) => ({
                label: item,
                value: index + 1
            }));

            // Find all assessments with the selected year
            const selectedDateAssessments = assessments.data.filter(item => item.bezugsdatum.includes(uniqueYears[valueDate - 1]));
            const gruppenAssessments = selectedDateAssessments.filter(item => item.gruppenAssessmentName !== null);
            const einzelAssessments = selectedDateAssessments.filter(item => item.gruppenAssessmentName === null);
            const mergedArray = einzelAssessments.concat(gruppenAssessments);

            const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];


            // create the name selector
            const selectorDataNames = mergedArray.map((item, index) => ({
                label: `${item.gruppenAssessmentName === null ?
                    'EA: ' + item.bezugsdatum.slice(-2) + '.' + months[parseInt(item.bezugsdatum.split('-')[1], 10) - 1] :
                    'GA: ' + item.gruppenAssessmentName}`,
                value: index + 1
            }));

            // const labelCounts = {};

            // // Iterate through the array and check for duplicate labels
            // selectorDataNames.forEach(item => {
            //     const label = item.label;

            //     // If label is already present, increment the count
            //     if (labelCounts[label]) {
            //         labelCounts[label]++;
            //     } else {
            //         labelCounts[label] = 1;
            //     }

            //     // If there are duplicates, append the count to the label
            //     if (labelCounts[label] > 1) {
            //         item.label = `${label} ${labelCounts[label] - 1}`;
            //     }
            // });


            setAssessmentNames(selectorDateList);
            //setAssessmentNames(selectorDataNames);
            //setAssessmentDates(selectorYearList);

        } catch (e) {
            // Handle the error, e.g., display an error message or retry fetching.
            console.log("Error on Page Load, could be while fetching the assessments and processing them.");
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!loading && !tableVisibility) {
            setTableVisibility(false);
        }
    }, [loading, tableVisibility]);

    useEffect(() => {
        try {
            onPageLoad();
        }
        catch {
            console.log('Could not fetch data from backend');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const user_id = authentificationManager.getCookie('user_id');

            // Fetch Assessments
            const resp_einzelAssessments = await dataFetcher.fetch_data_by_filter_when_equalto("Einzelassessment", "id,gruppenAssessmentName,bezugsdatum", 'user_id', user_id);

            // Get the year list
            const sortedData = resp_einzelAssessments.data.sort((a, b) => new Date(b.bezugsdatum) - new Date(a.bezugsdatum));
            // Extract unique years using Set and map
            const uniqueYearsSet = new Set(sortedData.map(item => item.bezugsdatum.split('-')[0]));
            // Sort them descending
            const uniqueYears = Array.from(uniqueYearsSet).sort((a, b) => b - a);

            // Find all assessments with the selected year
            const selectedDateAssessments = resp_einzelAssessments.data.filter(item => item.bezugsdatum.includes(uniqueYears[valueDate - 1]));
            const gruppenAssessments = selectedDateAssessments.filter(item => item.gruppenAssessmentName !== null);
            const einzelAssessments = selectedDateAssessments.filter(item => item.gruppenAssessmentName === null);
            const mergedArray = einzelAssessments.concat(gruppenAssessments);

            const sortedMergedArray = mergedArray.sort((a, b) => new Date(b.bezugsdatum) - new Date(a.bezugsdatum));

            if (sortedMergedArray.length != 0) {
                getTableData(sortedMergedArray[parseInt(valueName)-1].id);
            }

        };
        try {
            fetchData();
        }
        catch {
            console.log('Could not fetch data from backend');
        }
    }, [valueName]);

    // useEffect(() => {
    //     const fetchData = async () => {

    //         const user_id = authentificationManager.getCookie('user_id');
    //         // Fetch Assessments
    //         const resp_einzelAssessments = await dataFetcher.fetch_data_by_filter_when_equalto("Einzelassessment", "id,gruppenAssessmentName,bezugsdatum", 'user_id', user_id);


    //         // Get the year list
    //         const sortedData = resp_einzelAssessments.data.sort((a, b) => new Date(b.bezugsdatum) - new Date(a.bezugsdatum));
    //         // Extract unique years using Set and map
    //         const uniqueYearsSet = new Set(sortedData.map(item => item.bezugsdatum.split('-')[0]));
    //         // Sort them descending
    //         const uniqueYears = Array.from(uniqueYearsSet).sort((a, b) => b - a);

    //         // Find all assessments with the selected year
    //         const selectedDateAssessments = resp_einzelAssessments.data.filter(item => item.bezugsdatum.includes(uniqueYears[valueDate - 1]));
    //         const gruppenAssessments = selectedDateAssessments.filter(item => item.gruppenAssessmentName !== null);
    //         const einzelAssessments = selectedDateAssessments.filter(item => item.gruppenAssessmentName === null);
    //         const mergedArray = einzelAssessments.concat(gruppenAssessments);

    //         const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

    //         // create the name selector
    //         const selectorDataNames = mergedArray.map((item, index) => ({
    //             label: `${item.gruppenAssessmentName === null ?
    //                 'EA: ' + item.bezugsdatum.slice(-2) + '.' + months[parseInt(item.bezugsdatum.split('-')[1], 10) - 1] :
    //                 'GA: ' + item.gruppenAssessmentName}`,
    //             value: index + 1
    //         }));

    //         // const labelCounts = {};

    //         // // Iterate through the array and check for duplicate labels
    //         // selectorDataNames.forEach(item => {
    //         //     const label = item.label;

    //         //     // If label is already present, increment the count
    //         //     if (labelCounts[label]) {
    //         //         labelCounts[label]++;
    //         //     } else {
    //         //         labelCounts[label] = 1;
    //         //     }

    //         //     // If there are duplicates, append the count to the label
    //         //     if (labelCounts[label] > 1) {
    //         //         item.label = `${label} ${labelCounts[label] - 1}`;
    //         //     }
    //         // });

    //         //setAssessmentNames(selectorDataNames);
    //         if (mergedArray.length != 0) {
    //             getTableData(mergedArray[0].id);
    //             setValueName('1');
    //         }

    //     };
    //     try {
    //         fetchData();
    //     }
    //     catch {
    //         console.log('Could not fetch data from backend');
    //     }
    //     setValueName('1');
    // }, [valueDate]);

    return (
        <div className="bewertung-auswertung-screen">

            <div className="selector-container">
                <div className="selector-item-not-selected" onClick={linkToAllgemeine}>
                    Allgemeine Informationen
                </div>
                <div className="selector-divider" />
                <div className="selector-item-not-selected" onClick={linkToFragebogen}>
                    Bewertung
                </div>
                <div className="selector-divider" />
                <div className="selector-item-selected">
                    Mein Assessment
                </div>
                <div className="selector-divider" />
                <div className="selector-item-not-selected" onClick={linkToGruppenAuswertung}>
                    Gruppen Assessment
                </div>
            </div>

            {loading ? (
                <div className="fr-singleContent-container-noAssessment">Loading ...</div>
            ) : (
                tableVisibility ? (
                <div className="singleContent-container">
                    <div className="ba-singleContent-container-selector-doubleSelector">
                        <div style={{ fontSize: "12px", color: "var(--dark-theme-gray-4)", fontWeight: 600, lineHeight: "20px" }}>
                            EA: Einzel Assessment <br />
                            GA: Gruppen Assessment
                        </div>

                        <select className="ba-singleContent-container-selector-s1" onChange={handleSelectName}>
                            {assessmentNames.map(option => (
                            <option className="ba-singleContent-container-selector-item" value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        {/* <select className="ba-singleContent-container-selector-s1" onChange={handleSelectName}>
                            {assessmentNames.map(option => (
                                <option className="ba-singleContent-container-selector-item" value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <select className="ba-singleContent-container-selector-s1" onChange={handleSelectDate}>
                            {assessmentDates.map(option => (
                                <option className="ba-singleContent-container-selector-item" value={option.value}>{option.label}</option>
                            ))}
                        </select> */}
                    </div>
                    <div className="singleContent-container-tableheader">Bezugsdatum: {tableHeader}</div>
                    {tabData.map((item) => {
                        return (
                            <div className="singleContent-container-table">
                                <div className="singleContent-container-titel">{item[0].dimension}</div>
                                <Table
                                    // wordWrap="break-word"
                                    autoHeight
                                    data={item}
                                    cellBordered
                                    affixHeader
                                    style={{ width: "100%", position: "relative", zIndex: 0 }}>

                                    <Column flexGrow={6} align="left" resizable fullText>
                                        <HeaderCell className="HeaderCell-Custom-meinAssessment">Frage</HeaderCell>
                                        <Cell>
                                            {item => <div>{item.frage}</div>}
                                        </Cell>
                                    </Column>

                                    <Column flexGrow={2} align="center" resizable fullText>
                                        <HeaderCell className="HeaderCell-Custom-meinAssessment">Reifegrad Bar</HeaderCell>
                                        <Cell>
                                            {item =>
                                                <div className="progressBar-background">
                                                    <div className={`progressBar-progress-${item.antwort}`} />
                                                </div>
                                            }
                                        </Cell>
                                    </Column>

                                    <Column flexGrow={1} align="center" resizable fullText>
                                        <HeaderCell className="HeaderCell-Custom-meinAssessment">Level</HeaderCell>
                                        <Cell>
                                            {item =>
                                                <div className={`tableLevel-background tableLevel-background-${item.antwort}`}>Level {item.antwort}</div>
                                            }
                                        </Cell>
                                    </Column>

                                    <Column flexGrow={6} resizable fullText>
                                        <HeaderCell className="HeaderCell-Custom-meinAssessment">Antwort</HeaderCell>
                                        <Cell dataKey="antwort_text" />
                                    </Column>

                                    <Column flexGrow={2} resizable wordWrap>
                                        <HeaderCell className="HeaderCell-Custom-meinAssessment">Kommentar</HeaderCell>
                                        <Cell dataKey="kommentar" />
                                    </Column>
                                </Table>
                            </div>
                        );
                    })}
                </div>
                 ) : (
                    <div className="fr-singleContent-container-noAssessment">Es gibt noch keine Assessment Daten!</div>
                )
            )}
        </div>
    );
};

export default BewertungAuswertungMarkup;