// For Tree Graph
import { useState, useContext, useEffect } from 'react';
import Tree from "react-d3-tree";
// import treeData from '../questionnaire.json';
import React from "react";
import DataFetcher from "../pages/classes/databaseManager";
import AuthentificationManager from '../pages/classes/authentificationManager';
import treeGraphManager from '../pages/classes/treeGraphManager';
import tripettoJson from '../pages/classes/Tripetto_Fragebogen.json'
import { TreegraphFrage } from "../widgets/FragebogenWidget.js"


import "../widgets/BewertungFrageboegenWidgets.css";
import "../pages/BewertungFrageboegen.css";
import { height } from '@mui/system';

/* TREE GRAPH WIDGET */
export const TreeGraph = ({ selectedAss }) => {
  const dataFetcher = new DataFetcher();
  const authentificationManager = new AuthentificationManager();

  const [showPopup, setShowPopup] = useState(false);
  const [showTree, setShowTree] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const [treeData, setTreeData] = useState({ "name": "Befragung", "total_questions": 0, "open_questions": 0 });
  const [allAnswers, setAllAnswers] = useState([]);
  const [allQuestions, setAallQuestions] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [subDimensions, setSubDimensions] = useState([]);
  const [questionNumber, setQuestionNumber] = useState([]);
  const [currAssessmentDate, setCurrAssessmentDate] = useState("");
  const [antwortIdList, setAantwortIdList] = useState([]);
  const [alleAntwortenFullInfo, setAlleAntwortenFullInfo] = useState([]); 
  const [selectedAssessment, setSelectedAssessment] = useState([]);
  const [prepareFrage, setPrepareFrage] = useState();
  const [selectedLevels, setSelectedLevels] = useState();
  const [fragennummer, setFragennummer] = useState();

  const retrieveTreeData = async () => {

    try {
      const [treeDataJson, rootNodes, leafNodes, numberArray, alleAntworten, alleFragen, bezugsdatum, fullInfoAntw, selectAss] = await treeGraphManager(selectedAss);

      setTreeData(treeDataJson);
      setAllAnswers(alleAntworten);
      setAallQuestions(alleFragen);
      setDimensions(rootNodes);
      setSubDimensions(leafNodes);
      setQuestionNumber(numberArray);
      setShowTree(true);
      setCurrAssessmentDate(bezugsdatum);
      setAlleAntwortenFullInfo(fullInfoAntw);
      setSelectedAssessment(selectAss);
    }
    catch (err) {
      console.log("No Assessment");
    }
  }

  useEffect(() => {
    retrieveTreeData();
  }, []);


  const handleNodeClick = async (nodeData, fragennummerInput) => {
    if (!nodeData.children || nodeData.children.length === 0) {
      setCurrentNode(nodeData, fragennummerInput);
      setFragennummer(fragennummerInput);

      console.log("----------HIER SINGLE FRAGEN WAHL----------");
      console.log(allAnswers.filter(antw => antw.index === String(fragennummerInput))[0].antwort);  // Antwort vom index
      console.log(fragennummerInput); // fragen_index
      console.log(allQuestions); // Frage noch nach index filtern  !!!!!

      // TODO PRIO 1 !!!!!!!!
      const selectedLevelsTmp = {
        [fragennummerInput]: allAnswers.filter(antw => antw.index === String(fragennummerInput))[0].antwort,
      };
      setSelectedLevels(selectedLevelsTmp);

      const dimensionsPlainText = await dataFetcher.fetch_data_by_filter_when_equalto("Dimensionen","name","id",allQuestions.filter(question => question.index === String(fragennummerInput))[0].dimension);
      const kategoriePlainText = await dataFetcher.fetch_data_by_filter_when_equalto("Kategorien","name","id",allQuestions.filter(question => question.index === String(fragennummerInput))[0].kategorie);

      console.log(dimensionsPlainText);
      console.log(kategoriePlainText);
      console.log(fragennummerInput);

      const fragePreparation =
      {
        fragen_index: fragennummerInput ?? "",
        frage: allQuestions.filter(question => question.index === String(fragennummerInput))[0].frage,
        dimension: dimensionsPlainText.data[0].name,
        kategorie: kategoriePlainText.data[0].name,
        beispiel: allQuestions.filter(question => question.index === String(fragennummerInput))[0].beispiel ?? "",
        antworttyp: allQuestions.filter(question => question.index === String(fragennummerInput))[0].antworttyp,
        level_1: allQuestions.filter(question => question.index === String(fragennummerInput))[0].level_1,
        level_2: allQuestions.filter(question => question.index === String(fragennummerInput))[0].level_2,
        level_3: allQuestions.filter(question => question.index === String(fragennummerInput))[0].level_3,
        level_4: allQuestions.filter(question => question.index === String(fragennummerInput))[0].level_4,
        level_5: allQuestions.filter(question => question.index === String(fragennummerInput))[0].level_5,
        beispiel_1: allQuestions.filter(question => question.index === String(fragennummerInput))[0].beispiel_1 ?? "",
        beispiel_2: allQuestions.filter(question => question.index === String(fragennummerInput))[0].beispiel_2 ?? "",
        beispiel_3: allQuestions.filter(question => question.index === String(fragennummerInput))[0].beispiel_3 ?? "",
        beispiel_4: allQuestions.filter(question => question.index === String(fragennummerInput))[0].beispiel_4 ?? "",
        beispiel_5: allQuestions.filter(question => question.index === String(fragennummerInput))[0].beispiel_5 ?? ""
      };

      setPrepareFrage(fragePreparation);
      console.log(fragePreparation);
      console.log(fragePreparation.level_1);
      
      setShowPopup(true);
    }
  };

  /**
   * Decrease the index of the Questions
   */
  const handleRadioChangeLocal = async (index, selectedValue) => {
    console.log("You pressed a Button");
    console.log(index);
    console.log(selectedValue);
    console.log(typeof (selectedLevels[index]));

    const currentQuestion = allQuestions.filter(question => question.index === String(fragennummer))[0];

    const body = {
      "bezugsdatum": selectedAssessment.bezugsdatum,
      "antwort": selectedValue.toString(),
      "antwort_text": currentQuestion[`level_${selectedValue}`],
      "levelident": 'ID' + String(currentQuestion.id) + ".DI00" + String(currentQuestion.dimension) + ".CA00" + String(currentQuestion.kategorie) + ".LV00" + String(selectedValue),
      "fragen_id": currentQuestion.id,
      "fragen_index": currentQuestion.index,
      "user_id": dataFetcher.getCookie("user_id"),
      "assessment_id": selectedAssessment.id,
      "spital": selectedAssessment.spital,
      "kommentar": currentQuestion.index,
      "effizienzgewinn_spital": currentQuestion[`effizienzgewinn_spital_${selectedValue}`],
      "effizinzgewinn_system": currentQuestion[`effizienzgewinn_system_${selectedValue}`],
      "umsetzungsaufwand_spital": currentQuestion[`umsetzungsaufwand_spital_${selectedValue}`],
      "umsetzungsaufwand_system": currentQuestion[`umsetzungsaufwand_system_${selectedValue}`],
      "kategorie": currentQuestion.kategorie,
      "dimension": currentQuestion.dimension
    };

    if (selectedLevels[index]) {
      console.log("IS SET");
      const idResponse = await dataFetcher.fetch_data_by_filter_when_equalto("Reifegradantworten", "id,fragen_index", "assessment_id", selectedAssessment.id);
      console.log(idResponse);

      const currentAnswer = idResponse.data.filter(item => item.fragen_index === String(index));
      console.log(currentAnswer);

      const updateBody = {
        "antwort": selectedValue.toString(),
        "antwort_text": currentQuestion[`level_${selectedValue}`],
        "levelident": 'ID' + String(currentQuestion.id) + ".DI00" + String(currentQuestion.dimension) + ".CA00" + String(currentQuestion.kategorie) + ".LV00" + String(selectedValue),
        "effizienzgewinn_spital": currentQuestion[`effizienzgewinn_spital_${selectedValue}`],
        "effizinzgewinn_system": currentQuestion[`effizienzgewinn_system_${selectedValue}`],
        "umsetzungsaufwand_spital": currentQuestion[`umsetzungsaufwand_spital_${selectedValue}`],
        "umsetzungsaufwand_system": currentQuestion[`umsetzungsaufwand_system_${selectedValue}`]
      }


      console.log("UPDATED ????");
      console.log(currentAnswer[0].id);
      console.log(updateBody);
      const response = await dataFetcher.updateData("Reifegradantworten", currentAnswer[0].id, updateBody);
      console.log(response);

      retrieveTreeData();
      setShowPopup(false);

    } else {
      console.log("IS NOT SET");
      // ------------------------------------------------ TODO: Not working correctly
      const postResponse = await dataFetcher.postData("Reifegradantworten", body);
      console.log(postResponse);

    }

    const selectedLevelsTMP = {
      [index]: selectedValue,
    };
    setSelectedLevels(selectedLevelsTMP);
  };

  // Helper function to find last children
  const findLastChildren = (node, lastChildrenSet) => {
    if (!node.children || node.children.length === 0) {
      lastChildrenSet.add(node.name);
    } else {
      node.children.forEach(child => findLastChildren(child, lastChildrenSet));
    }
  }

  // Populate the set with the names of the leaf nodes
  const lastChildrenSet = new Set();
  findLastChildren(treeData, lastChildrenSet);


  const updateQuestionnaireStats = (node, lastChildrenSet) => {
    let total = 0;
    let open = 0;

    const traverseAndUpdate = (currentNode) => {
      if (lastChildrenSet.has(currentNode.name)) {
        total += 1;
        if (currentNode.progress === "incomplete") {
          open += 1;
        }
      }

      if (currentNode.children) {
        currentNode.children.forEach(child => traverseAndUpdate(child));
      }
    };

    traverseAndUpdate(node);

    // Update the root node
    node.total_questions = total;
    node.open_questions = open;
  };

  // Now update the questionnaire stats
  updateQuestionnaireStats(treeData, lastChildrenSet);

  // Use the correct variable name for the context
  const contextValue = {
    leafNodesSet: lastChildrenSet,
    handleNodeClick
  };

  return (
    <TreeContext.Provider value={contextValue}>
      <div className="skill-tree">
        <div className="treeWrapper">
          {showTree && <Tree
            pathFunc="step"
            initialDepth={1}
            orientation="horizontal"
            allowForeignObjects={true}
            translate={{ x: 200, y: 400 }}
            separation={{ siblings: 0.6, nonSiblings: 0.5 }}
            nodeSize={{ x: 250, y: 160 }}
            enableLegacyTransitions={true}
            nodeLabelComponent={{
              render: <NodeLabel numberQuestions={questionNumber.length} selectedDate={currAssessmentDate} />,
              foreignObjectWrapper: {
                x: -120,
                y: -70,
              },
            }}
            data={treeData}
          />}
        </div>
        {showPopup &&
          <div className='fragen-background'>
            <div className='fragen-content-container'>
              <img src='/closeButton.svg' className="fragen-close" onClick={() => setShowPopup(false)} draggable={false} />
              <TreegraphFrage
                closeContent={setShowPopup}
                index={prepareFrage.fragen_index}
                frage={prepareFrage.frage}
                dimension={prepareFrage.dimension}
                kategorie={prepareFrage.kategorie}
                beispiel={prepareFrage.beispiel}
                antworttyp={"Level"}
                level_1={prepareFrage.level_1}
                level_2={prepareFrage.level_2}
                level_3={prepareFrage.level_3}
                level_4={prepareFrage.level_4}
                level_5={prepareFrage.level_5}
                beispiel_1={prepareFrage.beispiel_1}
                beispiel_2={prepareFrage.beispiel_2}
                beispiel_3={prepareFrage.beispiel_3}
                beispiel_4={prepareFrage.beispiel_4}
                beispiel_5={prepareFrage.beispiel_5}
                kommentar={prepareFrage.kommentar}
                selectedLevels={selectedLevels}
                handleRadioChangeLocal={handleRadioChangeLocal}
              />
            </div>
          </div>
        }
      </div>
    </TreeContext.Provider>
  );
};


/* NODE LABLE WIDGET */
const NodeLabel = ({ nodeData, numberQuestions, selectedDate }) => {
  const { leafNodesSet, handleNodeClick } = useContext(TreeContext);

  const isRootNode = nodeData.name === "Befragung"; // Identify if it's the root node
  const displayName = nodeData.name.length > 14 ? `${nodeData.name.substring(0, 14)}...` : nodeData.name;
  const questionText = nodeData.questions === 1 ? "Frage" : "Fragen";
  const borderColorClass = nodeData.progress === 'complete' ? 'node-complete' : 'node-incomplete';



  // Calculate the progress bar widths
  const totalQuestions = nodeData.total_questions || 0;
  const openQuestions = nodeData.open_questions || 0;
  const completedQuestions = totalQuestions - openQuestions;

  const openPercentage = Math.round((openQuestions / totalQuestions) * 100);
  const openQuestionsWidth = `${openPercentage}%`;
  const completedQuestionsWidth = `${completedQuestions}%`;

  const handleClick = () => {

    if (leafNodesSet.has(nodeData.name)) {
      handleNodeClick(nodeData, nodeData.fragennummer); // Pass the fragennummer attribute
    }
  };

  return isRootNode ? (
    <div className='root-container'>
      <div className='root-title'>
        Befragung
      </div>
      <div className='root-divider' />
      <div className='root-root-content'>
        <div className='root-element-title'>
          Anzahl Fragen: {numberQuestions}
        </div>
        <div className='root-element-content'>
          {selectedDate}
        </div>
        {/* <div className='root-element-number'>
            
          </div>
          <div className='root-element-progressbar'>
            <div className='root-element-progress-number'>
              +32
            </div>
            <div className='root-element-progressbar-container'>
              <div className='root-element-progressbar'/>
              <div className='root-element-progressbar-complete'/>
            </div>
          </div> */}
      </div>
    </div>
  ) : (

    <div className={`node-layout5 ${borderColorClass} ${isRootNode ? 'root-node-hidden' : 'child-node'}`} onClick={handleClick}>
      <div className="title5">
        <b className="befragung">{displayName}</b>
        <div className="dimension">{nodeData.questions} {questionText}</div>
      </div>
    </div>
  );
};

const TreeContext = React.createContext();





























/*



// Tree Graph used in Bewertung
const Popup = ({ node, onClose, fetchData, allAnswers, allQuestions, dimension, subDimensions, questionNumber, antowrtIds, reloadData, setTreeFalse, alleAntwortenFullInfo, selectedAssessment }) => {

  const databaseManager = new DataFetcher();
  // Here, data is the state variable that will hold the JSON data,
  // and setData is the function to update that state.
  const [data, setData] = useState(null);
  const [selectedValue, setSelectedValue] = useState(1);
  // let updateBody = { "levelident": "ID004.DI001.CA004.LV004"};

  // Handler to save the data
  const handleSave = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    // Implement your save logic here
    const indexOfClickedLeafNode = questionNumber.indexOf(String(node.fragennummer))

    const levelString = "level_" + selectedValue.toString();

    const currentQuestion = allQuestions[indexOfClickedLeafNode];
    const currentAnswer = alleAntwortenFullInfo.filter(item => item.fragen_index === currentQuestion.index)
    // console.log(currentAnswer);
    // console.log(alleAntwortenFullInfo);
    // console.log(selectedAssessment);
    // console.log(node.progress);

    if (node.progress == 'complete') {
      const updateBody = {
        "antwort": selectedValue.toString(),
        "levelident": 'ID' + String(currentQuestion.id) + ".DI00" + String(currentQuestion.dimension) + ".CA00" + String(currentQuestion.kategorie) + ".LV00" + String(selectedValue),
        "antwort_text": currentQuestion[levelString]
      }
      const resp_patch = await databaseManager.updateData("Reifegradantworten", currentAnswer[0].id, updateBody);

      console.log(resp_patch);
    } else {

      const effSpitalString = "effizienzgewinn_spital_" + selectedValue.toString();
      const effSysString = "effizienzgewinn_system_" + selectedValue.toString();
      const umSpitalString = "umsetzungsaufwand_spital_" + selectedValue.toString();
      const umSysString = "umsetzungsaufwand_system_" + selectedValue.toString();

      const body = {
        "bezugsdatum": selectedAssessment.bezugsdatum,
        "antwort": selectedValue.toString(),
        "antwort_text": currentQuestion[levelString],
        "levelident": 'ID' + String(currentQuestion.id) + ".DI00" + String(currentQuestion.dimension) + ".CA00" + String(currentQuestion.kategorie) + ".LV00" + String(selectedValue),
        "fragen_id": currentQuestion.id,
        "fragen_index": currentQuestion.index,
        "user_id": databaseManager.getCookie("user_id"),
        "assessment_id": selectedAssessment.id,
        "spital": selectedAssessment.spital,
        "kommentar": currentQuestion.index,
        "effizienzgewinn_spital": effSpitalString,
        "effizinzgewinn_system": effSysString,
        "umsetzungsaufwand_spital": umSpitalString,
        "umsetzungsaufwand_system": umSysString,
        "kategorie": currentQuestion.kategorie,
        "dimension": currentQuestion.dimension
      };
      const respt_patch = await databaseManager.postData("Reifegradantworten", body);
    }


    setTreeFalse(false);
    reloadData();
    // Optionally close the popup after save
    onClose();
  };

  // Inside the useEffect hook of the Popup component
  useEffect(() => {
    // Ensure that fragennummer is part of your nodeData and is not undefined.
    //console.log(allQuestions);
    fetchData(node.fragennummer, allAnswers, allQuestions, dimension, subDimensions, questionNumber).then(fetchedData => setData(fetchedData));
  }, [fetchData, node, allAnswers, allQuestions, dimension, subDimensions, questionNumber]); // node here should include the fragennummer

  // If data has not yet been fetched, we display a loading message.
  if (!data) {
    return <div>Loading...</div>;
  }

  // Define the handleOptionChange function within your component
  const handleOptionChange = (event) => {
    // Update the selected option in the state
    const selectedOption = parseInt(event.target.value, 10);
    setSelectedValue(selectedOption);
    // setData or use another state variable to keep track of the selected option
    // For example, if you're using a state variable called 'selectedOption':
    setData({ ...data, selectedOption });
  };

  // The main return statement of the component that renders the popup.
  // Inside your Popup component's JSX
  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>{data.title}</h2>
        <p>{data.question}</p>
        <div className="question-inbetween">Wie bewerten Sie Ihre digitale Reife?</div>
        <form className="popup-form" onSubmit={handleSave}>
          {data.answers.map((answer, index) => (
            <div className="form-group" key={index}>
              <input
                type="radio"
                id={`option${index + 1}`}
                name="singleChoice"
                value={index + 1}
                checked={data.selectedOption === index + 1}
                onChange={handleOptionChange} // This needs to be a real function
                aria-labelledby={`label${index + 1}`}
              />
              <label htmlFor={`option${index + 1}`} id={`label${index + 1}`}>{answer}</label>
            </div>
          ))}

          <div className="button-container">
            <button type="submit" className="save-button">Speichern</button>
            <button type="button" className="cancel-button" onClick={onClose}>Abbrechen</button>
          </div>
        </form>
      </div>
    </div>
  );
};



const fetchData = async (fragennummer, allAnswers, allQuestions, dimension, subDimensions, questionNumber) => {
  // const dataFetcher = new DataFetcher();
  // const response = await dataFetcher.fetch_data_by_filter_when_equalto("Fragen_Reifegrad", "Dimension,Frage,Level_1_Text,Level_2_Text,Level_3_Text,Level_4_Text,Level_5_Text","Fragennummer", String(fragennummer))
  // const response_data = response["data"][0];

  // Figure out the index with the correct clicked LeafNode
  const indexOfClickedLeafNode = questionNumber.indexOf(String(fragennummer))

  let selectedOption = 0;
  if (allAnswers.length != 0) {
    selectedOption = parseInt(allAnswers[indexOfClickedLeafNode].antwort.charAt(allAnswers[indexOfClickedLeafNode].length - 1), 10)
  }


  const questionIndexes = String(fragennummer).split(".").map(part => parseInt(part, 10));
  const answers = tripettoJson.sections[1].branches[1].sections[questionIndexes[0]].nodes[questionIndexes[1] - 1].block.buttons;


  const json = {
    "title": dimension[indexOfClickedLeafNode],
    "question": allQuestions[indexOfClickedLeafNode].frage,
    "answers": [
      answers[0].name,
      answers[1].name,
      answers[2].name,
      answers[3].name,
      answers[4].name
    ],
    "selectedOption": selectedOption
  }
  return json;
};






<Popup
  node={currentNode}
  onClose={() => setShowPopup(false)}
  fetchData={fetchData}
  allAnswers={allAnswers}
  allQuestions={allQuestions}
  dimension={dimensions}
  subDimensions={subDimensions}
  questionNumber={questionNumber}
  antowrtIds={antwortIdList}
  reloadData={retrieveTreeData}
  setTreeFalse={setShowTree}
  alleAntwortenFullInfo={alleAntwortenFullInfo}
  selectedAssessment={selectedAssessment} />



beispiel_1: "Eine isolierte Spitalstrategie mit wenigen Elementen aus dem Bereich ICT ist vorhanden. "
beispiel_2: "Im Spital ist eine isolierte Strategie für den ICT Bereich vorhanden."
beispiel_3: "Im Spital ist eine Digitalisierungsstrategie vorhanden, die über den ICT Bereich hinausgeht. Diese ist nicht integraler Teil der Spitalstrategie."
beispiel_4: null
beispiel_5: "Roadmap und ein gemanagetes Projektportfolio mit klaren Verantwortlichkeiten zur Umsetzung vorhanden; SMARTE Ziele der Digitalstrategie werden kontinuierlich überwacht und weiterentwickelt"
beispiele: null
date_created: "2024-02-01T17:37:59.993Z"
date_updated: null
dimension: 1
effizienzgewinn_spital_1: "0"
effizienzgewinn_spital_2: "0,0003"
effizienzgewinn_spital_3: "0,0024"
effizienzgewinn_spital_4: "0,001"
effizienzgewinn_spital_5: "0,0014"
effizienzgewinn_system_1: "0"
effizienzgewinn_system_2: "0,0012"
effizienzgewinn_system_3: "0,0026"
effizienzgewinn_system_4: "0,0038"
effizienzgewinn_system_5: "0,0024"
frage: "Strategie:\nInwieweit hat das Spital eine umfassende digitale Strategie, welche integraler Teil der Spitalstrategie ist und mit den Zielen und der Vision des Spitals übereinstimmt?"
id: 238
index: "1.1"
kategorie: 1
level_1: "Es gibt keine strategische Ausrichtung im Bereich der Digitalisierung sowie keine ICT-Strategie, welche über die gesetzlichen Mindestanforderungen hinausgeht."
level_2: "Eine isolierte ICT Strategie ist vorhanden. Es ist keine Digitalisierungsstrategie vorhanden."
level_3: "Eine Digitalisierungsstrategie ist vorhanden. Diese ist nicht mit der übergeordneten Geschäftsstrategie (Corporate Strategy) verknüpft"
level_4: "Eine spitalweite Geschäftsstrategie mit Digitalisierungsthemen vorhanden. Diese ist nicht aktuell und wird nicht aktiv gelebt"
level_5: "Eine Geschäftsstrategie ist integral mit Digitalisierungselementen verknüpft. Diese wird stetig überwacht und weiterentwickelt."
sort: null
status: "draft"
umsetzungsaufwand_spital_1: "0"
umsetzungsaufwand_spital_2: "0,000666667"
umsetzungsaufwand_spital_3: "0,001"
umsetzungsaufwand_spital_4: "0,001"
umsetzungsaufwand_spital_5: "0,001333333"
umsetzungsaufwand_system_1: "0"
umsetzungsaufwand_system_2: "0,0005"
umsetzungsaufwand_system_3: "0,0015"
umsetzungsaufwand_system_4: "0,003"
umsetzungsaufwand_system_5: "0,001666667"
*/