import React from 'react';
import "../widgets/TripettoWidget.css";
import { useState, useEffect } from "react";

export const TripettoWidget = ({ tripettoLink, isOverlayVisible, toggleOverlay, userId, spital, groupAssessmentName }) => {
    const link = tripettoLink.replace(" ", "%20");
    return (
    <div>
        {isOverlayVisible && (
        <div className="overlay-tripetto">
            <div className="overlay-iframe-container">
            {<iframe className="overlay-iframe-1" src={link} title="Overlay Content"/>}
            <button className="overlay-close-btn" onClick={() => toggleOverlay()}></button>
            </div>
        </div>
        )}
    </div>
    );
};





/*
import React, { useState, useEffect } from 'react';
import { AutoscrollRunner } from "@tripetto/runner-autoscroll";
import { Import } from "@tripetto/runner";

export const TripettoWidget = ({ isOverlayVisible, toggleOverlay, userId, spital, groupAssessmentName }) => {
    const [runner, setRunner] = useState(null);

    useEffect(() => {
        // Read the definition from the JSON file
        const definitionJSON = require('../widgets/Tripetto_Fragebogen.json');


        // Create a new Import instance and import the definition
        const importer = new Import();
        importer.addJSON(definitionJSON);

        // Initialize AutoscrollRunner with the imported definition
        const autoscrollRunner = new AutoscrollRunner(importer);

        // Define values using onImport function
        autoscrollRunner.onImport(({ context, node }) => {
            if (node.key === "Intro / User") {
                return {
                    name: "Intro / User",
                    value: userId,
                };
            } else if (node.key === "b1d80ab4dfe3ea09364b1ce52116b5eb1623ae23527d311de9cea115e4d61636") {
                return {
                    key: "b1d80ab4dfe3ea09364b1ce52116b5eb1623ae23527d311de9cea115e4d61636",
                    value: spital,
                };
            } else if (node.key === "7ed69cd7de47dd3a01dad6b83830df6fb47386a2bb8084d3abc12cb4fe4521b6") {
                return {
                    key: "7ed69cd7de47dd3a01dad6b83830df6fb47386a2bb8084d3abc12cb4fe4521b6",
                    value: groupAssessmentName,
                };
            }

            return null;
        });

        // Set the runner to state
        setRunner(autoscrollRunner);

        // Cleanup function
        return () => {
            if (autoscrollRunner) {
                autoscrollRunner.destroy();
            }
        };
    }, [userId, spital, groupAssessmentName]);

    return (
        <div>
            {isOverlayVisible && runner && (
                <div className="overlay-tripetto">
                    <div className="overlay-iframe-container">
                        <AutoscrollRunner runner={runner} />
                        <button className="overlay-close-btn" onClick={() => toggleOverlay()}></button>
                    </div>
                </div>
            )}
        </div>
    );
};*/



/*import React from 'react';
import "../widgets/TripettoWidget.css";
import { useState, useEffect } from "react";
import { AutoscrollRunner } from "@tripetto/runner-autoscroll"; // Import the run module
import { run } from "@tripetto/runner-autoscroll";
import { Export, Import } from "@tripetto/runner";

export const TripettoWidget = ({ isOverlayVisible, toggleOverlay, userId, spital, groupAssessmentName }) => {
    const [runnerInstance, setRunnerInstance] = useState(null);

    useEffect(() => {
        const fetchDefinition = async () => {
            try {
                const response = await fetch("./classes/Tripetto_Fragenboegen.json");
                const definition = await response.json();

                run({
                    customCSS: `
                        [data-block="@tripetto/block-text"] { background-color: blue; }
                        [data-block="@tripetto/block-checkbox"] {
                            input {
                                background-color: red;
                            }
                        }
                    `,
                    onImport: (instance) => {
                        Import.fields(instance, [
                            {
                                name: "Intro / User",
                                value: userId,
                            },
                            {
                                key: "b1d80ab4dfe3ea09364b1ce52116b5eb1623ae23527d311de9cea115e4d61636",
                                value: spital,
                            },
                            {
                                key: "7ed69cd7de47dd3a01dad6b83830df6fb47386a2bb8084d3abc12cb4fe4521b6",
                                value: groupAssessmentName,
                            }
                        ]);
                    },
                    definition: definition,
                });
            } catch (error) {
                console.error("Error fetching or parsing JSON:", error);
            }
        };

        if (isOverlayVisible) {
            fetchDefinition();
        }
    }, [isOverlayVisible, userId, spital, groupAssessmentName]);

    return (
        <div>
            {isOverlayVisible && (
                <div className="overlay-tripetto">
                    <div className="overlay-iframe-container">
                        <AutoscrollRunner 
                            onSubmit={(form) => {
                                console.log(Export.NVPs(form));
                            }}
                            onReady={(instance) => {
                                setRunnerInstance(instance); // Save the runner instance
                            }}
                            display="page"
                            
                    />              
                    {/*<iframe className="overlay-iframe" src={overlayContentLink} title="Overlay Content" />}
                    <button className="overlay-close-btn" onClick={() => toggleOverlay()}></button>
                </div>
            </div>
        )}
        </div>
    );
};

*/


