import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';  // Correct import statement
import 'react-toastify/dist/ReactToastify.css';
import DataFetcher from "./databaseManager";

// Initialize the data fetcher instance
const dataFetcher = new DataFetcher();

/**
 * Manages user authentication, including login, token validation, and logout functionalities.
 */
class AuthentificationManager {

  // Base URLs for the authentication service
  base_url = 'https://be.digitalerreifegrad.ch';
  base_url_auth = this.base_url + "/auth";

  // Common headers for requests
  headers = new Headers();

  // Navigation function from react-router-dom
  navigate = useNavigate();

  /**
   * Retrieves the base URL of the authentication service.
   * @returns {string} The base URL.
   */
  getbaseURL() { return this.base_url; }


  /**
   * Asynchronously logs in a user by sending a request to the login API endpoint.
   * @param {string} email - The user's email.
   * @param {string} password - The user's password.
   * @returns {Promise<Object>} - Returns a Promise that resolves to the JSON response with an additional "valid" parameter.
   * Example:
   * const authentificationManager = new AuthentificationManager();
   * const response = await authentificationManager.login_User(in_user, in_pw);
   */
  async login_User(email, password) {
    const cookiemaxtime = 90000000;
    const loginSuccessMessage = "Anmeldung erfolgreich.";
    const loginFailedMessage = "Oops! Deine Anmeldedaten sind fehlerhaft!";
    const serverError = "Server Probleme! Versuchen Sie es später noch einmal.";
    const url = this.base_url_auth + "/login";
    const body = {
      "email": email,
      "password": password
    };

    //try {
    // Send a POST request to the login API endpoint
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    // Check if the response is valid
    if (response.ok) {

      // // Parse JSON response and get user tokens
      const json_resp = await response.json();

      // Notify success and store tokens in cookies
      toast.success(loginSuccessMessage, { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
      this.setCookie('dhi_auth_token', json_resp.data.access_token, json_resp.data.expires);
      this.setCookie('dhi_refresh_token', json_resp.data.refresh_token, json_resp.data.expires);

      // Fetch and store user data in cookies
      const resp_user = await dataFetcher.fetch_user_data('id,first_name,last_name,avatar,email,organisation', email);
      this.setCookie('user_id', resp_user['data'][0]['id'], cookiemaxtime);
      this.setCookie('first_name', resp_user['data'][0]['first_name'], cookiemaxtime);
      this.setCookie('last_name', resp_user['data'][0]['last_name'], cookiemaxtime);

      let avatarValue = "";
      if (resp_user['data'][0]['avatar'] === null) {
        avatarValue = "56fee9d6-254d-418a-8cf8-9e60a8d94be7";
      }
      else {
        avatarValue = resp_user['data'][0]['avatar'];
      }
      this.setCookie('img_id', avatarValue, cookiemaxtime);

      // Handle additional user data
      if (resp_user == -1) {
        this.setCookie('spital_Name', "VZK - Zürich", cookiemaxtime);
      }
      else {
        try {
          this.setCookie('spital_Name', resp_user.data[0].organisation, cookiemaxtime);
        } catch {
          this.setCookie('spital_Name', "VZK - Zürich", cookiemaxtime);
        }
      }
      // Return successful response
      return { valid: 1, data: json_resp.data };
    }

    // Handle invalid response
    else if (response.status === 401 || response.status === 400) {
      const json_resp = await response.json();
      toast.error(loginFailedMessage, { autoClose: 5000, position: toast.POSITION.BOTTOM_CENTER });
      return { valid: 2, errors: json_resp.errors };
    } else {
      toast.error(loginFailedMessage, { autoClose: 5000, position: toast.POSITION.BOTTOM_CENTER });
      throw new Error('Login failed');
    }
    /*} catch (error) {
      // Handle network or server errors
      toast.error(serverError, { autoClose: 5000, position: toast.POSITION.BOTTOM_CENTER });
      console.error("Error during login with email and password");
      return { valid: 0 };
    }*/
  }

  /**
   * Checks if the login token cookie exists and is valid. 
   * If valid, refreshes the token and updates the cookie.
   * If not, redirects to the login page and shows a notification.
   * @returns {Promise<void>}
   * Example:
   * const authentificationManager = new AuthentificationManager();
   * // Check for the presence of "auth_token" cookie when the component is mounted
     useEffect(() => {
      authentificationManager.checkAndRefreshToken();
     }, []);
   */
  async checkAndRefreshToken() {
    const tokenExpiredMessage = "Session vorbei. Melden Sie sich erneut an!";
    const tokenNotFoundMessage = "Sie wurden Abgemeldet.";
    const serverError = "Server Probleme! Melden Sie sich bitte nochmal an.";

    const user_name = this.getCookie("user_id");
    if(!user_name) {
      this.logout();
      this.naviToLoginPage();
      return;
    }


    // Retrieve the authentication and refresh tokens from cookies
    const authToken = this.getCookie('dhi_auth_token');
    const refreshToken = this.getCookie('dhi_refresh_token');

    // Check for the existence of both tokens
    if (!authToken || !refreshToken) {
      // Redirect to login page and show a notification if any token is missing
      this.naviToLoginPage();
      toast.info(tokenNotFoundMessage, { autoClose: 2000, position: toast.POSITION.BOTTOM_CENTER });
      return;
    }

    // Construct URL for the token refresh endpoint
    const refreshUrl = this.base_url_auth + "/refresh";
    const refreshBody = {
      "refresh_token": refreshToken,
      "mode": "json"
    };

    // Attempt to refresh the token
    const refreshResponse = await fetch(refreshUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(refreshBody),
    });

    if (refreshResponse.ok) {
      // Process a successful response
      const refreshJson = await refreshResponse.json();
      // Save the new token and refresh token in cookies
      this.setCookie('dhi_auth_token', refreshJson.data.access_token, refreshJson.data.expires);
      this.setCookie('dhi_refresh_token', refreshJson.data.refresh_token, refreshJson.data.expires);
      return true;
    } 
    else if(refreshResponse.status == 401){
      // If refresh fails, redirect to the base URL and display a toast notification
      if (window.location.href === window.location.origin) {
        console.log("REFRESH NOT OK");
        return false;
      }

      if (!refreshToken) {
        this.naviToLoginPage();
        toast.error(tokenExpiredMessage, { autoClose: 5000, position: toast.POSITION.BOTTOM_CENTER });
      }
    }
    else{
      console.log("Server error --> conntect server admin");
    }

    // Handle errors in the token refresh process
    //console.error("Error during token refresh");
    // If an error occurs, redirect to the base URL and display a toast notification
    //this.naviToLoginPage()
    //toast.error(serverError, { autoClose: 5000, position: toast.POSITION.BOTTOM_CENTER });
  }

  /**
   * Sets a cookie with the given name and value.
   * @param {string} name - The name of the cookie.
   * @param {string} value - The value of the cookie.
   * @param {number} [millis] - Optional. The number of milliseconds until the cookie expires.
   * Example:
   * const authentificationManager = new AuthentificationManager();
   * authentificationManager.setCookie("GesamtZiel",GesamtZiel,30000000);
   */
  setCookie(name, value, millis) {
    const expirationDate = new Date();
    if (millis) {
      expirationDate.setTime(expirationDate.getTime() + millis);
    }
    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
  }

  /**
   * Gets the value of a cookie with the given name.
   * @param {string} name - The name of the cookie.
   * @returns {string|null} - The value of the cookie, or null if the cookie is not found.
   * Example:
   * const authentificationManager = new AuthentificationManager();
   * const user_id = authentificationManager.getCookie('user_id');
   */
  getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName && cookieName === name) {
        return cookieValue;
      }
    }
    if(name === "user_id" || name === "dhi_auth_token" || name === "dhi_refresh_token" || name === "spital_Name") {
      this.naviToLoginPage()
    }
    return null;
  }

  /**
   * Deletes a cookie with the given name.
   * @param {string} name - The name of the cookie to delete.
   */
  deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  /**
  * Logs out the user by deleting the authentication and refresh tokens.
  */
  logout() {
    this.deleteCookie("dhi_auth_token");
    this.deleteCookie("dhi_refresh_token");
    this.naviToLoginPage();
  }

  /**
 * Redirects to the base URL if the user is not already there.
 */
  naviToLoginPage() {
    // Check if the current URL is already the base URL
    const isOnBaseURL = window.location.pathname === '/';

    if (!isOnBaseURL) {
      // Redirect to the base URL
      window.location.href = window.location.origin;
    }
  }
}

export default AuthentificationManager;
